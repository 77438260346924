import { createAction, handleActions } from 'redux-actions'
import * as globalMessageActions from '../global-message'
import { v4 as uuidv4 } from 'uuid'
import api from '../api'

const QUERY_TRANSACTION_ID_SUCCESS = createAction(
  'query transaction id success'
)

const PROCESS_CARD_PAYMENT = createAction('process card payment')
const PROCESS_CARD_PAYMENT_SUCCESS = createAction(
  'process card payment success'
)
const PROCESS_CARD_PAYMENT_ERROR = createAction('process card payment error')
const RESET_CARD_PAYMENT_REDIRECT = createAction('reset iframe popup')

const QUERY_TERMINALS_SUCCESS = createAction('fetch payment terminals success')

const initialState = {
  loading: false,
  paymentSucceded: null,
  message: null,
  embeddedUrl: null,
  terminals: []
}

// handlers
export default handleActions(
  {
    [QUERY_TRANSACTION_ID_SUCCESS]: (state, action) => {
      return {
        ...state,
        embeddedUrl: action.payload.data.embeddedUrl
      }
    },
    [PROCESS_CARD_PAYMENT]: state => {
      return { ...state, loading: true }
    },
    [PROCESS_CARD_PAYMENT_SUCCESS]: state => {
      return { ...state, loading: false, paymentSucceded: true }
    },
    [PROCESS_CARD_PAYMENT_ERROR]: state => {
      return { ...state, loading: false, paymentSucceded: false }
    },
    [RESET_CARD_PAYMENT_REDIRECT]: state => {
      return { ...state, embeddedUrl: null }
    },
    [QUERY_TERMINALS_SUCCESS]: (state, action) => {
      return { ...state, terminals: action.payload }
    }
  },
  initialState
)

// thunks
export const submitCardTransaction = (
  reservationId,
  amount,
  currencyCode,
  isConference,
  marketId,
  reservationCode,
  customerInfo,
  terminalId
) => {
  return dispatch => {
    let id = uuidv4()
    isConference = !!isConference
    const model = {
      id,
      marketId,
      amount,
      currencyId: currencyCode,
      reservationId,
      reservationCode,
      paymentType: 'CreditCard',
      isConference: isConference,
      redirectUrl: `${window.location.protocol}//${window.location.host}/booking/reservations/${reservationId}`,
      customerInfo,
      terminalId
    }

    return api.sendCommand('altapay/initiatepayment', model).then(res => {
      dispatch(queryTransactionId(id))
    })
  }
}

export const queryTransactionId = id => {
  return dispatch => {
    return api
      .query('travelbooking/cardPaymentTransaction/transactionId', { id })
      .then(res => {
        dispatch(QUERY_TRANSACTION_ID_SUCCESS(res))
      })
  }
}

export function submitProcessCardPayment (
  id,
  transactionId,
  responseCode,
  errorMessage
) {
  return function (dispatch) {
    if (responseCode === 'Ok') {
      dispatch(PROCESS_CARD_PAYMENT_SUCCESS())
      setTimeout(
        () =>
          dispatch(globalMessageActions.setGlobalSuccess('Payment succeded')),
        300
      )
    } else {
      dispatch(PROCESS_CARD_PAYMENT_ERROR())
      setTimeout(
        () =>
          dispatch(
            globalMessageActions.setGlobalError(
              `Payment failed: ${errorMessage}`
            )
          ),
        300
      )
    }
  }
}

export function resetShowIframe () {
  return function (dispatch) {
    dispatch(RESET_CARD_PAYMENT_REDIRECT())
  }
}

export function queryTerminals (marketId, useTestTerminals, isConference = false) {
  return function (dispatch) {
    return api
      .query('altapay/terminals', {
        marketId,
        useBackOfficeTerminal: true,
        useTestTerminals,
        isConference
      })
      .then(resp => {
        dispatch(QUERY_TERMINALS_SUCCESS(resp.data))
      })
  }
}
