import { createAction, handleActions } from 'redux-actions'
import api from '../api'

export const SET_UPCOMING_FLIGHT_RELEASES_ACTION = 'set flights to release';

const initialState = {
    flightsWithUpcomingReleases: []
}

const SET_FLIGHTS_WITH_UPCOMING_RELEASES = createAction(SET_UPCOMING_FLIGHT_RELEASES_ACTION)


export default handleActions({
    [SET_FLIGHTS_WITH_UPCOMING_RELEASES]: (state, action) => {
        return { ...state, flightsWithUpcomingReleases: [...action.payload] }
    }
},
    initialState)

export const queryFlightReleases = () => {
    return dispatch => {
        api.query('flightdeparture/contract/flightreleases')
            .then(result => dispatch(SET_FLIGHTS_WITH_UPCOMING_RELEASES(result.data)))
    }
}