import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_REPORT = createAction('query travel insurances')

const SET_DEPARTURE_DATE_FROM = createAction('accounts travel insurances set departure date from')
const SET_DEPARTURE_DATE_TO = createAction('accounts travel insurances set departure date to')

const initialState = {
  selectedDepartureDateFrom: moment().startOf('day'),
  selectedDepartureDateTo: moment().endOf('day'),
  selectedMarket: "",
  loading: false,
  item: ''
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, item: action.payload }
    },
    [SET_DEPARTURE_DATE_FROM]: (state, action) => {
      return { ...state, selectedDepartureDateFrom: moment(action.payload.item) }
    },
    [SET_DEPARTURE_DATE_TO]: (state, action) => {
      return { ...state, selectedDepartureDateTo: moment(action.payload.item) }
    },
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    var departureDateFrom = getState().economy.travelInsurances.selectedDepartureDateFrom
    var departureDateTo = getState().economy.travelInsurances.selectedDepartureDateTo.endOf('day')
    var market = getState().market.marketSelect.active

    if (departureDateFrom && departureDateTo && market) {
      api
        .query('travelbooking/reports/travelInsurances', {
          departureDateFrom,
          departureDateTo,
          marketId: market.id
        })
        .then(json => dispatch(QUERY_REPORT(json.data)))
    }
  }
}

export const setDepartureDateFrom = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_DATE_FROM({ item }))
  }
}

export const setDepartureDateTo = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_DATE_TO({ item }))
  }
}
