import { createAction, handleActions } from 'redux-actions'
import * as globalMessageActions from '../../global-message'
import api from '../../api'
import { weekdays, findWeekday } from '../../../common/enums/weekdays'

const QUERY_ADDON_CONTRACT_SUCCESS = createAction(
  'query addon contract success'
)
const SET_LOADING = createAction('set addon contract request in progress')
const SET_COMMAND_SUCCESS = createAction(
  'addon contract command executed successfully'
)
const SET_COMMAND_ERROR = createAction('addon contract command failed')

const initialState = {
  edit: null,
  loading: false,
  contract: null
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_ADDON_CONTRACT_SUCCESS]: (state, action) => {
      let contract = action.payload
      contract.articles = contract.articles.map(a => ({
        ...a,
        day: findWeekday(a.dayOfWeek),
        endDay: findWeekday(a.endDayOfWeek)
      }))
      return { ...state, loading: false, contract: contract }
    },
    [SET_LOADING]: (state, action) => {
      return { ...state, loading: true }
    },
    [SET_COMMAND_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [SET_COMMAND_ERROR]: (state, action) => {
      return { ...state, loading: false }
    }
  },
  initialState
)

export const queryAddonContract = id => {
  return dispatch => {
    dispatch(SET_LOADING())
    return api.query('destination/addonContract/contract', { id }).then(res => {
      dispatch(QUERY_ADDON_CONTRACT_SUCCESS(res.data))
    })
  }
}

export const submitUpdateAddonContract = contract => {
  return dispatch => {
    dispatch(SET_LOADING())
    let command = api.sendCommand(
      'destination/addonContract/updateContract',
      contract
    )
    command
      .then(result => {
        dispatch(SET_COMMAND_SUCCESS())
        dispatch(queryAddonContract(contract.id))
        dispatch(globalMessageActions.setGlobalSuccess(`Contract updated`))
      })
      .catch(() => dispatch(SET_COMMAND_ERROR()))
    return command
  }
}

export const submitAddArticle = article => {
  article.dayOfWeek = article.day
    ? weekdays.find(w => w.value === article.day).label
    : null
  article.endDayOfWeek = article.endDay
    ? weekdays.find(w => w.value === article.endDay).label
    : null
  return dispatch => {
    dispatch(SET_LOADING())
    return api
      .sendCommand('destination/addonContract/addArticle', article)
      .then(() => {
        dispatch(queryAddonContract(article.contractId))
        dispatch(SET_COMMAND_SUCCESS())
        dispatch(
          globalMessageActions.setGlobalSuccess('Article added to contract')
        )
      })
      .catch(() => dispatch(SET_COMMAND_ERROR()))
  }
}

export const submitUpdateArticle = article => {
  article.dayOfWeek = article.day
    ? weekdays.find(w => w.value === article.day).label
    : null
  article.endDayOfWeek = article.endDay
    ? weekdays.find(w => w.value === article.endDay).label
    : null
  return dispatch => {
    dispatch(SET_LOADING())
    let command = api.sendCommand(
      'destination/addonContract/updateArticle',
      article
    )
    command
      .then(result => {
        dispatch(SET_COMMAND_SUCCESS())
        dispatch(queryAddonContract(article.contractId))
        dispatch(globalMessageActions.setGlobalSuccess('Article updated'))
      })
      .catch(() => dispatch(SET_COMMAND_ERROR()))
    return command
  }
}

export const submitRemoveArticle = (contractId, articleId) => {
  return dispatch => {
    dispatch(SET_LOADING())
    const command = { contractId: contractId, id: articleId }
    return api
      .sendCommand('destination/addonContract/removeArticle', command)
      .then(result => {
        dispatch(SET_COMMAND_SUCCESS())
        dispatch(queryAddonContract(contractId))
        dispatch(globalMessageActions.setGlobalSuccess('Article removed'))
      })
      .catch(() => dispatch(SET_COMMAND_ERROR()))
  }
}

export const submitCopyAddonContract = command => {
  return dispatch => {
    dispatch(SET_LOADING())
    let cmd = api.sendCommand('destination/addonContract/copyContract', command)
    cmd
      .then(() => {
        dispatch(SET_COMMAND_SUCCESS())
        dispatch(globalMessageActions.setGlobalSuccess('Contract copied'))
      })
      .catch(() => dispatch(SET_COMMAND_ERROR()))
    return cmd
  }
}

export const deleteContract = command => {
  return dispatch => {
    dispatch(SET_LOADING())
    return api
      .sendCommand('destination/addonContract/deleteContract', command)
      .then(() => {
        dispatch(SET_COMMAND_SUCCESS)
        dispatch(globalMessageActions.setGlobalSuccess('Contract deleted'))
      })
      .catch(() => dispatch(SET_COMMAND_ERROR()))
  }
}
