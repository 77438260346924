import moment from 'moment-timezone'

export function mapSeatsData (airportId, name, color, prop, data) {
  return {
    name,
    data: data.map((x, i) => {
      const date = moment(x.departureDate)
      const week = date.isoWeek()

      return {
        y: x[prop],
        // id: `${name}-${i}`,
        name: `${date.format('YYYY-MM-DD')}`,
        week,
        date,
        drilldown: true,
        airportId
      }
    }),
    stack: 'Seats',
    color
  }
}

export function mapBedData (airportId, name, color, prop, data) {
  return {
    name,
    data: data.map((x, i) => {
      const date = moment(x.checkinDate)
      const week = date.isoWeek()

      return {
        y: x[prop],
        // id: `${name}-${i}`,
        name: `${date.format('YYYY-MM-DD')}`,
        week,
        date,
        drilldown: true,
        airportId
      }
    }),
    stack: 'Beds',
    color
  }
}

export function mapOriginSeatsData (name, color, prop, originAirports, airportId, date) {
  return {
    name,
    data: originAirports.map(airport => {
      return {
        y: airport[prop],
        name: airport.originAirportId,
        drilldown: true,
        originAirportId: airport.originAirportId,
        airportId: airportId,
        date
      }
    }),
    stack: 'Seats_Origin',
    color
  }
}

export function mapFlightSeatsData (name, color, prop, flights) {
  return {
    name,
    data: flights.map(flight => {
      return {
        y: flight[prop],
        name: flight.flightNumber
      }
    }),
    stack: 'Seats_Flight',
    color
  }
}

export function mapBedDestinationsData (name, color, prop, selectedWeek, airportId, date) {
  return {
    name,
    data: selectedWeek.map(dest => {
      return {
        y: dest[prop],
        name: dest.destinationId,
        drilldown: true,
        destinationId: dest.destinationId,
        airportId: airportId,
        date: date
      }
    }),
    stack: 'Beds_Destinations',
    color
  }
}

export function mapBedHotelsData (name, color, prop, selectedDestination, airportId, destinationId, date) {
  return {
    name,
    data: selectedDestination.map(hotel => {
      return {
        y: hotel[prop],
        name: hotel.hotelId,
        drilldown: true,
        hotelId: hotel.hotelId,
        airportId,
        destinationId,
        date
      }
    }),
    stack: 'Beds_Hotels',
    color
  }
}

export function mapBedHotelBedsData (name, color, prop, selectedHotel, airportId, destinationId, hotelId, date) {
  return {
    name,
    data: selectedHotel.map(roomType => {
      return {
        y: roomType[prop],
        name: roomType.roomCategoryId,
        airportId,
        destinationId,
        hotelId,
        date
      }
    }),
    stack: 'Beds_Hotel_Beds',
    color
  }
}
