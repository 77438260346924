import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_REPORT = createAction('query bus connection list')

const SET_DESTINATION_ID = createAction('bus connection list destination id')
const SET_DEPARTURE_CITY = createAction('bus connection list departure city')
const SET_DATE = createAction('bus connection list date')

const initialState = {
  selectedDestinationId: 'BAD',
  selectedDepartureCity: 'Malmö',
  selectedDate: moment(),
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload.item }
    },
    [SET_DEPARTURE_CITY]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload.item }
    },
    [SET_DATE]: (state, action) => {
      return { ...state, selectedDate: moment(action.payload.item) }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    var destinationId = getState().production.busConnections.selectedDestinationId
    var departureCity = getState().production.busConnections.selectedDepartureCity
    var date = getState().production.busConnections.selectedDate

    if (date) {
      date = moment(date).startOf('day')
    }

    if (destinationId && departureCity && date) {
      api
        .query('travelbooking/reports/BusConnections', { destinationId, departureCity, date })
        .then(json => dispatch(QUERY_REPORT(json.data)))
    }
  }
}

export const setDestinationId = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_ID({ item }))
  }
}

export const setDepartureCity = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_CITY({ item }))
  }
}

export const setDate = item => {
  return dispatch => {
    dispatch(SET_DATE({ item }))
  }
}
