import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'
import * as globalMessageActions from '../global-message'
import { uniqueByTwoDates } from '../../common/group-by'

const QUERY_CHARTER_PERIODS = createAction('query hotel charter periods')
const QUERY_CHARTER_PERIODS_SUCCESS = createAction(
  'query hotel charter periods success'
)
const QUERY_GROUPED_CHARTER_PERIODS = createAction(
  'query grouped hotel charter periods'
)
const QUERY_GROUPED_CHARTER_PERIODS_SUCCESS = createAction(
  'query grouped hotel charter periods success'
)
const SET_SELECTED_DESTINATION_ID = createAction(
  'set selected destination id hotel pricing charter period'
)
const SET_SELECTED_HOTEL_ID = createAction(
  'set selected hotel id hotel pricing charter period'
)

const SET_ACTIVE_CHARTER_PERIOD = createAction(
  'set active hotel charter period'
)
const SET_VALUE = createAction('set active hotel charter period value')

const SET_ACTIVE_EDIT_CHARTER_PERIOD = createAction(
  'set active edit hotel charter period'
)
const SET_EDIT_VALUE = createAction(
  'set active edit hotel charter period value'
)

const initialState = {
  groupedItems: [],
  items: [],
  allGroupedItems: [],
  selectedDestinationId: null,
  selectedHotelId: null,
  active: null,
  activeEdit: null,
  loading: false
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_CHARTER_PERIODS]: (state, action) => {
      return { ...state, items: [], groupedItems: [], loading: true }
    },
    [QUERY_CHARTER_PERIODS_SUCCESS]: (state, action) => {
      let items = action.payload.map(cp => {
        return { checkinDate: cp.checkinDate, checkoutDate: cp.checkoutDate }
      })

      let groupedItems = uniqueByTwoDates(items, 'checkinDate', 'checkoutDate')

      return {
        ...state,
        items: action.payload,
        groupedItems: groupedItems,
        loading: false
      }
    },
    [QUERY_GROUPED_CHARTER_PERIODS]: (state, action) => {
      return { ...state, allGroupedItems: [], loading: true }
    },
    [QUERY_GROUPED_CHARTER_PERIODS_SUCCESS]: (state, action) => {
      return { ...state, allGroupedItems: action.payload, loading: false }
    },
    [SET_ACTIVE_CHARTER_PERIOD]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    },
    [SET_ACTIVE_EDIT_CHARTER_PERIOD]: (state, action) => {
      return { ...state, activeEdit: action.payload }
    },
    [SET_EDIT_VALUE]: (state, action) => {
      return {
        ...state,
        activeEdit: {
          ...state.activeEdit,
          [action.payload.name]: action.payload.value
        }
      }
    },
    [SET_SELECTED_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload }
    },
    [SET_SELECTED_HOTEL_ID]: (state, action) => {
      return { ...state, selectedHotelId: action.payload }
    }
  },
  initialState
)

export const queryCharterPeriods = (
  destinationId,
  hotelId = null,
  fromDate = null,
  toDate = null
) => {
  return (dispatch, state) => {
    destinationId =
      destinationId || state().charterPeriods.hotel.selectedDestinationId
    hotelId = hotelId || state().charterPeriods.hotel.selectedHotelId

    dispatch(QUERY_CHARTER_PERIODS())

    return api
      .query('hotel/charterperiod/charterPeriods', {
        destinationId,
        hotelId,
        fromDate,
        toDate,
        sortDescending: true
      })
      .then(res => {
        dispatch(QUERY_CHARTER_PERIODS_SUCCESS(res.data))
        return res.data.charterPeriods
      })
  }
}

export const queryGroupedCharterPeriods = (destinationId, hotelId = '') => {
  return dispatch => {
    dispatch(QUERY_GROUPED_CHARTER_PERIODS())

    return api
      .query('hotel/charterPeriod/groupedcharterperiods', {
        destinationId,
        hotelId
      })
      .then(res => {
        dispatch(QUERY_GROUPED_CHARTER_PERIODS_SUCCESS(res.data))
        return res.data.charterPeriods
      })
  }
}

export const setActiveCharterPeriod = charterPeriod => {
  return dispatch => {
    dispatch(SET_ACTIVE_CHARTER_PERIOD(charterPeriod))
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const setActiveEditCharterPeriod = charterPeriod => {
  return dispatch => {
    dispatch(SET_ACTIVE_EDIT_CHARTER_PERIOD(charterPeriod))
  }
}

export const setEditValue = (value, name) => {
  return dispatch => {
    dispatch(SET_EDIT_VALUE({ value, name }))
  }
}

export const setSelectedDestination = value => {
  return dispatch => {
    dispatch(SET_SELECTED_DESTINATION_ID(value))
  }
}

export const setSelectedHotel = value => {
  return dispatch => {
    dispatch(SET_SELECTED_HOTEL_ID(value))
  }
}

export const submitAddCharterPeriods = commandList => {
  return dispatch => {
    let commands = commandList.map(command => {
      return api.sendCommand('hotel/charterPeriod/addCharterPeriod', command)
    })
    let all = Promise.all(commands)

    all.then(res => {
      dispatch(
        queryCharterPeriods(
          commandList[0].destinationId,
          commandList[0].hotelId
        )
      )
      dispatch(globalMessageActions.setGlobalSuccess('Charter periods added'))
      dispatch(SET_ACTIVE_CHARTER_PERIOD(null))
    })

    return all
  }
}

export const submitSaveCharterPeriod = charterPeriod => {
  return dispatch => {
    charterPeriod.checkinDate = moment(charterPeriod.checkinDate)
    charterPeriod.checkoutDate = moment(charterPeriod.checkoutDate)

    return api
      .sendCommand('hotel/charterPeriod/updateCharterPeriod', charterPeriod)
      .then(res => {
        dispatch(queryCharterPeriods())
        dispatch(globalMessageActions.setGlobalSuccess('Charter period saved'))
        dispatch(SET_ACTIVE_EDIT_CHARTER_PERIOD(null))
      })
  }
}

export const submitDeleteCharterPeriod = charterPeriod => {
  return dispatch => {
    return api
      .sendCommand('hotel/charterPeriod/deleteCharterPeriod', charterPeriod)
      .then(res => {
        dispatch(queryCharterPeriods())
        dispatch(
          globalMessageActions.setGlobalSuccess('Charter period deleted')
        )
        dispatch(SET_ACTIVE_EDIT_CHARTER_PERIOD(null))
      })
  }
}
