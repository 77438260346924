import { combineReducers } from 'redux'
import roomCategories from './room-categories'
import allotments from './allotments'
import prices from './prices'
import contracts from './contracts'
import contract from './contract'
import discounts from './discounts'
import supplements from './supplements'
import shortWeekSupplements from './short-week-supplements'
import touristTax from './tourist-tax'
import cancellations from './cancellations'
import releaseRules from './release-rules'
import minimumOfNights from './minimum-of-nights'
import minimumAge from './minimum-age'
import paymentPlan from './payment-plan'
import upload from './upload'
import supplementTypes from './supplement-types'
import facilities from './facilities'

export default combineReducers({
  roomCategories,
  allotments,
  prices,
  contracts,
  contract,
  discounts,
  supplements,
  touristTax,
  cancellations,
  paymentPlan,
  releaseRules,
  minimumOfNights,
  minimumAge,
  upload,
  shortWeekSupplements,
  supplementTypes,
  facilities
})
