import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_SIHOT_HOTEL_MAPPINGS = createAction('query sihot hotel mappings')
const SET_ACTIVE_HOTEL_MAPPING = createAction('set active sihot hotel mapping')
const SET_ACTIVE_VALUE = createAction('set active sihot hotel mapping value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_SIHOT_HOTEL_MAPPINGS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE_HOTEL_MAPPING]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    }
  },
  initialState
)

// Action creators
export const querySihotMappings = () => {
  return dispatch => {
    return api
      .query('sihot/Hotelmappings', { ignoreCache: true })
      .then(json => {
        dispatch(QUERY_SIHOT_HOTEL_MAPPINGS(json.data))
        return json.data
      })
  }
}

export const submitCreateHotelMapping = hotelRoom => {
  return dispatch => {
    return api.sendCommand('sihot/CreateHotelMapping', hotelRoom).then(() => {
      dispatch(querySihotMappings())
      dispatch(
        globalMessageActions.setGlobalSuccess(
          'Sihot Hotellroom category added!'
        )
      )
    })
  }
}

export const submitUpdateHotelMapping = hotelRoom => {
  return dispatch => {
    return api
      .sendCommand('sihot/UpdateHotelMapping', {
        oldSihotHotelCode: hotelRoom.oldSihotHotelCode,
        newSihotHotelCode: hotelRoom.sihotHotelCode,
        ethelHotelCodes: hotelRoom.ethelHotelCodes
      })
      .then(() => {
        dispatch(querySihotMappings())
        dispatch(
          globalMessageActions.setGlobalSuccess(
            'Sihot Hotellroom category updated!'
          )
        )
      })
  }
}

export const submitDeleteHotelMapping = hotelroom => {
  return (dispatch, getState) => {
    return api
      .sendCommand('sihot/DeleteHotelMapping', { sihotHotelCode: hotelroom })
      .then(() => {
        dispatch(querySihotMappings())
        dispatch(
          globalMessageActions.setGlobalSuccess(
            'Sihot Hotellroom category deleted!'
          )
        )
      })
  }
}

export const setActiveHotelMapping = hotelroom => {
  return dispatch => {
    dispatch(SET_ACTIVE_HOTEL_MAPPING(hotelroom))
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ value, name }))
  }
}
