import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const QUERY_MARKETS = createAction('query markets')
const QUERY_MARKETS_SUCCESS = createAction('query markets success')

const initialState = {
  active: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_MARKETS]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [QUERY_MARKETS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    }
  },
  initialState
)

// Action creators
export const queryMarkets = () => {
  return (dispatch, getState) => {
    dispatch(QUERY_MARKETS())
    return api.query('markets').then(json => dispatch(QUERY_MARKETS_SUCCESS(json.data)))
  }
}
