import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'
import * as globalMessageActions from '../global-message'

const QUERY_FLIGHTS = createAction('query flights for charter')
const QUERY_FLIGHTS_SUCCESS = createAction('query flights for charter success')
const QUERY_FLIGHT_CONTRACTS = createAction('query flight contracts for charter')

const QUERY_FLIGHTCHARTER_SUCCESS = createAction('query flight charter success')
const SET_ACTIVE_CHARTER = createAction('set active flight charter')
const SET_ACTIVE_EDIT_CHARTER = createAction('set active edit flight charter')
const SET_FILTER_VALUE = createAction('set filter value flight charter')
const SET_ACTIVE_FILTER_VALUE = createAction('set active filter value flight charter')
const ADD_ACTIVE_FILTER_VALUE = createAction('add active filter value flight charter')
const REMOVE_ACTIVE_FILTER_VALUE = createAction('remove active filter value flight charter')

const SET_SHOW_CONTRIBUTION_MARGIN = createAction('set show cm flight charter')
const SET_SHOW_CABIN_FACTOR = createAction('set show cabin factor')
const SET_SHOW_COST_ADJUSTMENT = createAction('set show cost adjustment flight charter')
const SET_SHOW_PRIORITY = createAction('set show priority flight charter')
const SET_SHOW_ALLOTMENT = createAction('set show allotment flight charter')
const SET_FLIGHT_CHARTER_SHOW_IN_SUMMARY = createAction('set show in flight charter summary')

const RESET = createAction('reset flight charter summary')

const initialState = {
  filter: {
    originAirportId: 'ARN',
    destinationAirportId: 'SZG',
    dateTimeFrom: null,
    dateTimeTo: null,
    flightNumber: ''
  },
  activeFilter: {
    dateTimeFrom: moment().startOf('day'),
    dateTimeTo: moment()
      .add(1, 'days')
      .endOf('day'),
    outwards: [
      {
        originAirportId: 'ARN',
        destinationAirportId: 'SZG',
        flightNumber: '',
        contractId: '',
        weekday: 6
      }
    ],
    homewards: [
      {
        originAirportId: 'SZG',
        destinationAirportId: 'ARN',
        flightNumber: '',
        contractId: '',
        weekday: 6
      }
    ]
  },
  contracts: [],
  charters: [],
  active: null,
  activeEdit: null,
  showContributionMargin: false,
  showCabinFactor: false,
  showCostAdjustment: false,
  showPriority: false,
  showAllotment: false,
  loading: false
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_FLIGHTS]: (state, action) => {
      return { ...state, loading: true }
    },
    [QUERY_FLIGHTS_SUCCESS]: (state, action) => {
      let flights = action.payload

      let activeFlights = state.active
      if (flights.length !== 0) {
        let selectedFlights = flights.map(f => {
          f.selected = true
          f.contributionMargin = !f.contributionMargin ? activeFlights.contributionMargin : f.contributionMargin
          f.cabinFactorPercentage = !f.cabinFactorPercentage
            ? activeFlights.cabinFactorPercentage
            : f.cabinFactorPercentage
          f.priority = !f.priority ? activeFlights.priority : f.priority
          f.allotment = !f.allotment ? activeFlights.allotment : f.allotment
          return f
        })
        activeFlights = { ...state.active, flights: selectedFlights }
      } else if (activeFlights) {
        activeFlights = { ...state.active, flights: [] }
      }

      return { ...state, loading: false, active: activeFlights }
    },
    [QUERY_FLIGHTCHARTER_SUCCESS]: (state, action) => {
      return { ...state, charters: action.payload }
    },
    [QUERY_FLIGHT_CONTRACTS]: (state, action) => {
      return { ...state, contracts: action.payload }
    },
    [SET_FLIGHT_CHARTER_SHOW_IN_SUMMARY]: (state, action) => {
      return {
        ...state,
        charters: state.charters.map((charter, i) =>
          i === action.payload.index ? { ...charter, showInSummary: action.payload.value } : charter
        )
      }
    },
    [SET_SHOW_CONTRIBUTION_MARGIN]: (state, action) => {
      return { ...state, showContributionMargin: action.payload }
    },
    [SET_SHOW_CABIN_FACTOR]: (state, action) => {
      return { ...state, showCabinFactor: action.payload }
    },
    [SET_SHOW_COST_ADJUSTMENT]: (state, action) => {
      return { ...state, showCostAdjustment: action.payload }
    },
    [SET_SHOW_PRIORITY]: (state, action) => {
      return { ...state, showPriority: action.payload }
    },
    [SET_SHOW_ALLOTMENT]: (state, action) => {
      return { ...state, showAllotment: action.payload }
    },
    [SET_ACTIVE_CHARTER]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_EDIT_CHARTER]: (state, action) => {
      return { ...state, activeEdit: action.payload }
    },
    [SET_FILTER_VALUE]: (state, action) => {
      return { ...state, filter: { ...state.filter, [action.payload.name]: action.payload.value } }
    },
    [SET_ACTIVE_FILTER_VALUE]: (state, action) => {
      if (!action.payload.type) {
        return {
          ...state,
          activeFilter: {
            ...state.activeFilter,
            [action.payload.name]: action.payload.value
          }
        }
      }

      let list = state.activeFilter[action.payload.type]
      let item = list[action.payload.idx || 0]
      item[action.payload.name] = action.payload.value

      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          [action.payload.type]: list
        }
      }
    },
    [ADD_ACTIVE_FILTER_VALUE]: (state, action) => {
      let list = state.activeFilter[action.payload.type]
      list.push(action.payload.value)

      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          [action.payload.type]: list
        }
      }
    },
    [REMOVE_ACTIVE_FILTER_VALUE]: (state, action) => {
      let list = state.activeFilter[action.payload.type]
      list.splice(action.payload.idx, 1)

      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          [action.payload.type]: list
        }
      }
    },
    [RESET]: (state, action) => {
      return { ...state, activeFilter: state.activeFilter }
    }
  },
  initialState
)

export const submitSetAutomaticCostAdjustment = command => {
  return dispatch => {
    api.sendCommand('flightDeparture/charter/setAutomaticCostAdjustment', command).then(() => {
      dispatch(
        queryFlightCharters(
          command.originAirportId,
          command.destinationAirportId,
          command.dateTimeFrom,
          command.dateTimeTo,
          command.flightNumber
        )
      )

      dispatch(globalMessageActions.setGlobalSuccess('Automatic cost adjustment set'))
    })
  }
}

export const queryFlightCharters = (originAirportId, destinationAirportId, dateTimeFrom, dateTimeTo, flightNumber) => {
  return dispatch => {
    let query = api.query('flightDeparture/charter/charters', {
      originAirportId,
      destinationAirportId,
      dateTimeFrom,
      dateTimeTo,
      flightNumber
    })

    query.then(res => dispatch(QUERY_FLIGHTCHARTER_SUCCESS(res.data)))
    return query
  }
}

export const queryFlightContracts = (fromDate, toDate) => {
  return dispatch => {
    api
      .query('flightDeparture/charter/contractsForCharter', {
        fromDate,
        toDate
      })
      .then(res => dispatch(QUERY_FLIGHT_CONTRACTS(res.data)))
  }
}

export const setFlightCharterShowInSummary = (index, value) => {
  return dispatch => {
    dispatch(SET_FLIGHT_CHARTER_SHOW_IN_SUMMARY({ index, value }))
  }
}

export const queryFlights = () => {
  return (dispatch, getState) => {
    dispatch(QUERY_FLIGHTS())

    var filter = { ...getState().charterPeriods.flight.activeFilter }

    api.query('flightDeparture/contract/FlightsForCharters', filter).then(res => {
      dispatch(QUERY_FLIGHTS_SUCCESS(res.data))
    })
  }
}

export const setShowContributionMargin = value => {
  return dispatch => {
    dispatch(SET_SHOW_CONTRIBUTION_MARGIN(value))
  }
}

export const setShowCabinFactor = value => {
  return dispatch => {
    dispatch(SET_SHOW_CABIN_FACTOR(value))
  }
}

export const setShowCostAdjustment = value => {
  return dispatch => {
    dispatch(SET_SHOW_COST_ADJUSTMENT(value))
  }
}

export const setShowPriority = value => {
  return dispatch => {
    dispatch(SET_SHOW_PRIORITY(value))
  }
}

export const setShowAllotment = value => {
  return dispatch => {
    dispatch(SET_SHOW_ALLOTMENT(value))
  }
}

export const setFilterValue = (value, name) => {
  return dispatch => {
    dispatch(SET_FILTER_VALUE({ value, name }))
  }
}

export const setActiveFilterValue = (value, name, type, idx) => {
  return dispatch => {
    dispatch(SET_ACTIVE_FILTER_VALUE({ value, name, type, idx }))
  }
}

export const addActiveFilterValue = (type, value) => {
  return dispatch => {
    dispatch(ADD_ACTIVE_FILTER_VALUE({ type, value }))
  }
}

export const removeActiveFilterValue = (type, idx) => {
  return dispatch => {
    dispatch(REMOVE_ACTIVE_FILTER_VALUE({ type, idx }))
  }
}

export const setActiveCharter = charter => {
  return dispatch => {
    dispatch(SET_ACTIVE_CHARTER(charter))
  }
}

export const setActiveEditCharter = charter => {
  return dispatch => {
    dispatch(SET_ACTIVE_EDIT_CHARTER(charter))
  }
}

export const reset = () => {
  return dispatch => {
    dispatch(RESET())
  }
}

export const submitCreateCharter = command => {
  return (dispatch, getState) => {
    api.sendCommand('flightDeparture/charter/createCharter', command).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Flight charter added'))
      dispatch(setActiveCharter(null))
      const filter = getState().charterPeriods.flight.filter
      dispatch(
        queryFlightCharters(
          filter.originAirportId,
          filter.destinationAirportId,
          filter.dateTimeFrom,
          filter.dateTimeTo,
          filter.flightNumber
        )
      )
    })
  }
}

export const submitStopSales = command => {
  return (dispatch, getState) => {
    api.sendCommand('flightDeparture/charter/stopSales', command).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Stopped sales on charter'))
      const charter = { ...getState().charterPeriods.flight.activeEdit, salesStopped: true }
      dispatch(setActiveEditCharter(charter))
      const filter = getState().charterPeriods.flight.filter
      dispatch(
        queryFlightCharters(
          filter.originAirportId,
          filter.destinationAirportId,
          filter.dateTimeFrom,
          filter.dateTimeTo,
          filter.flightNumber
        )
      )
    })
  }
}

export const submitStartSales = command => {
  return (dispatch, getState) => {
    api.sendCommand('flightDeparture/charter/startSales', command).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Started sales on charter'))
      const charter = { ...getState().charterPeriods.flight.activeEdit, salesStopped: false }
      dispatch(setActiveEditCharter(charter))
      const filter = getState().charterPeriods.flight.filter
      dispatch(
        queryFlightCharters(
          filter.originAirportId,
          filter.destinationAirportId,
          filter.dateTimeFrom,
          filter.dateTimeTo,
          filter.flightNumber
        )
      )
    })
  }
}

export const submitSetContributionMargin = command => {
  return (dispatch, getState) => {
    api.sendCommand('flightDeparture/charter/setContributionMargin', command).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Contribution margin updated'))
      const charter = { ...getState().charterPeriods.flight.activeEdit, contributionMargin: command.contributionMargin }
      dispatch(setActiveEditCharter(charter))
      dispatch(setShowContributionMargin(false))
      const filter = getState().charterPeriods.flight.filter
      dispatch(
        queryFlightCharters(
          filter.originAirportId,
          filter.destinationAirportId,
          filter.dateTimeFrom,
          filter.dateTimeTo,
          filter.flightNumber
        )
      )
    })
  }
}

export const submitSetCabinFactor = command => {
  return (dispatch, getState) => {
    api.sendCommand('flightDeparture/charter/setCabinFactor', command).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Cabin factor updated'))
      const charter = {
        ...getState().charterPeriods.flight.activeEdit,
        cabinFactorPercentage: command.cabinFactorPercentage
      }
      dispatch(setActiveEditCharter(charter))
      dispatch(setShowCabinFactor(false))
      const filter = getState().charterPeriods.flight.filter
      dispatch(
        queryFlightCharters(
          filter.originAirportId,
          filter.destinationAirportId,
          filter.dateTimeFrom,
          filter.dateTimeTo,
          filter.flightNumber
        )
      )
    })
  }
}

export const submitSetAllotment = command => {
  return (dispatch, getState) => {
    api.sendCommand('flightDeparture/charter/setAllotment', command).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Allotment updated'))
      const charter = { ...getState().charterPeriods.flight.activeEdit, allotment: command.allotment }
      dispatch(setActiveEditCharter(charter))
      dispatch(setShowAllotment(false))
      const filter = getState().charterPeriods.flight.filter
      dispatch(
        queryFlightCharters(
          filter.originAirportId,
          filter.destinationAirportId,
          filter.dateTimeFrom,
          filter.dateTimeTo,
          filter.flightNumber
        )
      )
    })
  }
}

export const submitSetCostAdjustment = command => {
  return (dispatch, getState) => {
    api.sendCommand('flightDeparture/charter/setCostAdjustment', command).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Cost adjustment updated'))
      const charter = { ...getState().charterPeriods.flight.activeEdit, costAdjustment: command.adjustment }
      dispatch(setActiveEditCharter(charter))
      dispatch(setShowContributionMargin(false))
      dispatch(setShowCostAdjustment(false))
      const filter = getState().charterPeriods.flight.filter
      dispatch(
        queryFlightCharters(
          filter.originAirportId,
          filter.destinationAirportId,
          filter.dateTimeFrom,
          filter.dateTimeTo,
          filter.flightNumber
        )
      )
    })
  }
}

export const submitSetPriority = command => {
  return (dispatch, getState) => {
    api.sendCommand('flightDeparture/charter/setPriority', command).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Priority updated'))
      const charter = { ...getState().charterPeriods.flight.activeEdit, priority: command.priority }
      dispatch(setActiveEditCharter(charter))
      dispatch(setShowPriority(false))
      const filter = getState().charterPeriods.flight.filter
      dispatch(
        queryFlightCharters(
          filter.originAirportId,
          filter.destinationAirportId,
          filter.dateTimeFrom,
          filter.dateTimeTo,
          filter.flightNumber
        )
      )
    })
  }
}

export const submitDeleteCharter = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('flightDeparture/charter/deleteCharter', command)

    cmd
      .then(() => {
        dispatch(globalMessageActions.setGlobalSuccess('Charter deleted'))
        dispatch(setActiveCharter(null))
        const filter = getState().charterPeriods.flight.filter
        dispatch(
          queryFlightCharters(
            filter.originAirportId,
            filter.destinationAirportId,
            filter.dateTimeFrom,
            filter.dateTimeTo,
            filter.flightNumber
          )
        )
      })
      .catch(err => dispatch(globalMessageActions.setGlobalError(err.error.message)))

    return cmd
  }
}
