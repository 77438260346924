const config = {
  apiUrl:
    process.env.NODE_ENV === 'development'
      ? '/v1'
      : '/api/v1',
  pdfUrl: '/api/v1'
}

// const config = {
//   baseQueryUrl: 'http://backoffice.alpresor.local/api/v1/query',
//   baseCommandUrl: 'http://backoffice.alpresor.local/api/v1/command'
// }
//

export default config
