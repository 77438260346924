import { createAction, handleActions } from 'redux-actions'
import api from '../api'
// import * as globalMessageActions from '../global-message'
import moment from 'moment-timezone'

// Actions
const QUERY_RELEASES = createAction('query release')

const SET_DESTINATION_ID = createAction('release destination id')
const SET_HOTEL_ID = createAction('release hotel id')

const RESET_ACTIVE_UPCOMING_RELEASE = createAction('cancel active upcoming release')
const SET_VALUE = createAction('update active upcoming release')
const EDIT_ACTIVE_UCOMING_RELEASE = createAction('edit active upcoming release')

const RELEASE_MAIL_TEXT_COPIED = createAction('release mail text copied')
const RELEASE_CUSTOMER_MAIL_TEXT_COPIED = createAction('release customer mail text copied')

const initialState = {
  activeUpcomingRelease: null,
  selectedDestinationId: null,
  selectedHotelId: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_RELEASES]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [EDIT_ACTIVE_UCOMING_RELEASE]: (state, action) => {
      return { ...state, activeUpcomingRelease: action.payload }
    },
    [RESET_ACTIVE_UPCOMING_RELEASE]: (state, action) => {
      return { ...state, activeUpcomingRelease: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let allotments = state.activeUpcomingRelease.allotments
      let active = allotments.find(a => a.roomCategoryId === action.payload.roomCategoryId)
      active[action.payload.name] = action.payload.value
      return { ...state, allotments: allotments }
    },
    [SET_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload.item, selectedHotelId: null }
    },
    [SET_HOTEL_ID]: (state, action) => {
      return { ...state, selectedHotelId: action.payload.item }
    },
    [RELEASE_MAIL_TEXT_COPIED]: (state, action) => {
      let activeUpcomingRelease = state.activeUpcomingRelease
      activeUpcomingRelease.releaseMailTextCopied = true
      return { ...state, activeUpcomingRelease: activeUpcomingRelease }
    },
    [RELEASE_CUSTOMER_MAIL_TEXT_COPIED]: (state, action) => {
      let activeUpcomingRelease = state.activeUpcomingRelease
      activeUpcomingRelease.releaseCustomerMailTextCopied = true
      return { ...state, activeUpcomingRelease: activeUpcomingRelease }
    }
  },
  initialState
)

// Action creators
export const queryReleases = () => {
  return (dispatch, getState) => {
    var destinationId = getState().production.upcomingReleases.selectedDestinationId
    var hotelId = getState().production.upcomingReleases.selectedHotelId

    if (hotelId) {
      api
        .query('hotel/release/upcomingReleasesByHotel', { hotelId, NoOfDays: 10, Date: moment() })
        .then(json => dispatch(QUERY_RELEASES(json.data)))
    } else if (destinationId) {
      api
        .query('hotel/release/upcomingReleasesByDestination', { destinationId, NoOfDays: 10, Date: moment() })
        .then(json => dispatch(QUERY_RELEASES(json.data)))
    } else {
      api
        .query('hotel/release/upcomingReleases', { NoOfDays: 10, Date: moment() })
        .then(json => dispatch(QUERY_RELEASES(json.data)))
    }
  }
}

export const editActiveUpcomingRelease = release => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_UCOMING_RELEASE(release))
  }
}

export const resetActiveUpcomingRelease = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_UPCOMING_RELEASE())
  }
}

export const setValue = (value, name, roomCategoryId) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name, roomCategoryId }))
  }
}

export const setDestinationId = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_ID({ item }))
  }
}

export const setHotelId = item => {
  return dispatch => {
    dispatch(SET_HOTEL_ID({ item }))
  }
}

export const releaseMailTextCopied = () => {
  return dispatch => {
    dispatch(RELEASE_MAIL_TEXT_COPIED({}))
  }
}

export const releaseCustomerMailTextCopied = () => {
  return dispatch => {
    dispatch(RELEASE_CUSTOMER_MAIL_TEXT_COPIED({}))
  }
}
