import { createAction, handleActions } from 'redux-actions'
import * as globalMessageActions from '../../global-message'
import api from '../../api'

const SET_LOADING = createAction(
  'set flight contract action request in progress'
)

const SET_COMMAND_FINISHED = createAction(
  'set flight contract action completed'
)
const QUERY_FLIGHT_CONTRACT = createAction('query flight contract')
const QUERY_FLIGHT_CONTRACT_SUCCESS = createAction(
  'query flight contract success'
)
const CREATE_FLIGHT_CONTRACT_SUCCESS = createAction(
  'create flight contract success'
)
const SET_NEW_CONTRACT = createAction('set new flight contract')
const SET_NEW_VALUE = createAction('flight contract set new value')
const SET_EDIT_CONTRACT = createAction('set edit flight contract')
const SET_EDIT_VALUE = createAction('flight contract set edit value')
const SET_SELECTED_RELEASE_RULE = createAction(
  'flight contract set release rule'
)
const SET_RELEASE_RULE_VALUE = createAction(
  'flight contract set release rule value'
)
const SAVE_RELEASE_RULE = createAction(
  'flight contract add/update release rule'
)
const REMOVE_RELEASE_RULE = createAction('flight contract delete release rule')

const initialState = {
  new: null,
  edit: null,
  loading: false,
  item: null,
  selectedReleaseRule: null
}

// Reducers/handlers
export default handleActions(
  {
    [SET_LOADING]: (state, action) => {
      return { ...state, loading: true }
    },
    [SET_COMMAND_FINISHED]: (state, action) => {
      return { ...state, loading: false }
    },
    [QUERY_FLIGHT_CONTRACT]: (state, action) => {
      return {
        ...state,
        loading: true,
        item: action.payload ? null : state.item
      }
    },
    [QUERY_FLIGHT_CONTRACT_SUCCESS]: (state, action) => {
      return { ...state, loading: false, item: action.payload }
    },
    [CREATE_FLIGHT_CONTRACT_SUCCESS]: (state, action) => {
      return { ...state, loading: false, new: null }
    },
    [SET_NEW_CONTRACT]: (state, action) => {
      return { ...state, new: action.payload }
    },
    [SET_NEW_VALUE]: (state, action) => {
      return {
        ...state,
        new: { ...state.new, [action.payload.name]: action.payload.value }
      }
    },
    [SET_SELECTED_RELEASE_RULE]: (state, action) => {
      return { ...state, selectedReleaseRule: action.payload }
    },
    [SET_RELEASE_RULE_VALUE]: (state, action) => {
      return {
        ...state,
        selectedReleaseRule: {
          ...state.selectedReleaseRule,
          [action.payload.name]: action.payload.value
        }
      }
    },
    [SET_EDIT_CONTRACT]: (state, action) => {
      return { ...state, edit: action.payload }
    },
    [SET_EDIT_VALUE]: (state, action) => {
      return {
        ...state,
        edit: { ...state.edit, [action.payload.name]: action.payload.value }
      }
    },
    [SAVE_RELEASE_RULE]: (state, action) => {
      const rules = [...state.item.releaseRules]
      const index = rules.findIndex(
        rule => rule.ruleId === action.payload.ruleId
      )
      rules[index] = action.payload
      return {
        ...state,
        selectedReleaseRule: null,
        item: { ...state.item, releaseRules: rules }
      }
    },
    [REMOVE_RELEASE_RULE]: (state, action) => {
      return {
        ...state,
        selectedReleaseRule: null,
        item: {
          ...state.item,
          releaseRules: [
            ...state.item.releaseRules.filter(
              rule => rule.ruleId !== action.payload.ruleId
            )
          ]
        }
      }
    }
  },
  initialState
)

export const queryFlightContract = (contractId, reload = false) => {
  return dispatch => {
    dispatch(QUERY_FLIGHT_CONTRACT(reload))
    api
      .query('flightDeparture/contract/contract', { contractId })
      .then(res => dispatch(QUERY_FLIGHT_CONTRACT_SUCCESS(res.data)))
  }
}

export const submitCreateFlightContract = contract => {
  return dispatch => {
    dispatch(SET_LOADING())
    let command = api.sendCommand(
      'flightDeparture/contract/createContract',
      contract
    )
    command.then(res => {
      dispatch(CREATE_FLIGHT_CONTRACT_SUCCESS())
      dispatch(globalMessageActions.setGlobalSuccess('Flight contract created'))
    })
    return command
  }
}

export const submitCopyFlightContract = command => {
  return dispatch => {
    dispatch(SET_LOADING())
    let cmd = api.sendCommand('flightDeparture/contract/copyContract', command)
    cmd
      .then(() => {
        dispatch(globalMessageActions.setGlobalSuccess('Contract copied'))
      })
      .finally(() => dispatch(SET_COMMAND_FINISHED()))
    return cmd
  }
}

export const submitAddReleaseRule = releaseRule => {
  return dispatch => {
    dispatch(SET_LOADING())
    return api
      .sendCommand('flightDeparture/contract/addReleaseRule', releaseRule)
      .then(res => {
        dispatch(queryFlightContract(releaseRule.contractId))
        dispatch(globalMessageActions.setGlobalSuccess('Release rule added'))
      })
      .finally(() => dispatch(SET_COMMAND_FINISHED()))
  }
}

export const submitUpdateReleaseRule = releaseRule => dispatch => {
  dispatch(SET_LOADING())
  return api
    .sendCommand('flightDeparture/contract/updateReleaseRule', releaseRule)
    .then(res => {
      dispatch(SAVE_RELEASE_RULE(releaseRule))
      dispatch(SET_SELECTED_RELEASE_RULE(null))
      dispatch(globalMessageActions.setGlobalSuccess('Release rule updated'))
    })
    .finally(() => dispatch(SET_COMMAND_FINISHED()))
}

export const submitUpdateFlightContract = contract => {
  return dispatch => {
    dispatch(SET_LOADING())
    let command = api.sendCommand(
      'flightDeparture/contract/updateContract',
      contract
    )
    command
      .then(res => {
        dispatch(queryFlightContract(contract.id))
        dispatch(
          globalMessageActions.setGlobalSuccess('Flight contract updated')
        )
      })
      .finally(() => dispatch(SET_COMMAND_FINISHED()))
    return command
  }
}

export const submitDeleteContract = contractId => {
  return dispatch => {
    dispatch(SET_LOADING())
    let command = api.sendCommand('flightDeparture/contract/deleteContract', {
      contractId
    })
    command
      .then(res => {
        dispatch(globalMessageActions.setGlobalSuccess('Contract removed'))
      })
      .finally(() => dispatch(SET_COMMAND_FINISHED()))

    return command
  }
}

export const submitSplitRow = command => {
  return dispatch => {
    dispatch(SET_LOADING())
    api
      .sendCommand('flightDeparture/contract/splitRow', command)
      .then(() => {
        dispatch(globalMessageActions.setGlobalSuccess('Flight row splitted'))
        dispatch(queryFlightContract(command.contractId))
      })
      .finally(() => dispatch(SET_COMMAND_FINISHED()))
  }
}

export const setNewContract = value => {
  return dispatch => {
    dispatch(SET_NEW_CONTRACT(value))
  }
}

export const setNewValue = (value, name) => {
  return dispatch => {
    dispatch(SET_NEW_VALUE({ value, name }))
  }
}

export const removeReleaseRule =
  ({ ruleId, contractId }) =>
  dispatch => {
    dispatch(SET_LOADING())
    return api
      .sendCommand('flightDeparture/contract/removeReleaseRule', {
        ruleId,
        contractId
      })
      .then(() => {
        dispatch(queryFlightContract(contractId))
        dispatch(globalMessageActions.setGlobalSuccess('Release rule removed'))
        dispatch(REMOVE_RELEASE_RULE({ ruleId }))
      })
      .finally(() => dispatch(SET_COMMAND_FINISHED()))
  }

export const setSelectedReleaseRule = value => dispatch =>
  dispatch(SET_SELECTED_RELEASE_RULE(value))

export const setSelectedReleaseRuleValue = (value, name) => {
  return dispatch => {
    dispatch(SET_RELEASE_RULE_VALUE({ value, name }))
  }
}

export const setEditContract = value => {
  return dispatch => {
    dispatch(SET_EDIT_CONTRACT(value))
  }
}

export const setEditValue = (value, name) => {
  return dispatch => {
    dispatch(SET_EDIT_VALUE({ value, name }))
  }
}
