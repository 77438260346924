import { combineReducers } from 'redux'
import details from './details'
import latest from './latest'
import search from './search'

export default combineReducers({
  details,
  latest,
  search
})
