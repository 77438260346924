import { createAction, handleActions } from 'redux-actions'
import moment from 'moment-timezone'
import { v4 as uuid } from 'uuid'
import * as globalMessageActions from '../../global-message'
import api from '../../api'

const SET_ACTIVE_ROW = createAction('set active row flight contract')
const SET_VALUE = createAction('set active value flight contract')
const SET_FLIGHT_VALUE = createAction('set active flight value flight contract')
const SET_GENERATED_ROWS = createAction('set generated rows flight contract')
const TOGGLE_INCLUDE_ROW = createAction('toggle generated row flight contract')
const ADD_FLIGHT_TO_ROW = createAction('add flight to row')
const REMOVE_FLIGHT_FROM_ROW = createAction('remove flight from row')

const initialState = {
  active: null,
  generatedRows: []
}

// Reducers/handlers
export default handleActions(
  {
    [SET_ACTIVE_ROW]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_VALUE]: (state, action) => {
      return { ...state, active: { ...state.active, [action.payload.name]: action.payload.value } }
    },
    [SET_FLIGHT_VALUE]: (state, action) => {
      return {
        ...state,
        active: {
          ...state.active,
          flights: state.active.flights.map((flight, i) =>
            i === action.payload.index ? { ...flight, [action.payload.name]: action.payload.value } : flight
          )
        }
      }
    },
    [SET_GENERATED_ROWS]: (state, action) => {
      return { ...state, generatedRows: action.payload }
    },
    [TOGGLE_INCLUDE_ROW]: (state, action) => {
      return {
        ...state,
        generatedRows: state.generatedRows.map((row, i) =>
          i === action.payload ? { ...row, include: !row.include } : row
        )
      }
    },
    [ADD_FLIGHT_TO_ROW]: (state, action) => {
      let prevFlight = state.active.flights[state.active.flights.length - 1]
      let newFlight = {
        ...prevFlight,
        originAirportId: prevFlight.destinationAirportId,
        destinationAirportId: prevFlight.originAirportId
      }

      return {
        ...state,
        active: {
          ...state.active,
          flights: [...state.active.flights, newFlight]
        }
      }
    },
    [REMOVE_FLIGHT_FROM_ROW]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, flights: state.active.flights.filter((f, i) => i !== action.payload) }
      }
    }
  },
  initialState
)

export const setActiveRow = value => {
  return dispatch => {
    dispatch(SET_ACTIVE_ROW(value))
    dispatch(generateFlightRows())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
    dispatch(generateFlightRows())
  }
}

export const setFlightValue = (index, value, name) => {
  return dispatch => {
    dispatch(SET_FLIGHT_VALUE({ index, value, name }))
    dispatch(generateFlightRows())
  }
}

export const addFlightToRow = () => {
  return dispatch => {
    dispatch(ADD_FLIGHT_TO_ROW())
    dispatch(generateFlightRows())
  }
}

export const removeFlightFromRow = index => {
  return dispatch => {
    dispatch(REMOVE_FLIGHT_FROM_ROW(index))
    dispatch(generateFlightRows())
  }
}

export const toggleIncludeRow = index => {
  return dispatch => {
    dispatch(TOGGLE_INCLUDE_ROW(index))
  }
}

export const submitAddRows = commandList => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      commandList
        .reduce(
          (chain, command) => chain.then(() => api.sendCommand('flightDeparture/contract/addRow', command)),
          Promise.resolve()
        )
        .then(() => {
          dispatch(globalMessageActions.setGlobalSuccess('Flights added to contract'))
          resolve()
        })
    })
  }
}

const generateFlightRows = () => {
  return (dispatch, getState) => {
    const active = getState().contract.flight.flightRow.active
    const airports = getState().management.airports.items
    let rows = []
    let startDate = moment(active.startDate).clone()
    let endDate = moment(active.endDate).clone()

    while (startDate.isSameOrBefore(endDate, 'day')) {
      rows.push({
        id: uuid(),
        date: startDate,
        remark: active.remark,
        [active.priceType]: active.price,
        flights: getFlights(active.flights, startDate, airports),
        include: true
      })
      startDate = startDate.clone().add(1, 'week')
    }

    dispatch(SET_GENERATED_ROWS(rows))
  }
}

const getFlights = (flights, startDate, airports) => {
  return flights.map(flight => {
    if (!airports.length) return []

    const originAirport = airports.find(a => a.id === flight.originAirportId)
    const destinationAirport = airports.find(a => a.id === flight.destinationAirportId)

    const [departureHours, departureMinutes] = flight.departureTime.split(':')
    const [arrivalHours, arrivalMinutes] = flight.arrivalTime.split(':')

    const departureDateTime = startDate
      .clone()
      .tz(originAirport.timeZoneName)
      .set({ hours: departureHours, minutes: departureMinutes })

    let arrivalDateTime = startDate
      .clone()
      .tz(destinationAirport.timeZoneName)
      .set({ hours: arrivalHours, minutes: arrivalMinutes })

    if (arrivalDateTime.isBefore(departureDateTime)) {
      arrivalDateTime = arrivalDateTime.add(1, 'days')
    }

    return {
      ...flight,
      departureDateTime: departureDateTime,
      arrivalDateTime: arrivalDateTime
    }
  })
}
