import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_MARKETS = createAction('query market markets')
const SET_ACTIVE = createAction('set active market market')
const SET_ACTIVE_VALUE = createAction('set active market market value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_MARKETS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return { ...state, active: { ...state.active, [action.payload.name]: action.payload.value } }
    }
  },
  initialState
)

// Action creators
export const queryMarkets = () => {
  return dispatch => {
    let query = api.query('market/market/markets')
    query.then(json => dispatch(QUERY_MARKETS(json.data)))
    return query
  }
}

export const setActive = market => {
  return dispatch => {
    dispatch(SET_ACTIVE(market))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}

export const submitCreateMarket = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/market/createMarket', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Market added'))
      dispatch(queryMarkets())
    })
    return cmd
  }
}

export const submitUpdateMarket = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/market/updateMarket', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Market updated'))
      dispatch(queryMarkets())
    })
    return cmd
  }
}
