import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'

// Actions
const SET_SUPPLEMENTS = createAction('set contract supplements')

const RESET_ACTIVE_SUPPLEMENT = createAction('cancel active supplements')
const SET_VALUE = createAction('update active supplements')
const SET_ACTIVE_SUPPLEMENT = createAction('edit active supplements')
const SET_ROOM_CATEGORY_VALUE = createAction('set room category')

const initialState = {
  active: '',
  loading: false,
  allItems: [],
  hotelItems: [],
  apartmentItems: [],
  roomsItems: [],
  selectedRoomType: ''
}

// Reducers/handlers
export default handleActions(
  {
    [SET_SUPPLEMENTS]: (state, action) => {
      return {
        ...state,
        loading: false,
        allItems: action.payload.hotelSupplements.filter(x => x.roomType === 'All'),
        hotelItems: action.payload.hotelSupplements.filter(x => x.roomType === 'HotelRoom'),
        apartmentItems: action.payload.hotelSupplements.filter(x => x.roomType === 'Apartment'),
        roomsItems: action.payload.roomCategories
      }
    },
    [SET_ACTIVE_SUPPLEMENT]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_SUPPLEMENT]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [SET_ROOM_CATEGORY_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      active.selectedRoomType = action.payload.value
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators
export const setSupplements = items => {
  return dispatch => {
    dispatch(SET_SUPPLEMENTS(items))
  }
}

export const addSupplement = item => {
  return dispatch => {
    return api.sendCommand('hotel/contract/addSupplement', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_SUPPLEMENT())
      dispatch(globalMessageActions.setGlobalSuccess('Supplement added'))
    })
  }
}

export const updateSupplement = item => {
  return (dispatch, getState) => {
    return api.sendCommand('hotel/contract/updateSupplement', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_SUPPLEMENT())
      dispatch(globalMessageActions.setGlobalSuccess('Supplement updated'))
    })
  }
}

export const deleteSupplement = item => {
  return (dispatch, getState) => {
    return api.sendCommand('hotel/contract/deleteSupplement', { id: item.id, contractId: item.contractId }).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_SUPPLEMENT())
      dispatch(globalMessageActions.setGlobalSuccess('Supplement deleted'))
    })
  }
}

export const setActiveSupplement = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_SUPPLEMENT(item))
  }
}

export const resetActiveSupplement = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_SUPPLEMENT())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const setRoomCategoryValue = (value, name) => {
  return dispatch => {
    dispatch(SET_ROOM_CATEGORY_VALUE({ value, name }))
  }
}
