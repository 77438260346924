import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { loadState, saveState } from './local-storage'

import globalMessage from './global-message.js'
import page from './page.js'
import charterPeriods from './charter-periods'
import contract from './contract'
import economy from './economy'
import management from './management'
import reservation from './reservation'
import booking from './booking'
import account from './account'
import production from './production'
import market from './market'
import revenue from './revenue'

let rootReducer = {
  globalMessage,
  booking,
  charterPeriods,
  page,
  economy,
  management,
  contract,
  reservation,
  account,
  production,
  market,
  revenue
}

// let store = createStore(combineReducers(rootReducer), applyMiddleware(thunkMiddleware))

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedState = loadState()

let store = createStore(
  combineReducers(rootReducer),
  persistedState,
  composeEnhancers(applyMiddleware(thunkMiddleware))
)

store.subscribe(() => {
  const bookingState = store.getState().booking

  saveState({
    booking: {
      tabs: bookingState.tabs,
      searchFilter: bookingState.searchFilter
      // conferences: store.getState().booking.conferences
    }
  })
})

export default store
