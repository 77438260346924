import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import AuthService from '../../common/auth-service'

const QUERY_MARKETS = createAction('query booking markets')
const SET_MARKET = createAction('set booking market')

const initialState = {
  selectedMarketId: null,
  data: []
}

// handlers
export default handleActions(
  {
    [QUERY_MARKETS]: (state, action) => {
      return { ...state, data: action.payload }
    },
    [SET_MARKET]: (state, action) => {
      return { ...state, selectedMarketId: action.payload }
    }
  },
  initialState
)

// thunks
export const queryMarkets = () => {
  return dispatch => {
    let qParams = {}
    const profile = AuthService.getProfile()
    if (AuthService.userIsAgent(profile)) {
      qParams['marketIds'] = profile.markets
    }
    let query = api.query('market/market/markets', qParams)
    query.then(res => {
      dispatch(QUERY_MARKETS(res.data))
    })
    return query
  }
}

export const setMarket = marketId => {
  return (dispatch, getState) => {
    dispatch(SET_MARKET(marketId))
  }
}
