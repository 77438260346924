import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'
import * as roomCategoriesActions from './room-categories'
import * as hotelsActions from './hotels'

// Actions
const FETCH_HOTEL = createAction('get hotel from server')
export const FETCH_HOTEL_SUCCESS = createAction('fetch hotel success')
const CLEAR_HOTEL = createAction('clear hotel')

const RESET_ACTIVE_HOTEL = createAction('reset active hotel')
const SET_VALUE = createAction('set hotel value')
export const SET_ACTIVE_HOTEL = createAction('set active hotel')
const UNDO_ACTIVE_HOTEL = createAction('undo active hotel')
const TOGGLE_FACILITY = createAction('toggle hotel facility')

const initialState = {
  active: null,
  loading: false,
  item: null,
  selectedHotel: ''
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_HOTEL]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [FETCH_HOTEL_SUCCESS]: (state, action) => {
      return { ...state, loading: false, item: action.payload }
    },
    [CLEAR_HOTEL]: (state, action) => {
      return { ...initialState }
    },
    [SET_ACTIVE_HOTEL]: (state, action) => {
      return { ...state, active: { ...action.payload } }
    },
    [RESET_ACTIVE_HOTEL]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [UNDO_ACTIVE_HOTEL]: (state, action) => {
      return { ...state, active: { ...state.item } }
    },
    [TOGGLE_FACILITY]: (state, action) => {
      let active = state.active
      if (active.facilities.some(f => f === action.payload)) {
        let index = active.facilities.indexOf(action.payload)
        active.facilities.splice(index, 1)
      } else {
        active.facilities.push(action.payload)
      }
      return { ...state, active: { ...active } }
    }
  },
  initialState
)

//  Action creators
export const getHotel = (id, includeRoomContent = true) => {
  return (dispatch, getState) => {
    dispatch(FETCH_HOTEL())
    dispatch(CLEAR_HOTEL())
    return api
      .query('hotel/hotel/hotel', { id, includeRoomContent })
      .then(json => {
        dispatch(FETCH_HOTEL_SUCCESS(json.data))
        let hotel = getState().management.hotel.item
        dispatch(SET_ACTIVE_HOTEL(hotel))
        dispatch(
          roomCategoriesActions.setRoomCategories(json.data.roomCategories)
        )
        return json.data
      })
      .catch(errorMessage => {
        dispatch(globalMessageActions.setGlobalError(errorMessage))
      })
  }
}

export const updateHotel = hotel => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/hotel/updateHotel', hotel)
      .then(() => {
        dispatch(getHotel(hotel.id))
        dispatch(RESET_ACTIVE_HOTEL())
        dispatch(globalMessageActions.setGlobalSuccess('Hotel updated'))
      })
      .catch(errorMessage =>
        dispatch(
          globalMessageActions.setGlobalError(errorMessage.error.message)
        )
      )
  }
}

export const UpdateImages = hotel => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/hotel/UpdateHotelImages', hotel)
      .then(() => {
        dispatch(getHotel(hotel.id, true))
        dispatch(globalMessageActions.setGlobalSuccess('Hotel content updated'))
      })
      .catch(errorMessage =>
        dispatch(
          globalMessageActions.setGlobalError(errorMessage.error.message)
        )
      )
  }
}

export const deleteHotel = id => {
  return (dispatch, getState) => {
    return api.sendCommand('hotel/hotel/deleteHotel', { id }).then(() => {
      hotelsActions.getHotels()
      dispatch(globalMessageActions.setGlobalSuccess('Hotel deleted'))
    })
  }
}

export const setFacilities = hotel => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/hotel/setFacilities', hotel)
      .then(() => {
        dispatch(getHotel(hotel.id))
        dispatch(RESET_ACTIVE_HOTEL())
        dispatch(globalMessageActions.setGlobalSuccess('Facilities updated'))
      })
      .catch(errorMessage =>
        dispatch(
          globalMessageActions.setGlobalError(errorMessage.error.message)
        )
      )
  }
}

export const setInfo = hotel => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/hotel/setHotelInfo', hotel)
      .then(() => {
        dispatch(getHotel(hotel.id))
        dispatch(RESET_ACTIVE_HOTEL())
        dispatch(globalMessageActions.setGlobalSuccess('Info updated'))
      })
      .catch(errorMessage =>
        dispatch(
          globalMessageActions.setGlobalError(errorMessage.error.message)
        )
      )
  }
}

export const setDescriptions = (hotelId, descriptions) => {
  return dispatch => {
    return api
      .sendCommand('hotel/hotel/setDescriptions', { hotelId, descriptions })
      .then(() => {
        dispatch(globalMessageActions.setGlobalSuccess('Descriptions updated'))
      })
      .catch(errorMessage =>
        dispatch(
          globalMessageActions.setGlobalError(errorMessage.error.message)
        )
      )
  }
}

export const setActiveHotel = hotel => {
  return dispatch => {
    dispatch(SET_ACTIVE_HOTEL(hotel))
  }
}

export const resetActiveHotel = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_HOTEL())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const undoActiveHotel = () => {
  return dispatch => {
    dispatch(UNDO_ACTIVE_HOTEL())
  }
}

export const toggleFacility = id => {
  return dispatch => {
    dispatch(TOGGLE_FACILITY(id))
  }
}
