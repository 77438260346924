import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const FETCH_GENERAL_ROOM_CATEGORIES = createAction('get general room categories from server')
const FETCH_GENERAL_ROOM_CATEGORIES_SUCCESS = createAction('fetch general room categories success')

const RESET_ACTIVE_GENERAL_ROOM_CATEGORY = createAction('cancel active general room category')
const SET_VALUE = createAction('set active general room category value')
const SET_ACTIVE_GENERAL_ROOM_CATEGORY = createAction('set active general room category')

const SELECT_ROOM_CATEGORY = createAction('select room category')

const initialState = {
  active: null,
  loading: false,
  selectedRoomCategoryId: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_GENERAL_ROOM_CATEGORIES]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [FETCH_GENERAL_ROOM_CATEGORIES_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_ACTIVE_GENERAL_ROOM_CATEGORY]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_GENERAL_ROOM_CATEGORY]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [SELECT_ROOM_CATEGORY]: (state, action) => {
      return { ...state, selectedRoomCategoryId: action.payload }
    }
  },
  initialState
)

// Action creators
export const getGeneralRoomCategories = () => {
  return (dispatch, getState) => {
    dispatch(FETCH_GENERAL_ROOM_CATEGORIES())

    return api.query('general/generalRoomCategories').then(json => {
      dispatch(FETCH_GENERAL_ROOM_CATEGORIES_SUCCESS(json.data))
      return json.data
    })
  }
}

export const addGeneralRoomCategory = category => {
  return dispatch => {
    return api.sendCommand('general/AddGeneralRoomCategory', category).then(() => {
      dispatch(getGeneralRoomCategories())
      dispatch(resetActiveGeneralRoomCategory())
      dispatch(globalMessageActions.setGlobalSuccess('General room category added'))
    })
  }
}

export const updateRoomCategoryType = category => {
  return (dispatch, getState) => {
    return api.sendCommand('general/UpdateGeneralRoomCategory', category).then(() => {
      dispatch(getGeneralRoomCategories())
      dispatch(resetActiveGeneralRoomCategory())
      dispatch(globalMessageActions.setGlobalSuccess('General room category updated'))
    })
  }
}

export const deleteGeneralRoomCategory = category => {
  return (dispatch, getState) => {
    return api.sendCommand('general/DeleteGeneralRoomCategory', category).then(() => {
      dispatch(getGeneralRoomCategories())
      dispatch(resetActiveGeneralRoomCategory())
      dispatch(globalMessageActions.setGlobalSuccess('General room category updated'))
    })
  }
}

export const setActiveGeneralRoomCategory = category => {
  return dispatch => {
    dispatch(SET_ACTIVE_GENERAL_ROOM_CATEGORY(category))
  }
}

export const resetActiveGeneralRoomCategory = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_GENERAL_ROOM_CATEGORY())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const selectRoomCategory = selectedRoomCategoryId => {
  return dispatch => {
    dispatch(SELECT_ROOM_CATEGORY(selectedRoomCategoryId))
  }
}
