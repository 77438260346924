import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_UNIT_PRICES = createAction('query market unit prices')
const SET_ACTIVE = createAction('set active market unit price')
const SET_ACTIVE_VALUE = createAction('set active market unit price value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_UNIT_PRICES]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    }
  },
  initialState
)

// Action creators
export const queryUnitPrices = () => {
  return dispatch => {
    let query = api.query('market/unitPrice/unitPrices')
    query.then(json => dispatch(QUERY_UNIT_PRICES(json.data)))
    return query
  }
}

export const setActive = unitPrice => {
  return dispatch => {
    dispatch(SET_ACTIVE(unitPrice))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}

export const submitCreateUnitPrice = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/unitPrice/addUnitPrice', command)
    cmd.then(() => {
      dispatch(queryUnitPrices())
      dispatch(globalMessageActions.setGlobalSuccess('Unit price added'))
    })
    return cmd
  }
}

export const submitUpdateUnitPrice = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/unitPrice/updateUnitPrice', command)
    cmd.then(() => {
      dispatch(queryUnitPrices())
      dispatch(globalMessageActions.setGlobalSuccess('Unit price updated'))
    })
    return cmd
  }
}

export const submitDeleteUnitPrice = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/unitPrice/deleteUnitPrice', command)
    cmd.then(() => {
      dispatch(queryUnitPrices())
      dispatch(globalMessageActions.setGlobalSuccess('Unit price deleted'))
    })
    return cmd
  }
}
