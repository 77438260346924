import { mapBedDestinationsData } from '../mappers'

export const getBedDestinationSeries = (
  destinationsByWeeks,
  airportId,
  date
) => {
  return [
    mapBedDestinationsData(
      'Allotments beds left',
      '#c5cae9',
      'allotmentBedsLeft',
      destinationsByWeeks,
      airportId,
      date
    ),
    mapBedDestinationsData(
      'Warranty beds left',
      '#ffcdd2',
      'warrantyBedsLeft',
      destinationsByWeeks,
      airportId,
      date
    ),
    mapBedDestinationsData(
      'Booked beds',
      '#3f51b5',
      'bedsBooked',
      destinationsByWeeks,
      airportId,
      date
    )
  ]
}
