import { createAction, handleActions } from 'redux-actions'

// Actions
export const SET_GLOBAL_MESSAGE = createAction('set global message')
export const RESET_GLOBAL_MESSAGE = createAction('reset global message')
export const SET_GLOBAL_MESSAGE_STATUS = createAction(
  'set global message with status class'
)

const initialState = {
  message: null,
  isError: false,
  status: null
}

// Reducers/handlers
export default handleActions(
  {
    [SET_GLOBAL_MESSAGE]: (state, action) => {
      return {
        ...state,
        message: action.payload,
        status: '',
        isError: false,
        className: null
      }
    },
    [RESET_GLOBAL_MESSAGE]: (state, action) => {
      return initialState
    },
    [SET_GLOBAL_MESSAGE_STATUS]: (state, action) => {
      let error = action.payload.status === 'error'
      return {
        ...state,
        message: action.payload.message,
        isError: error,
        status: action.payload.status
      }
    }
  },
  initialState
)

// Action creators
export const setGlobalMessage = message => {
  return (dispatch, getState) => {
    dispatch(SET_GLOBAL_MESSAGE(message))
  }
}

export const resetMessage = () => {
  return (dispatch, getState) => {
    dispatch(RESET_GLOBAL_MESSAGE())
  }
}

export const setGlobalError = message => {
  return (dispatch, getState) => {
    let status = 'error'
    if (typeof message === 'object') message = message.message
    dispatch(SET_GLOBAL_MESSAGE_STATUS({ message, status }))
  }
}

export const setGlobalProgress = message => {
  return (dispatch, getState) => {
    let status = 'progress'
    dispatch(SET_GLOBAL_MESSAGE_STATUS({ message, status }))
  }
}

export const setGlobalSuccess = message => {
  return (dispatch, getState) => {
    let status = 'success'
    dispatch(SET_GLOBAL_MESSAGE_STATUS({ message, status }))
  }
}

export const setGlobalWarning = message => {
  return (dispatch, getState) => {
    let status = 'warning'
    dispatch(SET_GLOBAL_MESSAGE_STATUS({ message, status }))
  }
}
