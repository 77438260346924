import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

const QUERY_AGENT_USERS = createAction('query agent users')
const SET_ACTIVE = createAction('set active agent user')
const SET_VALUE = createAction('set active agent user value')

const initalState = {
  items: [],
  active: null
}

export default handleActions(
  {
    [QUERY_AGENT_USERS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_VALUE]: (state, action) => {
      return { ...state, active: { ...state.active, [action.payload.name]: action.payload.value } }
    }
  },
  initalState
)

export const submitCreateAgentUser = command => {
  return dispatch => {
    let cmd = api.sendCommand('crm/agentUser/createAgentUser', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Agent user added'))
      dispatch(queryAgentUsers())
    })
    return cmd
  }
}

export const submitUpdateAgentUser = command => {
  return dispatch => {
    let cmd = api.sendCommand('crm/agentUser/updateAgentUser', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Agent user updated'))
      dispatch(queryAgentUsers())
    })
    return cmd
  }
}

export const submitDeleteAgentUser = command => {
  return dispatch => {
    let cmd = api.sendCommand('crm/agentUser/deleteAgentUser', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Agent user deleted'))
      dispatch(queryAgentUsers())
    })
    return cmd
  }
}

export const queryAgentUsers = () => {
  return dispatch => {
    api.query('crm/agentUser/agentUsers').then(res => dispatch(QUERY_AGENT_USERS(res.data)))
  }
}

export const setActive = user => {
  return dispatch => {
    dispatch(SET_ACTIVE(user))
  }
}

export const setValue = (name, value) => {
  return dispatch => {
    dispatch(SET_VALUE({ name, value }))
  }
}
