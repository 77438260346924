import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_AIRLINES = createAction('query airlines')
const SET_ACTIVE_AIRLINE = createAction('set active airline')
const SET_ACTIVE_VALUE = createAction('set active airline value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_AIRLINES]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE_AIRLINE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return { ...state, active: { ...state.active, [action.payload.name]: action.payload.value } }
    }
  },
  initialState
)

// Action creators
export const queryAirlines = () => {
  return dispatch => {
    return api.query('flightDeparture/airline/airlines').then(json => {
      dispatch(QUERY_AIRLINES(json.data))
      return json.data
    })
  }
}

export const submitCreateAirline = airline => {
  return dispatch => {
    return api.sendCommand('flightDeparture/airline/createairline', airline).then(() => {
      dispatch(queryAirlines())
      dispatch(globalMessageActions.setGlobalSuccess('Airline added'))
    })
  }
}

export const submitUpdateAirline = airline => {
  return (dispatch, getState) => {
    return api.sendCommand('flightDeparture/airline/updateAirline', airline).then(() => {
      dispatch(queryAirlines())
      dispatch(globalMessageActions.setGlobalSuccess('Airline updated'))
    })
  }
}

export const submitDeleteAirline = airlineId => {
  return (dispatch, getState) => {
    return api.sendCommand('flightDeparture/airline/deleteAirline', { id: airlineId }).then(() => {
      dispatch(queryAirlines())
      dispatch(globalMessageActions.setGlobalSuccess('Airline deleted'))
    })
  }
}

export const setActiveAirline = airline => {
  return dispatch => {
    dispatch(SET_ACTIVE_AIRLINE(airline))
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ value, name }))
  }
}
