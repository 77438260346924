import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'

const UPLOAD_BANKGIRO_FILES = createAction('upload bankgiro files')
const UPLOAD_BANKGIRO_FILES_SUCCESS = createAction('upload bankgiro files succeeded')
const UPLOAD_BANKGIRO_FILES_ERROR = createAction('upload bankgiro files error')
const FETCH_LATEST_TRANSACTIONS = createAction('fetch latest transactions')
const FETCH_LATEST_TRANSACTIONS_SUCCESS = createAction('fetch latest transactions succeeded')
const FETCH_LATEST_TRANSACTIONS_ERROR = createAction('fetch latest transactions failed')

const initalState = {
  loading: false,
  error: null,
  latestTransactions: []
}

export default handleActions(
  {
    [UPLOAD_BANKGIRO_FILES]: (state, action) => ({
      ...state,
      error: null,
      latestTransactions: [
        {
          id: null,
          uploadDateTime: new Date().toString(),
          uploadedInSession: true,
          status: 'uploading'
        }
      ].concat(state.latestTransactions)
    }),
    [UPLOAD_BANKGIRO_FILES_SUCCESS]: (state, action) => ({
      ...state,
      latestTransactions: (action.payload.duplicate
        ? []
        : [
          {
            ...state.latestTransactions[0],
            id: action.payload.bankPaymentsTransactionId,
            status: 'uploaded'
          }
        ]
      ).concat(state.latestTransactions.slice(1))
    }),
    [UPLOAD_BANKGIRO_FILES_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      latestTransactions: [
        {
          ...state.latestTransactions[0],
          status: 'failed',
          message: 'Failed to read the file: ' + action.payload
        }
      ].concat(state.latestTransactions.slice(1))
    }),
    [FETCH_LATEST_TRANSACTIONS]: (state, action) => ({ ...state, loading: true, error: null }),
    [FETCH_LATEST_TRANSACTIONS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      latestTransactions: action.payload.map(t => ({ ...t, status: 'uploaded' }))
    }),
    [FETCH_LATEST_TRANSACTIONS_ERROR]: (state, action) => ({ ...state, loading: false, error: action.payload })
  },
  initalState
)

export function uploadFiles (files) {
  return function (dispatch, getState) {
    dispatch(UPLOAD_BANKGIRO_FILES(files))

    return api
      .sendCommandUpload('uploadbankfile', { SE: files[0] }, 'travelbookingcommand')
      .then(response => {
        dispatch(UPLOAD_BANKGIRO_FILES_SUCCESS(response.data))
        dispatch(globalMessageActions.setGlobalSuccess('Bankgiro files uploaded'))
      })
      .catch(err => {
        dispatch(UPLOAD_BANKGIRO_FILES_ERROR(err))
        dispatch(globalMessageActions.setGlobalError('Bankgiro file upload failed'))
      })
  }
}

export function getLatestTransactions () {
  return function (dispatch) {
    dispatch(FETCH_LATEST_TRANSACTIONS())

    return api
      .query('travelbooking/latestbankpaymentstransactions')
      .then(response => {
        dispatch(FETCH_LATEST_TRANSACTIONS_SUCCESS(response.data))
      })
      .catch(err => {
        dispatch(FETCH_LATEST_TRANSACTIONS_ERROR(err))
        dispatch(globalMessageActions.setGlobalError('Failed to update latest contract files'))
      })
  }
}
