import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_REPORT = createAction('query hotelStatistics list')

const SET_DESTINATION_ID = createAction('hotel statistics list destination id')
const SET_HOTEL_ID = createAction('hotel statistics list hotel id')
const SET_FROM_DATE = createAction('hotel statistics list from date')
const SET_TO_DATE = createAction('hotel statistics list to date')
const RESET_DATES = createAction('hotel statistics reset dates')
const SET_SEASON_FROM_DATE = createAction('hotel statistics list season from date')
const SET_SEASON_TO_DATE = createAction('hotel statistics list season to date')

const initialState = {
  selectedDestinationId: null,
  selectedHotelId: null,
  selectedFromDate: null,
  selectedToDate: null,
  selectedSeasonFromDate: moment(),
  selectedSeasonToDate: moment().add(3, 'weeks'),
  loading: false,
  item: {}
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, item: action.payload }
    },
    [SET_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload.item }
    },
    [SET_HOTEL_ID]: (state, action) => {
      return { ...state, selectedHotelId: action.payload.item }
    },
    [SET_FROM_DATE]: (state, action) => {
      return { ...state, selectedFromDate: moment(action.payload.item) }
    },
    [SET_TO_DATE]: (state, action) => {
      return { ...state, selectedToDate: moment(action.payload.item) }
    },
    [RESET_DATES]: (state, action) => {
      return { ...state, selectedToDate: initialState.selectedToDate, selectedFromDate: initialState.selectedFromDate }
    },
    [SET_SEASON_FROM_DATE]: (state, action) => {
      return { ...state, selectedSeasonFromDate: moment(action.payload.item) }
    },
    [SET_SEASON_TO_DATE]: (state, action) => {
      return { ...state, selectedSeasonToDate: moment(action.payload.item) }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    var destinationId = getState().production.hotelStatistics.selectedDestinationId
    var hotelId = getState().production.hotelStatistics.selectedHotelId
    var fromDate = getState().production.hotelStatistics.selectedFromDate
    var toDate = getState().production.hotelStatistics.selectedToDate

    if (destinationId && fromDate && toDate) {
      api
        .query('hotel/charterPeriod/hotelStatistics', { destinationId, hotelId, fromDate, toDate })
        .then(json => dispatch(QUERY_REPORT(json.data)))
    }
  }
}

export const setDestinationId = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_ID({ item }))
  }
}

export const setHotelId = item => {
  return dispatch => {
    dispatch(SET_HOTEL_ID({ item }))
  }
}

export const setFromDate = item => {
  return dispatch => {
    dispatch(SET_FROM_DATE({ item }))
  }
}

export const setToDate = item => {
  return dispatch => {
    dispatch(SET_TO_DATE({ item }))
  }
}

export const resetDates = () => {
  return dispatch => {
    dispatch(RESET_DATES())
  }
}

export const setSeasonFromDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_FROM_DATE({ item }))
  }
}

export const setSeasonToDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_TO_DATE({ item }))
  }
}
