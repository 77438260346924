import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_REPORT = createAction('query cancellation insurances')
const QUERY_EXPORTS = createAction('query exports')

const SET_DEPARTURE_DATE_FROM = createAction(
  'accounts cancellation insurances set departure date from'
)
const SET_DEPARTURE_DATE_TO = createAction(
  'accounts cancellation insurances set departure date to'
)

const SET_BOOKING_DATE_FROM = createAction(
  'accounts cancellation insurances set booking date from'
)
const SET_BOOKING_DATE_TO = createAction(
  'accounts cancellation insurances set booking date to'
)
const SET_MARKET_ID = createAction(
  'accounts cancellation insurances set market id'
)

const TOGGLE_INCLUDE_ALREADY_EXPORTED = createAction(
  'accounts cancellation insurances toggle include already exported'
)

const initialState = {
  selectedDepartureDateFrom: moment().startOf('day'),
  selectedDepartureDateTo: moment().endOf('day'),
  selectedBookingDateFrom: moment().startOf('day'),
  selectedBookingDateTo: moment().endOf('day'),
  loading: false,
  item: '',
  includeAlreadyExported: false,
  marketId: null,
  exports: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, item: action.payload }
    },
    [QUERY_EXPORTS]: (state, action) => {
      return { ...state, exports: action.payload }
    },
    [SET_DEPARTURE_DATE_FROM]: (state, action) => {
      return {
        ...state,
        selectedDepartureDateFrom: moment(action.payload.item)
      }
    },
    [SET_DEPARTURE_DATE_TO]: (state, action) => {
      return { ...state, selectedDepartureDateTo: moment(action.payload.item) }
    },
    [SET_BOOKING_DATE_FROM]: (state, action) => {
      return { ...state, selectedBookingDateFrom: moment(action.payload.item) }
    },
    [SET_BOOKING_DATE_TO]: (state, action) => {
      return { ...state, selectedBookingDateTo: moment(action.payload.item) }
    },
    [TOGGLE_INCLUDE_ALREADY_EXPORTED]: state => {
      console.log(state)
      return {
        ...state,
        includeAlreadyExported: !state.includeAlreadyExported
      }
    },
    [SET_MARKET_ID]: (state, action) => {
      return { ...state, marketId: action.payload }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    const marketId = getState().economy.cancellationInsurances.marketId
    var departureDateFrom =
      getState().economy.cancellationInsurances.selectedDepartureDateFrom
    var departureDateTo =
      getState().economy.cancellationInsurances.selectedDepartureDateTo.endOf(
        'day'
      )
    var bookingDateFrom =
      getState().economy.cancellationInsurances.selectedBookingDateFrom
    var bookingDateTo =
      getState().economy.cancellationInsurances.selectedBookingDateTo.endOf(
        'day'
      )
    var includeAlreadyExported =
      getState().economy.cancellationInsurances.includeAlreadyExported
    api
      .query('travelbooking/reports/cancellationInsurances', {
        departureDateFrom,
        departureDateTo,
        bookingDateFrom,
        bookingDateTo,
        marketId,
        includeAlreadyExported
      })
      .then(json => dispatch(QUERY_REPORT(json.data)))
  }
}

export const queryExports = () => {
  return (dispatch, getState) => {
    api
      .query(
        'travelBooking/cancellationInsurancesExport/CancellationInsurancesExports',
        {}
      )
      .then(json => dispatch(QUERY_EXPORTS(json.data)))
  }
}

export const createCancellationInsurancesExport = () => {
  return (dispatch, getState) => {
    const item = getState().economy.cancellationInsurances.item
    api.sendCommand(
      'travelbooking/cancellationInsurancesExport/createCancellationInsurancesExport',
      {
        numberOfInsurances: item.insurances.length,
        reservationIds: [...new Set(item.insurances.map(x => x.reservationId))],
        insuranceRows: item.insurances,
        ervFile: item.ervFile
      }
    )
  }
}

export const setDepartureDateFrom = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_DATE_FROM({ item }))
  }
}

export const setDepartureDateTo = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_DATE_TO({ item }))
  }
}

export const setBookingDateFrom = item => {
  return dispatch => {
    dispatch(SET_BOOKING_DATE_FROM({ item }))
  }
}

export const setBookingDateTo = item => {
  return dispatch => {
    dispatch(SET_BOOKING_DATE_TO({ item }))
  }
}

export const toggleIncludeAlreadyExported = () => {
  return dispatch => {
    dispatch(TOGGLE_INCLUDE_ALREADY_EXPORTED())
  }
}

export const setMarketId = marketId => dispatch =>
  dispatch(SET_MARKET_ID(marketId))
