import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

const GET_UNPROCESSED_PAYMENTS = createAction('get unprocessed card payments')
const GET_UNPROCESSED_PAYMENTS_SUCCESS = createAction('get unprocessed card payments successful')
const GET_UNPROCESSED_PAYMENTS_ERROR = createAction('get unprocessed card payments error')

const PROCESS_PAYMENT = createAction('set payment as processed')
const PROCESS_PAYMENT_SUCCESS = createAction('set payment as processed successful')
const PROCESS_PAYMENT_ERROR = createAction('set payment as processed error')

const SELECT_PAYMENT = createAction('select payment to be processed')
const UNSELECT_PAYMENT = createAction('unselect payments from being processed')
const TOGGLE_ALL = createAction('toggle all payments to be processed')

const initalState = {
  payments: [],
  selectedPayments: [],
  totalAmountCaptured: 0,
  totalAmountProcessed: 0,
  loading: false,
  allSelected: false,
  error: null
}

export default handleActions(
  {
    [GET_UNPROCESSED_PAYMENTS]: (state, action) => ({ ...state, loading: true, payments: [], error: null }),
    [GET_UNPROCESSED_PAYMENTS_SUCCESS]: (state, action) => {
      action.payload.unprocessedTransactions.forEach(function (p) {
        p.loading = false
        p.checked = false
        p.isProcessed = null
      })
      return {
        ...state,
        loading: false,
        payments: action.payload.unprocessedTransactions,
        totalAmountCaptured: action.payload.totalAmountCaptured,
        totalAmountProcessed: action.payload.totalAmountProcessed
      }
    },
    [GET_UNPROCESSED_PAYMENTS_ERROR]: (state, action) => ({ ...state, loading: false, error: action.payload }),
    [PROCESS_PAYMENT]: (state, action) => {
      let p = state.payments.find(i => i.transactionId === action.payload)
      p.loading = true
      return { ...state, payments: state.payments }
    },
    [PROCESS_PAYMENT_SUCCESS]: (state, action) => {
      let p = state.payments.find(i => i.transactionId === action.payload)
      p.loading = false
      p.isProcessed = true
      return { ...state, payments: state.payments }
    },
    [PROCESS_PAYMENT_ERROR]: (state, action) => {
      let p = state.payments.find(i => i.transactionId === action.payload)
      p.loading = false
      p.isProcessed = false
      return { ...state, loading: false, payments: state.payments }
    },
    [SELECT_PAYMENT]: (state, action) => {
      let selectedPayment = state.payments.find(i => i.transactionId === action.payload)
      selectedPayment.checked = true
      state.selectedPayments.push({ ...selectedPayment })
      let all = state.payments && state.selectedPayments && state.payments.length === state.selectedPayments.length
      return {
        ...state,
        payments: state.payments,
        selectedPayments: state.selectedPayments,
        allSelected: all
      }
    },
    [UNSELECT_PAYMENT]: (state, action) => {
      let selectedPayment = state.payments.find(i => i.transactionId === action.payload)
      selectedPayment.checked = false
      let selectedPayments = state.selectedPayments.filter(function (p) {
        return p.transactionId !== selectedPayment.transactionId
      })
      return {
        ...state,
        payments: state.payments,
        selectedPayments: selectedPayments,
        allSelected: false
      }
    },
    [TOGGLE_ALL]: (state, action) => {
      let allSelected = !state.allSelected
      state.payments.forEach(function (p) {
        p.checked = allSelected
      })
      return {
        ...state,
        payments: state.payments,
        selectedPayments: allSelected ? [...state.payments] : [],
        allSelected: allSelected
      }
    }
  },
  initalState
)

export const getUnprocessedPayments = (fromDate, toDate) => {
  return dispatch => {
    dispatch(GET_UNPROCESSED_PAYMENTS())
    return api
      .query(`travelbooking/cardpaymenttransaction/unprocessedTransactions?fromDate=${fromDate}&toDate=${toDate}`)
      .then(result => {
        dispatch(GET_UNPROCESSED_PAYMENTS_SUCCESS(result.data))
      })
      .catch(err => {
        dispatch(GET_UNPROCESSED_PAYMENTS_ERROR(err))
        dispatch(globalMessageActions.setGlobalError('Could not get unprocessed payments'))
      })
  }
}

export const processSelectedPayments = () => {
  return (dispatch, getState) => {
    let state = getState().economy.unprocessedPayments
    state.selectedPayments.forEach(function (p) {
      dispatch(PROCESS_PAYMENT(p.transactionId))
      return api
        .sendCommand('travelbooking/cardpaymenttransaction/completeCardPaymentTransaction', {
          cardPaymentTransactionId: p.id
        })
        .then(res => dispatch(PROCESS_PAYMENT_SUCCESS(p.transactionId)))
        .then(res => dispatch(UNSELECT_PAYMENT(p.transactionId)))
        .catch(err => dispatch(PROCESS_PAYMENT_ERROR(p.transactionId)))
    })
  }
}

export const toggleSelect = (transactionId, checked) => {
  return dispatch => {
    checked ? dispatch(UNSELECT_PAYMENT(transactionId)) : dispatch(SELECT_PAYMENT(transactionId))
  }
}

export const toggleSelectAll = () => {
  return dispatch => {
    dispatch(TOGGLE_ALL())
  }
}
