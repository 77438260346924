import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import { v4 as uuid } from 'uuid'
import * as globalMessageActions from '../global-message'

const EDIT_ACTIVE_CARD_PAYMENT = createAction('edit active card payment')
const UPDATE_ACTIVE_CARD_PAYMENT = createAction('update active card payment')
const RESET_ACTIVE_CARD_PAYMENT = createAction('reset active card payment')

const INIT_TRANSACTION = createAction('init active to payment model')
const SET_TRANSACTION = createAction('set active to payment model')
const RESET_TRANSACTION_URL = createAction('reset active payment model')
const REGISTER_TRANSACTION = createAction('register transaction')
const REGISTER_TRANSACTION_SUCCESS = createAction(
  'register transaction successful'
)

const ADD_MANUAL_PAYMENT = createAction('add a manual payment')
const ADD_MANUAL_PAYMENT_SUCCESS = createAction('manual payment success')
const ADD_MANUAL_PAYMENT_ERROR = createAction('manual payment error')

const GET_PAYMENT_CODES = createAction('get payment codes')
const GET_PAYMENT_CODES_SUCCESS = createAction('get payment codes successful')
const GET_PAYMENT_CODES_ERROR = createAction('get payment codes error')

const GET_TRANSACTION_ID = createAction('get transactionid for payment')
const GET_TRANSACTION_ID_SUCCESS = createAction(
  'get transactionid for payment successful'
)

const PERFORM_TRANSACTION_SALE = createAction('perform transaction sale')
const PERFORM_TRANSACTION_SALE_SUCCESS = createAction(
  'perform transaction sale successful'
)
const PERFORM_TRANSACTION_SALE_ERROR = createAction(
  'perform transaction sale error'
)

const GET_TERMINALS = createAction('query terminals')
const GET_TERMINALS_SUCCESS = createAction('query terminals success')
const GET_TERMINALS_ERROR = createAction('query terminals error')

const initalState = {
  items: [],
  loading: false,
  active: null,
  paymentCodes: [],
  sort: { orderBy: 'timestamp', direction: 'desc' },
  filter: {
    paymentMethod: '',
    currencyId: 'SEK',
    status: '',
    paymentType: '',
    card: 'AllCards',
    directBank: 'AllBanks'
  },
  totalAmount: 0,
  fromDate: null,
  toDate: null,
  reservationCode: '',
  embeddedUrl: '',
  page: 0,
  rowsPerPage: 25,
  paymentMethod: '',
  paymentStatus: null
}

let errorMessages = {
  Internal: 'An internal error occured while trying to process the payment.',
  InvalidCard: 'Could not process the payment. The card number was invalid',
  ExpiredCard: 'Could not process the payment. The card is expired.',
  InvalidCvc: 'Could not process the payment. The CVC provided was incorrect.',
  Denied3DSecure: 'Could not process the payment. Denied by 3D Secure.',
  ContactIssuer:
    'Could not process the payment. Please contact the issuer for the card for more details.'
}

export default handleActions(
  {
    [EDIT_ACTIVE_CARD_PAYMENT]: (state, action) => {
      if (!action.payload) return { ...state }
      // let status = action.payload.payment.status === 'PROCESSED_OK' ? true : false;
      if (action.payload.payment.comment == null)
        action.payload.payment.comment = ''
      return { ...state, active: { ...action.payload.payment } }
    },
    [UPDATE_ACTIVE_CARD_PAYMENT]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: { ...active } }
    },
    [RESET_ACTIVE_CARD_PAYMENT]: (state, action) => {
      return { ...state, active: null }
    },
    [INIT_TRANSACTION]: (state, action) => {
      let id = uuid()
      let model = {
        id: id,
        amount: 0,
        marketId: 'SE',
        reservationCode: '',
        reservationId: '',
        reference: '',
        languageId: 'sv_SE',
        paymentMethod: state.paymentMethod,
        redirectUrl: `${window.location.protocol}//${window.location.host}/economy/payments/card/make-payment?id=${id}`,
        type: 'Other'
      }
      return { ...state, active: model }
    },
    [SET_TRANSACTION]: (state, action) => {
      let active = state.active
      active[action.payload.name] = action.payload.model
      return { ...state, active: { ...active } }
    },
    [RESET_TRANSACTION_URL]: (state, action) => {
      return { ...state, embeddedUrl: '' }
    },
    [REGISTER_TRANSACTION]: (state, action) => {
      return { ...state, loading: true }
    },
    [REGISTER_TRANSACTION_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [ADD_MANUAL_PAYMENT]: (state, action) => {
      return { ...state, loading: true, paymentStatus: null }
    },
    [ADD_MANUAL_PAYMENT_SUCCESS]: (state, action) => {
      return { ...state, loading: false, paymentStatus: true }
    },
    [ADD_MANUAL_PAYMENT_ERROR]: (state, action) => {
      return { ...state, loading: false, paymentStatus: false }
    },
    [GET_PAYMENT_CODES]: (state, action) => {
      return { ...state, loading: true }
    },
    [GET_PAYMENT_CODES_SUCCESS]: (state, action) => {
      return { ...state, loading: false, paymentCodes: action.payload }
    },
    [GET_PAYMENT_CODES_ERROR]: (state, action) => {
      return { ...state, loading: false }
    },
    [GET_TRANSACTION_ID]: (state, action) => {
      return { ...state, loading: true }
    },
    [GET_TRANSACTION_ID_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
        embeddedUrl: action.payload.data.embeddedUrl ?? ''
      }
    },
    [PERFORM_TRANSACTION_SALE]: (state, action) => {
      return { ...state, loading: true }
    },
    [PERFORM_TRANSACTION_SALE_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [PERFORM_TRANSACTION_SALE_ERROR]: (state, action) => {
      return { ...state, loading: false }
    },
    [GET_TERMINALS]: state => {
      return { ...state, loading: true }
    },
    [GET_TERMINALS_SUCCESS]: state => {
      return { ...state, loading: false }
    },
    [GET_TERMINALS_ERROR]: state => {
      return { ...state, loading: false }
    }
  },
  initalState
)

export function getTerminals () {
  return function (dispatch) {
    dispatch(GET_TERMINALS())

    return api
      .query('altapay/terminals', { useBackOfficeTerminal: true })
      .then(res => {
        const terminals = res.data
        if (terminals.length === 0) {
          dispatch(
            UPDATE_ACTIVE_CARD_PAYMENT({
              name: 'paymentType',
              value: 'CreditCard'
            })
          )
        } else {
          dispatch(
            UPDATE_ACTIVE_CARD_PAYMENT({
              name: 'terminalId',
              value: terminals.find(t => t.useInBackOffice)?.id
            })
          )
        }
        dispatch(GET_TERMINALS_SUCCESS())
      })
      .catch(err => {
        dispatch(GET_TERMINALS_ERROR())
        dispatch(
          globalMessageActions.setGlobalError(
            `Failed to fetch altapay terminals`
          )
        )
      })
  }
}

export function beginAltaPayTransaction (model) {
  return function (dispatch) {
    dispatch(REGISTER_TRANSACTION())
    if (model.reservationId) {
      return api
        .sendCommand('altapay/initiatepayment', model)
        .then(() =>
          api
            .query('travelbooking/cardPaymentTransaction/transactionId', {
              id: model.id
            })
            .then(result => dispatch(GET_TRANSACTION_ID_SUCCESS(result)))
        )
        .finally(() => dispatch(REGISTER_TRANSACTION_SUCCESS()))
    }
  }
}

export function addManualPayment (
  amount,
  currencyId,
  paymentCode,
  reservationCode,
  serialNumber,
  comment
) {
  return dispatch => {
    dispatch(ADD_MANUAL_PAYMENT())
    return api
      .sendCommand('travelbooking/AddManualPayment', {
        amount: amount,
        currencyId: currencyId,
        reservationCode: reservationCode,
        paymentCode: paymentCode,
        serialNumber: serialNumber,
        comment: comment
      })
      .then(result => {
        dispatch(ADD_MANUAL_PAYMENT_SUCCESS())
        dispatch(
          globalMessageActions.setGlobalSuccess('Payment was successful')
        )
      })
      .catch(err => {
        dispatch(ADD_MANUAL_PAYMENT_ERROR())
        dispatch(
          globalMessageActions.setGlobalError(
            `Payment unsuccessful. Error: ${err.error.message}`
          )
        )
      })
  }
}

export function getTransactionId (id) {
  return function (dispatch, getState) {
    dispatch(GET_TRANSACTION_ID())
    return api
      .query('travelbooking/cardpaymenttransaction/TransactionId', { id })
      .then(result => {
        dispatch(GET_TRANSACTION_ID_SUCCESS(result))
      })
  }
}

export function performTransactionSale (id, transactionId, responseCode) {
  return function (dispatch, getState) {
    if (responseCode === 'OK') {
      dispatch(PERFORM_TRANSACTION_SALE())
      let command = { cardPaymentTransactionId: id }
      return api
        .sendCommand(
          'travelbooking/cardpaymenttransaction/ProcessCardPaymentTransaction',
          command
        )
        .then(result => {
          dispatch(PERFORM_TRANSACTION_SALE_SUCCESS())
          dispatch(
            globalMessageActions.setGlobalSuccess('Payment was successful')
          )
        })
        .catch(err => {
          dispatch(PERFORM_TRANSACTION_SALE_ERROR())
          if (
            err !== undefined &&
            err.error !== undefined &&
            err.error.errors.length === 1 &&
            err.error.errors['0'].details !== null
          ) {
            dispatch(
              globalMessageActions.setGlobalError(
                errorMessages[
                  err.error.errors['0'].details.error.result.responseSource
                ]
              )
            )
          }
        })
    }
  }
}

export const getPaymentCodes = () => {
  return dispatch => {
    dispatch(GET_PAYMENT_CODES())
    return api
      .query('travelbooking/paymentcodes', '')
      .then(result => {
        dispatch(GET_PAYMENT_CODES_SUCCESS(result.data))
      })
      .catch(error => {
        dispatch(GET_PAYMENT_CODES_ERROR())
      })
  }
}

export const editActiveCardPayment = payment => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_CARD_PAYMENT({ payment }))
  }
}

export const resetActiveCardPayment = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_CARD_PAYMENT())
  }
}

export const updateActiveCardPayment = (value, name) => {
  return dispatch => {
    dispatch(UPDATE_ACTIVE_CARD_PAYMENT({ value, name }))
  }
}

export const formIsValid = model => {
  return dispatch => {
    if (!model.amount) {
      dispatch(globalMessageActions.setGlobalError("Payment amount can't be 0"))
      return false
    } else if (
      model.amount.indexOf('.') >= 0 ||
      model.amount.indexOf(',') >= 0
    ) {
      if (model.currencyId === 'EUR') {
        let separatorIndex =
          model.amount.indexOf('.') >= 0
            ? model.amount.indexOf('.')
            : model.amount.indexOf(',')
        let decimals =
          model.amount.length -
          model.amount.substring(separatorIndex, model.amount.length)
        if (decimals !== 1) {
          dispatch(
            globalMessageActions.setGlobalError(
              'Euro payments can only contain 1 decimal'
            )
          )
          return false
        }
        return true
      } else {
        dispatch(
          globalMessageActions.setGlobalError(
            'Non-euro payments can not contain decimals'
          )
        )
        return false
      }
    }
    return true
  }
}

export const initTransaction = () => {
  return dispatch => {
    dispatch(INIT_TRANSACTION())
  }
}

export const setTransaction = (name, model) => {
  return dispatch => {
    dispatch(SET_TRANSACTION({ name, model }))
  }
}

export const resetEmbeddedUrl = () => {
  return dispatch => {
    dispatch(RESET_TRANSACTION_URL())
  }
}
