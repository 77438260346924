import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'

// Actions
const SET_FACILITIES = createAction('set contract facilities')
const CANCEL_EDITING_OF_FACILITIES = createAction('cancel set contract facilities')
const RESET_ACTIVE_FACILITIES = createAction('cancel active faclities')
const SET_ACTIVE_FACILITIES = createAction('set active faclities')
const TOGGLE_FACILITY = createAction('toggle hotel contract facility')

const initialState = {
  active: [],
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [SET_FACILITIES]: (state, action) => {
      if (!action.payload) return { ...state, active: null }
      return { ...state, items: action.payload, active: { facilities: action.payload.slice(0) } }
    },
    [CANCEL_EDITING_OF_FACILITIES]: (state, action) => {
      return { ...state, active: { facilities: state.items.slice(0) } }
    },
    [SET_ACTIVE_FACILITIES]: (state, action) => {
      return { ...state, active: { facilities: action.payload.slice(0) } }
    },
    [RESET_ACTIVE_FACILITIES]: (state, action) => {
      return { ...state, active: null }
    },
    [TOGGLE_FACILITY]: (state, action) => {
      let active = state.active
      if (active.facilities.some(f => f === action.payload)) {
        let index = active.facilities.indexOf(action.payload)
        active.facilities.splice(index, 1)
      } else {
        active.facilities.push(action.payload)
      }
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators
export const setFacilities = items => {
  return dispatch => {
    dispatch(SET_FACILITIES(items))
  }
}

export const updateFacilities = (contractId, item) => {
  return (dispatch, getState) => {
    return api.sendCommand('hotel/contract/setFacilities', { contractId, facilities: item.facilities }).then(() => {
      dispatch(contractActions.getHotelContract(contractId))
      dispatch(RESET_ACTIVE_FACILITIES())
      dispatch(globalMessageActions.setGlobalSuccess('Facilities updated'))
    })
  }
}

export const cancelEditing = item => {
  return dispatch => {
    dispatch(CANCEL_EDITING_OF_FACILITIES())
  }
}

export const setActiveFacilities = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_FACILITIES(item))
  }
}

export const resetActiveFacilities = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_FACILITIES())
  }
}

export const toggleFacility = id => {
  return dispatch => {
    dispatch(TOGGLE_FACILITY(id))
  }
}
