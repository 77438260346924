import { createAction, handleActions } from "redux-actions";
import api from "../api";
import moment from "moment-timezone";

// Actions
const SET_LOADING = createAction("set loading is true");
const QUERY_REPORT = createAction("query cost for reservations");
const CHANGE_FILTER = createAction(
  "cost for reservations change filter"
);


const initialState = {
  loading: false,
  reservations: [],
  filter: {
    selectedReservationCode: '',
    selectedDepartureDateFrom: moment().startOf("day"),
    selectedDepartureDateTo: moment().endOf("day"),
    selectedBookingDateFrom: moment().startOf("day"),
    selectedBookingDateTo: moment().endOf("day"),
    selectedSalesChannel: 'All',
    selectedDestinationId: 'All',
    selectedMarketId: 'All'
  }
};

// Reducers/handlers
export default handleActions(
  {
    [SET_LOADING]: (state, action) => {
      return { ...state, loading: true }
    },
    [QUERY_REPORT]: (state, action) => {
      return { ...state, reservations: action.payload, loading: false };
    },
    [CHANGE_FILTER]: (state, action) => {
      return {
        ...state,
        filter: { ...state.filter, [action.payload.key]: action.payload.value }
      }
    }
  },
  initialState
);

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    dispatch(SET_LOADING())
    var filter = getState().economy.costForReservations.filter
    var departureDateFrom =
      filter.selectedDepartureDateFrom;
    var departureDateTo =
      getState().economy.costForReservations.filter.selectedDepartureDateTo.endOf(
        "day"
      );
    var bookingDateFrom =
      filter.selectedBookingDateFrom;
    var bookingDateTo =
     filter.selectedBookingDateTo.endOf(
        "day"
      );
    var reservationCode = filter.selectedReservationCode
    var marketId = filter.selectedMarketId === 'All' ? '' : filter.selectedMarketId
    var destinationId = filter.selectedDestinationId === 'All' ? '' : filter.selectedDestinationId
    var salesChannel = filter.selectedSalesChannel === 'All' ? '' : filter.selectedSalesChannel
    api
      .query("travelbooking/reports/costForReservations", {
        travelDateFrom: departureDateFrom,
        travelDateTo: departureDateTo,
        bookingDateFrom,
        bookingDateTo,
        reservationCode,
        marketId,
        destinationId,
        salesChannel
      })
      .then((json) => dispatch(QUERY_REPORT(json.data)));
  };
};

export const changeFilter = (key, value) => {
  return (dispatch) => {
    console.log(key, value)
    dispatch(CHANGE_FILTER({ key, value }));
  };
};

