import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_REPORT = createAction('query income')

const SET_RESERVATION_FROM_DATE = createAction('incomes reservation date from')
const SET_RESERVATION_TO_DATE = createAction('incomes reservation date to')
const SET_DEPARTURE_FROM_DATE = createAction('incomes departure date from')
const SET_DEPARTURE_TO_DATE = createAction('incomes departure date to')
const SET_DESTINATION_IDS = createAction('incomes destination id')
const SET_HOTEL_ID = createAction('incomes hotel id')
const SET_MARKET_ID = createAction('incomes market id')
const SET_RESERVATION_STATUS = createAction('incomes reservation status')
const SET_FLIGHT_NUMBERS = createAction('incomes statistics set flight number')
const SET_TIME_CONSISTANCY_DATE = createAction('incomes statistics set time consistancy date')
const SET_SELLER = createAction('incomes statistics set seller')

const initialState = {
  selectedReservationFromDate: null,
  selectedReservationToDate: null,
  selectedDepartureFromDate: null,
  selectedDepartureToDate: null,
  selectedFlightNumbers: null,
  selectedDestinationIds: null,
  selectedHotelId: null,
  selectedMarketId: null,
  selectedReservationStatus: null,
  selectedTimeConsistancyDate: null,
  selectedSellerId: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_RESERVATION_FROM_DATE]: (state, action) => {
      return { ...state, selectedReservationFromDate: action.payload.item }
    },
    [SET_RESERVATION_TO_DATE]: (state, action) => {
      return { ...state, selectedReservationToDate: action.payload.item }
    },
    [SET_DEPARTURE_FROM_DATE]: (state, action) => {
      return { ...state, selectedDepartureFromDate: action.payload.item }
    },
    [SET_DEPARTURE_TO_DATE]: (state, action) => {
      return { ...state, selectedDepartureToDate: action.payload.item }
    },
    [SET_DESTINATION_IDS]: (state, action) => {
      return { ...state, selectedDestinationIds: action.payload.item }
    },
    [SET_HOTEL_ID]: (state, action) => {
      return { ...state, selectedHotelId: action.payload.item }
    },
    [SET_MARKET_ID]: (state, action) => {
      return { ...state, selectedMarketId: action.payload.item }
    },
    [SET_RESERVATION_STATUS]: (state, action) => {
      return { ...state, selectedReservationStatus: action.payload.item }
    },
    [SET_FLIGHT_NUMBERS]: (state, action) => {
      return { ...state, selectedFlightNumbers: action.payload.item }
    },
    [SET_TIME_CONSISTANCY_DATE]: (state, action) => {
      return { ...state, selectedTimeConsistancyDate: action.payload.item }
    },
    [SET_SELLER]: (state, action) => {
      return { ...state, selectedSellerId: action.payload.item }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    let reservationFromDate = getState().economy.incomes.selectedReservationFromDate
    let reservationToDate = getState().economy.incomes.selectedReservationToDate
    let departureFromDate = getState().economy.incomes.selectedDepartureFromDate
    let departureToDate = getState().economy.incomes.selectedDepartureToDate
    let destinationIds = getState().economy.incomes.selectedDestinationIds
    let hotelId = getState().economy.incomes.selectedHotelId
    let marketId = getState().economy.incomes.selectedMarketId
    let reservationStatus = getState().economy.incomes.selectedReservationStatus
    let transports = getState().economy.incomes.selectedFlightNumbers
    let seller = getState().economy.incomes.selectedSellerId
    let viewDate = getState().economy.incomes.selectedTimeConsistancyDate

    if (destinationIds && destinationIds.some(d => d === '<All>')) {
      destinationIds = null
    }

    if (transports && transports.some(d => d === 'All transports')) {
      transports = null
    }

    api
      .query('travelbooking/reports/incomes', {
        reservationFromDate,
        reservationToDate : reservationToDate ? moment(reservationToDate).endOf('day') : null,
        departureFromDate,
        departureToDate : departureToDate ? moment(departureToDate).endOf('day') : null,
        destinationIds,
        hotelId,
        marketId,
        reservationStatus,
        transports,
        seller,
        viewDate: viewDate ? moment(viewDate).endOf('day') : null
      })
      .then(json => dispatch(QUERY_REPORT(json.data)))
  }
}

export const setReservationFromDate = item => {
  return dispatch => {
    dispatch(SET_RESERVATION_FROM_DATE({ item }))
  }
}

export const setReservationToDate = item => {
  return dispatch => {
    dispatch(SET_RESERVATION_TO_DATE({ item }))
  }
}

export const setDepartureFromDate = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_FROM_DATE({ item }))
  }
}

export const setDepartureToDate = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_TO_DATE({ item }))
  }
}

export const setDestinationIds = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_IDS({ item }))
  }
}

export const setHotelId = item => {
  return dispatch => {
    dispatch(SET_HOTEL_ID({ item }))
  }
}

export const setMarketId = item => {
  return dispatch => {
    dispatch(SET_MARKET_ID({ item }))
  }
}

export const setReservationStatus = item => {
  return dispatch => {
    dispatch(SET_RESERVATION_STATUS({ item }))
  }
}

export const setFlightNumbers = item => {
  return dispatch => {
    dispatch(SET_FLIGHT_NUMBERS({ item }))
  }
}

export const setTimeConsistancyDate = item => {
  return dispatch => {
    dispatch(SET_TIME_CONSISTANCY_DATE({ item }))
  }
}

export const setSeller = item => {
  return dispatch => {
    dispatch(SET_SELLER({ item }))
  }
}
