import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import { uniqueByTwoDates } from '../../common/group-by'
// import * as globalMessageActions from '../global-message'

const QUERY_CHARTER_PERIODS = createAction('query destination charter periods')
const QUERY_CHARTER_PERIODS_SUCCESS = createAction('query destination charter periods success')
const QUERY_GROUPED_CHARTER_PERIODS = createAction('query grouped destination charter periods')
const QUERY_GROUPED_CHARTER_PERIODS_SUCCESS = createAction('query grouped destination charter periods success')

const initialState = {
  items: [],
  groupedItems: [],
  allGroupedItems: [],
  active: null,
  loading: false
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_CHARTER_PERIODS]: (state, action) => {
      return { ...state, items: [], loading: true }
    },
    [QUERY_CHARTER_PERIODS_SUCCESS]: (state, action) => {
      let items = action.payload.map(cp => {
        return { checkinDate: cp.checkinDate, checkoutDate: cp.checkoutDate }
      })

      let groupedItems = uniqueByTwoDates(items, 'checkinDate', 'checkoutDate')

      return { ...state, items: action.payload, groupedItems: groupedItems, loading: false }
    },
    [QUERY_GROUPED_CHARTER_PERIODS]: (state, action) => {
      return { ...state, allGroupedItems: [], loading: true }
    },
    [QUERY_GROUPED_CHARTER_PERIODS_SUCCESS]: (state, action) => {
      return { ...state, allGroupedItems: action.payload, loading: false }
    }
  },
  initialState
)

export const queryCharterPeriods = (countryId, fromDate, toDate) => {
  return dispatch => {
    dispatch(QUERY_CHARTER_PERIODS())

    return api
      .query('hotel/destinationCharterPeriod/destinationcharterperiods', { countryId, fromDate, toDate })
      .then(res => dispatch(QUERY_CHARTER_PERIODS_SUCCESS(res.data)))
  }
}

export const queryGroupedCharterPeriods = countryId => {
  return dispatch => {
    dispatch(QUERY_GROUPED_CHARTER_PERIODS())

    return api
      .query('hotel/destinationCharterPeriod/groupeddestinationcharterperiods', { countryId })
      .then(res => dispatch(QUERY_GROUPED_CHARTER_PERIODS_SUCCESS(res.data)))
  }
}
