import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'

// Actions
const SET_MINIMUM_AGE = createAction('set minimum age')
const RESET_MINIMUM_AGE = createAction('reset active minimum age')
const SET_VALUE = createAction('set active minimum age value')
const SET_ACTIVE_CONTRACT_MINIMUM_AGE = createAction('set active minimum age')

const initialState = {
  active: null,
  loading: false,
  minimumAge: null,
  item: null
}

// Reducers/handlers
export default handleActions(
  {
    [SET_MINIMUM_AGE]: (state, action) => {
      return { ...state, loading: false, item: action.payload }
    },
    [SET_ACTIVE_CONTRACT_MINIMUM_AGE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_MINIMUM_AGE]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators
export const setMinimumAge = items => {
  return (dispatch, getState) => {
    dispatch(SET_MINIMUM_AGE(items))
  }
}

export const updateMinimumAge = item => {
  return (dispatch, getState) => {
    return api.sendCommand('hotel/contract/setMinimumAge', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(SET_ACTIVE_CONTRACT_MINIMUM_AGE())
      dispatch(globalMessageActions.setGlobalSuccess('Minimum age updated'))
    })
  }
}

export const setActiveMinimumAge = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_CONTRACT_MINIMUM_AGE(item))
  }
}

export const resetActiveMinimumAge = () => {
  return dispatch => {
    dispatch(RESET_MINIMUM_AGE())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
