import { createAction, handleActions } from 'redux-actions'
import api from '../../api'

// Actions
const QUERY_FLIGHT_CONTRACTS = createAction('query flight contracts from server')
const QUERY_FLIGHT_CONTRACTS_SUCCESS = createAction('query flight contracts from server success')

const initialState = {
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_FLIGHT_CONTRACTS]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [QUERY_FLIGHT_CONTRACTS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload.contracts }
    }
  },
  initialState
)

// Action creators
export const queryFlightContracts = () => {
  return (dispatch, getState) => {
    dispatch(QUERY_FLIGHT_CONTRACTS())
    api.query('flightdeparture/contract/contracts').then(res => dispatch(QUERY_FLIGHT_CONTRACTS_SUCCESS(res.data)))
  }
}
