import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const QUERY_PROPOSAL = createAction('query booking proposal')
const QUERY_ROOM_PROPOSAL = createAction('query booking room proposal')
const LOADING = createAction('loading')
const SET_SORT = createAction('set proposal sort')
const SET_FILTER_VALUE = createAction('set proposal filter value')
const SET_ACTIVE_TAB = createAction('booking set active tab')
const SET_ACTIVE_ALTERNATIVE_PROPOSAL = createAction('booking set active alternative proposal')

const initialState = {
  data: null,
  loading: false,
  sortOrder: '',
  filter: {},
  activeTab: 0
}

// handlers
export default handleActions(
  {
    [QUERY_PROPOSAL]: (state, action) => {
      return { ...state, data: action.payload }
    },
    [QUERY_ROOM_PROPOSAL]: (state, action) => {
      const newPeriod = action.payload.periods[0]

      return {
        ...state,
        data: {
          ...state.data,
          periods: state.data.periods.map(period =>
            period.out === newPeriod.out && period.home === newPeriod.home
              ? {
                ...period,
                proposals: period.proposals.map(proposal =>
                  proposal.hotel.hotelId === newPeriod.proposals[0].hotel.hotelId &&
                    proposal.package.id === newPeriod.proposals[0].package.id
                    ? {
                      ...proposal,
                      flights: newPeriod.proposals.map(newPeriodProposal => {
                        return {
                          ...newPeriodProposal.flightCharter,
                          price: newPeriodProposal.packagePrice
                        }
                      }),
                      allProposals: newPeriod.proposals.map(newPeriodProposal => {
                        return {
                          flightCharter: newPeriodProposal.flightCharter,
                          roomProposals: newPeriodProposal.hotel.roomProposals
                        }
                      }),
                      flightCharter: newPeriod.proposals[0].flightCharter,
                      hotel: {
                        ...proposal.hotel,
                        roomProposals: newPeriod.proposals[0].hotel.roomProposals
                      }
                    }
                    : proposal
                )
              }
              : period
          )
        }
      }
    },
    [SET_ACTIVE_ALTERNATIVE_PROPOSAL]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          periods: state.data.periods.map(period =>
            period.out === action.payload.period.out && period.home === action.payload.period.home
              ? {
                ...period,
                proposals: period.proposals.map(proposal =>
                  proposal.hotel.hotelId === action.payload.hotelId &&
                    proposal.package.id === action.payload.packageId
                    ? {
                      ...proposal,
                      flightCharter: proposal.allProposals[action.payload.index].flightCharter,
                      hotel: {
                        ...proposal.hotel,
                        roomProposals: proposal.allProposals[action.payload.index].roomProposals
                      }
                    }
                    : proposal
                )
              }
              : period
          )
        }
      }
    },
    [LOADING]: (state, action) => {
      return { ...state, loading: action.payload }
    },
    [SET_ACTIVE_TAB]: (state, action) => {
      return { ...state, activeTab: action.payload }
    },
    [SET_FILTER_VALUE]: (state, action) => {
      return { ...state, filter: { ...state.filter, [action.payload.name]: action.payload.value } }
    },
    [SET_SORT]: (state, action) => {
      return { ...state, sortOrder: action.payload }
    }
  },
  initialState
)

// thunks
export const queryProposals = reservationId => {
  return (dispatch, getState) => {
    const state = getState().booking
    const { sortOrder, filter } = state.proposals
    const reservation = state.reservations.reservation

    let queryParams = {
      reservationId,
      includeHotelInfo: true
    }

    if (state.reservations.searchMode === 'add') {
      queryParams.addMode = true
    }

    if (sortOrder) queryParams = { ...queryParams, sortOrder }
    if (filter && Object.keys(filter).length > 0 && filter.constructor === Object) {
      queryParams = { ...queryParams, ...filter }
    }
    if (reservation.searchInfo.hotelId) {
      queryParams = { ...queryParams, hotelId: reservation.searchInfo.hotelId }
    }
    if (reservation.searchInfo.travelType === 'Flight') {
      queryParams = { ...queryParams, showAlternativeFlights: true }
    }

    dispatch(QUERY_PROPOSAL(null))
    dispatch(LOADING(true))
    api.query('travelBooking/proposals', queryParams).then(res => {
      dispatch(LOADING(false))
      dispatch(QUERY_PROPOSAL(res.data))
      dispatch(setActiveTab(0))
    })
  }
}

export const setSort = value => {
  return dispatch => {
    dispatch(SET_SORT(value))
  }
}

export const setFilterValue = (value, name) => {
  return dispatch => {
    dispatch(SET_FILTER_VALUE({ value, name }))
  }
}

export const queryRoomProposals = (reservationId, hotelId, packageId, rooms = null) => {
  let queryParams = {
    reservationId,
    hotelId,
    rooms,
    packageId,
    showRooms: true,
    ignoreSetCharterPeriods: true,
    showAlternativeFlights: true
  }

  return (dispatch, getState) => {
    if (getState().booking.reservations.searchMode === 'add') {
      queryParams.addMode = true
    }

    api.query('travelBooking/proposals', queryParams).then(res => {
      dispatch(QUERY_ROOM_PROPOSAL(res.data))
    })
  }
}

export const queryRoomCategories = (marketId, languageId, currencyId, charterPeriodId, rooms) => {
  return dispatch => {
    return api.query('hotel/hotel/proposalroomcategories', {
      marketId,
      languageId,
      currencyId,
      charterPeriodId,
      rooms
    })
  }
}

export const setActiveTab = index => {
  return dispatch => {
    dispatch(SET_ACTIVE_TAB(index))
  }
}

export const emptyProposals = () => {
  return dispatch => {
    dispatch(QUERY_PROPOSAL(null))
  }
}

export const setActiveAlternatveProposal = (period, hotelId, packageId, index) => {
  return dispatch => {
    dispatch(SET_ACTIVE_ALTERNATIVE_PROPOSAL({ period, hotelId, packageId, index }))
  }
}
