import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const QUERY_NOTETYPES = createAction('query booking note types')

const initialState = {
  data: []
}

// handlers
export default handleActions(
  {
    [QUERY_NOTETYPES]: (state, action) => {
      return { ...state, data: action.payload }
    }
  },
  initialState
)

// thunks
export const queryNoteTypes = () => {
  return dispatch => {
    let query = api.query('travelBooking/noteTypes')
    query.then(res => {
      dispatch(QUERY_NOTETYPES(res.data))
    })
    return query
  }
}
