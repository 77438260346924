import { mapBedData, mapSeatsData } from '../mappers'

export function getCapacityOverviewSeries (
  airportId,
  seatsOverview,
  destinationsByWeeks
) {
  return [
    mapSeatsData(
      airportId,
      'Seats left',
      '#bbdefb',
      'seatsLeft',
      seatsOverview
    ),
    mapSeatsData(
      airportId,
      'Booked seats',
      '#2196f3',
      'seatsBooked',
      seatsOverview
    ),
    mapBedData(
      airportId,
      'Allotments beds left',
      '#c5cae9',
      'allotmentBedsLeft',
      destinationsByWeeks
    ),
    mapBedData(
      airportId,
      'Warranty beds left',
      '#ffcdd2',
      'warrantyBedsLeft',
      destinationsByWeeks
    ),
    mapBedData(
      airportId,
      'Booked beds',
      '#3f51b5',
      'bedsBooked',
      destinationsByWeeks
    )
  ]
}
