import { createAction, handleActions } from 'redux-actions'

const SET_SEARCH_FILTER = createAction('booking set search filter')

const initialState = {
  searchText: '',
  take: 25,
  skip: 0,
  showDetails: false,
  statuses: [],
  destinations: [],
  salesChannels: [],
  agents: [],
  markets: [],
  travelDates: [],
  bookingDates: [],
  requireCustomerConfirmation: false,
  priceProposalSent: false,
  ignoreCancellationFee: false,
  users: [],
  orderByNextDueDate: true
}

export default handleActions(
  {
    [SET_SEARCH_FILTER]: (state, action) => {
      return { ...state, ...action.payload }
    }
  },
  initialState
)

export const setSearchFilter = filter => {
  return dispatch => {
    dispatch(SET_SEARCH_FILTER(filter))
    return Promise.resolve()
  }
}
