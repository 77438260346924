import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const QUERY_DUE_DATES_FEES = createAction('query booking due dates fees')
const QUERY_CONFERENCE_PAYMENT_RULES = createAction('query booking conference payment rules')

const initialState = {
  data: null,
  conferenceData: null
}

// handlers
export default handleActions(
  {
    [QUERY_DUE_DATES_FEES]: (state, action) => {
      return { ...state, data: action.payload }
    },
    [QUERY_CONFERENCE_PAYMENT_RULES]: (state, action) => {
      return { ...state, conferenceData: action.payload }
    }
  },
  initialState
)

// thunks
export const queryDueDatesFees = reservationId => {
  return dispatch => {
    api.query('travelBooking/dueDatesFees', { reservationId }).then(res => {
      dispatch(QUERY_DUE_DATES_FEES(res.data))
    })
  }
}

export const queryConferencePaymentRules = reservationId => {
  return dispatch => {
    api.query('travelBooking/conferencePaymentRules', { reservationId }).then(res => {
      dispatch(QUERY_CONFERENCE_PAYMENT_RULES(res.data))
    })
  }
}
