import Currency from '../currency'

const percentage = 'Percentage'
const currency = 'Currency'

const labels = {
  currency: 'Currency',
  percentage: '%'
}

export const UnitTypeEnum = Object.freeze({
  percentage: percentage,
  currency: currency,

  labels: labels,

  format: (value, currencyId, isPercentage) => {
    if (isPercentage) {
      return `${value}${labels.percentage}`
    } else {
      return Currency(value, currencyId)
    }
  },

  toList: () => [{ value: currency, label: labels.currency }, { value: percentage, label: labels.percentage }]
})

export default UnitTypeEnum
