import { combineReducers } from 'redux'
import contract from './contract'
import contracts from './contracts'
import flightRow from './flight-row'
import flightRowEdit from './flight-row-edit'

export default combineReducers({
  contract,
  contracts,
  flightRow,
  flightRowEdit
})
