import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const QUERY_RESERVATION_DETAILS = createAction('query reservation details')
const QUERY_RESERVATION_DETAILS_SUCCESS = createAction(
  'query reservation details success'
)
const QUERY_RESERVATION_EVENTS = createAction('query reservation events')
const QUERY_RESERVATION_EVENTS_SUCCESS = createAction(
  'query reservation events success'
)

const SET_ACTIVE_EVENT = createAction('set reservation active event')

const initialState = {
  loading: false,
  item: null,
  events: [],
  activeEvent: 0
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_RESERVATION_DETAILS]: (state, action) => {
      return { ...state, loading: true }
    },
    [QUERY_RESERVATION_DETAILS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, item: action.payload }
    },
    [QUERY_RESERVATION_EVENTS]: (state, action) => {
      return { ...state, loading: true }
    },
    [QUERY_RESERVATION_EVENTS_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
        events: action.payload,
        activeEvent: action.payload.length
      }
    },
    [SET_ACTIVE_EVENT]: (state, action) => {
      return { ...state, activeEvent: action.payload }
    }
  },
  initialState
)

// Action creators
export const queryReservationDetails = (id, eventSequenceNumber = null) => {
  return dispatch => {
    dispatch(QUERY_RESERVATION_DETAILS())
    return api
      .query(`reservation/${id}`, { eventSequenceNumber })
      .then(json => dispatch(QUERY_RESERVATION_DETAILS_SUCCESS(json.data)))
  }
}

export const queryReservationEvents = id => {
  return dispatch => {
    dispatch(QUERY_RESERVATION_EVENTS())
    return api
      .query(`eventsbyid/${id}`)
      .then(json => dispatch(QUERY_RESERVATION_EVENTS_SUCCESS(json.data)))
  }
}

export const setActiveEvent = event => {
  return dispatch => {
    dispatch(SET_ACTIVE_EVENT(event))
  }
}
