import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'

// Actions
const SET_SHORT_WEEK_SUPPLEMENTS = createAction('set contract short week supplements')

const RESET_ACTIVE_SHORT_WEEK_SUPPLEMENT = createAction('cancel active short week supplements')
const SET_VALUE = createAction('update active short week supplements')
const SET_ACTIVE_SHORT_WEEK_SUPPLEMENT = createAction('edit active short week supplements')

const initialState = {
  active: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [SET_SHORT_WEEK_SUPPLEMENTS]: (state, action) => {
      return {
        ...state,
        loading: false,
        items: action.payload.supplements
      }
    },
    [SET_ACTIVE_SHORT_WEEK_SUPPLEMENT]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_SHORT_WEEK_SUPPLEMENT]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators
export const setShortWeekSupplements = items => {
  return dispatch => {
    dispatch(SET_SHORT_WEEK_SUPPLEMENTS(items))
  }
}

export const addShortWeekSupplement = item => {
  return dispatch => {
    return api.sendCommand('hotel/contract/AddShortWeekSupplement', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_SHORT_WEEK_SUPPLEMENT())
      dispatch(globalMessageActions.setGlobalSuccess('Short week supplement added'))
    })
  }
}

export const updateShortWeekSupplement = item => {
  return (dispatch, getState) => {
    return api.sendCommand('hotel/contract/UpdateShortWeekSupplement', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_SHORT_WEEK_SUPPLEMENT())
      dispatch(globalMessageActions.setGlobalSuccess('Short week supplement updated'))
    })
  }
}

export const deleteShortWeekSupplement = item => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/contract/DeleteShortWeekSupplement', { id: item.id, contractId: item.contractId })
      .then(() => {
        dispatch(contractActions.getHotelContract(item.contractId))
        dispatch(RESET_ACTIVE_SHORT_WEEK_SUPPLEMENT())
        dispatch(globalMessageActions.setGlobalSuccess('Short week supplement deleted'))
      })
  }
}

export const setActiveShortWeekSupplement = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_SHORT_WEEK_SUPPLEMENT(item))
  }
}

export const resetActiveShortWeekSupplement = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_SHORT_WEEK_SUPPLEMENT())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
