import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_PERIODS = createAction('query periods')
const SET_ACTIVE = createAction('set active period')
const SET_ACTIVE_VALUE = createAction('set active period value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_PERIODS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return { ...state, active: { ...state.active, [action.payload.name]: action.payload.value } }
    }
  },
  initialState
)

// Action creators
export const queryPeriods = () => {
  return dispatch => {
    api.query('currency/currencyHedge/periods').then(json => {
      dispatch(QUERY_PERIODS(json.data))
    })
  }
}

export const submitCreatePeriod = command => {
  return dispatch => {
    let cmd = api.sendCommand('currency/currencyHedge/createPeriod', command)
    cmd.then(() => {
      dispatch(queryPeriods())
      dispatch(globalMessageActions.setGlobalSuccess('Period added'))
    })
    return cmd
  }
}

export const submitUpdatePeriod = command => {
  return dispatch => {
    let cmd = api.sendCommand('currency/currencyHedge/updatePeriod', command)
    cmd.then(() => {
      dispatch(queryPeriods())
      dispatch(globalMessageActions.setGlobalSuccess('Period updated'))
    })
    return cmd
  }
}

export const submitDeletePeriod = command => {
  return dispatch => {
    let cmd = api.sendCommand('currency/currencyHedge/deletePeriod', command)
    cmd.then(() => {
      dispatch(queryPeriods())
      dispatch(globalMessageActions.setGlobalSuccess('Period deleted'))
    })
    return cmd
  }
}

export const setActive = period => {
  return dispatch => {
    dispatch(SET_ACTIVE(period))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}
