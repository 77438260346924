import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

import * as globalMessageActions from '../global-message'
import * as priceManagementActions from './price-management-room-categories'

// Actions
const QUERY_REQUESTS = createAction('query requests')

const SET_DESTINATION_ID = createAction('request destination id')
const SET_HOTEL_ID = createAction('request hotel id')
const SET_FROM_DATE = createAction('request from date')
const SET_TO_DATE = createAction('request to date')
const SET_RELEASE_RULE = createAction('request active release rule')

const RESET_ACTIVE_REQUEST = createAction('cancel active request')
const SET_VALUE = createAction('update active request')
const EDIT_ACTIVE_REQUEST = createAction('edit active request')
const RESET_DATES = createAction('active price reset dates')
const SET_SEASON_FROM_DATE = createAction('active price season from date')
const SET_SEASON_TO_DATE = createAction('active price season to date')

const initialState = {
  activeRequest: null,
  selectedDestinationId: null,
  selectedHotelId: null,
  selectedFromDate: null,
  selectedToDate: null,
  selectedSeasonFromDate: moment(),
  selectedSeasonToDate: moment().add(3, 'weeks'),
  selectedReleaseRule: 'ReleaseDate',
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REQUESTS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [EDIT_ACTIVE_REQUEST]: (state, action) => {
      return { ...state, activeRequest: action.payload }
    },
    [RESET_ACTIVE_REQUEST]: (state, action) => {
      return { ...state, activeRequest: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.activeRequest
      active[action.payload.name] = action.payload.value
      return { ...state, activeRequest: active }
    },
    [SET_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload.item }
    },
    [SET_HOTEL_ID]: (state, action) => {
      return { ...state, selectedHotelId: action.payload.item }
    },
    [SET_FROM_DATE]: (state, action) => {
      return { ...state, selectedFromDate: moment(action.payload.item) }
    },
    [SET_TO_DATE]: (state, action) => {
      return { ...state, selectedToDate: moment(action.payload.item) }
    },
    [SET_RELEASE_RULE]: (state, action) => {
      return { ...state, selectedReleaseRule: action.payload.item }
    },
    [RESET_DATES]: (state, action) => {
      return { ...state, selectedToDate: initialState.selectedToDate, selectedFromDate: initialState.selectedFromDate }
    },
    [SET_SEASON_FROM_DATE]: (state, action) => {
      return { ...state, selectedSeasonFromDate: moment(action.payload.item) }
    },
    [SET_SEASON_TO_DATE]: (state, action) => {
      return { ...state, selectedSeasonToDate: moment(action.payload.item) }
    }
  },
  initialState
)

// Action creators
export const queryRequests = () => {
  return (dispatch, getState) => {
    var destinationId = getState().production.requests.selectedDestinationId
    var hotelId = getState().production.requests.selectedHotelId
    var fromDate = getState().production.requests.selectedFromDate
    var toDate = getState().production.requests.selectedToDate

    if (hotelId && fromDate && toDate) {
      api
        .query('hotel/request/RequestsByHotel', { hotelId, fromDate, toDate })
        .then(json => dispatch(QUERY_REQUESTS(json.data)))
    } else if (fromDate && toDate) {
      api
        .query('hotel/request/requestsByDestination', { destinationId, fromDate, toDate })
        .then(json => dispatch(QUERY_REQUESTS(json.data)))
    }
  }
}

export const submitRequest = requests => {
  return dispatch => {
    return api.sendCommand('hotel/request/initiateRequest', requests).then(() => {
      dispatch(priceManagementActions.queryPrices())
      dispatch(priceManagementActions.setSelectedCells([]))
      dispatch(priceManagementActions.resetActiveAllotments())
      dispatch(globalMessageActions.setGlobalSuccess('Request initiated'))
    })
  }
}

export const submitRequestApproval = request => {
  return (dispatch, getState) => {
    if (getState().production.requests.selectedReleaseRule === 'Days') {
      request.releaseDate = null
    } else {
      request.releaseNoOfDays = null
    }

    return api.sendCommand('hotel/request/approveRequest', request).then(() => {
      dispatch(RESET_ACTIVE_REQUEST())
      dispatch(queryRequests())
      dispatch(globalMessageActions.setGlobalSuccess('Request approved'))
    })
  }
}

export const submitRequestRejection = request => {
  return dispatch => {
    return api.sendCommand('hotel/request/rejectRequest', { id: request.id }).then(() => {
      dispatch(RESET_ACTIVE_REQUEST())
      dispatch(queryRequests())
      dispatch(globalMessageActions.setGlobalSuccess('Request rejected'))
    })
  }
}

export const editActiveRequest = request => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_REQUEST(request))
  }
}

export const resetActiveRequest = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_REQUEST())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const setDestinationId = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_ID({ item }))
  }
}

export const setHotelId = item => {
  return dispatch => {
    dispatch(SET_HOTEL_ID({ item }))
  }
}

export const setFromDate = item => {
  return dispatch => {
    dispatch(SET_FROM_DATE({ item }))
  }
}

export const setToDate = item => {
  return dispatch => {
    dispatch(SET_TO_DATE({ item }))
  }
}

export const setReleaseRule = item => {
  return dispatch => {
    dispatch(SET_RELEASE_RULE({ item }))
  }
}

export const resetDates = () => {
  return dispatch => {
    dispatch(RESET_DATES())
  }
}

export const setSeasonFromDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_FROM_DATE({ item }))
  }
}

export const setSeasonToDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_TO_DATE({ item }))
  }
}
