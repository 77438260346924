import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const QUERY_HOTELS = createAction('query hotels')

const initialState = {
  data: []
}

// handlers
export default handleActions(
  {
    [QUERY_HOTELS]: (state, action) => {
      return { ...state, data: action.payload }
    }
  },
  initialState
)

// thunks
export const queryHotels = destinationId => {
  return dispatch => {
    api.query('hotel/hotel/hotels', { destinationId }).then(res => {
      dispatch(QUERY_HOTELS(res.data.hotels))
    })
  }
}
