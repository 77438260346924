import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_OFFERS = createAction('query market offers')
const SET_ACTIVE = createAction('set active market offer')
const SET_ACTIVE_VALUE = createAction('set active market offer value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_OFFERS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    }
  },
  initialState
)

// Action creators
export const queryOffers = (marketId, ignoreCache) => {
  return dispatch => {
    api.query('market/offer/offers', { marketId, ignoreCache }).then(json => {
      dispatch(QUERY_OFFERS(json.data))
    })
  }
}

export const submitCreateOffer = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/offer/createOffer', command)
    cmd.then(() => {
      setTimeout(() => dispatch(queryOffers(command.marketId)), 200)
      dispatch(globalMessageActions.setGlobalSuccess('Offer added'))
    })
    return cmd
  }
}

export const submitUpdateOffer = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/offer/updateOffer', command)
    cmd.then(() => {
      setTimeout(() => dispatch(queryOffers(command.marketId)), 200)
      dispatch(globalMessageActions.setGlobalSuccess('Offer updated'))
    })
    return cmd
  }
}

export const submitDeleteOffer = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/offer/deleteOffer', command)
    cmd.then(() => {
      dispatch(queryOffers(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Offer deleted'))
    })
    return cmd
  }
}

export const setActive = offer => {
  return dispatch => {
    dispatch(SET_ACTIVE(offer))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}
