import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_CHARTER_TYPES = createAction('query market charter types')
const SET_ACTIVE = createAction('set active charter type')
const SET_ACTIVE_VALUE = createAction('set active charter type value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_CHARTER_TYPES]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: {
          ...state.active,
          [action.payload.name]: action.payload.value
        }
      }
    }
  },
  initialState
)

// Action creators
export const queryCharterTypes = (
  marketId,
  ignoreCache,
  sortByDescription = false
) => {
  return dispatch => {
    api
      .query('market/charterType/CharterTypesForBackoffice', {
        marketId,
        ignoreCache,
        sortByDescription
      })
      .then(json => dispatch(QUERY_CHARTER_TYPES(json.data)))
  }
}

export const submitCreateCharterType = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/chartertype/createCharterType', command)
    cmd.then(() => {
      dispatch(queryCharterTypes(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Charter type added'))
    })
    return cmd
  }
}

export const submitUpdateCharterType = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/chartertype/updateCharterType', command)
    cmd.then(() => {
      dispatch(queryCharterTypes(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Charter type updated'))
    })
    return cmd
  }
}

export const submitDeleteCharterType = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/chartertype/deleteCharterType', command)
    cmd.then(() => {
      dispatch(queryCharterTypes(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Charter type deleted'))
    })
    return cmd
  }
}

export const setActive = charterType => {
  return dispatch => {
    dispatch(SET_ACTIVE(charterType))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}
