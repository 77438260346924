const breakfast = 'Breakfast'
const halfboard = 'Halfboard'
const none = 'None'

const labels = {
  breakfast: 'Breakfast',
  halfboard: 'Halfboard',
  none: 'None'
}

export const BoardTypeEnum = Object.freeze({
  breakfast: breakfast,
  halfboard: halfboard,
  none: none,

  labels: labels,

  format: boardType => {
    switch (boardType) {
      case breakfast:
        return labels.breakfast
      case halfboard:
        return labels.halfboard
      case none:
        return labels.none
      default:
        break
    }
  },

  toList: () => [
    { value: halfboard, label: labels.halfboard },
    { value: breakfast, label: labels.breakfast },
    { value: none, label: labels.none }
  ]
})

export default BoardTypeEnum
