import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'
import * as reservationActions from './reservations'

const QUERY_ALTERNATIVE_FLIGHTS = createAction('query booking alternative flights')

const initialState = {
  items: []
}

// handlers
export default handleActions(
  {
    [QUERY_ALTERNATIVE_FLIGHTS]: (state, action) => {
      return { ...state, items: action.payload }
    }
  },
  initialState
)

// thunks
export const queryAlternativeFlights = (reservationId, travelerId) => {
  return dispatch => {
    api.query('travelBooking/alternativeFlights', { reservationId, travelerId }).then(res => {
      dispatch(QUERY_ALTERNATIVE_FLIGHTS(res.data))
    })
  }
}

export const submitSelectAlternativeFlight = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/selectAlternativeFlight', command)
    cmd.then(() => {
      dispatch(reservationActions.queryReservation(command.reservationId))
      dispatch(globalMessageActions.setGlobalSuccess('Traveler flights updated'))
    })
    return cmd
  }
}
