import { createAction, handleActions } from 'redux-actions'
import api from '../api'
// import * as globalMessageActions from '../global-message'
import { uniqueByTwoDates } from '../../common/group-by'

const QUERY_CHARTER_PERIODS = createAction(
  'query hotel pricing charter periods'
)
const QUERY_CHARTER_PERIODS_SUCCESS = createAction(
  'query hotel pricing charter periods success'
)
const SET_SELECTED_DESTINATION_ID = createAction(
  'set selected destination id hotel pricing charter period'
)
const SET_SELECTED_HOTEL_ID = createAction(
  'set selected hotel id hotel pricing charter period'
)

const initialState = {
  groupedItems: [],
  items: [],
  selectedDestinationId: null,
  selectedHotelId: null,
  loading: false
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_CHARTER_PERIODS]: (state, action) => {
      return { ...state, items: [], groupedItems: [], loading: true }
    },
    [QUERY_CHARTER_PERIODS_SUCCESS]: (state, action) => {
      let items = action.payload.map(cp => {
        return { checkinDate: cp.checkinDate, checkoutDate: cp.checkoutDate }
      })

      let groupedItems = uniqueByTwoDates(items, 'checkinDate', 'checkoutDate')

      return {
        ...state,
        items: action.payload,
        groupedItems: groupedItems,
        loading: false
      }
    },
    [SET_SELECTED_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload }
    },
    [SET_SELECTED_HOTEL_ID]: (state, action) => {
      return { ...state, selectedHotelId: action.payload }
    }
  },
  initialState
)

export const queryCharterPeriods = (
  destinationId,
  hotelId = '',
  fromDate = null,
  toDate = null,
  fromPeriod = null,
  toPeriod = null,

) => {
  return dispatch => {
    dispatch(QUERY_CHARTER_PERIODS())
    if(fromPeriod && toPeriod){
      const checkinDateFrom = fromPeriod.checkinDate
      const checkinDateTo = toPeriod.checkinDate
      const checkoutDateFrom = fromPeriod.checkoutDate
      const checkoutDateTo = toPeriod.checkoutDate
      return api
      .query('hotel/charterperiod/charterPeriods', {
        destinationId,
        hotelId,
        fromDate,
        toDate,
        checkinDateFrom,
        checkinDateTo,
        checkoutDateFrom,
        checkoutDateTo
      })
      .then(res => dispatch(QUERY_CHARTER_PERIODS_SUCCESS(res.data)))
    }
    return api
      .query('hotel/charterperiod/charterPeriods', {
        destinationId,
        hotelId,
        fromDate,
        toDate,
      })
      .then(res => dispatch(QUERY_CHARTER_PERIODS_SUCCESS(res.data)))
  }
}

export const setSelectedDestination = value => {
  return dispatch => {
    dispatch(SET_SELECTED_DESTINATION_ID(value))
  }
}

export const setSelectedHotel = value => {
  return dispatch => {
    dispatch(SET_SELECTED_HOTEL_ID(value))
  }
}
