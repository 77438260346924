import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const QUERY_REPORT = createAction('query accounts receivables')

const SET_DEPARTURE_DATE_FROM = createAction('accounts receivables departure date from')
const SET_DEPARTURE_DATE_TO = createAction('accounts receivables departure date to')
const SET_IS_EXPIRED = createAction('accounts receivables set status')
const SET_IS_DEBT = createAction('accounts receivables set is debt')
const SET_MARKET_ID = createAction('accounts receivables set market id')

const initialState = {
  selectedDepartureDateFrom: null,
  selectedDepartureDateTo: null,
  selectedIsExpired: false,
  selectedIsDebt: false,
  selectedMarketId: 'FI',
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_DEPARTURE_DATE_FROM]: (state, action) => {
      return { ...state, selectedDepartureDateFrom: action.payload.item }
    },
    [SET_DEPARTURE_DATE_TO]: (state, action) => {
      return { ...state, selectedDepartureDateTo: action.payload.item }
    },
    [SET_IS_EXPIRED]: (state, action) => {
      return { ...state, selectedIsExpired: action.payload.item }
    },
    [SET_IS_DEBT]: (state, action) => {
      return { ...state, selectedIsDebt: action.payload.item }
    },
    [SET_MARKET_ID]: (state, action) => {
      return { ...state, selectedMarketId: action.payload.item }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    var departureDateFrom = getState().economy.accountsReceivables.selectedDepartureDateFrom
    var departureDateTo = getState().economy.accountsReceivables.selectedDepartureDateTo
    var isExpired = getState().economy.accountsReceivables.selectedIsExpired
    var isDebt = getState().economy.accountsReceivables.selectedIsDebt
    var marketId = getState().economy.accountsReceivables.selectedMarketId

    api
      .query('travelbooking/reports/AccountsReceivables', {
        departureDateFrom,
        departureDateTo,
        isExpired,
        isDebt,
        marketId
      })
      .then(json => dispatch(QUERY_REPORT(json.data)))
  }
}

export const setDepartureDateFrom = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_DATE_FROM({ item }))
  }
}

export const setDepartureDateTo = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_DATE_TO({ item }))
  }
}

export const setIsExpired = item => {
  return dispatch => {
    dispatch(SET_IS_EXPIRED({ item }))
  }
}

export const setIsDebt = item => {
  return dispatch => {
    dispatch(SET_IS_DEBT({ item }))
  }
}

export const setMarketId = item => {
  return dispatch => {
    dispatch(SET_MARKET_ID({ item }))
  }
}
