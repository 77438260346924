import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'

// Actions
const SET_TOURIST_TAX = createAction('set contract tourist tax')
const RESET_ACTIVE_TOURIST_TAX = createAction('cancel active tourist tax')
const SET_VALUE = createAction('set active tourist tax value')
const SET_ACTIVE_TOURIST_TAX = createAction('set active tourist tax')

const initialState = {
  active: null,
  loading: false,
  item: null
}

// Reducers/handlers
export default handleActions(
  {
    [SET_TOURIST_TAX]: (state, action) => {
      return { ...state, loading: false, item: action.payload }
    },
    [SET_ACTIVE_TOURIST_TAX]: (state, action) => {
      return { ...state, active: { ...action.payload } }
    },
    [RESET_ACTIVE_TOURIST_TAX]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators
export const setTouristTax = items => {
  return dispatch => {
    dispatch(SET_TOURIST_TAX(items))
  }
}

export const updateTouristTax = item => {
  return (dispatch, getState) => {
    return api.sendCommand('hotel/contract/setTouristTax', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_TOURIST_TAX())
      dispatch(globalMessageActions.setGlobalSuccess('Tourist tax updated'))
    })
  }
}

export const setActiveTouristTax = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_TOURIST_TAX(item))
  }
}

export const resetActiveTouristTax = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_TOURIST_TAX())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
