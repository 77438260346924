import { combineReducers } from 'redux'
import cancellationFees from './cancellation-fees'
import cancellationInsurances from './cancellation-insurances'
import travelInsurances from './travel-insurances'
import charterTypes from './charter-types'
import markets from './markets'
import marketSelect from './market-select'
import offers from './offers'
import originAirports from './origin-airports'
import packages from './packages'
import seasons from './seasons'
import unitPrices from './unit-prices'
import fullRefundInsurances from './full-refund-insurances'

export default combineReducers({
  cancellationFees,
  cancellationInsurances,
  travelInsurances,
  charterTypes,
  markets,
  marketSelect,
  offers,
  originAirports,
  packages,
  seasons,
  unitPrices,
  fullRefundInsurances
})
