import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_REPORT = createAction('query agent commissions')

const SET_DEPARTURE_FROM_DATE = createAction('agent commissions departure date from')
const SET_DEPARTURE_TO_DATE = createAction('agent commissions departure date to')

const initialState = {
  selectedDepartureFromDate: moment().startOf('day'),
  selectedDepartureToDate: moment().startOf('day'),
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_DEPARTURE_FROM_DATE]: (state, action) => {
      return { ...state, selectedDepartureFromDate: moment(action.payload.item) }
    },
    [SET_DEPARTURE_TO_DATE]: (state, action) => {
      return { ...state, selectedDepartureToDate: moment(action.payload.item) }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    var departureFromDate = getState().economy.agentCommissions.selectedDepartureFromDate
    var departureToDate = getState().economy.agentCommissions.selectedDepartureToDate

    if (departureFromDate && departureToDate) {
      api
        .query('travelbooking/reports/agentCommissions', {
          departureFromDate,
          departureToDate
        })
        .then(json => dispatch(QUERY_REPORT(json.data)))
    }
  }
}

export const setDepartureFromDate = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_FROM_DATE({ item }))
  }
}

export const setDepartureToDate = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_TO_DATE({ item }))
  }
}
