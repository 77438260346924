import { createAction, handleActions } from 'redux-actions'
import { getSeatsOriginsSeries } from './data/seats-origins'
import { getSeatsFlightSeries } from './data/seats-flights'
import { getBedDestinationSeries } from './data/beds-destinations'
import { getBedsHotelSeries } from './data/beds-hotels'
import { getBedsHotelbedsSeries } from './data/beds-hotelbeds'
import { getCapacityOverviewSeries } from './data/capacity-overview'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_AIRPORTS = createAction('query airports')
const QUERY_AIRPORTS_SUCCESS = createAction('query airports success')
const SET_FILTER = createAction('set filter')
const SET_AIRPORT_DATA = createAction('set airport data')
const SET_DRILLDOWN = createAction('set drilldown')
const SET_AIRPORT_SERIES_FN = createAction('set airport series fn')
const DRILLUP = createAction('drillup')

const lsFilter = localStorage.getItem('revenueChartFilter')

const initialState = {
  loading: false,
  /*
      Each airport holds the following structure:
      * id: airport's id (SZG, etc.)
      * series: the airport chart's series data at the current drilldown level
      * title: the current title of the airport's chart at the current drilldown state
      * titles: stack of the airport's chart titles for each current drilldown level
      * updateSeriesFns: stack of the airport's functions to update series for each current drilldown level;
      *   an update function will take (dispatch, getState) as arguments
  */
  airports: [],
  filter: lsFilter
    ? JSON.parse(lsFilter)
    : {
        fromDate: moment().startOf('day'),
        toDate: moment()
          .add(1, 'month')
          .endOf('day'),
        airportId: 'all',
        destinationId: 'all',
        hotelId: 'all',
        viewDate: moment().format('YYYY-MM-DD')
      }
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_AIRPORTS]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [QUERY_AIRPORTS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, airports: action.payload }
    },
    [SET_FILTER]: (state, action) => {
      localStorage.setItem(
        'revenueChartFilter',
        JSON.stringify({ ...state.filter, ...action.payload })
      )
      return { ...state, filter: { ...state.filter, ...action.payload } }
    },
    [SET_AIRPORT_DATA]: (state, action) => {
      const updatedAirports = state.airports.slice()
      const index = updatedAirports.findIndex(a => a.id === action.payload.id)
      const titles =
        (updatedAirports[index] && updatedAirports[index].titles) || []
      updatedAirports[index] = {
        ...updatedAirports[index],
        // Push another title onto the stack of titles
        titles: titles.concat([action.payload.title]),
        title: action.payload.title,
        series: action.payload.series
      }
      return { ...state, airports: updatedAirports }
    },
    [SET_DRILLDOWN]: (state, action) => {
      const updatedAirports = state.airports.slice()
      const index = updatedAirports.findIndex(a => a.id === action.payload.id)
      const titles =
        (updatedAirports[index] && updatedAirports[index].titles) || []
      updatedAirports[index] = {
        ...updatedAirports[index],
        // Push another title onto the stack of titles
        titles: titles.concat([action.payload.title]),
        title: action.payload.title,
        drilldown: action.payload.drilldown
      }

      return { ...state, airports: updatedAirports }
    },
    [SET_AIRPORT_SERIES_FN]: (state, action) => {
      const updatedAirports = state.airports.slice()
      const index = updatedAirports.findIndex(a => a.id === action.payload.id)

      updatedAirports[index] = {
        ...updatedAirports[index],
        // Push another update function onto the stack of titles
        updateSeriesFns: (updatedAirports[index].updateSeriesFns || []).concat([
          action.payload.updateSeriesFn
        ])
      }

      return { ...state, airports: updatedAirports }
    },
    [DRILLUP]: (state, action) => {
      const updatedAirports = state.airports.slice()
      const index = updatedAirports.findIndex(a => a.id === action.payload.id)
      const updateSeriesFns = updatedAirports[index].updateSeriesFns || []
      const titles = updatedAirports[index].titles || []

      updatedAirports[index] = {
        ...updatedAirports[index],
        // Pop last update function from stack of functions
        updateSeriesFns: updateSeriesFns.slice(0, updateSeriesFns.length - 1),
        title: titles[titles.length - 2],
        // Pop last title from stack of titles
        titles: titles.slice(0, titles.length - 1)
      }

      return { ...state, airports: updatedAirports }
    }
  },
  initialState
)

// Action creators
export const queryAirports = () => (dispatch, getState) => {
  dispatch(QUERY_AIRPORTS())
  const { fromDate, toDate } = getState().revenue.capacity.filter
  return api
    .query('flightdeparture/charter/revenuedestinationairports', {
      fromDate,
      toDate
    })
    .then(json => {
      dispatch(
        QUERY_AIRPORTS_SUCCESS(
          json.data.map(airport => {
            const updateSeriesFn = (dispatch, getState) =>
              Promise.all([
                queryAirportOverview(airport.id)(dispatch, getState),
                queryHotelsOverview(airport.id)(dispatch, getState)
              ]).then(results => ({
                title: airport.description,
                series: getCapacityOverviewSeries(
                  airport.id,
                  results[0],
                  results[1]
                )
              }))
            updateSeriesFn(dispatch, getState).then(({ title, series }) =>
              dispatch(SET_AIRPORT_DATA({ id: airport.id, title, series }))
            )
            return {
              ...airport,
              updateSeriesFns: [updateSeriesFn]
            }
          })
        )
      )
    })
}

var count = 0

export const drilldown = drilldown => (dispatch, getState) => {
  const {
    type,
    airportId,
    originAirportId,
    destinationId,
    hotelId,
    date
  } = drilldown
  const dateStr = `w${date.isoWeek()} / ${date.format('YYYY-MM-DD')}`
  var p
  var title
  switch (type) {
    case 'seats':
      title = `${dateStr} ${airportId}: seats by origin airport`
      p = (dispatch, getState) =>
        querySeatsByOriginAirport(airportId, date)(dispatch, getState).then(
          originAirportsByWeeks => ({
            title,
            series: getSeatsOriginsSeries(
              originAirportsByWeeks,
              airportId,
              date
            )
          })
        )
      break
    case 'seatsOrigin':
      title = `${dateStr} ${airportId}: seats by flight`
      p = (dispatch, getState) =>
        querySeatsByFlight(
          airportId,
          originAirportId,
          date
        )(dispatch, getState).then(flightByWeeks => ({
          title,
          series: getSeatsFlightSeries(flightByWeeks, date)
        }))
      break
    case 'beds':
      title = `${dateStr} ${airportId}: beds by destination`
      p = (dispatch, getState) =>
        queryBedsByDestination(airportId, date)(dispatch, getState).then(
          destinationsByWeeks => ({
            title,
            series: getBedDestinationSeries(
              destinationsByWeeks,
              airportId,
              date
            )
          })
        )
      break
    case 'bedsDestination':
      title = `${dateStr} ${airportId} / ${destinationId}: beds by hotel`
      p = (dispatch, getState) =>
        queryBedsByHotel(
          airportId,
          destinationId,
          date
        )(dispatch, getState).then(destinationsByWeeks => ({
          title,
          series: getBedsHotelSeries(
            destinationsByWeeks,
            airportId,
            destinationId,
            date
          )
        }))
      break
    case 'bedsHotel':
      title = `${dateStr} ${airportId} / ${destinationId} / ${hotelId}: beds by room category`
      p = (dispatch, getState) =>
        queryBedsByRoomCategory(
          airportId,
          destinationId,
          hotelId,
          date
        )(dispatch, getState).then(destinationsByWeeks => ({
          title,
          series: getBedsHotelbedsSeries(
            destinationsByWeeks,
            airportId,
            destinationId,
            hotelId,
            date
          )
        }))
      break
    default:
      throw new Error(`Unknown drilldown type ${type}.`)
  }

  dispatch(SET_AIRPORT_SERIES_FN({ id: airportId, updateSeriesFn: p }))

  return p(dispatch, getState)
    .then(({ title, series }) => {
      count++
      dispatch(
        SET_DRILLDOWN({
          id: airportId,
          title,
          drilldown: {
            id: `${type}-${date}-${count}`,
            point: drilldown,
            series
          }
        })
      )
    })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export const drillup = airportId => (dispatch, getState) =>
  dispatch(DRILLUP({ id: airportId }))

export const setViewDate = viewDate => (dispatch, getState) => {
  dispatch(SET_FILTER({ viewDate }))
  getState().revenue.capacity.airports.forEach(airport => {
    airport.updateSeriesFns[airport.updateSeriesFns.length - 1](
      dispatch,
      getState
    ).then(({ series, title }) =>
      dispatch(SET_AIRPORT_DATA({ id: airport.id, series, title }))
    )
  })
}

export const setFromDate = fromDate => dispatch => {
  dispatch(SET_FILTER({ fromDate }))
}

export const setToDate = toDate => dispatch => {
  dispatch(SET_FILTER({ toDate }))
}

const queryAirportOverview = airportId => (dispatch, getState) => {
  const { fromDate, toDate, viewDate } = getState().revenue.capacity.filter
  return api
    .query('flightdeparture/contract/revenueoverview', {
      destinationAirportId: airportId,
      dateTimeFrom: fromDate,
      dateTimeTo: toDate,
      viewDate
    })
    .then(json => json.data)
}

const queryHotelsOverview = airportId => (dispatch, getState) => {
  const { fromDate, toDate, viewDate } = getState().revenue.capacity.filter
  return api
    .query('hotel/charterperiod/revenueoverview', {
      airportId: airportId,
      dateTimeFrom: fromDate,
      dateTimeTo: toDate,
      viewDate
    })
    .then(json => json.data)
}

const querySeatsByOriginAirport = (destinationAirportId, departureDate) => (
  dispatch,
  getState
) => {
  const { viewDate } = getState().revenue.capacity.filter
  return api
    .query('flightdeparture/contract/revenuebyoriginairport', {
      destinationAirportId,
      departureDate,
      viewDate
    })
    .then(json => json.data)
}

const querySeatsByFlight = (
  destinationAirportId,
  originAirportId,
  departureDate
) => (dispatch, getState) => {
  const { viewDate } = getState().revenue.capacity.filter
  return api
    .query('flightdeparture/contract/revenuebyflight', {
      destinationAirportId,
      originAirportId,
      departureDate,
      viewDate
    })
    .then(json => json.data)
}

const queryBedsByDestination = (airportId, checkinDate) => (
  dispatch,
  getState
) => {
  const { viewDate } = getState().revenue.capacity.filter
  return api
    .query('hotel/charterperiod/revenuebydestination', {
      airportId,
      checkinDate,
      viewDate
    })
    .then(json => json.data)
}

const queryBedsByHotel = (airportId, destinationId, checkinDate) => (
  dispatch,
  getState
) => {
  const { viewDate } = getState().revenue.capacity.filter
  return api
    .query('hotel/charterperiod/revenuebyhotel', {
      airportId,
      destinationId,
      checkinDate,
      viewDate
    })
    .then(json => json.data)
}

const queryBedsByRoomCategory = (
  airportId,
  destinationId,
  hotelId,
  checkinDate
) => (dispatch, getState) => {
  const { viewDate } = getState().revenue.capacity.filter
  return api
    .query('hotel/charterperiod/revenuebyroomcategory', {
      airportId,
      destinationId,
      hotelId,
      checkinDate,
      viewDate
    })
    .then(json => json.data)
}
