import { mapBedHotelBedsData } from '../mappers'

export function getBedsHotelbedsSeries (
  destinationsByWeeks,
  airportId,
  destinationId,
  hotelId,
  date
) {
  return [
    mapBedHotelBedsData(
      'Allotments beds left',
      '#c5cae9',
      'allotmentBedsLeft',
      destinationsByWeeks,
      airportId,
      destinationId,
      hotelId,
      date
    ),
    mapBedHotelBedsData(
      'Warranty beds left',
      '#ffcdd2',
      'warrantyBedsLeft',
      destinationsByWeeks,
      airportId,
      destinationId,
      hotelId,
      date
    ),
    mapBedHotelBedsData(
      'Booked beds',
      '#3f51b5',
      'bedsBooked',
      destinationsByWeeks,
      airportId,
      destinationId,
      hotelId,
      date
    )
  ]
}
