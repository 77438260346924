import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'

// Actions
const SET_PAYMENTPLAN = createAction('set contract payment plan')
const CANCEL_EDITING_OF_PAYMENTPLAN = createAction('cancel set contract payment plan')
const SET_VALUE = createAction('set contract payment plan value')
const RESET_ACTIVE_PAYMENTPLAN = createAction('cancel active payment plan')
const SET_ACTIVE_PAYMENTPLAN = createAction('set active payment plan')

const initialState = {
  active: '',
  item: ''
}

// Reducers/handlers
export default handleActions(
  {
    [SET_PAYMENTPLAN]: (state, action) => {
      return { ...state, item: { ...action.payload }, active: action.payload }
    },
    [CANCEL_EDITING_OF_PAYMENTPLAN]: (state, action) => {
      return { ...state, active: { ...state.item } }
    },
    [SET_ACTIVE_PAYMENTPLAN]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_PAYMENTPLAN]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators

export const setPaymentPlan = item => {
  return dispatch => {
    dispatch(SET_PAYMENTPLAN(item))
  }
}

export const updatePaymentPlan = item => {
  return (dispatch, getState) => {
    return api.sendCommand('hotel/contract/setPaymentPlan', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_PAYMENTPLAN())
      dispatch(globalMessageActions.setGlobalSuccess('Payment plan updated'))
    })
  }
}

export const cancelEditing = item => {
  return dispatch => {
    dispatch(CANCEL_EDITING_OF_PAYMENTPLAN())
  }
}

export const setActivePaymentPlan = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_PAYMENTPLAN(item))
  }
}

export const resetActivePaymentPlan = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_PAYMENTPLAN())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
