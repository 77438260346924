import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'

// Actions
const SET_CONTRACT_RELEASE_RULES = createAction('set contract release rules')

const ADD_CONTRACT_RELEASE_RULE = createAction('add release rule')
const ADD_CONTRACT_RELEASE_RULE_SUCCESS = createAction('add release rule success')

const UPDATE_CONTRACT_RELEASE_RULE = createAction('update active release rule')
const UPDATE_CONTRACT_RELEASE_RULE_SUCCESS = createAction('update release rule success')

const RESET_ACTIVE_CONTRACT_RELEASE_RULE = createAction('cancel active release rule')
const SET_VALUE = createAction('set active release rule value')
const EDIT_ACTIVE_CONTRACT_RELEASE_RULE = createAction('edit active release rule')

const RESET_ACTIVE_OVERALL_CONTRACT_RELEASE_RULE = createAction('cancel active overall release rule')
const SET_OVERALL_VALUE = createAction('set active overall release rule value')
const EDIT_ACTIVE_OVERALL_CONTRACT_RELEASE_RULE = createAction('edit active overall release rule')

const initialState = {
  active: null,
  activeOverall: null,
  loading: false,
  entirePeriod: {},
  periods: []
}

// Reducers/handlers
export default handleActions(
  {
    [SET_CONTRACT_RELEASE_RULES]: (state, action) => {
      return { ...state, loading: false, entirePeriod: action.payload.entirePeriod, periods: action.payload.periods }
    },
    [ADD_CONTRACT_RELEASE_RULE_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [UPDATE_CONTRACT_RELEASE_RULE]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [UPDATE_CONTRACT_RELEASE_RULE_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [EDIT_ACTIVE_CONTRACT_RELEASE_RULE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_CONTRACT_RELEASE_RULE]: (state, action) => {
      return { ...state, active: null }
    },

    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [EDIT_ACTIVE_OVERALL_CONTRACT_RELEASE_RULE]: (state, action) => {
      return { ...state, activeOverall: action.payload }
    },
    [RESET_ACTIVE_OVERALL_CONTRACT_RELEASE_RULE]: (state, action) => {
      return { ...state, activeOverall: null }
    },

    [SET_OVERALL_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.activeOverall
      active[action.payload.name] = action.payload.value
      return { ...state, activeOverall: active }
    }
  },
  initialState
)

export const setReleaseRules = items => {
  return dispatch => {
    dispatch(SET_CONTRACT_RELEASE_RULES(items))
  }
}

export const setOverallReleaseRule = item => {
  return dispatch => {
    return api.sendCommand('hotel/contract/setOverallReleaseRule', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_OVERALL_CONTRACT_RELEASE_RULE())
      dispatch(globalMessageActions.setGlobalSuccess('Release rule set'))
    })
  }
}

export const addReleaseRule = item => {
  return dispatch => {
    dispatch(ADD_CONTRACT_RELEASE_RULE())
    return api.sendCommand('hotel/contract/AddReleaseRule', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_CONTRACT_RELEASE_RULE())
      dispatch(globalMessageActions.setGlobalSuccess('Release rules added'))
    })
  }
}

export const updateReleaseRule = item => {
  return (dispatch, getState) => {
    dispatch(UPDATE_CONTRACT_RELEASE_RULE())
    return api.sendCommand('hotel/contract/UpdateReleaseRule', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_CONTRACT_RELEASE_RULE())
      dispatch(globalMessageActions.setGlobalSuccess('Release rules updated'))
    })
  }
}

export const deleteReleaseRule = item => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/contract/DeleteReleaseRule', { id: item.id, contractId: item.contractId })
      .then(() => {
        dispatch(contractActions.getHotelContract(item.contractId))
        dispatch(RESET_ACTIVE_CONTRACT_RELEASE_RULE())
        dispatch(globalMessageActions.setGlobalSuccess('Release rules deleted'))
      })
  }
}

export const editActiveReleaseRule = item => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_CONTRACT_RELEASE_RULE(item))
  }
}

export const resetActiveReleaseRule = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_CONTRACT_RELEASE_RULE())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const editActiveOverallReleaseRule = item => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_OVERALL_CONTRACT_RELEASE_RULE(item))
  }
}

export const resetActiveOverallReleaseRule = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_OVERALL_CONTRACT_RELEASE_RULE())
  }
}

export const setOverallValue = (value, name) => {
  return dispatch => {
    dispatch(SET_OVERALL_VALUE({ value, name }))
  }
}
