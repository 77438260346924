import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const QUERY_CUSTOMER_CATEGORES = createAction('query customer categories')
const SET_CUSTOMER_CATEGORY_VALUE = createAction('set customer category value')

const initialState = {
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_CUSTOMER_CATEGORES]: (state, action) => {
      return {
        ...state,
        items: action.payload.map(cc => {
          return { ...cc, number: 2 }
        })
      }
    },
    [SET_CUSTOMER_CATEGORY_VALUE]: (state, action) => {
      return {
        ...state,
        items: state.items.map(cc => (cc.id === action.payload.id ? { ...cc, number: action.payload.number } : cc))
      }
    }
  },
  initialState
)

export const queryCustomerCategories = () => {
  return dispatch => {
    api.query('general/customerCategories').then(res => dispatch(QUERY_CUSTOMER_CATEGORES(res.data)))
  }
}

export const setValue = cc => {
  return dispatch => {
    dispatch(SET_CUSTOMER_CATEGORY_VALUE(cc))
  }
}
