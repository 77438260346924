import { createAction, handleActions } from 'redux-actions'
import * as reservationActions from './reservations'
import api from '../api'

// actions
const QUERY_HALFBOARD = createAction('query booking halfboard')
const SET_HALFBOARD = createAction('set booking halfboard')

const initialState = {
  items: []
}

// handlers
export default handleActions(
  {
    [QUERY_HALFBOARD]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_HALFBOARD]: (state, action) => {
      if (action.payload.travelerId) {
        return {
          ...state,
          items: state.items.map(t =>
            t.id === action.payload.travelerId
              ? {
                ...t,
                halfboard: { ...t.halfboard, reserved: action.payload.reserved }
              }
              : t
          )
        }
      }
      return {
        ...state,
        items: state.items.map(t => {
          return { ...t, halfboard: { ...t.halfboard, reserved: action.payload.reserved } }
        })
      }
    }
  },
  initialState
)

// thunks
export const queryHalfboard = reservationId => {
  return dispatch => {
    api.query('travelbooking/halfboardPerTraveler', { reservationId }).then(res => dispatch(QUERY_HALFBOARD(res.data)))
  }
}

export const submitAddHalfboard = command => {
  return dispatch => {
    dispatch(SET_HALFBOARD({ ...command, reserved: true }))
    let cmd = api.sendCommand('travelBooking/addHalfboard', command)
    cmd.then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
    return cmd
  }
}

export const submitRemoveHalfboard = command => {
  return dispatch => {
    dispatch(SET_HALFBOARD({ ...command, reserved: false }))
    let cmd = api.sendCommand('travelBooking/removeHalfboard', command)
    cmd.then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
    return cmd
  }
}
