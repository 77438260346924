import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import moment from 'moment-timezone'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'
import { ContractTypeEnum } from '../../../common/enums'

// Actions
const SET_ALLOTMENTS = createAction('set contract allotments')
const RESET_ACTIVE_ALLOTMENT = createAction('cancel active hotel allotment')
const SET_VALUE = createAction('set active hotel allotment value')
const SET_ACTIVE_ALLOTMENT = createAction('set active hotel allotment')

const NEXT_ALLOTMENT = createAction('go to next allotment')

const initialState = {
  active: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [SET_ALLOTMENTS]: (state, action) => {
      return { ...state, loading: false, items: action.payload.roomCategories }
    },
    [SET_ACTIVE_ALLOTMENT]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_ALLOTMENT]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [NEXT_ALLOTMENT]: (state, action) => {
      let nextAllotment = null
      if (action.payload.isNew) {
        nextAllotment = {
          isNew: true,
          startDate: moment(action.payload.endDate),
          endDate: moment(action.payload.endDate).add(14, 'days'),
          contractType: action.payload.contractType,
          priceUnit: action.payload.priceUnit,
          contractId: action.payload.contractId,
          roomCategoryId: action.payload.roomCategoryId
        }
      } else {
        let selectedRoom = state.items.find(i => i.id === action.payload.roomCategoryId)
        let currentAllotmentsPosition = selectedRoom.allotments.findIndex(a => a.id === action.payload.id)
        nextAllotment = selectedRoom.allotments[currentAllotmentsPosition + 1]
      }
      if (nextAllotment) {
        return { ...state, active: nextAllotment }
      } else return { ...state, active: null }
    }
  },
  initialState
)

export const setAllotments = items => {
  return (dispatch, getState) => {
    dispatch(SET_ALLOTMENTS(items))
  }
}

export const addAllotment = (item, actionAfterSave) => {
  return (dispatch, getState) => {
    let url = ''
    if (item.contractType === ContractTypeEnum.notAvailable) {
      url = 'hotel/contract/addAllotmentNotAvailable'
    } else {
      url = 'hotel/contract/addAllotment'
    }

    return api.sendCommand(url, item, 'hotel/contract').then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))

      let active = getState().contract.hotel.allotments.active
      if (actionAfterSave.moveNext) {
        dispatch(RESET_ACTIVE_ALLOTMENT()) // Make a reset to make autofocus run. Couldn't get focus() to work...
        dispatch(NEXT_ALLOTMENT(active))
      } else {
        dispatch(RESET_ACTIVE_ALLOTMENT())
      }
      dispatch(globalMessageActions.setGlobalSuccess('Allotment added'))
    })
  }
}

export const updateAllotment = (item, actionAfterSave) => {
  return (dispatch, getState) => {
    let url = ''
    if (item.contractType === ContractTypeEnum.notAvailable) {
      url = 'hotel/contract/updateAllotmentNotAvailable'
    } else {
      url = 'hotel/contract/updateAllotment'
    }

    return api.sendCommand(url, item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(globalMessageActions.setGlobalSuccess('Allotment updated'))

      let active = getState().contract.hotel.allotments.active
      if (actionAfterSave.moveNext) {
        dispatch(RESET_ACTIVE_ALLOTMENT()) // Make a reset to make autofocus run. Couldn't get focus() to work...
        dispatch(NEXT_ALLOTMENT(active))
      } else {
        dispatch(RESET_ACTIVE_ALLOTMENT())
      }
    })
  }
}

export const deleteAllotment = item => {
  return (dispatch, getState) => {
    let url = ''
    if (item.contractType === ContractTypeEnum.notAvailable) {
      url = 'hotel/contract/deleteAllotmentNotAvailable'
    } else {
      url = 'hotel/contract/deleteAllotment'
    }

    return api.sendCommand(url, { id: item.id, contractId: item.contractId }).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_ALLOTMENT())
      dispatch(globalMessageActions.setGlobalSuccess('Allotment deleted'))
    })
  }
}

export const copyPeriods = (contractId, roomCategoryId, nextRoomCategoryId) => {
  return dispatch => {
    return api
      .sendCommand('hotel/contract/copyAllotmentPeriods', {
        contractId: contractId,
        fromRoomCategoryId: roomCategoryId,
        toRoomCategoryId: nextRoomCategoryId
      })
      .then(() => {
        dispatch(contractActions.getHotelContract(contractId))
        dispatch(RESET_ACTIVE_ALLOTMENT())
        dispatch(globalMessageActions.setGlobalSuccess('Allotment periods copied'))
      })
  }
}

export const setActiveAllotment = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_ALLOTMENT(item))
  }
}

export const resetActiveAllotment = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_ALLOTMENT())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
