import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const FETCH_GENERAL_FACILITIES = createAction('get general facilities from server')
const FETCH_GENERAL_FACILITIES_SUCCESS = createAction('fetch general facilities success')

const RESET_ACTIVE_GENERAL_FACILITY = createAction('cancel active general facility')
const SET_VALUE = createAction('set active general facility value')

const SET_ACTIVE_GENERAL_FACILITY = createAction('set active general facility')

const initialState = {
  active: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_GENERAL_FACILITIES]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [FETCH_GENERAL_FACILITIES_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_ACTIVE_GENERAL_FACILITY]: (state, action) => {
      // TODO: Needs more clone...
      return { ...state, active: Object.assign({}, action.payload) }
    },
    [RESET_ACTIVE_GENERAL_FACILITY]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators
export const getGeneralFacilities = (ignoreCache=false) => {
  return (dispatch, getState) => {
    dispatch(FETCH_GENERAL_FACILITIES())
    api.query('general/generalFacilities', { ignoreCache }).then(json => dispatch(FETCH_GENERAL_FACILITIES_SUCCESS(json.data)))
  }
}

export const addGeneralFacility = category => {
  return dispatch => {
    return api.sendCommand('general/addGeneralFacility', category).then(() => {
      dispatch(getGeneralFacilities())
      dispatch(resetActiveGeneralFacility())
      dispatch(globalMessageActions.setGlobalSuccess('General facility added'))
    })
  }
}

export const updateGeneralFacility = category => {
  return (dispatch, getState) => {
    return api.sendCommand('general/updateGeneralFacility', category).then(() => {
      dispatch(getGeneralFacilities())
      dispatch(resetActiveGeneralFacility())
      dispatch(globalMessageActions.setGlobalSuccess('General facility updated'))
    })
  }
}

export const deleteGeneralFacility = category => {
  return (dispatch, getState) => {
    return api.sendCommand('general/DeleteGeneralFacility', category).then(() => {
      dispatch(getGeneralFacilities())
      dispatch(resetActiveGeneralFacility())
      dispatch(globalMessageActions.setGlobalSuccess('General facility updated'))
    })
  }
}

export const setActiveGeneralFacility = category => {
  return dispatch => {
    dispatch(SET_ACTIVE_GENERAL_FACILITY(category))
  }
}

export const resetActiveGeneralFacility = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_GENERAL_FACILITY())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
