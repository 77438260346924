import { combineReducers } from 'redux'
import addonTypes from './addon-types'
import articles from './articles'
import contract from './contract'
import contracts from './contracts'

export default combineReducers({
  addonTypes,
  articles,
  contract,
  contracts
})
