import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as reservationActions from './reservations'

// actions
const QUERY_SKILUGGAGE = createAction('query booking skiluggage')
const QUERY_SKILUGGAGE_SUCCESS = createAction('query booking skiluggage success')
const SET_SKILUGGAGE = createAction('set booking ski luggage')

const initialState = {
  error: null,
  loading: false,
  items: []
}

// handlers
export default handleActions(
  {
    [QUERY_SKILUGGAGE]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [QUERY_SKILUGGAGE_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_SKILUGGAGE]: (state, action) => {
      return {
        ...state,
        items: state.items.map(
          (traveler, i) =>
            (traveler.id === action.payload.travelerId
              ? {
                ...traveler,
                skiLuggage: { ...traveler.skiLuggage, reserved: action.payload.reserved }
              }
              : traveler)
        )
      }
    }
  },
  initialState
)

// thunks
export const querySkiLuggage = reservationId => {
  return dispatch => {
    api.query('travelbooking/skiluggage', { reservationId }).then(res => dispatch(QUERY_SKILUGGAGE_SUCCESS(res.data)))
  }
}

export const submitAddSkiLuggage = command => {
  return dispatch => {
    dispatch(SET_SKILUGGAGE({ ...command, reserved: true }))
    let cmd = api
      .sendCommand('travelBooking/addSkiluggage', command)
      .then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
      .catch(() => dispatch(SET_SKILUGGAGE({ ...command, reserved: false })))
    return cmd
  }
}

export const submitRemoveSkiLuggage = command => {
  return dispatch => {
    dispatch(SET_SKILUGGAGE({ ...command, reserved: false }))
    let cmd = api
      .sendCommand('travelBooking/removeSkiluggage', command)
      .then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
    return cmd
  }
}
