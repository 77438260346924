import { createAction, handleActions } from 'redux-actions'
import moment from 'moment-timezone'
import api from '../api'

const QUERY_OFFERS = createAction('query booking offers')

const initialState = {
  data: []
}

// handlers
export default handleActions(
  {
    [QUERY_OFFERS]: (state, action) => {
      return { ...state, data: action.payload }
    }
  },
  initialState
)

// thunks
export const queryOffers = (marketId, bookingDate = moment()) => {
  return dispatch => {
    let query = api.query('market/offer/offers', { marketId, bookingDate })
    query.then(res => {
      dispatch(QUERY_OFFERS(res.data))
    })
    return query
  }
}
