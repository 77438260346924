import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const QUERY_REPORT = createAction('query balance accounts')

const SET_RESERVATION_DATE_FROM = createAction('balance accounts reservation date from')
const SET_RESERVATION_DATE_TO = createAction('balance accounts reservation date to')
const SET_DEPARTURE_DATE_FROM = createAction('balance accounts departure date from')
const SET_DEPARTURE_DATE_TO = createAction('balance accounts departure date to')
const SET_TIME_CONSISTANCY_DATE = createAction('balance accounts time consistancy date from')
const SET_RESERVATION_CODE = createAction('balance accounts set reservation code')

const initialState = {
  selectedReservationDateFrom: null,
  selectedReservationDateTo: null,
  selectedDepartureDateFrom: null,
  selectedDepartureDateTo: null,
  selectedTimeConsistancyDate: null,
  reservationCode: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_RESERVATION_DATE_FROM]: (state, action) => {
      return { ...state, selectedReservationDateFrom: action.payload.item }
    },
    [SET_RESERVATION_DATE_TO]: (state, action) => {
      return { ...state, selectedReservationDateTo: action.payload.item }
    },
    [SET_DEPARTURE_DATE_FROM]: (state, action) => {
      return { ...state, selectedDepartureDateFrom: action.payload.item }
    },
    [SET_DEPARTURE_DATE_TO]: (state, action) => {
      return { ...state, selectedDepartureDateTo: action.payload.item }
    },
    [SET_TIME_CONSISTANCY_DATE]: (state, action) => {
      return { ...state, selectedTimeConsistancyDate: action.payload.item }
    },
    [SET_RESERVATION_CODE]: (state, action) => {
      return { ...state, reservationCode: action.payload.item }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    var bookingDateFrom = getState().economy.balanceAccounts.selectedReservationDateFrom
    var bookingDateTo = getState().economy.balanceAccounts.selectedReservationDateTo
    var departureDateFrom = getState().economy.balanceAccounts.selectedDepartureDateFrom
    var departureDateTo = getState().economy.balanceAccounts.selectedDepartureDateTo
    var viewDate = getState().economy.balanceAccounts.selectedTimeConsistancyDate
    var reservationCode = getState().economy.balanceAccounts.reservationCode

    api
      .query('travelBooking/reports/balanceAccounts', {
        bookingDateFrom,
        bookingDateTo,
        departureDateFrom,
        departureDateTo,
        viewDate,
        reservationCode
      })
      .then(json => dispatch(QUERY_REPORT(json.data)))
  }
}

export const setReservationDateFrom = item => {
  return dispatch => {
    dispatch(SET_RESERVATION_DATE_FROM({ item }))
  }
}

export const setReservationDateTo = item => {
  return dispatch => {
    dispatch(SET_RESERVATION_DATE_TO({ item }))
  }
}

export const setDepartureDateFrom = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_DATE_FROM({ item }))
  }
}

export const setDepartureDateTo = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_DATE_TO({ item }))
  }
}

export const setTimeConsistancyDate = item => {
  return dispatch => {
    dispatch(SET_TIME_CONSISTANCY_DATE({ item }))
  }
}

export const setReservationCode = item => {
  return dispatch => {
    dispatch(SET_RESERVATION_CODE({ item }))
  }
}
