import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const GET_BANKGIRO_PAYMENTS = createAction('get bankgiro payments')
const GET_BANKGIRO_PAYMENTS_SUCCESS = createAction('get bankgiro payments succeeded')

const REFETCH_BANKGIRO_PAYMENTS_BY_ID = createAction('refetch bankgiro payments by id')

const GET_BANKGIRO_PAYMENTS_BY_ID = createAction('get bankgiro payments by id')
const GET_BANKGIRO_PAYMENTS_BY_ID_SUCCESS = createAction('get bankgiro payments by id succeeded')

const RESET_ACTIVE_BANKGIRO_PAYMENT = createAction('cancel active bankgiro payment')
const UPDATE_ACTIVE_BANKGIRO_PAYMENT = createAction('update active bankgiro payment')
const EDIT_ACTIVE_BANKGIRO_PAYMENT = createAction('edit active bankgiro payment')

const SET_FROMDATE = createAction('set list fromDate')
const SET_TODATE = createAction('set list toDate')

const SET_RESERVATIONCODE = createAction('set bankgiro reservation code')

const SET_FILTER = createAction('Set bankgiro payment filter')
const SET_SORT = createAction('set bankgiro payment sort')

const SET_PAGE = createAction('set bankgiro pagination page')
const SET_ROWS_PER_PAGE = createAction('set bankgiro pagination rows per page')

const initalState = {
  items: [],
  loading: false,
  active: null,
  filter: { currencyId: 'SEK', status: '' },
  sort: { orderBy: 'dateTime', direction: 'desc' },
  totalAmount: 0,
  fromDate: null,
  toDate: null,
  reservationCode: '',
  page: 0,
  rowsPerPage: 25
}

export default handleActions(
  {
    [GET_BANKGIRO_PAYMENTS]: (state, action) => ({ ...state, loading: true, items: [] }),
    [GET_BANKGIRO_PAYMENTS_SUCCESS]: (state, action) => {
      return { ...state, items: action.payload, loading: false }
    },
    [REFETCH_BANKGIRO_PAYMENTS_BY_ID]: (state, action) => {
      return { ...state, items: action.payload.payments, loading: false, marketId: action.payload.marketId }
    },
    [GET_BANKGIRO_PAYMENTS_BY_ID]: (state, action) => ({ ...state, loading: true, items: [], marketId: '' }),
    [GET_BANKGIRO_PAYMENTS_BY_ID_SUCCESS]: (state, action) => {
      return { ...state, items: action.payload.payments, loading: false, marketId: action.payload.marketId }
    },
    [EDIT_ACTIVE_BANKGIRO_PAYMENT]: (state, action) => {
      return { ...state, active: { ...action.payload } }
    },
    [UPDATE_ACTIVE_BANKGIRO_PAYMENT]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: { ...active } }
    },
    [RESET_ACTIVE_BANKGIRO_PAYMENT]: (state, action) => {
      return { ...state, active: null, reservationCode: '' }
    },
    [SET_FROMDATE]: (state, action) => {
      return { ...state, fromDate: action.payload }
    },
    [SET_TODATE]: (state, action) => {
      return { ...state, toDate: action.payload }
    },
    [SET_RESERVATIONCODE]: (state, action) => {
      return { ...state, reservationCode: action.payload }
    },
    [SET_FILTER]: (state, action) => {
      let filter = state.filter
      filter[action.payload.name] = action.payload.value
      return { ...state, filter: filter }
    },
    [SET_SORT]: (state, action) => {
      return {
        ...state,
        sort: { orderBy: action.payload, direction: state.sort.direction === 'asc' ? 'desc' : 'asc' }
      }
    },
    [SET_PAGE]: (state, action) => {
      return { ...state, page: action.payload }
    },
    [SET_ROWS_PER_PAGE]: (state, action) => {
      return { ...state, rowsPerPage: action.payload }
    }
  },
  initalState
)

export function getBankGiroPayments () {
  return function (dispatch, getState) {
    dispatch(GET_BANKGIRO_PAYMENTS())

    return api.query('travelbooking/bankgirofile/BankGiroPayments').then(result => {
      dispatch(GET_BANKGIRO_PAYMENTS_SUCCESS(result.data))
    })
  }
}
export function refetchBankGiroPaymentsById (id) {
  return function (dispatch, getState) {

    return api.query('travelbooking/bankgirofile/BankGiroFile', { bankGiroFileId: id }).then(result => {
      dispatch(REFETCH_BANKGIRO_PAYMENTS_BY_ID(result.data))
      let currencyId = result.data.marketId === 'FI' ? 'EUR' : result.data.marketId === 'NO' ? 'NOK' : 'SEK'
      dispatch(setFilter('currencyId', currencyId))
    })
  }
}
export function getBankGiroPaymentsById (id) {
  return function (dispatch, getState) {
    dispatch(GET_BANKGIRO_PAYMENTS_BY_ID())

    return api.query('travelbooking/bankgirofile/BankGiroFile', { bankGiroFileId: id }).then(result => {
      dispatch(GET_BANKGIRO_PAYMENTS_BY_ID_SUCCESS(result.data))
      let currencyId = result.data.marketId === 'FI' ? 'EUR' : result.data.marketId === 'NO' ? 'NOK' : 'SEK'
      dispatch(setFilter('currencyId', currencyId))
    })
  }
}

export function submitBankGiroPaymentSearch (fromDate, toDate, reservationCode, marketId = null) {
  return function (dispatch, getState) {
    dispatch(GET_BANKGIRO_PAYMENTS())

    return api
      .query('travelbooking/bankgirofile/BankGiroPayments', { marketId, reservationCode, fromDate, toDate })
      .then(result => {
        dispatch(GET_BANKGIRO_PAYMENTS_SUCCESS(result.data))
        dispatch(SET_FROMDATE(fromDate))
        dispatch(SET_TODATE(toDate))
        dispatch(SET_RESERVATIONCODE(reservationCode))
      })
  }
}

export const editActiveBankGiroPayment = payment => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_BANKGIRO_PAYMENT(payment))
  }
}

export const resetActiveBankGiroPayment = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_BANKGIRO_PAYMENT())
  }
}

export const updateActiveBankGiroPayment = (value, name) => {
  return dispatch => {
    dispatch(UPDATE_ACTIVE_BANKGIRO_PAYMENT({ value, name }))
  }
}

export function setBankGiroPaymentReservationCode (payment) {
  return function (dispatch, getState) {
    dispatch(SET_RESERVATIONCODE())
    let reservationCommand = {
      bankGiroPaymentId: payment.id,
      bankGiroFileId: payment.transactionId,
      reservationId: payment.reservationId,
      reservationCode: payment.reservationCode,
      reference: payment.referenceNumber
    }
    return api
      .sendCommand('travelbooking/bankgirofile/SetBankGiroPaymentReservation', reservationCommand)
      .then(() => dispatch(getBankGiroPaymentsById(payment.transactionId)))
  }
}

export const setFromDate = value => {
  return dispatch => {
    dispatch(SET_FROMDATE(value))
  }
}

export const setToDate = value => {
  return dispatch => {
    dispatch(SET_TODATE(value))
  }
}

export const setReservationCode = value => {
  return dispatch => {
    dispatch(SET_RESERVATIONCODE(value))
  }
}

export const setFilter = (name, value) => {
  return dispatch => {
    dispatch(SET_FILTER({ name, value }))
  }
}

export const setSort = sortKey => {
  return dispatch => {
    dispatch(SET_SORT(sortKey))
  }
}

export const setPage = value => {
  return dispatch => {
    dispatch(SET_PAGE(value))
  }
}

export const setRowsPerPage = value => {
  return dispatch => {
    dispatch(SET_ROWS_PER_PAGE(value))
  }
}
