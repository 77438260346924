import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'
import * as hotelActions from './hotel'

// Actions
const SET_ROOM_CATEGORIES = createAction('set room categories')

const RESET_ACTIVE_ROOM_CATEGORIES = createAction('cancel active room category')
const SET_VALUE = createAction('set active room category value')
const SET_ACTIVE_ROOM_CATEGORIES = createAction('set active room category')
const SET_ROOM_CATEGORY = createAction('set selected room category values')

const initialState = {
  active: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [SET_ROOM_CATEGORIES]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_ACTIVE_ROOM_CATEGORIES]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_ROOM_CATEGORIES]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [SET_ROOM_CATEGORY]: (state, action) => {
      let active = state.active
      active.id = action.payload.id
      active.internalDescription = action.payload.internalDescription
      active.hotelDescription = action.payload.hotelDescription
      active.descriptions = action.payload.descriptions
      active.roomType = action.payload.roomType
      active.squareMeter = action.payload.squareMeter
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators
export const setRoomCategories = items => {
  return dispatch => {
    dispatch(SET_ROOM_CATEGORIES(items))
  }
}

export const queryRoomCategories = (hotelId, destinationIds = null, hotelIds =  null) => {
  return dispatch => {
    let query = api.query('hotel/hotel/roomcategories', { hotelId, destinationIds, hotelIds })

    return query
      .then(json => {
        dispatch(SET_ROOM_CATEGORIES(json.data))
        return json.data
      })
      .catch(errorMessage => dispatch(globalMessageActions.setGlobalError(errorMessage.error.message)))
  }
}

export const addRoomCategory = roomCategory => {
  return dispatch => {
    return api
      .sendCommand('hotel/hotel/AddRoomCategory', roomCategory)
      .then(() => {
        dispatch(hotelActions.getHotel(roomCategory.hotelId))
        dispatch(resetActiveHotelRoomCategory())
        dispatch(globalMessageActions.setGlobalSuccess('Room category added'))
      })
      .catch(errorMessage => dispatch(globalMessageActions.setGlobalError(errorMessage.error.message)))
  }
}

export const updateRoomCategory = roomCategory => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/hotel/UpdateRoomCategory', roomCategory)
      .then(() => {
        dispatch(hotelActions.getHotel(roomCategory.hotelId))
        dispatch(resetActiveHotelRoomCategory())
        dispatch(globalMessageActions.setGlobalSuccess('Room category updated'))
      })
      .catch(errorMessage => dispatch(globalMessageActions.setGlobalError(errorMessage.error.message)))
  }
}

export const deleteRoomCategory = item => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/hotel/DeleteRoomCategory', { id: item.id, hotelId: item.hotelId })
      .then(() => {
        dispatch(hotelActions.getHotel(item.hotelId))
        dispatch(resetActiveHotelRoomCategory())
        dispatch(globalMessageActions.setGlobalSuccess('Room category deleted'))
      })
      .catch(errorMessage => dispatch(globalMessageActions.setGlobalError(errorMessage.error.message)))
  }
}

export const setActiveHotelRoomCategory = hotelRoom => {
  return dispatch => {
    dispatch(SET_ACTIVE_ROOM_CATEGORIES(hotelRoom))
  }
}

export const resetActiveHotelRoomCategory = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_ROOM_CATEGORIES())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const setRoomCategory = roomCategory => {
  return dispatch => {
    dispatch(SET_ROOM_CATEGORY(roomCategory))
  }
}
