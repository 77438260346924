import { createAction, handleActions } from 'redux-actions'
import moment from 'moment'
import api from '../api'

const QUERY_CHARTERS_FROM = createAction('query move reservation charters from')
const QUERY_CHARTERS_FROM_SUCCESS = createAction('query move reservation charters from success')
const QUERY_CHARTERS_TO = createAction('query move reservation charters to')
const QUERY_CHARTERS_TO_SUCCESS = createAction('query move reservation charters success to')
const QUERY_CHARTER_RESERVATIONS = createAction('query move reservation charter reservations')
const QUERY_CHARTER_RESERVATIONS_SUCCESS = createAction('query move reservation charter reservations success')
const SET_ERROR = createAction('move reservation error')
const SELECT_RESERVATION = createAction('select reservation to be moved')
const UNSELECT_RESERVATION = createAction('unselect reservation to be moved')
const TOGGLE_ALL = createAction('toggle all reservations to be moved')
const SET_SELECTION_FROM = createAction('set move reservation selection from')
const SET_SELECTION_TO = createAction('set move reservation selection to')
const MOVE_RESERVATION = createAction('move reservation to new charter')
const MOVE_RESERVATION_SUCCESS = createAction('move reservation to new charter success')
const MOVE_RESERVATION_ERROR = createAction('move reservation to new charter error')
const CLEAR_SELECTION = createAction('clear move reservation selection')

const initialState = {
  chartersFrom: [],
  chartersTo: [],
  reservations: [],
  selectedReservations: [],
  allSelected: false,
  selectionFrom: { originAirportId: '', destinationAirportId: '', date: null, charterId: '', flight: '' },
  selectionTo: { originAirportId: '', destinationAirportId: '', date: null, charterId: '', flight: '' },
  chartersFromLoading: false,
  chartersToLoading: false,
  reservationsLoading: false,
  error: null
}

export default handleActions(
  {
    [QUERY_CHARTERS_FROM]: (state, action) => ({
      ...state,
      chartersFrom: [],
      chartersFromLoading: true
    }),
    [QUERY_CHARTERS_FROM_SUCCESS]: (state, action) => ({
      ...state,
      chartersFrom: action.payload,
      chartersFromLoading: false
    }),
    [QUERY_CHARTERS_TO]: (state, action) => ({
      ...state,
      chartersTo: [],
      chartersToLoading: true
    }),
    [QUERY_CHARTERS_TO_SUCCESS]: (state, action) => ({
      ...state,
      chartersTo: action.payload,
      chartersToLoading: false
    }),
    [QUERY_CHARTER_RESERVATIONS]: (state, action) => ({
      ...state,
      reservations: [],
      reservationsLoading: true
    }),
    [QUERY_CHARTER_RESERVATIONS_SUCCESS]: (state, action) => {
      action.payload.forEach(function (r) {
        r.checked = false
        r.moveStatus = null
        r.loading = false
      })
      return { ...state, reservations: action.payload, reservationsLoading: false }
    },
    [SET_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      chartersToLoading: false,
      chartersFromLoading: false,
      reservationsLoading: false
    }),
    [SELECT_RESERVATION]: (state, action) => {
      let selectedReservation = state.reservations.find(i => i.id === action.payload)
      selectedReservation.checked = true
      state.selectedReservations.push({ ...selectedReservation })
      let all =
        state.reservations &&
        state.selectedReservations &&
        state.reservations.length === state.selectedReservations.length
      return {
        ...state,
        reservations: state.reservations,
        selectedReservations: state.selectedReservations,
        allSelected: all
      }
    },
    [UNSELECT_RESERVATION]: (state, action) => {
      let selectedReservation = state.reservations.find(i => i.id === action.payload)
      selectedReservation.checked = false
      let selectedReservations = state.selectedReservations.filter(function (r) {
        return r.id !== selectedReservation.id
      })
      return {
        ...state,
        reservations: state.reservations,
        selectedReservations: selectedReservations,
        allSelected: false
      }
    },
    [TOGGLE_ALL]: (state, action) => {
      let allSelected = !state.allSelected
      state.reservations.forEach(function (r) {
        r.checked = allSelected
      })
      return {
        ...state,
        reservations: state.reservations,
        selectedReservations: allSelected ? [...state.reservations] : [],
        allSelected: allSelected
      }
    },
    [SET_SELECTION_FROM]: (state, action) => {
      let from = state.selectionFrom
      from[action.payload.propertyName] = action.payload.value
      return { ...state, selectionFrom: from }
    },
    [SET_SELECTION_TO]: (state, action) => {
      let to = state.selectionTo
      to[action.payload.propertyName] = action.payload.value
      return { ...state, selectionTo: to }
    },
    [MOVE_RESERVATION]: (state, action) => {
      let r = state.reservations.find(i => i.id === action.payload)
      r.loading = true
      return { ...state, reservations: state.reservations }
    },
    [MOVE_RESERVATION_SUCCESS]: (state, action) => {
      let r = state.reservations.find(i => i.id === action.payload)
      r.loading = false
      r.moveStatus = true
      return { ...state, reservations: state.reservations }
    },
    [MOVE_RESERVATION_ERROR]: (state, action) => {
      let r = state.reservations.find(i => i.id === action.payload)
      r.loading = false
      r.moveStatus = false
      return { ...state, reservations: state.reservations }
    },
    [CLEAR_SELECTION]: (state, action) => ({
      ...state,
      selectionFrom: { originAirportId: '', destinationAirportId: '', date: null, charterId: '', flight: '' },
      selectionTo: { originAirportId: '', destinationAirportId: '', date: null, charterId: '', flight: '' }
    })
  },
  initialState
)

export const queryCharters = name => {
  return (dispatch, getState) => {
    name === 'from' ? dispatch(QUERY_CHARTERS_FROM()) : dispatch(QUERY_CHARTERS_TO())
    let state = getState().charterPeriods.moveReservations
    let searchModel = name === 'from' ? state.selectionFrom : state.selectionTo
    searchModel.dateTimeFrom = moment(searchModel.date).startOf('day')
    searchModel.dateTimeTo = moment(searchModel.date).endOf('day')
    return api
      .query('flightdeparture/charter/charters', searchModel)
      .then(res =>
        name === 'from'
          ? dispatch(QUERY_CHARTERS_FROM_SUCCESS(res.data))
          : dispatch(QUERY_CHARTERS_TO_SUCCESS(res.data))
      )
      .catch(err => dispatch(SET_ERROR(err)))
  }
}

export const queryCharterReservations = charterId => {
  return dispatch => {
    dispatch(QUERY_CHARTER_RESERVATIONS())
    return api
      .query('travelbooking/charterreservations', { charterId })
      .then(res => dispatch(QUERY_CHARTER_RESERVATIONS_SUCCESS(res.data)))
      .catch(err => dispatch(SET_ERROR(err)))
  }
}

export const moveReservationsToNewCharter = sendConfirmation => {
  return (dispatch, getState) => {
    let state = getState().charterPeriods.moveReservations
    state.selectedReservations.forEach(function (r) {
      dispatch(MOVE_RESERVATION(r.id))
      return api
        .sendCommand('travelbooking/moveReservationToCharter', {
          reservationId: r.id,
          charterIdFrom: state.selectionFrom['charterId'],
          charterIdTo: state.selectionTo['charterId'],
          sendConfirmation: sendConfirmation
        })
        .then(res => dispatch(MOVE_RESERVATION_SUCCESS(r.id)))
        .then(res => dispatch(UNSELECT_RESERVATION(r.id)))
        .catch(err => dispatch(MOVE_RESERVATION_ERROR(r.id)))
    })
  }
}

export const toggleSelect = (id, checked) => {
  return dispatch => {
    checked ? dispatch(UNSELECT_RESERVATION(id)) : dispatch(SELECT_RESERVATION(id))
  }
}

export const toggleSelectAll = () => {
  return dispatch => {
    dispatch(TOGGLE_ALL())
  }
}

export const setSelection = (name, propertyName, value) => {
  return dispatch => {
    name === 'from'
      ? dispatch(SET_SELECTION_FROM({ propertyName, value }))
      : dispatch(SET_SELECTION_TO({ propertyName, value }))
  }
}

export const isSelectionValid = name => {
  return (dispatch, getState) => {
    if (name === 'from') {
      let selection = getState().charterPeriods.moveReservations.selectionFrom
      return selection['originAirportId'] !== '' && selection['destinationAirportId'] !== '' && selection['date']
    } else {
      let selection = getState().charterPeriods.moveReservations.selectionTo
      return selection['originAirportId'] !== '' && selection['destinationAirportId'] !== '' && selection['date']
    }
  }
}

export const clearSelection = () => {
  return dispatch => {
    dispatch(CLEAR_SELECTION())
  }
}
