import { createAction, handleActions } from 'redux-actions'

const localStorageNameSpace = 'booking'
const SET_ACTIVE = createAction('booking set active route')

const initialState = {
  activeSubMenu: 'proposal'
}

// Reducers/handlers
export default handleActions(
  {
    [SET_ACTIVE]: (state, action) => {
      if (!action.payload.value) {
        localStorage.removeItem(`${localStorageNameSpace}-${action.payload.id}`)
        return { ...state, activeSubMenu: 'proposal' }
      } else {
        localStorage.setItem(`${localStorageNameSpace}-${action.payload.id}`, action.payload.value)
        return { ...state, activeSubMenu: action.payload.value }
      }
    }
  },
  initialState
)

export const getActive = id => {
  return localStorage.getItem(`${localStorageNameSpace}-${id}`) || initialState.activeSubMenu
}

export const setActive = (id, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE({ id, value }))
  }
}
