import { combineReducers } from 'redux'
import users from './users'
import agents from './agents'
import agentUsers from './agent-users'
import accountActions from './account-actions'

export default combineReducers({
  users,
  agents,
  agentUsers,
  accountActions
})
