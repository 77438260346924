import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const FETCH_HOTELS = createAction('get hotels from server')
const FETCH_HOTELS_SUCCESS = createAction('fetch hotels success')

const RESET_ACTIVE_HOTEL = createAction('reset active hotel (add)')
const SET_VALUE = createAction('set hotel (add) value')
const SET_ACTIVE_HOTEL = createAction('set active hotel (add)')
const UNDO_ACTIVE_HOTEL = createAction('undo active hotel (add)')

const SELECT_HOTEL = createAction('select hotel')

const initialState = {
  active: null,
  loading: false,
  items: [],
  selectedHotel: ''
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_HOTELS]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [FETCH_HOTELS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload.hotels }
    },
    [SET_ACTIVE_HOTEL]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_HOTEL]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [UNDO_ACTIVE_HOTEL]: (state, action) => {
      let hotel = state.items.find(item => item.id === state.active.id)
      hotel.isNew = false
      return { ...state, active: hotel }
    },
    [SELECT_HOTEL]: (state, action) => {
      if (!action.payload) return { ...state, active: null, selectedHotel: '' }
      let hotel = state.items.find(item => item.id === action.payload)
      return { ...state, active: hotel, selectedHotel: action.payload }
    }
  },
  initialState
)

//  Action creators
export const getHotels = (ignoreCache, showDisabled = false) => {
  return (dispatch, getState) => {
    dispatch(FETCH_HOTELS())

    let query = api.query('hotel/hotel/hotels', {
      ignoreCache,
      showDisabled
    })

    query
      .then(json => {
        dispatch(FETCH_HOTELS_SUCCESS(json.data))
      })
      .catch(errorMessage =>
        dispatch(
          globalMessageActions.setGlobalError(errorMessage.error.message)
        )
      )

    return query
  }
}

export const addHotel = hotel => {
  return dispatch => {
    return api
      .sendCommand('hotel/hotel/addHotel', hotel)
      .then(() => {
        dispatch(getHotels())
        dispatch(RESET_ACTIVE_HOTEL())
        dispatch(globalMessageActions.setGlobalSuccess('Hotel added'))
      })
      .catch(errorMessage =>
        dispatch(
          globalMessageActions.setGlobalError(errorMessage.error.message)
        )
      )
  }
}

export const setActiveHotel = hotel => {
  return dispatch => {
    dispatch(SET_ACTIVE_HOTEL(hotel))
  }
}

export const resetActiveHotel = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_HOTEL())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const undoActiveHotel = () => {
  return dispatch => {
    dispatch(UNDO_ACTIVE_HOTEL())
  }
}

export const selectHotel = hotelId => {
  return dispatch => {
    dispatch(SELECT_HOTEL(hotelId))
  }
}
