import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const QUERY_TRAVELERS_BY_ROOM = createAction('query booking travelers by room')
const QUERY_TRAVELERS = createAction('query booking travelers')
const SET_VALUE = createAction('set booking traveler value')
const SET_TRAVELER_DETAILS = createAction('set booking traveler details')

const initialState = {
  error: null,
  loading: false,
  data: []
}

// handlers
export default handleActions(
  {
    [QUERY_TRAVELERS_BY_ROOM]: (state, action) => {
      action.payload.forEach(room => {
        room.travelers.forEach(traveler => {
          if (!traveler.genderCode) traveler.genderCode = 'M'
          if (!traveler.birthDate) traveler.birthDate = ''
        })
      })

      return { ...state, loading: false, data: action.payload }
    },
    [QUERY_TRAVELERS]: (state, action) => {
      action.payload.forEach(traveler => {
        if (!traveler.genderCode) traveler.genderCode = 'M'
        if (!traveler.birthDate) traveler.birthDate = ''
      })

      return { ...state, loading: false, data: action.payload }
    },
    [SET_VALUE]: (state, action) => {
      if (action.payload.roomIndex !== undefined && action.payload.roomIndex !== null) {
        return {
          ...state,
          data: state.data.map(
            (room, i) =>
              (i === action.payload.roomIndex
                ? {
                  ...room,
                  travelers: room.travelers.map(
                      (traveler, j) =>
                        (j === action.payload.travelerIndex
                          ? { ...traveler, [action.payload.name]: action.payload.value }
                          : traveler)
                    )
                }
                : room)
          )
        }
      } else {
        return {
          ...state,
          data: state.data.map(
            (traveler, i) =>
              (i === action.payload.travelerIndex
                ? { ...traveler, [action.payload.name]: action.payload.value }
                : traveler)
          )
        }
      }
    },

    [SET_TRAVELER_DETAILS]: (state, action) => {
      return { ...state }
    }
  },
  initialState
)

// thunks
export const queryTravelersByRooms = reservationId => {
  return dispatch => {
    return api
      .query('travelBooking/travelersByRooms', { reservationId })
      .then(res => dispatch(QUERY_TRAVELERS_BY_ROOM(res.data)))
  }
}

export const queryTravelers = reservationId => {
  return dispatch => {
    return api.query('travelBooking/travelers', { reservationId }).then(res => dispatch(QUERY_TRAVELERS(res.data)))
  }
}

export const setValue = (value, name, travelerIndex, roomIndex) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name, travelerIndex, roomIndex }))
  }
}
