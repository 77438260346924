import { createAction, handleActions } from 'redux-actions'
import * as globalMessageActions from '../../global-message'
import api from '../../api'

// Actions
const CREATE_ADDON_CONTRACT = createAction('create addon contract')
const CREATE_ADDON_CONTRACT_SUCCESS = createAction('create addon contract success')
const QUERY_ADDON_CONTRACTS = createAction('query addon contracts from server')
const QUERY_ADDON_CONTRACTS_SUCCESS = createAction('query addon contracts from server success')

const SET_ACTIVE_CONTRACT = createAction('set new addon contract')
const SET_ACTIVE_VALUE = createAction('addon contract set new value')
const RESET_ACTIVE_CONTRACT = createAction('reset new addon contract')

const SET_SORT = createAction('set addon contract sort')

const initialState = {
  active: null,
  loading: false,
  items: [],
  sort: { orderBy: 'id', direction: 'asc' }
}

// Reducers/handlers
export default handleActions(
  {
    [CREATE_ADDON_CONTRACT]: (state, action) => {
      return { ...state, loading: true }
    },
    [CREATE_ADDON_CONTRACT_SUCCESS]: (state, action) => {
      return { ...state, loading: false, active: null }
    },
    [QUERY_ADDON_CONTRACTS]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [QUERY_ADDON_CONTRACTS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_ACTIVE_CONTRACT]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    },
    [RESET_ACTIVE_CONTRACT]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_SORT]: (state, action) => {
      return {
        ...state,
        sort: { orderBy: action.payload, direction: state.sort.direction === 'asc' ? 'desc' : 'asc' }
      }
    }
  },
  initialState
)

export const submitCreateAddonContract = contract => {
  return dispatch => {
    dispatch(CREATE_ADDON_CONTRACT())

    return api.sendCommand('destination/addonContract/createContract', contract).then(result => {
      dispatch(CREATE_ADDON_CONTRACT_SUCCESS(result.data))
      dispatch(queryAddonContracts())
      dispatch(globalMessageActions.setGlobalSuccess(`${contract.addonType} contract created`))
    })
  }
}


export const queryAddonContracts = (params = null) => {
  return dispatch => {
    dispatch(QUERY_ADDON_CONTRACTS())
    return api.query('destination/addonContract/contracts', params).then(result => {
      dispatch(QUERY_ADDON_CONTRACTS_SUCCESS(result.data))
    })
  }
}

export const setActiveContract = value => {
  return dispatch => {
    dispatch(SET_ACTIVE_CONTRACT(value))
  }
}

export const resetActiveContract = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_CONTRACT())
  }
}

export const setActiveValue = (value, name) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ value, name }))
  }
}

export const setSort = sortOrder => {
  return dispatch => {
    dispatch(SET_SORT(sortOrder))
  }
}
