import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const QUERY_CHARTER_TYPE_CATEGORIES = createAction('query charter type categories')
const QUERY_CHARTER_TYPE_CATEGORIES_SUCCESS = createAction('query charter type categories success')

const initialState = {
  data: [],
  loading: false
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_CHARTER_TYPE_CATEGORIES]: (state, action) => {
      return { ...state, loading: true }
    },
    [QUERY_CHARTER_TYPE_CATEGORIES_SUCCESS]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    }

  },
  initialState
)

export const queryCharterTypeCategories = () => {
  return dispatch => {
    dispatch(QUERY_CHARTER_TYPE_CATEGORIES)
    api.query('general/charterTypeCategories').then(res => dispatch(QUERY_CHARTER_TYPE_CATEGORIES_SUCCESS(res.data)))
  }
}
