import { combineReducers } from 'redux'
import priceManagementRoomCategories from './price-management-room-categories'
import priceManagementHotel from './price-management-hotel'
import priceManagementDestination from './price-management-destination'
import releases from './releases'
import requests from './requests'
import cancellations from './cancellations'
import upcomingReleases from './upcoming-releases'
import charterPeriods from './charter-periods'
import rooming from './rooming'
import originalRooming from './original-rooming'
import skipasses from './skipasses'
import skiSchool from './ski-school'
import skiRentals from './ski-rentals'
import excursion from './excursion'
import excursions from './excursions'
import hiking from './hiking'
import busConnections from './bus-connections'
import hotelStatistics from './hotel-statistics'
import flightPassengers from './flight-passengers'
import flightManifest from './flight-manifest'
import flightStatistics from './flight-statistics'
import moveCharter from './move-charter'
import events from './events'
import capacityErrors from './capacity-errors'
import flightReleases from './flight-releases'

export default combineReducers({
  priceManagementRoomCategories,
  priceManagementHotel,
  priceManagementDestination,
  releases,
  requests,
  hotelStatistics,
  cancellations,
  upcomingReleases,
  charterPeriods,
  rooming,
  originalRooming,
  skipasses,
  flightManifest,
  flightReleases,
  skiSchool,
  excursion,
  excursions,
  hiking,
  busConnections,
  flightStatistics,
  flightPassengers,
  skiRentals,
  events,
  moveCharter,
  capacityErrors
})
