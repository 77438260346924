import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_REPORT = createAction('query skiSchool list')

const SET_DESTINATION_ID = createAction('skiSchool list destination id')
const SET_FROM_DATE = createAction('skiSchool list from date')
const SET_TO_DATE = createAction('skiSchool list to date')
const RESET_DATES = createAction('skiSchool reset dates')
const SET_SEASON_FROM_DATE = createAction('skiSchool list season from date')
const SET_SEASON_TO_DATE = createAction('skiSchool list season to date')
const SET_FLIGHT_NUMBER = createAction('skiSchool statistics set flight number')

const initialState = {
  selectedDestinationId: null,
  selectedFlightNumber: null,
  selectedFromDate: null,
  selectedToDate: null,
  selectedSeasonFromDate: moment(),
  selectedSeasonToDate: moment().add(3, 'weeks'),
  loading: false,
  item: {}
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, item: action.payload }
    },
    [SET_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload.item }
    },
    [SET_FLIGHT_NUMBER]: (state, action) => {
      return { ...state, selectedFlightNumber: action.payload.item }
    },
    [SET_FROM_DATE]: (state, action) => {
      return { ...state, selectedFromDate: moment(action.payload.item) }
    },
    [SET_TO_DATE]: (state, action) => {
      return { ...state, selectedToDate: moment(action.payload.item) }
    },
    [RESET_DATES]: (state, action) => {
      return {
        ...state,
        selectedToDate: initialState.selectedToDate,
        selectedFromDate: initialState.selectedFromDate
      }
    },
    [SET_SEASON_FROM_DATE]: (state, action) => {
      return { ...state, selectedSeasonFromDate: moment(action.payload.item) }
    },
    [SET_SEASON_TO_DATE]: (state, action) => {
      return { ...state, selectedSeasonToDate: moment(action.payload.item) }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    var destinationId = getState().production.skiSchool.selectedDestinationId
    var fromDate = getState().production.skiSchool.selectedFromDate
    var toDate = getState().production.skiSchool.selectedToDate
    var flightNumber = getState().production.skiSchool.selectedFlightNumber

    if (destinationId && fromDate && toDate) {
      api
        .query('travelbooking/reports/skiSchoolList', {
          destinationId,
          fromDate,
          toDate,
          flightNumber
        })
        .then(json => dispatch(QUERY_REPORT(json.data)))
    }
  }
}

export const queryReportAsync = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var destinationId = getState().production.skiSchool.selectedDestinationId
      var fromDate = getState().production.skiSchool.selectedFromDate
      var toDate = getState().production.skiSchool.selectedToDate
      var flightNumber = getState().production.skiSchool.selectedFlightNumber

      if (destinationId && fromDate && toDate) {
        api
          .query('travelbooking/reports/skiSchoolList', {
            destinationId,
            fromDate,
            toDate,
            flightNumber
          })
          .then(json => {
            dispatch(QUERY_REPORT(json.data))
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }
}

export const setDestinationId = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_ID({ item }))
  }
}

export const setFromDate = item => {
  return dispatch => {
    dispatch(SET_FROM_DATE({ item }))
  }
}

export const setToDate = item => {
  return dispatch => {
    dispatch(SET_TO_DATE({ item }))
  }
}

export const resetDates = () => {
  return dispatch => {
    dispatch(RESET_DATES())
  }
}

export const setSeasonFromDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_FROM_DATE({ item }))
  }
}

export const setSeasonToDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_TO_DATE({ item }))
  }
}

export const setFlightNumber = item => {
  return dispatch => {
    dispatch(SET_FLIGHT_NUMBER({ item }))
  }
}
