import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const QUERY_CHARTER_PERIODS = createAction('query charter periods')

const initialState = {
  data: []
}

// handlers
export default handleActions(
  {
    [QUERY_CHARTER_PERIODS]: (state, action) => {
      return { ...state, data: action.payload }
    }
  },
  initialState
)

// thunks
export const queryCharterPeriods = (destinationId, charterTypeId, airportId) => {
  return (dispatch, getState) => {
    const reservation = getState().booking.reservations.reservation
    if (reservation.searchInfo.travelType === 'Hotel') {
      api.query('hotel/charterPeriod/periods', { destinationId, charterTypeId }).then(res => {
        dispatch(QUERY_CHARTER_PERIODS(res.data))
      })
    } else if (reservation.searchInfo.travelType === 'Flight') {
      api.query('flightDeparture/charter/airportPeriods', { airportId, destinationId, charterTypeId }).then(res => {
        dispatch(QUERY_CHARTER_PERIODS(res.data))
      })
    } else {
      api.query('flightDeparture/charter/periods', { airportId, destinationId, charterTypeId }).then(res => {
        dispatch(QUERY_CHARTER_PERIODS(res.data))
      })
    }
  }
}
