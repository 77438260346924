import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_REPORT = createAction('query skipass list')

const SET_DESTINATION_ID = createAction('skipass list destination id')
const SET_FLIGHT_NUMBER = createAction('flight statistics set flight number')
const SET_FROM_DATE = createAction('skipass list from date')
const SET_TO_DATE = createAction('skipass list to date')
const RESET_DATES = createAction('skipass reset dates')
const SET_SEASON_FROM_DATE = createAction('skipass list season from date')
const SET_SEASON_TO_DATE = createAction('skipass list season to date')
const SET_SEASON_ID = createAction('set season id')
const SET_SHOW_COST = createAction('set show cost')

const initialState = {
  selectedDestinationId: null,
  selectedFlightNumber: null,
  selectedFromDate: null,
  selectedToDate: null,
  selectedSeasonFromDate: moment(),
  selectedSeasonToDate: moment().add(3, 'weeks'),
  loading: false,
  item: {}
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, item: action.payload }
    },
    [SET_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload.item }
    },
    [SET_FLIGHT_NUMBER]: (state, action) => {
      return { ...state, selectedFlightNumber: action.payload.item }
    },
    [SET_FROM_DATE]: (state, action) => {
      return { ...state, selectedFromDate: moment(action.payload.item) }
    },
    [SET_TO_DATE]: (state, action) => {
      return { ...state, selectedToDate: moment(action.payload.item) }
    },
    [RESET_DATES]: (state, action) => {
      return {
        ...state,
        selectedToDate: initialState.selectedToDate,
        selectedFromDate: initialState.selectedFromDate
      }
    },
    [SET_SEASON_FROM_DATE]: (state, action) => {
      return { ...state, selectedSeasonFromDate: moment(action.payload.item) }
    },
    [SET_SEASON_TO_DATE]: (state, action) => {
      return { ...state, selectedSeasonToDate: moment(action.payload.item) }
    },
    [SET_SEASON_ID]: (state, action) => {
      return { ...state, selectedSeasonId: action.payload.item }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    const destinationId = getState().production.skipasses.selectedDestinationId
    const fromDate = getState().production.skipasses.selectedFromDate
    const toDate = getState().production.skipasses.selectedToDate
    const flightNumber = getState().production.skipasses.selectedFlightNumber

    if (destinationId && fromDate && toDate) {
      api
        .query('travelbooking/reports/skipassList', {
          destinationId,
          fromDate,
          toDate,
          flightNumber
        })
        .then(json => dispatch(QUERY_REPORT(json.data)))
    }
  }
}

export const queryReportAsync = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const destinationId =
        getState().production.skipasses.selectedDestinationId
      const fromDate = getState().production.skipasses.selectedFromDate
      const toDate = getState().production.skipasses.selectedToDate
      const flightNumber = getState().production.skipasses.selectedFlightNumber

      if (destinationId && fromDate && toDate) {
        api
          .query('travelbooking/reports/skipassList', {
            destinationId,
            fromDate,
            toDate,
            flightNumber
          })
          .then(json => {
            dispatch(QUERY_REPORT(json.data))
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      } else {
        resolve() // or reject if these conditions should result in an error
      }
    })
  }
}

export const setDestinationId = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_ID({ item }))
  }
}

export const setFlightNumber = item => {
  return dispatch => {
    dispatch(SET_FLIGHT_NUMBER({ item }))
  }
}

export const setFromDate = item => {
  return dispatch => {
    dispatch(SET_FROM_DATE({ item }))
  }
}

export const setToDate = item => {
  return dispatch => {
    dispatch(SET_TO_DATE({ item }))
  }
}

export const resetDates = () => {
  return dispatch => {
    dispatch(RESET_DATES())
  }
}

export const setSeasonFromDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_FROM_DATE({ item }))
  }
}

export const setSeasonId = item => {
  return dispatch => {
    dispatch(SET_SEASON_ID({ item }))
  }
}

export const setShowCost = item => {
  return dispatch => {
    dispatch(SET_SHOW_COST({ item }))
  }
}

export const setSeasonToDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_TO_DATE({ item }))
  }
}
