import React from 'react'
import ReactDOM from 'react-dom'
import './set-moment-locale'
import './set-moment-time-zone'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import App from './app'
import store from './store'
import * as serviceWorker from './serviceWorker'
import ErrorBoundary from './common/error-boundary'
import './index.css'

const { REACT_APP_SENTRY_URL: sentryUrl, REACT_APP_VERSION: appVersion } = process.env

const getEnvironment = () => {
  const host = window.location.host
  if (host.includes('.dev.')) {
    return 'dev'
  } else if (host.includes('.qa.')) {
    return 'qa'
  } else if (host.includes('.local.') || host.includes('localhost')) {
    return 'local'
  }
  return 'prod'
}

if (sentryUrl) {
  Sentry.init({
    dsn: sentryUrl,
    environment: getEnvironment()
  })

  Sentry.configureScope(scope => {
    scope.setTag('version', appVersion)
  })
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>,
  document.getElementById('root')
)

// Temporarily disabled service worker since it causes trouble
// with queries that download files (setting the window.href to
// /api/v1/query/...) - the service worker will serve a cached
// version of index.html for those queries.
//
// See https://github.com/facebook/create-react-app/issues/3171
// and https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#opting-out-of-caching
//
//
// registerServiceWorker()

/*
serviceWorker.register({
  onUpdate: () => {
    // Some UI notification comes here, then reload
    window.location.reload()
  }
})
*/

serviceWorker.unregister()
