import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_SIHOT_HOTELROOM_CATEGORIES = createAction(
  'query sihot hotellroom categories'
)
const SET_ACTIVE_HOTELROOM = createAction(
  'set active sihot hotellroom category'
)
const SET_ACTIVE_VALUE = createAction(
  'set active sihot hotellroom category value'
)

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_SIHOT_HOTELROOM_CATEGORIES]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE_HOTELROOM]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    }
  },
  initialState
)

// Action creators
export const querySihot = () => {
  return dispatch => {
    return api.query('sihot/sihotHotelRoomCategories').then(json => {
      dispatch(QUERY_SIHOT_HOTELROOM_CATEGORIES(json.data))
      return json.data
    })
  }
}

export const submitCreateHotelRoom = hotelRoom => {
  return dispatch => {
    return api
      .sendCommand('sihot/CreateSihotHotelCategory', hotelRoom)
      .then(() => {
        dispatch(querySihot())
        dispatch(
          globalMessageActions.setGlobalSuccess(
            'Sihot Hotellroom category added'
          )
        )
      })
  }
}

export const submitDeleteHotelRoom = hotelroom => {
  return (dispatch, getState) => {
    return api
      .sendCommand('sihot/DeleteSihotHotelCategory', { id: hotelroom })
      .then(() => {
        dispatch(querySihot())
        dispatch(
          globalMessageActions.setGlobalSuccess(
            'Sihot Hotellroom category deleted'
          )
        )
      })
  }
}

export const setActiveHotelRoom = hotelroom => {
  return dispatch => {
    dispatch(SET_ACTIVE_HOTELROOM(hotelroom))
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ value, name }))
  }
}
