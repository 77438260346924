import { createAction, handleActions } from 'redux-actions'
import api from '../api'
// import * as globalMessageActions from '../global-message'

// Actions
const FETCH_FLIGHT_NUMBERS = createAction('get flight number from server')
const FETCH_FLIGHT_NUMBERS_SUCCESS = createAction('fetch flight number success')
const SELECT_FLIGHT_NUMBER = createAction('select flight number')

const initialState = {
  active: null,
  loading: false,
  selectedFlightNumber: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_FLIGHT_NUMBERS]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [FETCH_FLIGHT_NUMBERS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SELECT_FLIGHT_NUMBER]: (state, action) => {
      return { ...state, selectedFlightNumber: action.payload }
    }
  },
  initialState
)

// Action creators
export const getFlightNumbers = () => {
  return dispatch => {
    dispatch(FETCH_FLIGHT_NUMBERS())
    return api.query('flightDeparture/charter/flightnumbers').then(json => {
      dispatch(FETCH_FLIGHT_NUMBERS_SUCCESS(json.data))
      return json.data
    })
  }
}

export const selectFlightNumber = selectedFlightNumber => {
  return dispatch => {
    dispatch(SELECT_FLIGHT_NUMBER(selectedFlightNumber))
  }
}
