import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

const GET_CONCORDE_FILES = createAction('get concorde files')
const GET_CONCORDE_FILES_SUCCESS = createAction('get concorde files success')
const GET_CONCORDE_FILES_ERROR = createAction('get concorde files error')

const CREATE_CONCORDE_FILE = createAction('create concorde file')
const CREATE_CONCORDE_FILE_SUCCESS = createAction('create concorde file success')
const CREATE_CONCORDE_FILE_ERROR = createAction('create concorde file error')

const EXPORT_CONCORDE_FILE = createAction('export concorde file')
const EXPORT_CONCORDE_FILE_SUCCESS = createAction('export concorde file success')
const EXPORT_CONCORDE_FILE_ERROR = createAction('export concorde file error')

const SET_TO_DATE = createAction('set to date')
const SET_LOADING = createAction('set loading')

const initalState = {
  items: [],
  loading: true,
  toDate: ''
}

export default handleActions(
  {
    [GET_CONCORDE_FILES]: (state, action) => {
      return { ...state, loading: true }
    },
    [GET_CONCORDE_FILES_SUCCESS]: (state, action) => {
      return { ...state, items: action.payload.concordeFiles, loading: false }
    },
    [GET_CONCORDE_FILES_ERROR]: (state, action) => {
      return { ...state, loading: false }
    },
    [CREATE_CONCORDE_FILE]: (state, action) => {
      return { ...state, loading: true }
    },
    [CREATE_CONCORDE_FILE_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [CREATE_CONCORDE_FILE_ERROR]: (state, action) => {
      return { ...state, loading: false }
    },
    [EXPORT_CONCORDE_FILE]: (state, action) => {
      return { ...state, loading: true }
    },
    [EXPORT_CONCORDE_FILE_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [EXPORT_CONCORDE_FILE_ERROR]: (state, action) => {
      return { ...state, loading: false }
    },
    [SET_TO_DATE]: (state, action) => {
      return { ...state, toDate: action.payload }
    },
    [SET_LOADING]: (state, action) => {
      return { ...state, loading: action.payload }
    }
  },
  initalState
)

export const setToDate = toDate => {
  return function (dispatch) {
    dispatch(SET_TO_DATE(toDate))
  }
}

export const setLoading = loading => {
  return function (dispatch) {
    dispatch(SET_LOADING(loading))
  }
}

export function getConcordeFiles () {
  return function (dispatch, getState) {
    dispatch(GET_CONCORDE_FILES())

    return api
      .query('Bookkeeping/ConcordeFiles/ConcordeFiles')
      .then(result => {
        dispatch(GET_CONCORDE_FILES_SUCCESS(result.data))
      })
      .catch(err => {
        dispatch(GET_CONCORDE_FILES_ERROR())
        dispatch(globalMessageActions.setGlobalError('Could not load previously exported Concorde files'))
      })
  }
}

export function createConcordeFile (toDate) {
  return function (dispatch, getState) {
    dispatch(GET_CONCORDE_FILES())

    return api
      .sendCommand('Bookkeeping/ConcordeFiles/CreateConcordeFile', { toDate })
      .then(result => {
        dispatch(CREATE_CONCORDE_FILE_SUCCESS())
        dispatch(getConcordeFiles())
      })
      .catch(err => {
        dispatch(CREATE_CONCORDE_FILE_ERROR())
        dispatch(globalMessageActions.setGlobalError('Could not create a new Concorde file'))
      })
  }
}
