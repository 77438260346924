const all = 'All'
const apartment = 'Apartment'
const hotelRoom = 'HotelRoom'

const labels = {
  all: 'All',
  apartment: 'Apartment',
  hotelRoom: 'Hotel room'
}

export const RoomTypeEnum = Object.freeze({
  all: all,
  apartment: apartment,
  hotelRoom: hotelRoom,

  labels: labels,

  format: roomType => {
    switch (roomType) {
      case all:
        return labels.all
      case apartment:
        return labels.apartment
      case hotelRoom:
        return labels.hotelRoom
      default:
        break
    }
  },

  toList: () => [
    { value: all, label: labels.all },
    { value: apartment, label: labels.apartment },
    { value: hotelRoom, label: labels.hotelRoom }
  ]
})

export default RoomTypeEnum
