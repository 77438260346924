import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const FETCH_EXCHANGE_RATES = createAction('get exchange rate from server')
const FETCH_EXCHANGE_RATES_SUCCESS = createAction('fetch exchange rate success')

const SET_EXCHANGE_RATE = createAction('update active exchange rate')
const SET_EXCHANGE_RATE_SUCCESS = createAction('update exchange rate success')

const RESET_ACTIVE_EXCHANGE_RATE = createAction('cancel active exchange rate')
const UPDATE_ACTIVE_EXCHANGE_RATE = createAction('update active exchange rate')
const EDIT_ACTIVE_EXCHANGE_RATE = createAction('edit active exchange rate')

const initialState = {
  active: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_EXCHANGE_RATES]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [FETCH_EXCHANGE_RATES_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_EXCHANGE_RATE]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [SET_EXCHANGE_RATE_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [EDIT_ACTIVE_EXCHANGE_RATE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_EXCHANGE_RATE]: (state, action) => {
      return { ...state, active: null }
    },
    [UPDATE_ACTIVE_EXCHANGE_RATE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators
export const getExchangeRates = () => {
  return dispatch => {
    api.query('currency/currency/exchangeRates').then(json => dispatch(FETCH_EXCHANGE_RATES_SUCCESS(json.data)))
  }
}

export const setExchangeRate = exchangeRate => {
  return (dispatch, getState) => {
    dispatch(SET_EXCHANGE_RATE())
    return api.sendCommand('currency/currency/setExchangeRate', exchangeRate).then(() => {
      dispatch(SET_EXCHANGE_RATE_SUCCESS())
      dispatch(getExchangeRates())
      dispatch(globalMessageActions.setGlobalSuccess('Exchange rate updated'))
    })
  }
}

export const editActiveExchangeRate = exchangeRate => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_EXCHANGE_RATE(exchangeRate))
  }
}

export const resetActiveExchangeRate = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_EXCHANGE_RATE())
  }
}

export const updateActiveExchangeRate = (value, name) => {
  return dispatch => {
    dispatch(UPDATE_ACTIVE_EXCHANGE_RATE({ value, name }))
  }
}
