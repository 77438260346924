import { createAction, handleActions } from 'redux-actions'
import * as globalMessageActions from '../global-message'
import api from '../api'

// Actions
const QUERY_FLIGHT_MANIFESTS = createAction('query flight manifests')
const QUERY_FLIGHT_MANIFESTS_SUCCESS = createAction('query flight manifests success')
const QUERY_AIRLINE_INTEGRATIONS = createAction('quert airline integration')
const QUERY_AIRLINE_INTEGRATIONS_SUCCESS = createAction('quert airline integration success')

const initialState = {
  loading: false,
  flightManifestExports: [],
  airlineIntegrations: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_FLIGHT_MANIFESTS]: state => {
      return { ...state, loading: true }
    },
    [QUERY_FLIGHT_MANIFESTS_SUCCESS]: (state, action) => {
      return { ...state, flightManifestExports: action.payload }
    },
    [QUERY_AIRLINE_INTEGRATIONS]: state => {
      return { ...state, airlineIntegrations: [], loading: true }
    },
    [QUERY_AIRLINE_INTEGRATIONS_SUCCESS]: (state, action) => {
      return { ...state, airlineIntegrations: action.payload }
    }
  },
  initialState
)

// Action creators
export const queryFlightManifests = id => {
  return dispatch => {
    dispatch(QUERY_FLIGHT_MANIFESTS())
    return api
      .query('flightDeparture/airlineIntegration/listExports', { id })
      .then(json => dispatch(QUERY_FLIGHT_MANIFESTS_SUCCESS(json.data)))
  }
}

export const queryAirlineIntegrations = (includeManual = true) => {
  return dispatch => {
    dispatch(QUERY_AIRLINE_INTEGRATIONS())

    return api
      .query('flightDeparture/airlineIntegration/airlineIntegrations', { includeManual })
      .then(json => dispatch(QUERY_AIRLINE_INTEGRATIONS_SUCCESS(json.data)))
  }
}

export const downloadFlightManifestFile = (fileId, id) => {
  return dispatch => {
    window.location.href = api.href('exportFlightManifestFile', `fileId=${fileId}&id=${id}`)

    dispatch(globalMessageActions.setGlobalSuccess('Flight manifest downloaded'))
  }
}

export const performFlightManifestExport = (id, fileId) => {
  return dispatch => {
    return api
      .sendCommand('flightDeparture/airlineIntegration/exportManifests', {
        id,
        fileId
      })
      .then(() => {
        dispatch(globalMessageActions.setGlobalSuccess('Flight manifests exported'))
        return dispatch(queryFlightManifests(id))
      })
  }
}

export const performFlightManifestExportWithFilter = command => {
  return dispatch => {
    return api.sendCommand('flightDeparture/airlineIntegration/exportManifests', command).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Flight manifests exported'))
      return dispatch(queryFlightManifests(command.id))
    })
  }
}
