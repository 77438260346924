import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const FETCH_CURRENCIES = createAction('get currencies')
const FETCH_CURRENCIES_SUCCESS = createAction('fetch currencies success')

const initialState = {
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_CURRENCIES]: (state, action) => {
      return { ...state, loading: true }
    },
    [FETCH_CURRENCIES_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    }
  },
  initialState
)

// Action creators
export const getCurrencies = () => {
  return dispatch => {
    dispatch(FETCH_CURRENCIES())
    let query = api.query('currency/currency/currencies')
    query.then(json => dispatch(FETCH_CURRENCIES_SUCCESS(json.data.currencies)))
    return query
  }
}
