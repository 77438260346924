import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'

// Actions
const SET_CONTRACT_ROOM_CATEGORIES = createAction('set contract room categories')

const RESET_ACTIVE_ROOM_CATEGORIES = createAction('cancel active contract room category')
const SET_VALUE = createAction('set active contract room category value')
const SET_ACTIVE_ROOM_CATEGORIES = createAction('set active contract room category')

const SET_ROOM_CATEGORY = createAction('set selected contract room category values')

const initialState = {
  active: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [SET_CONTRACT_ROOM_CATEGORIES]: (state, action) => {
      return {
        ...state,
        loading: false,
        items: action.payload.items
      }
    },
    [SET_ACTIVE_ROOM_CATEGORIES]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_ROOM_CATEGORIES]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [SET_ROOM_CATEGORY]: (state, action) => {
      let active = state.active
      active.id = action.payload.id
      active.internalDescription = action.payload.internalDescription
      active.hotelDescription = action.payload.hotelDescription
      active.descriptions = action.payload.descriptions
      active.information = action.payload.information
      active.boardIncluded = action.payload.boardIncluded
      active.roomType = action.payload.roomType
      active.squareMeter = action.payload.squareMeter
      active.numberOfOrdinaryBeds = action.payload.numberOfOrdinaryBeds
      active.numberOfExtraBeds = action.payload.numberOfExtraBeds
      active.noOfAdultsInExtraBeds = action.payload.noOfAdultsInExtraBeds
      active.noOfChildrenInExtraBeds = action.payload.noOfChildrenInExtraBeds
      active.childrenMaxAge = action.payload.childrenMaxAge
      active.minimumNumberOfPayingGuests = action.payload.minimumNumberOfPayingGuests
      active.roomFacilityIds = action.payload.roomFacilityIds
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators
export const setContractRoomCategories = items => {
  return dispatch => {
    dispatch(SET_CONTRACT_ROOM_CATEGORIES(items))
  }
}

export const addContractRoomCategory = roomCategory => {
  return dispatch => {
    return api
      .sendCommand('hotel/contract/AddContractRoomCategory', roomCategory)
      .then(() => {
        dispatch(contractActions.getHotelContract(roomCategory.contractId))
        dispatch(resetActiveHotelRoomCategory())
        dispatch(globalMessageActions.setGlobalSuccess('Room category added'))
      })
      .catch(errorMessage => dispatch(globalMessageActions.setGlobalError(errorMessage.error.message)))
  }
}

export const updateContractRoomCategory = roomCategory => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/contract/UpdateContractRoomCategory', roomCategory)
      .then(() => {
        dispatch(contractActions.getHotelContract(roomCategory.contractId))
        dispatch(resetActiveHotelRoomCategory())
        dispatch(globalMessageActions.setGlobalSuccess('Room category updated'))
      })
      .catch(errorMessage => dispatch(globalMessageActions.setGlobalError(errorMessage.error.message)))
  }
}

export const deleteContractRoomCategory = item => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/contract/DeleteContractRoomCategory', { id: item.id, contractId: item.contractId })
      .then(() => {
        dispatch(contractActions.getHotelContract(item.contractId))
        dispatch(resetActiveHotelRoomCategory())
        dispatch(globalMessageActions.setGlobalSuccess('Room category deleted'))
      })
      .catch(errorMessage => dispatch(globalMessageActions.setGlobalError(errorMessage.error.message)))
  }
}

export const setActiveHotelRoomCategory = hotelRoom => {
  return dispatch => {
    dispatch(SET_ACTIVE_ROOM_CATEGORIES(hotelRoom))
  }
}

export const resetActiveHotelRoomCategory = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_ROOM_CATEGORIES())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const setRoomCategory = roomCategory => {
  return dispatch => {
    dispatch(SET_ROOM_CATEGORY(roomCategory))
  }
}
