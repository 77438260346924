import * as Sentry from '@sentry/browser'
import { jwtDecode } from 'jwt-decode'

export default class AuthService {
  static isAuthenticated () {
    const token = AuthService.getToken()
    return token && !AuthService.isTokenExpired(token)
  }

  static setToken (idToken) {
    window.localStorage.setItem('token', idToken)
  }

  static getToken () {
    return window.localStorage.getItem('token')
  }

  static isTokenExpired () {
    const token = AuthService.getToken()
    if (!token) return true
    const date = AuthService.getTokenExpirationDate()
    const offsetSeconds = 0
    if (date === null) {
      return false
    }
    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000)
  }

  static userIsAgent (profile) {
    profile = profile || AuthService.getProfile()
    return !!profile.agentId
  }

  static getTokenExpirationDate () {
    try {
      const token = AuthService.getToken()
      const decoded = jwtDecode(token)
      if (!decoded.exp) {
        return null
      }
      const date = new Date(0) // The 0 here is the key, which sets the date to the epoch
      date.setUTCSeconds(decoded.exp)
      return date
    } catch {
      window.localStorage.removeItem('token')
      window.location.href = '/login'
    }
  }

  static clearToken () {
    window.localStorage.removeItem('token')
  }

  static logout () {
    this.clearToken()
    Sentry.configureScope(scope => scope.setUser({}))
    window.location.href = '/'
  }

  static getProfile () {
    // Update to read profiles from token istead of "profile" in local storage
    try {
      const token = AuthService.getToken()
      const decoded = jwtDecode(token)
      if (!decoded.exp) {
        return null
      }
      Sentry.configureScope(scope => scope.setUser({ id: decoded.UserId }))
      var parsedProfile = {
        email: decoded.sub,
        role: decoded[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
        ],
        userId: decoded.UserId,
        agentId: decoded.AgentId,
        markets: decoded.Market
      }
      return parsedProfile
    } catch {
      return {}
    }
  }

  static ifExpiredThenLogout = () => {
    const token = AuthService.getToken()
    if (token && !AuthService.isAuthenticated()) {
      AuthService.logout()
    }
  }

  static setProfile () {
    const token = AuthService.getToken()
    if (token) {
      try {
        const json = jwtDecode(token)
        if (json) {
          Sentry.configureScope(scope => scope.setUser({ id: json['UserId'] }))
        }
      } catch {
        window.localStorage.removeItem('token')
        window.location.href = '/login'
      }
    }
  }
}
