import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_SSR = createAction('query ssr')

const RESET_ACTIVE_SSR = createAction('management/reset active ssr')
const SET_ACTIVE_SSR = createAction('set active ssr')
const SET_VALUE = createAction('set active ssr value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_SSR]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE_SSR]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [RESET_ACTIVE_SSR]: (state, action) => {
      return { ...state, active: null }
    }
  },
  initialState
)

// Action creators
export const querySsr = (ignoreCache) => {
  return dispatch => {
    api.query('general/ssr/ssrcodes', { ignoreCache }).then(json => {
      dispatch(QUERY_SSR(json.data))
    })
  }
}


export const submitCreateSsr = ssr => {
  return dispatch => {
    return api.sendCommand('general/ssr/addSsrCode', ssr).then(() => {
      dispatch(querySsr())
      dispatch(resetActiveSsr())
      dispatch(globalMessageActions.setGlobalSuccess('SSR added'))
    })
  }
}

export const submitUpdateSsr = ssr => {
  return (dispatch, getState) => {
    return api.sendCommand('general/ssr/updateSsrCode', ssr).then(() => {
      // dispatch(querySsr())
      dispatch(resetActiveSsr())
      dispatch(globalMessageActions.setGlobalSuccess('SSR updated'))
    })
  }
}

export const submitDeleteSsr = ssrId => {
  return (dispatch, getState) => {
    return api
      .sendCommand('general/ssr/deleteSsrCode', { id: ssrId })
      .then(() => {
        dispatch(querySsr())
        dispatch(resetActiveSsr())
        dispatch(globalMessageActions.setGlobalSuccess('SSR deleted'))
      })
  }
}

export const setActiveSsr = ssr => {
  return dispatch => {
    dispatch(SET_ACTIVE_SSR(ssr))
  }
}

export const resetActiveSsr = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_SSR())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
