import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const QUERY_CHARTER_TYPES = createAction('query charter types')

const initialState = {
  data: []
}

// handlers
export default handleActions(
  {
    [QUERY_CHARTER_TYPES]: (state, action) => {
      return { ...state, data: action.payload }
    }
  },
  initialState
)

// thunks
export const queryCharterTypes = marketId => {
  return (dispatch, getState) => {
    dispatch(QUERY_CHARTER_TYPES([]))
    let query = api.query('market/charterType/charterTypes', { marketId })
    query.then(res => dispatch(QUERY_CHARTER_TYPES(res.data)))
    return query
  }
}
