import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import teal from '@material-ui/core/colors/teal'
import routes, { publicAccountRoutes } from './routes'
import AuthService from './common/auth-service'
import { SnackbarProvider } from 'notistack'
import { GlobalMessageProvider } from './common/global-message-context'
// import moment from 'moment-timezone'
// import 'moment/locale/sv'

const theme = createTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: teal,
    secondary: teal
  },
  colors: {
    danger: '#F0675D',
    warning: '#F4E471',
    success: '#6ABFA3'
  },
  overrides: {
    MuiTable: {
      root: {
        tableLayout: 'fixed'
      }
    }
  }
})

const PrivateRoute = ({
  component: Component,
  allowedRoles,
  roles,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      AuthService.isAuthenticated() ? (
        allowedRoles.some(r => roles.indexOf(r) >= 0) && (
          <Component {...props} />
        )
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
            search: AuthService.isTokenExpired() ? '?expired' : ''
          }}
        />
      )
    }
  />
)

class App extends Component {
  render () {
    return (
      <GlobalMessageProvider>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={theme}>
              {publicAccountRoutes.map(route => (
                <Route {...route} key={route.name} exact={route.exact} />
              ))}
              {routes.map((route, i) => (
                <PrivateRoute
                  {...route}
                  key={i}
                  roles={AuthService.getProfile().role}
                  allowedRoles={route.allowedRoles}
                />
              ))}
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </GlobalMessageProvider>
    )
  }
}

export default App
