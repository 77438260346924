import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import CommonStyles from '../../../common/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import GlobalMessageSnackbar from '../../../common/global-message-snackbar'
import * as accountActions from '../../../store/account/account-actions'

const mapStateToProps = state => ({
  account: state.account.accountActions
})

const mapDispatchToProps = dispatch => ({
  accountActions: bindActionCreators(accountActions, dispatch)
})

class LoginPage extends Component {
  state = { showForgotDialog: false, email: '', expired: false }

  componentDidMount () {
    let search = window.location.search.split('?')
    search.length && search[1] === 'expired' && this.setState({ expired: true })
  }

  inputChanged = name => event => {
    this.props.accountActions.setLoginModel(name, event.target.value)
  }

  login (e, username, password) {
    e.preventDefault()
    this.props.accountActions
      .login(username, password)
      .then(() => (window.location.href = '/'))
      .catch(err => {})
  }

  handleSubmitForgot (e, email) {
    e.preventDefault()
    this.props.accountActions
      .forgotPassword(email)
      .then(() => this.handleToggleForgotDialog())
  }

  handleToggleForgotDialog () {
    this.setState({
      ...this.state,
      showForgotDialog: !this.state.showForgotDialog
    })
  }

  render () {
    const { classes, account } = this.props

    return (
      <div
        className={classes.center}
        style={{
          background:
            'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://www2.alpresor.se/qbank/published/3146_ImageBlockOriginalFull.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <Card
          style={{
            width: '100%',
            maxWidth: '600px'
          }}
        >
          {account.loading && <LinearProgress />}
          <CardContent>
            <img
              src='/sts_alpresor_logotype.svg'
              style={{ width: '40%' }}
              alt='Alpresor logo'
            />
            <Typography
              variant='h6'
              id='loginTitle'
              className={classes.marginTop}
            >
              Back Office
            </Typography>
          </CardContent>
          <form onSubmit={e => this.login(e, account.loginModel)}>
            <CardContent>
              <TextField
                autoFocus
                margin='normal'
                type='email'
                label='Email'
                name='username'
                onChange={this.inputChanged('username')}
                value={account.loginModel.username}
                fullWidth
              />

              <TextField
                margin='normal'
                type='password'
                label='Password'
                name='password'
                onChange={this.inputChanged('password')}
                value={account.loginModel.password}
                fullWidth
              />

              {account.error !== null ? (
                <Typography
                  type='subheading'
                  style={{ color: 'red' }}
                  className={`${classes.marginLeft} ${classes.marginRight}`}
                >
                  {account.error}
                </Typography>
              ) : (
                ''
              )}

              {this.state.expired ? (
                <Typography
                  type='subheading'
                  style={{ color: 'red' }}
                  className={`${this.props.classes.marginLeft} ${this.props.classes.marginRight}`}
                >
                  The session has expired. Please login again
                </Typography>
              ) : (
                ''
              )}
            </CardContent>
            <CardActions
              className={classes.marginBottom}
              style={{
                justifyContent: 'space-between',
                alignItems: 'flex-end'
              }}
            >
              <Button
                type='button'
                size='small'
                onClick={() => this.handleToggleForgotDialog()}
              >
                Forgot password?
              </Button>

              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={account.loading}
              >
                Log in
              </Button>
            </CardActions>
          </form>
        </Card>
        <GlobalMessageSnackbar />
        {this.state.showForgotDialog && (
          <Dialog
            open={this.state.showForgotDialog != null}
            classes={{ paper: classes.dialog }}
            onClose={() => this.handleToggleForgotDialog()}
          >
            <DialogTitle>Forgot password</DialogTitle>
            <form onSubmit={e => this.handleSubmitForgot(e, this.state.email)}>
              <DialogContent>
                <TextField
                  autoFocus
                  margin='normal'
                  fullWidth
                  required
                  type='email'
                  label='Email'
                  name='name'
                  value={this.state.email}
                  onChange={e =>
                    this.setState({ ...this.state, email: e.target.value })
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button
                  type='button'
                  onClick={() => this.handleToggleForgotDialog()}
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  disabled={!this.state.email}
                  color='primary'
                  variant='contained'
                >
                  Send
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </div>
    )
  }
}

LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage)
export default withStyles(CommonStyles)(LoginPage)
