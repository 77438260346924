import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_REPORT = createAction('query flight passenger list')
const SET_DESTINATION_IDS = createAction(
  'flight passenger list destination ids'
)
const SET_DEPARTURE_AIRPORT_ID = createAction(
  'flight passenger list departure airport id'
)
const SET_ARRIVAL_AIRPORT_ID = createAction(
  'flight passenger list arrival airport id'
)
const SET_DEPARTURE_FROM_DATE = createAction(
  'flight passenger list departure from date'
)
const SET_DEPARTURE_TO_DATE = createAction(
  'flight passenger list departure to date'
)
const SET_FLIGHT_NUMBER = createAction('flight passenger set flight number')
const SET_FLIGHT_CLASS = createAction('flight passenger set flight class')
const SET_SELECTED_ORDER = createAction('flight passenger set selected order')
const SET_VIEW_MODE = createAction('flight passenger set view mode')
const SET_COLUMN = createAction('flight passenger set column')
const SET_SHOW_PASSENGERS = createAction('flight passenger set show passengers')
const SET_SHOW_HIKING = createAction('flight passenger set show hiking')

const initialState = {
  selectedDestinationIds: null,
  selectedDepartureAirportId: null,
  selectedArrivalAirportId: null,
  selectedDepartureFromDate: moment().startOf('day'),
  selectedDepartureToDate: moment().add(3, 'weeks').endOf('day'),
  selectedFlightNumber: null,
  selectedFlightClass: null,
  loading: false,
  item: null,
  showPassengers: 'all',
  selectedOrder: 'lastName',
  viewMode: 'standard',
  columns: [
    {
      name: 'hotel',
      label: 'Show Hotels',
      show: true,
      disableOnFlight: true
    },
    {
      name: 'transfer',
      label: 'Show Transfers',
      show: true
    },
    {
      name: 'ski',
      label: 'Show Ski',
      show: true
    },
    {
      name: 'golfbag',
      label: 'Show Golfbag',
      show: true
    },
    {
      name: 'skipass',
      label: 'Show Skipass',
      show: true
    },
    {
      name: 'hiking',
      label: 'Show Hiking package',
      show: true
    },
    {
      name: 'class',
      label: 'Show Class',
      show: true
    },
    {
      name: 'age',
      label: 'Show Child Age',
      show: true
    },
    {
      name: 'ssrcodes',
      label: 'Show SSR Codes',
      show: true
    }
  ]
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, item: action.payload }
    },
    [SET_DEPARTURE_AIRPORT_ID]: (state, action) => {
      return { ...state, selectedDepartureAirportId: action.payload.item }
    },
    [SET_ARRIVAL_AIRPORT_ID]: (state, action) => {
      return { ...state, selectedArrivalAirportId: action.payload.item }
    },
    [SET_DEPARTURE_FROM_DATE]: (state, action) => {
      return {
        ...state,
        selectedDepartureFromDate: moment(action.payload.item)
      }
    },
    [SET_DEPARTURE_TO_DATE]: (state, action) => {
      return { ...state, selectedDepartureToDate: moment(action.payload.item) }
    },
    [SET_FLIGHT_NUMBER]: (state, action) => {
      return { ...state, selectedFlightNumber: action.payload.item }
    },
    [SET_FLIGHT_CLASS]: (state, action) => {
      return { ...state, selectedFlightClass: action.payload.item }
    },
    [SET_SELECTED_ORDER]: (state, action) => {
      return { ...state, selectedOrder: action.payload.item }
    },
    [SET_VIEW_MODE]: (state, action) => {
      return { ...state, viewMode: action.payload.item }
    },
    [SET_COLUMN]: (state, action) => {
      return { ...state, columns: action.payload.item }
    },
    [SET_DESTINATION_IDS]: (state, action) => {
      return { ...state, selectedDestinationIds: action.payload.item }
    },
    [SET_SHOW_PASSENGERS]: (state, action) => {
      return { ...state, showPassengers: action.payload.item }
    },
    [SET_SHOW_HIKING]: (state, action) => {
      return { ...state, showHiking: action.payload.item }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    const state = getState().production.flightPassengers
    var originAirportId = state.selectedDepartureAirportId
    var destinationAirportId = state.selectedArrivalAirportId
    var departureDateFrom = state.selectedDepartureFromDate
    var departureDateTo = state.selectedDepartureToDate
    var flightNumber = state.selectedFlightNumber
    let destinationIds = state.selectedDestinationIds
    let noTransport = state.showPassengers === 'noTransport'
    let flightOnly = state.showPassengers === 'flightOnly'
    if (destinationIds && destinationIds.some(d => d === '<All>')) {
      destinationIds = null
    }

    if (destinationAirportId && (departureDateFrom || departureDateTo)) {
      api
        .query('travelBooking/reports/PassengerList', {
          destinationIds,
          originAirportId,
          destinationAirportId,
          departureDateFrom,
          departureDateTo,
          flightNumber,
          noTransport,
          flightOnly
        })
        .then(json => dispatch(QUERY_REPORT(json.data)))
    }
  }
}

export const setDestinationIds = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_IDS({ item }))
  }
}

export const setDepartureAirportId = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_AIRPORT_ID({ item }))
  }
}

export const setArrivalAirportId = item => {
  return dispatch => {
    dispatch(SET_ARRIVAL_AIRPORT_ID({ item }))
  }
}

export const setDepartureFromDate = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_FROM_DATE({ item }))
  }
}

export const setDepatureToDate = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_TO_DATE({ item }))
  }
}

export const setFlightNumber = item => {
  return dispatch => {
    dispatch(SET_FLIGHT_NUMBER({ item }))
  }
}

export const setFlightClass = item => {
  return dispatch => {
    dispatch(SET_FLIGHT_NUMBER({ item }))
  }
}

export const setSelectedOrder = item => {
  return dispatch => {
    dispatch(SET_SELECTED_ORDER({ item }))
  }
}

export const setViewMode = item => {
  return dispatch => {
    dispatch(SET_VIEW_MODE({ item }))
  }
}

export const setColumn = (column, show) => {
  return (dispatch, getState) => {
    const state = getState().production.flightPassengers
    let item = state.columns
    item[column].show = show
    dispatch(SET_COLUMN({ item }))
  }
}

export const setShowPassengers = item => {
  return dispatch => {
    dispatch(SET_SHOW_PASSENGERS({ item }))
  }
}
