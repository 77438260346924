import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_FLIGHT_ADDONS = createAction('query flight addons')
const QUERY_FLIGHT_ADDON = createAction('query flight addon')
const QUERY_FLIGHT_ADDON_TYPES = createAction('query flight addon types')
const SET_ACTIVE = createAction('set active flight addon')
const SET_ACTIVE_VALUE = createAction('set active flight addon value')
const SET_ACTIVE_ARTICLE = createAction('set active flight addon article')
const SET_ACTIVE_ARTICLE_VALUE = createAction('set active article value')

const initialState = {
  active: null,
  article: null,
  details: null,
  addonTypes: [],
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_FLIGHT_ADDONS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [QUERY_FLIGHT_ADDON]: (state, action) => {
      return { ...state, details: action.payload }
    },
    [QUERY_FLIGHT_ADDON_TYPES]: (state, action) => {
      return { ...state, addonTypes: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return { ...state, active: { ...state.active, [action.payload.name]: action.payload.value } }
    },
    [SET_ACTIVE_ARTICLE]: (state, action) => {
      return { ...state, article: action.payload }
    },
    [SET_ACTIVE_ARTICLE_VALUE]: (state, action) => {
      return { ...state, article: { ...state.article, [action.payload.name]: action.payload.value } }
    }
  },
  initialState
)

// Action creators
export const submitCreateFlightAddon = addon => {
  return dispatch => {
    let cmd = api.sendCommand('flightDeparture/addons/createAddonSetup', addon)
    cmd.then(res => {
      dispatch(globalMessageActions.setGlobalSuccess(`Flight addon created`))
    })
    return cmd
  }
}

export const submitUpdateFlightAddon = addon => {
  return dispatch => {
    let cmd = api.sendCommand('flightDeparture/addons/updateAddonSetup', addon)
    cmd.then(res => {
      dispatch(globalMessageActions.setGlobalSuccess(`Flight addon updated`))
      dispatch(queryFlightAddon(addon.id))
    })
    return cmd
  }
}

export const submitDeleteFlightAddon = addon => {
  return dispatch => {
    let cmd = api.sendCommand('flightDeparture/addons/deleteAddonSetup', addon)
    cmd.then(res => {
      dispatch(globalMessageActions.setGlobalSuccess(`Flight addon deleted`))
      dispatch(queryFlightAddon(addon.id))
    })
    return cmd
  }
}

export const submitAddArticle = article => {
  return dispatch => {
    let cmd = api.sendCommand('flightDeparture/addons/addArticle', article)
    cmd.then(() => {
      dispatch(queryFlightAddon(article.setupId))
      dispatch(globalMessageActions.setGlobalSuccess('Article added'))
    })
    return cmd
  }
}

export const submitUpdateArticle = article => {
  return dispatch => {
    let cmd = api.sendCommand('flightDeparture/addons/updateArticle', article)
    cmd.then(() => {
      dispatch(queryFlightAddon(article.setupId))
      dispatch(globalMessageActions.setGlobalSuccess('Article updated'))
    })
    return cmd
  }
}

export const submitRemoveArticle = article => {
  return dispatch => {
    let cmd = api.sendCommand('flightDeparture/addons/removeArticle', article)
    cmd.then(() => {
      dispatch(queryFlightAddon(article.setupId))
      dispatch(globalMessageActions.setGlobalSuccess('Article removed'))
    })
    return cmd
  }
}

export const queryFlightAddons = () => {
  return dispatch => {
    api.query('flightDeparture/addons/addonSetups').then(json => dispatch(QUERY_FLIGHT_ADDONS(json.data)))
  }
}

export const queryFlightAddon = id => {
  return dispatch => {
    api.query('flightDeparture/addons/addonSetup', { id }).then(json => dispatch(QUERY_FLIGHT_ADDON(json.data)))
  }
}

export const queryFlightAddonTypes = () => {
  return dispatch => {
    api.query('flightDeparture/addons/addonTypes').then(json => dispatch(QUERY_FLIGHT_ADDON_TYPES(json.data)))
  }
}

export const setActiveAddon = addon => {
  return dispatch => {
    dispatch(SET_ACTIVE(addon))
  }
}

export const setActiveAddonValue = (value, name) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}

export const setActiveArticle = article => {
  return dispatch => {
    dispatch(SET_ACTIVE_ARTICLE(article))
  }
}

export const setActiveArticleValue = (value, name) => {
  return dispatch => {
    dispatch(SET_ACTIVE_ARTICLE_VALUE({ name, value }))
  }
}
