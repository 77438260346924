const styles = theme => ({
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '100vh'
  },
  dialog: {
    width: 'auto',
    maxWidth: '1140px',
    minWidth: '500px'
  },
  dialogLarge: {
    width: '90vw',
    maxWidth: '1140px'
  },
  danger: {
    color: '#F0675D'
  },
  fullWidth: {
    width: '100%'
  },
  subHeader: {
    marginTop: theme.spacing(3)
  },
  pointer: {
    cursor: 'pointer'
  },
  hoverItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  avatarImg: {
    height: '100%',
    width: 'auto',
    maxWidth: 'initial'
  },
  secondaryTextColor: {
    color: theme.palette.text.secondary
  },
  selectedRow: {
    backgroundColor: '#f5f5f5'
  },
  selectedPriceRow: {
    backgroundColor: 'rgba(0,150,136,0.2)'
  },
  // salesStoppedPriceRow: {
  //   backgroundColor: '#FFF3E0'
  // },
  salesStoppedSelectedPriceRow: {
    backgroundColor: '#FFE0B2'
  },
  selectedCharterPeriodRow: {
    backgroundColor: '#0096882e'
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing()
  },
  marginRight: {
    marginRight: theme.spacing()
  },
  marginLeft: {
    marginLeft: theme.spacing()
  },
  padding: {
    padding: theme.spacing(2)
  },
  paddingTopBottom: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  paddingTopBottomNone: {
    paddingTop: 0,
    paddingBottom: 0
  },
  flexWrap: {
    flexWrap: 'wrap'
  },
  alignFlexStart: {
    alignItems: 'flex-start'
  },
  row: {
    flexDirection: 'row'
  },
  denseListSubheader: {
    marginBottom: '-16px'
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4)
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(),
    fontSize: '24px'
  },
  listTotal: {
    textAlign: 'right',
    marginTop: theme.spacing(2),
    width: '100%'
  },
  closeIcon: {
    fontSize: '13px',
    position: 'absolute',
    right: '3px',
    top: '8px',
    '&:hover': {
      color: 'red'
    }
  },
  paymentError: {
    backgroundColor: '#F0675D',
    color: '#fff'
  },
  paymentWarning: {
    backgroundColor: '#FEEFB3'
  },
  checkboxTeal: {
    color: '#009688'
  },
  checkboxLightGrey: {
    color: 'rgba(0, 0, 0, 0.1)'
  },
  uploadInner: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
    cursor: 'pointer'
  },
  dateInput: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'inline-block'
  },
  subMenuActive: {
    borderBottom: '1px solid #009688'
  },
  tabBorder: {
    backgroundColor: 'rgb(255,255,255)',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)'
  },
  smallTab: {
    minWidth: 'auto !important'
  },
  statusDropdown: {
    width: '48%',
    marginLeft: theme.spacing(2)
  },
  approveButton: {
    marginTop: theme.spacing(6),
    float: 'right'
  },
  notAvailable: {
    backgroundColor: '#fafafa'
  },
  globalSuccess: {
    color: '#3c763d',
    backgroundColor: '#dff0d8',
    borderColor: '#3c763d'
  },
  globalError: {
    color: '#a94442',
    backgroundColor: '#f2dede',
    borderColor: '#a94442'
  },
  globalWarning: {
    color: '#c19445',
    backgroundColor: '#fcf8e3',
    borderColor: '#faebcc'
  },
  failingTableRow: {
    backgroundColor: '#ff00001f'
  },
  toUpper: {
    textTransform: 'uppercase'
  },
  listLink: {
    textDecoration: 'none'
  },
  borderedCell: {
    border: '1px solid #EBEBEB',
    cursor: 'pointer',
    padding: '10px 20px 15px 20px',
    alignItems: 'cOver',
    minWidth: 250
  },
  cellSelectedCheckbox: {
    width: 'auto',
    height: 'auto',
    color: 'rgba(0,0,0,0.2)'
  },
  inheritFontSize: {
    fontSize: 'inherit'
  },
  inheritFontSizeAlignRight: {
    fontSize: 'inherit',
    textAlign: 'right'
  },
  infoIcon: {
    color: 'rgba(0,0,0,0.2)'
  },
  chip: {
    margin: '0 ' + theme.spacing(0.5)
  },
  tableBorderRight: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)'
  },
  backgroundColorNone: {
    '&:before': {
      backgroundColor: '#fff'
    }
  },
  noSideMarginSlick: {
    marginLeft: '-24px',
    marginRight: '-24px',
    marginBottom: '16px'
  },
  changeCancelled: {
    textDecoration: 'line-through'
  },
  changeAdded: {
    fontStyle: 'italic'
  },
  unclickableButton: {
    cursor: 'default!important'
  }
})
export default styles
