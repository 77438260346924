import { createAction, handleActions } from 'redux-actions'
import api from '../api'
// import * as globalMessageActions from '../global-message'

// Actions
const QUERY_CANCELLATIONS = createAction('query cancellation')

const RESET_ACTIVE_CANCELLATION = createAction('cancel active pricing cancellation')
const SET_VALUE = createAction('update active pricing cancellation')
const EDIT_ACTIVE_CANCELLATION = createAction('edit active pricing cancellation')

const RELEASE_MAIL_TEXT_COPIED = createAction('cancellation release mail text copied')
const RELEASE_CUSTOMER_MAIL_TEXT_COPIED = createAction('cancellation release customer mail text copied')

const initialState = {
  activeCancellation: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_CANCELLATIONS]: (state, action) => {
      return { ...state, items: action.payload.cancellations }
    },
    [EDIT_ACTIVE_CANCELLATION]: (state, action) => {
      return { ...state, activeCancellation: action.payload }
    },
    [RESET_ACTIVE_CANCELLATION]: (state, action) => {
      return { ...state, activeCancellation: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.activeCancellation
      active[action.payload.name] = action.payload.value
      return { ...state, activeCancellation: active }
    },
    [RELEASE_MAIL_TEXT_COPIED]: (state, action) => {
      let activeCancellation = state.activeCancellation
      activeCancellation.releaseMailTextCopied = true
      return { ...state, activeCancellation: activeCancellation }
    },
    [RELEASE_CUSTOMER_MAIL_TEXT_COPIED]: (state, action) => {
      let activeCancellation = state.activeCancellation
      activeCancellation.releaseCustomerMailTextCopied = true
      return { ...state, activeCancellation: activeCancellation }
    }
  },
  initialState
)

// Action creators
export const queryCancellations = () => {
  return (dispatch, getState) => {
    api.query('hotel/cancellations', {}).then(json => dispatch(QUERY_CANCELLATIONS(json.data)))
  }
}

export const removeCancellation = item => {
  return dispatch => {
    return api.sendCommand('hotel/cancellation/removeCancellation', item).then(() => {
      dispatch(resetActiveCancellation())
      dispatch(queryCancellations())
    })
  }
}

export const editActiveCancellation = item => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_CANCELLATION(item))
  }
}

export const resetActiveCancellation = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_CANCELLATION())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const releaseMailTextCopied = () => {
  return dispatch => {
    dispatch(RELEASE_MAIL_TEXT_COPIED({}))
  }
}

export const releaseCustomerMailTextCopied = () => {
  return dispatch => {
    dispatch(RELEASE_CUSTOMER_MAIL_TEXT_COPIED({}))
  }
}
