import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const QUERY_MARKETS = createAction('query market select markets')
const SET_ACTIVE = createAction('set active market select market')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_MARKETS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    }
  },
  initialState
)

// Action creators
export const queryMarkets = () => {
  return (dispatch, getState) => {
    let query = api.query('market/market/markets')
    query.then(json => {
      if (!getState().market.marketSelect.active) dispatch(SET_ACTIVE(json.data[0]))
      dispatch(QUERY_MARKETS(json.data))
    })
    return query
  }
}

export const setActive = market => {
  return dispatch => {
    dispatch(SET_ACTIVE(market))
  }
}
