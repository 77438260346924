import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'
import moment from 'moment-timezone'
import { uniqueBy } from '../../common/group-by'

// Actions
const QUERY_PRICE_MANAGEMENT = createAction('query price management hotel')

const SET_SELECTED_CELLS_FLAT = createAction('price management hotel selected cells flat')
const RESET_SELECTED_CELLS_FLAT = createAction('price management hotel reset selected cells flat')
const SET_SELECTED_CELLS = createAction('price management hotel selected cells')
const SET_DESTINATION_ID = createAction('price management hotel destination id')
const SET_HOTEL_ID = createAction('price management hotel hotel id')
const SET_FROM_DATE = createAction('price management hotel from date')
const SET_TO_DATE = createAction('price management hotel to date')

const RESET_ACTIVE_PRICE = createAction('cancel active price')
const SET_VALUE = createAction('set contribution margin value')
const EDIT_ACTIVE_PRICE = createAction('edit active price')
const RESET_DATES = createAction('active price reset dates')
const SET_SEASON_FROM_DATE = createAction('active price season from date')
const SET_SEASON_TO_DATE = createAction('active price season to date')

const initialState = {
  // selectedCells contains the positions of the selected cells in the grid
  // state = {
  //   selectedCells: [{x: 1, y: 2}]
  // }
  selectedCells: [],
  selectedCellsFlat: [[]],
  activePrice: null,
  selectedDestinationId: null,
  selectedHotelId: null,
  selectedFromDate: null,
  selectedToDate: null,
  selectedSeasonFromDate: moment(),
  selectedSeasonToDate: moment().add(3, 'weeks'),
  hotels: [],
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_PRICE_MANAGEMENT]: (state, action) => {
      if (!action.payload) return { ...state, items: [], roomCategories: [] }

      var hotels = action.payload.map(p => {
        return {
          hotelId: p.hotelId,
          name: p.name,
          stars: p.stars,
          imageUrls: p.imageUrls
        }
      })
      var groupedHotels = uniqueBy(hotels, 'hotelId')

      return { ...state, items: action.payload, hotels: groupedHotels }
    },
    [EDIT_ACTIVE_PRICE]: (state, action) => {
      return { ...state, activePrice: action.payload }
    },
    [RESET_ACTIVE_PRICE]: (state, action) => {
      return { ...state, activePrice: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.activePrice
      active[action.payload.name] = action.payload.value
      return { ...state, activePrice: active }
    },
    [SET_SELECTED_CELLS_FLAT]: (state, action) => {
      return { ...state, selectedCellsFlat: action.payload.cells }
    },
    [RESET_SELECTED_CELLS_FLAT]: (state, action) => {
      let cells = state.selectedCellsFlat

      cells.forEach((cellRow, x) => {
        cells[x].forEach((cellColumn, y) => {
          cells[x][y] = false
        })
      })

      return { ...state, selectedCellsFlat: cells }
    },
    [SET_SELECTED_CELLS]: (state, action) => {
      return { ...state, selectedCells: action.payload.selectedCells }
    },
    [SET_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload.item }
    },
    [SET_HOTEL_ID]: (state, action) => {
      return { ...state, selectedHotelId: action.payload.item }
    },
    [SET_FROM_DATE]: (state, action) => {
      return { ...state, selectedFromDate: moment(action.payload.item) }
    },
    [SET_TO_DATE]: (state, action) => {
      return { ...state, selectedToDate: moment(action.payload.item) }
    },
    [RESET_DATES]: (state, action) => {
      return { ...state, selectedToDate: initialState.selectedToDate, selectedFromDate: initialState.selectedFromDate }
    },
    [SET_SEASON_FROM_DATE]: (state, action) => {
      return { ...state, selectedSeasonFromDate: moment(action.payload.item) }
    },
    [SET_SEASON_TO_DATE]: (state, action) => {
      return { ...state, selectedSeasonToDate: moment(action.payload.item) }
    }
  },
  initialState
)

// Action creators
export const queryPrices = () => {
  return (dispatch, getState) => {
    if (
      !getState().production.priceManagementRoomCategories.selectedHotelId &&
      !getState().production.priceManagementHotel.selectedDestinationId
    ) {
      dispatch(QUERY_PRICE_MANAGEMENT(''))
      return
    }

    var destinationId = getState().production.priceManagementHotel.selectedDestinationId
    var hotelId = getState().production.priceManagementHotel.selectedHotelId
    var fromDate = getState().production.priceManagementHotel.selectedFromDate
    var toDate = getState().production.priceManagementHotel.selectedToDate
    var seasonFromDate = getState().production.priceManagementRoomCategories.selectedSeasonFromDate

    if (!fromDate || !toDate || !seasonFromDate) return
    if (hotelId) {
      return api.query('hotel/CharterPeriod/ProductionHotelByHotel', { hotelId, fromDate, toDate }).then(json => {
        dispatch(QUERY_PRICE_MANAGEMENT(json.data))
        return { destinationId, hotelId, fromDate, toDate }
      })
    } else {
      return api
        .query('hotel/CharterPeriod/ProductionHotelByDestination', { destinationId, fromDate, toDate })
        .then(json => {
          dispatch(QUERY_PRICE_MANAGEMENT(json.data))
          return { destinationId, hotelId, fromDate, toDate }
        })
    }
  }
}

export const setContributionMargin = item => {
  return dispatch => {
    return api.sendCommand('hotel/charterPeriod/setContributionMargin', item).then(() => {
      dispatch(resetActivePrice())
      dispatch(queryPrices())
      dispatch(setSelectedCells([]))
      dispatch(globalMessageActions.setGlobalSuccess('Contribution margin set'))
    })
  }
}

export const stopSales = items => {
  return dispatch => {
    return api.sendCommand('hotel/charterPeriod/stopSales', { charterPeriods: items }).then(() => {
      dispatch(queryPrices())
      dispatch(setSelectedCells([]))
      dispatch(globalMessageActions.setGlobalSuccess('Sales stopped'))
    })
  }
}

export const startSales = items => {
  return dispatch => {
    return api.sendCommand('hotel/charterPeriod/startSales', { charterPeriods: items }).then(() => {
      dispatch(queryPrices())
      dispatch(setSelectedCells([]))
      dispatch(globalMessageActions.setGlobalSuccess('Sales started'))
    })
  }
}

export const editActivePrice = price => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_PRICE(price))
  }
}

export const resetActivePrice = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_PRICE())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const setDestinationId = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_ID({ item }))
  }
}

export const setHotelId = item => {
  return dispatch => {
    dispatch(SET_HOTEL_ID({ item }))
  }
}

export const setFromDate = item => {
  return (dispatch, getState) => {
    dispatch(SET_FROM_DATE({ item }))
  }
}

export const setToDate = item => {
  return dispatch => {
    dispatch(SET_TO_DATE({ item }))
  }
}

export const reset = () => {
  return dispatch => {
    dispatch(QUERY_PRICE_MANAGEMENT(''))
  }
}

export const setSelectedCells = cells => {
  return dispatch => {
    if (cells.length !== 0) {
      dispatch(SET_SELECTED_CELLS_FLAT({ cells }))
    } else {
      dispatch(RESET_SELECTED_CELLS_FLAT())
    }

    let selectedCells = []
    cells.forEach((rows, x) => {
      return rows.forEach((cell, y) => {
        if (y === 0 || x === 0) {
          dispatch(SET_SELECTED_CELLS({ selectedCells }))
        }
        if (cell) {
          selectedCells.push({ x: x, y: y })
        }
      })
    })
    dispatch(SET_SELECTED_CELLS({ selectedCells }))
  }
}

export const resetDates = () => {
  return dispatch => {
    dispatch(RESET_DATES())
  }
}

export const setSeasonFromDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_FROM_DATE({ item }))
  }
}

export const setSeasonToDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_TO_DATE({ item }))
  }
}
