import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'

// Actions
const FETCHED_SUPPLEMENT_TYPES = createAction('get supplement types')
const MATCH_SUPPLEMENT_TYPES = createAction('matching supplement types')
const CLEAR_SUPPLEMENT_TYPES = createAction('clear supplement types')

const initialState = {
  loading: false,
  items: [],
  searchedItems: []
}

// Reducers/handlers
export default handleActions(
  {
    [FETCHED_SUPPLEMENT_TYPES]: (state, action) => {
      return {
        ...state,
        loading: false,
        items: action.payload.supplementTypes,
        searchedItems: action.payload.supplementTypes
      }
    },
    [MATCH_SUPPLEMENT_TYPES]: (state, action) => {
      let searchItems = state.items.filter(type => type.id.toLowerCase().startsWith(action.payload.toLowerCase()))
      return { ...state, loading: false, searchedItems: searchItems }
    },
    [CLEAR_SUPPLEMENT_TYPES]: (state, action) => {
      return { ...state, loading: false, searchedItems: state.items.slice(0) }
    }
  },
  initialState
)

// Action creators
export const getSupplementTypes = items => {
  return (dispatch, getState) => {
    // dispatch(FETCHED_SUPPLEMENT_TYPES([
    //   { name: 'New year dinner'},
    //   { name: 'Christmas dinner'},
    //   { name: 'Breakfast'},
    //   { name: 'Room cleaning'},
    //   { name: 'Sheets/Blankets'}
    // ]));

    return api
      .query('hotel/contract/supplementTypes')
      .then(json => {
        dispatch(FETCHED_SUPPLEMENT_TYPES(json.data))
      })
      .catch(errorMessage => {
        dispatch(globalMessageActions.setGlobalError(errorMessage))
      })
  }
}

export const queryTypes = value => {
  return (dispatch, getState) => {
    dispatch(MATCH_SUPPLEMENT_TYPES(value))
  }
}

export const clearTypes = value => {
  return (dispatch, getState) => {
    dispatch(CLEAR_SUPPLEMENT_TYPES(value))
  }
}
