import { mapFlightSeatsData } from '../mappers'

export function getSeatsFlightSeries (flightsByWeeks, date) {
  return [
    mapFlightSeatsData(
      'Seats left',
      '#bbdefb',
      'seatsLeft',
      flightsByWeeks,
      date
    ),
    mapFlightSeatsData(
      'Booked seats',
      '#2196f3',
      'seatsBooked',
      flightsByWeeks,
      date
    )
  ]
}
