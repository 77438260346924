import { handleActions } from 'redux-actions'
import api from '../api'

// Reducers/handlers
export default handleActions({}, {})

// Action creators
export const queryReservations = charterId => {
  return () => {
    return api.query('travelBooking/reservationsByCharter', { charterId })
  }
}

export const submitMoveCharter = command => {
  return () => {
    return api.sendCommand('travelBooking/moveCharter', command)
  }
}
