import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as reservationActions from './reservations'

// actions
const QUERY_FLIGHT_MEALS = createAction('query booking flight meals')
const QUERY_FLIGHT_MEALS_SUCCESS = createAction('query booking flight meals success')
const SET_FLIGHT_MEAL_INSURANCE = createAction('set booking flight meals')

const initialState = {
  error: null,
  loading: false,
  items: []
}

// handlers
export default handleActions(
  {
    [QUERY_FLIGHT_MEALS]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [QUERY_FLIGHT_MEALS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_FLIGHT_MEAL_INSURANCE]: (state, action) => {
      return {
        ...state,
        items: state.items.map(
          (traveler, j) =>
            (traveler.id === action.payload.travelerId
              ? {
                ...traveler,
                meals: traveler.meals.map(
                    (meal, k) =>
                      (meal.articleId === action.payload.mealId ? { ...meal, reserved: action.payload.reserved } : meal)
                  )
              }
              : traveler)
        )
      }
    }
  },
  initialState
)

// thunks
export const queryFlightMeals = reservationId => {
  return dispatch => {
    api.query('travelbooking/flightMeal', { reservationId }).then(res => dispatch(QUERY_FLIGHT_MEALS_SUCCESS(res.data)))
  }
}

export const submitAddFlightMeal = command => {
  return dispatch => {
    dispatch(SET_FLIGHT_MEAL_INSURANCE({ ...command, reserved: true }))
    let cmd = api.sendCommand('travelBooking/addFlightMeal', command).then(() => {
      dispatch(queryFlightMeals(command.reservationId))
      dispatch(reservationActions.queryReservation(command.reservationId))
    })
    .catch(() => dispatch(SET_FLIGHT_MEAL_INSURANCE({ ...command, reserved: false })))
    return cmd
  }
}

export const submitRemoveFlightMeal = command => {
  return dispatch => {
    dispatch(SET_FLIGHT_MEAL_INSURANCE({ ...command, reserved: false }))
    let cmd = api.sendCommand('travelBooking/removeFlightMeal', command).then(() => {
      dispatch(queryFlightMeals(command.reservationId))
      dispatch(reservationActions.queryReservation(command.reservationId))
    })
    return cmd
  }
}
