import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const QUERY_LANGUAGES = createAction('management/query languages')

const initialState = {
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_LANGUAGES]: (state, action) => {
      return { ...state, items: action.payload }
    }
  },
  initialState
)

// Action creators
export const queryLanguages = () => {
  return dispatch => {
    api.query('general/languages').then(json => dispatch(QUERY_LANGUAGES(json.data)))
  }
}
