import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const FETCH_GENERAL_ROOM_FACILITIES = createAction('get general room facilities from server')
const FETCH_GENERAL_ROOM_FACILITIES_SUCCESS = createAction('fetch general room facilities success')

const RESET_ACTIVE_GENERAL_ROOM_FACILITY = createAction('cancel active room general facility')
const SET_VALUE = createAction('set active general room facility value')

const SET_ACTIVE_GENERAL_ROOM_FACILITY = createAction('set active general room facility')

const initialState = {
  active: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_GENERAL_ROOM_FACILITIES]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [FETCH_GENERAL_ROOM_FACILITIES_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_ACTIVE_GENERAL_ROOM_FACILITY]: (state, action) => {
      // TODO: Needs more clone...
      return { ...state, active: Object.assign({}, action.payload) }
    },
    [RESET_ACTIVE_GENERAL_ROOM_FACILITY]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators
export const getGeneralRoomFacilities = () => {
  return (dispatch, getState) => {
    dispatch(FETCH_GENERAL_ROOM_FACILITIES())
    api.query('general/generalRoomFacilities').then(json => dispatch(FETCH_GENERAL_ROOM_FACILITIES_SUCCESS(json.data)))
  }
}

export const addGeneralRoomFacility = category => {
  return dispatch => {
    return api.sendCommand('general/addGeneralRoomFacility', category).then(() => {
      dispatch(getGeneralRoomFacilities())
      dispatch(resetActiveGeneralRoomFacility())
      dispatch(globalMessageActions.setGlobalSuccess('General room facility added'))
    })
  }
}

export const updateGeneralRoomFacility = category => {
  return (dispatch, getState) => {
    return api.sendCommand('general/updateGeneralRoomFacility', category).then(() => {
      dispatch(getGeneralRoomFacilities())
      dispatch(resetActiveGeneralRoomFacility())
      dispatch(globalMessageActions.setGlobalSuccess('General room facility updated'))
    })
  }
}

export const deleteGeneralRoomFacility = category => {
  return (dispatch, getState) => {
    return api.sendCommand('general/deleteGeneralRoomFacility', category).then(() => {
      dispatch(getGeneralRoomFacilities())
      dispatch(resetActiveGeneralRoomFacility())
      dispatch(globalMessageActions.setGlobalSuccess('General room facility updated'))
    })
  }
}

export const setActiveGeneralRoomFacility = category => {
  return dispatch => {
    dispatch(SET_ACTIVE_GENERAL_ROOM_FACILITY(category))
  }
}

export const resetActiveGeneralRoomFacility = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_GENERAL_ROOM_FACILITY())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
