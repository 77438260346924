import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as allotmentActions from './allotments'
import * as priceActions from './prices'
import * as cancellationActions from './cancellations'
import * as discountActions from './discounts'
import * as minimumOfNightActions from './minimum-of-nights'
import * as releaseRuleActions from './release-rules'
import * as supplementsRuleActions from './supplements'
import * as shortWeekSupplementActions from './short-week-supplements'
import * as touristTaxActions from './tourist-tax'
import * as paymentPlanActions from './payment-plan'
import * as hotelActions from '../../management/hotel'
import * as minimumAgeActions from './minimum-age'
import * as roomCategoryActions from './room-categories'
import * as facilitiesActions from './facilities'

// Actions
const FETCH_HOTEL_CONTRACT_SUCCESS = createAction(
  'fetch hotel contract success'
)

const CREATE_HOTEL_CONTRACT_SUCCESS = createAction(
  'create hotel contract success'
)

const SET_LOADING = createAction('set hotel contract action loading')
const SET_HOTEL_CONTRACT_COMMAND_FINISHED = createAction(
  'set hotel contract action completed'
)

const INITIATE_NEW_CONTRACT_WITH_HOTEL_INFO = createAction(
  'initiate contract with hotel info'
)

const RESET_ACTIVE_HOTEL_CONTRACT = createAction('cancel active hotel contract')
const UPDATE_ACTIVE_HOTEL_CONTRACT = createAction(
  'update active hotel contract'
)
const EDIT_ACTIVE_HOTEL_CONTRACT = createAction('edit active hotel contract')

const SEASON_SELECTED = createAction('season selected')

const SET_NEW_CONTRACT = createAction('set new hotel contract')
const SET_NEW_VALUE = createAction('hotel contract set new value')
const RESET_NEW_CONTRACT = createAction('reset new contract')

const initialState = {
  active: null,
  new: null,
  loading: false,
  item: null,
  sort: {}
}

// Reducers/handlers
export default handleActions(
  {
    [SET_LOADING]: (state, action) => {
      return { ...state, loading: true }
    },
    [SET_HOTEL_CONTRACT_COMMAND_FINISHED]: (state, action) => {
      return { ...state, loading: false }
    },
    [FETCH_HOTEL_CONTRACT_SUCCESS]: (state, action) => {
      return { ...state, loading: false, item: action.payload }
    },
    [CREATE_HOTEL_CONTRACT_SUCCESS]: (state, action) => {
      return { ...state, loading: false, new: null }
    },
    [INITIATE_NEW_CONTRACT_WITH_HOTEL_INFO]: (state, action) => {
      let newContract = state.new
      newContract.owner = action.payload.owner
      newContract.generalEmail = action.payload.generalEmail
      newContract.contactPerson = action.payload.contactPerson
      return { ...state, new: newContract }
    },
    [EDIT_ACTIVE_HOTEL_CONTRACT]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_HOTEL_CONTRACT]: (state, action) => {
      return { ...state, active: null }
    },
    [UPDATE_ACTIVE_HOTEL_CONTRACT]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [SEASON_SELECTED]: (state, action) => {
      return { ...state, selectedSeasonId: action.payload }
    },
    [SET_NEW_CONTRACT]: (state, action) => {
      return { ...state, new: action.payload }
    },
    [SET_NEW_VALUE]: (state, action) => {
      return {
        ...state,
        new: { ...state.new, [action.payload.name]: action.payload.value }
      }
    },
    [RESET_NEW_CONTRACT]: (state, action) => {
      return { ...state, new: null }
    }
  },
  initialState
)

// Action creators
export const getHotelContract = id => {
  return (dispatch, getState) => {
    dispatch(SET_LOADING())
    return api
      .query('hotel/contract/contract', { id })
      .then(json => {
        dispatch(FETCH_HOTEL_CONTRACT_SUCCESS(json.data))
        dispatch(hotelActions.getHotel(json.data.hotelId))
        dispatch(allotmentActions.setAllotments(json.data.allotments))
        dispatch(priceActions.setPrices(json.data.prices))
        dispatch(cancellationActions.setCancellations(json.data.cancellations))
        dispatch(discountActions.setDiscounts(json.data.discounts))
        dispatch(
          minimumOfNightActions.setMinimumOfNights(json.data.minimumOfNights)
        )
        dispatch(releaseRuleActions.setReleaseRules(json.data.releaseRules))
        dispatch(supplementsRuleActions.setSupplements(json.data.supplements))
        dispatch(touristTaxActions.setTouristTax(json.data.touristTax))
        dispatch(paymentPlanActions.setPaymentPlan(json.data.paymentPlan))
        dispatch(minimumAgeActions.setMinimumAge(json.data.minimumAge))
        dispatch(
          shortWeekSupplementActions.setShortWeekSupplements(
            json.data.shortWeekSupplements
          )
        )
        dispatch(
          roomCategoryActions.setContractRoomCategories(
            json.data.roomCategories
          )
        )
        dispatch(facilitiesActions.setFacilities(json.data.facilities))
      })
      .catch(errorMessage => {
        dispatch(globalMessageActions.setGlobalError(errorMessage))
      })
  }
}

export const createHotelContract = hotelContract => {
  return dispatch => {
    dispatch(SET_LOADING())
    return api
      .sendCommand('hotel/contract/createContract', hotelContract)
      .then(res => {
        dispatch(CREATE_HOTEL_CONTRACT_SUCCESS())
        dispatch(
          globalMessageActions.setGlobalSuccess('Hotel Contract created')
        )
        dispatch(getHotelContract(hotelContract.id))
      })
      .catch(errorMessage =>
        dispatch(
          globalMessageActions.setGlobalError(errorMessage.error.message)
        )
      )
  }
}

export const updateHotelContract = hotelContract => {
  return (dispatch, getState) => {
    dispatch(SET_LOADING())
    return api
      .sendCommand('hotel/contract/UpdateContract', hotelContract)
      .then(() => {
        dispatch(SET_HOTEL_CONTRACT_COMMAND_FINISHED())
        dispatch(getHotelContract(hotelContract.id))
        dispatch(
          globalMessageActions.setGlobalSuccess('Hotel Contract updated')
        )
        dispatch(RESET_ACTIVE_HOTEL_CONTRACT())
      })
      .catch(errorMessage => {
        dispatch(
          globalMessageActions.setGlobalError(errorMessage.error.message)
        )
        dispatch(SET_HOTEL_CONTRACT_COMMAND_FINISHED())
      })
  }
}

export const deleteHotelContract = item => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/contract/deleteContract', { Id: item.id })
      .then(() => {
        dispatch(RESET_ACTIVE_HOTEL_CONTRACT())
        dispatch(
          globalMessageActions.setGlobalSuccess('Hotel Contract deleted')
        )
      })
  }
}

export const publishForSales = item => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/contract/publishForSales', { contractId: item.id })
      .then(() => {
        dispatch(getHotelContract(item.id))
        dispatch(
          globalMessageActions.setGlobalSuccess(
            'Contract published for sales: ' + item.hotelName
          )
        )
      })
  }
}

export const startTest = item => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/contract/startTest', { contractId: item.id })
      .then(() => {
        dispatch(getHotelContract(item.id))
        dispatch(
          globalMessageActions.setGlobalSuccess(
            'Contract started for testing: ' + item.hotelName
          )
        )
      })
  }
}

export const stopTest = item => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/contract/stopTest', { contractId: item.id })
      .then(() => {
        dispatch(getHotelContract(item.id))
        dispatch(
          globalMessageActions.setGlobalSuccess(
            'Contract stopped for testing: ' + item.hotelName
          )
        )
      })
  }
}

export const stopSales = contractId => {
  return dispatch => {
    dispatch(SET_LOADING())
    return api
      .sendCommand('hotel/contract/stopSales', { contractId })
      .then(() => {
        dispatch(getHotelContract(contractId))
        dispatch(globalMessageActions.setGlobalSuccess('Sales stopped'))
      })
      .finally(() => dispatch(SET_HOTEL_CONTRACT_COMMAND_FINISHED()))
  }
}

export const startSales = contractId => {
  return dispatch => {
    dispatch(SET_LOADING())
    return api
      .sendCommand('hotel/contract/startSales', { contractId })
      .then(() => {
        dispatch(getHotelContract(contractId))
        dispatch(globalMessageActions.setGlobalSuccess('Sales started'))
      })
      .finally(() => dispatch(SET_HOTEL_CONTRACT_COMMAND_FINISHED()))
  }
}

export const setTicketTexts = (contractId, ticketTexts) => {
  return dispatch => {
    return api
      .sendCommand('hotel/contract/settickettexts', { contractId, ticketTexts })
      .then(() => {
        dispatch(getHotelContract(contractId))
        dispatch(globalMessageActions.setGlobalSuccess('Ticket texts updated'))
      })
  }
}

export const submitCopyHotelContract = command => {
  return dispatch => {
    dispatch(SET_LOADING())
    let cmd = api.sendCommand('hotel/contract/copyContract', command)
    cmd
      .then(() => {
        dispatch(globalMessageActions.setGlobalSuccess('Contract copied'))
      })
      .catch(err =>
        dispatch(
          globalMessageActions.setGlobalError(`Failed to copy contract: ${err}`)
        )
      )
      .finally(() => dispatch(SET_HOTEL_CONTRACT_COMMAND_FINISHED()))
    return cmd
  }
}

export const getInitialContractValues = hotelId => {
  return dispatch => {
    dispatch(hotelActions.getHotel(hotelId)).then(data => {
      dispatch(INITIATE_NEW_CONTRACT_WITH_HOTEL_INFO(data))
    })
  }
}

export const setActiveHotelContract = hotelContract => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_HOTEL_CONTRACT(hotelContract))
  }
}

export const resetActiveHotelContract = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_HOTEL_CONTRACT())
  }
}

export const setValueContract = (value, name) => {
  return dispatch => {
    dispatch(UPDATE_ACTIVE_HOTEL_CONTRACT({ value, name }))
  }
}

export const seasonSelected = seasonId => {
  return dispatch => {
    dispatch(SEASON_SELECTED(seasonId))
  }
}

export const updateSendRoomingLists = (contractId, sendRoomingLists) => {
  return dispatch => {
    return api
      .sendCommand('hotel/contract/setSendRoomingLists', {
        contractId,
        sendRoomingLists
      })
      .then(() => {
        dispatch(getHotelContract(contractId))
      })
  }
}

export const setNewContract = value => {
  return dispatch => {
    dispatch(SET_NEW_CONTRACT(value))
  }
}

export const resetNewContract = () => {
  return dispatch => {
    dispatch(RESET_NEW_CONTRACT())
  }
}

export const setNewValue = (value, name) => {
  return dispatch => {
    dispatch(SET_NEW_VALUE({ value, name }))
  }
}
