import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

const QUERY_AGENTS = createAction('query agents')
const SET_ACTIVE = createAction('set active agent')
const SET_VALUE = createAction('set active agent value')

const initalState = {
  items: [],
  active: null
}

export default handleActions(
  {
    [QUERY_AGENTS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_VALUE]: (state, action) => {
      return { ...state, active: { ...state.active, [action.payload.name]: action.payload.value } }
    }
  },
  initalState
)

export const submitCreateAgent = command => {
  return dispatch => {
    let cmd = api.sendCommand('crm/agent/createAgent', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Agent added'))
      dispatch(queryAgents())
    })
    return cmd
  }
}

export const submitUpdateAgent = command => {
  return dispatch => {
    let cmd = api.sendCommand('crm/agent/updateAgent', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Agent updated'))
      dispatch(queryAgents())
    })
    return cmd
  }
}

export const submitDeleteAgent = command => {
  return dispatch => {
    let cmd = api.sendCommand('crm/agent/deleteAgent', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Agent deleted'))
      dispatch(queryAgents())
    })
    return cmd
  }
}

export const queryAgents = () => {
  return dispatch => {
    api.query('crm/agent/agents').then(res => dispatch(QUERY_AGENTS(res.data)))
  }
}

export const setActive = agent => {
  return dispatch => {
    dispatch(SET_ACTIVE(agent))
  }
}

export const setValue = (name, value) => {
  return dispatch => {
    dispatch(SET_VALUE({ name, value }))
  }
}
