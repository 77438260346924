import { createAction, handleActions } from 'redux-actions'
import api from '../../api'

const QUERY_ADDON_TYPES = createAction('query addon types')

const initialState = {
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_ADDON_TYPES]: (state, action) => {
      return { ...state, items: action.payload }
    }
  },
  initialState
)

export const queryAddonTypes = () => {
  return dispatch => {
    return api.query('destination/addonContract/addonTypes').then(res => {
      dispatch(QUERY_ADDON_TYPES(res.data))
    })
  }
}
