import { createAction, handleActions } from 'redux-actions'
import api from '../api'
// import * as charterPeriodActions from './charter-periods'

const QUERY_SSRCODES = createAction('query booking ssr codes')

const initialState = {
  data: []
}

// handlers
export default handleActions(
  {
    [QUERY_SSRCODES]: (state, action) => {
      return { ...state, data: action.payload }
    }
  },
  initialState
)

// thunks
export const querySsrCodes = reservationId => {
  return dispatch => {
    api.query('travelBooking/ssrcodes', { reservationId }).then(res => {
      dispatch(QUERY_SSRCODES(res.data))
    })
  }
}
