import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_TRAVEL_INSURANCES = createAction('query market travel insurances')
const SET_ACTIVE = createAction('set active travel insurance')
const SET_ACTIVE_VALUE = createAction('set active travel insurance value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_TRAVEL_INSURANCES]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: {
          ...state.active,
          [action.payload.name]: action.payload.value
        }
      }
    }
  },
  initialState
)

// Action creators
export const queryTravelInsurances = marketId => {
  return dispatch => {
    api
      .query('market/travelInsurance/travelInsurances', { marketId })
      .then(json => dispatch(QUERY_TRAVEL_INSURANCES(json.data)))
  }
}

export const submitCreateTravelInsurance = command => {
  return dispatch => {
    let cmd = api.sendCommand(
      'market/travelInsurance/createTravelInsurance',
      command
    )
    cmd.then(() => {
      dispatch(queryTravelInsurances(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Travel insurance added'))
    })
    return cmd
  }
}

export const submitUpdateTravelInsurance = command => {
  return dispatch => {
    let cmd = api.sendCommand(
      'market/travelInsurance/updateTravelInsurance',
      command
    )
    cmd.then(() => {
      dispatch(queryTravelInsurances(command.marketId))
      dispatch(
        globalMessageActions.setGlobalSuccess('Travel insurance updated')
      )
    })
    return cmd
  }
}

export const submitDeleteTravelInsurance = command => {
  return dispatch => {
    let cmd = api.sendCommand(
      'market/travelInsurance/deleteTravelInsurance',
      command
    )
    cmd.then(() => {
      dispatch(queryTravelInsurances(command.marketId))
      dispatch(
        globalMessageActions.setGlobalSuccess('Travel insurance deleted')
      )
    })
    return cmd
  }
}

export const setActive = travelInsurance => {
  return dispatch => {
    dispatch(SET_ACTIVE(travelInsurance))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}
