import { createAction, handleActions } from 'redux-actions'
import { v4 as uuid } from 'uuid'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'
import api from '../../api'

const SET_EDIT_ROW = createAction('set edit row flight contract')
const SET_VALUE = createAction('set edit value flight contract')
const SET_EDIT_FLIGHT_VALUE = createAction('set edit flight value flight contract')
const ADD_EDIT_FLIGHT_TO_ROW = createAction('add edit flight to row')
const REMOVE_EDIT_FLIGHT_FROM_ROW = createAction('remove edit flight from row')

const initialState = {
  edit: null
}

// Reducers/handlers
export default handleActions(
  {
    [SET_EDIT_ROW]: (state, action) => {
      return { ...state, edit: action.payload }
    },
    [SET_VALUE]: (state, action) => {
      return { ...state, edit: { ...state.edit, [action.payload.name]: action.payload.value } }
    },
    [SET_EDIT_FLIGHT_VALUE]: (state, action) => {
      return {
        ...state,
        edit: {
          ...state.edit,
          flights: state.edit.flights.map(
            (flight, i) =>
              (i === action.payload.index ? { ...flight, [action.payload.name]: action.payload.value } : flight)
          )
        }
      }
    },
    [ADD_EDIT_FLIGHT_TO_ROW]: (state, action) => {
      let prevFlight = state.edit.flights[state.edit.flights.length - 1]
      let newFlight = {
        ...prevFlight,
        id: uuid(),
        originAirportId: prevFlight.destinationAirportId,
        destinationAirportId: prevFlight.originAirportId
      }

      return {
        ...state,
        edit: {
          ...state.edit,
          flights: [...state.edit.flights, newFlight]
        }
      }
    },
    [REMOVE_EDIT_FLIGHT_FROM_ROW]: (state, action) => {
      return {
        ...state,
        edit: { ...state.edit, flights: state.edit.flights.filter((f, i) => i !== action.payload) }
      }
    }
  },
  initialState
)

export const setEditRow = value => {
  return dispatch => {
    if (value) {
      if (value.totalPrice) {
        value.priceType = 'totalPrice'
        value.price = value.totalPrice
      } else {
        value.priceType = 'pricePerSeat'
        value.price = value.pricePerSeat
      }
    }

    dispatch(SET_EDIT_ROW(value))
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const setFlightValue = (index, value, name) => {
  return dispatch => {
    dispatch(SET_EDIT_FLIGHT_VALUE({ index, value, name }))
  }
}
export const addFlightToRow = () => {
  return dispatch => {
    dispatch(ADD_EDIT_FLIGHT_TO_ROW())
  }
}

export const removeFlightFromRow = index => {
  return dispatch => {
    dispatch(REMOVE_EDIT_FLIGHT_FROM_ROW(index))
  }
}

export const submitRemoveRow = command => {
  return dispatch => {
    return api.sendCommand('flightDeparture/contract/removeRow', command).then(res => {
      dispatch(globalMessageActions.setGlobalSuccess('Row removed from contract'))
      dispatch(contractActions.queryFlightContract(command.contractId))
      dispatch(SET_EDIT_ROW(null))
    })
  }
}

export const submitEditRow = command => {
  return dispatch => {
    return api.sendCommand('flightDeparture/contract/updateRow', command).then(res => {
      dispatch(globalMessageActions.setGlobalSuccess('Row updated'))
      dispatch(contractActions.queryFlightContract(command.contractId))
      dispatch(SET_EDIT_ROW(null))
    })
  }
}
