import { createAction, handleActions } from 'redux-actions'
import { v4 as uuid } from 'uuid'

// Actions
const SET_TAB = createAction('set booking reservation tab')
const REMOVE_TAB = createAction('remove booking reservation tab')
const REMOVE_TABS = createAction('remove booking reservation all tabs')

const initialState = []

// Reducers/handlers
export default handleActions(
  {
    [SET_TAB]: (state, action) => {
      let existingIndex = state.findIndex(tab => tab.id === action.payload.id)
      if (existingIndex >= 0) {
        return state.map((tab, i) => (i === existingIndex ? { ...tab, name: action.payload.name } : tab))
      }
      return [...state, action.payload]
    },
    [REMOVE_TAB]: (state, action) => {
      return state.filter(tab => action.payload !== tab.id)
    },
    [REMOVE_TABS]: () => {
      return []
    }
  },
  initialState
)

export const setTab = (name, id = uuid()) => {
  return dispatch => {
    let tab = { name, id }
    dispatch(SET_TAB(tab))
  }
}

export const removeTab = id => {
  return dispatch => {
    dispatch(REMOVE_TAB(id))
  }
}

export const removeAllTabs = () => {
  return dispatch => {
    dispatch(REMOVE_TABS())
  }
}
