import { combineReducers } from 'redux'
import airlines from './airlines'
import airports from './airports'
import charterTypeCategories from './charter-type-categories'
import currencies from './currencies'
import currencyHedge from './currency-hedge'
import destinations from './destinations'
import countries from './countries'
import exchangeRates from './exchange-rates'
import flightAddons from './flight-addons'
import hotels from './hotels'
import hotel from './hotel'
import languages from './languages'
import markets from './markets'
import generalRoomCategories from './general-room-categories'
import generalFacilities from './general-facilities'
import generalRoomFacilities from './general-room-facilities'
import roomCategories from './room-categories'
import weekdays from './weekdays'
import ssr from './ssr'
import travelTypes from './travel-types'
import flightNumbers from './flight-numbers'
import sellers from './sellers'
import sihotRoomCategories from './sihot-room-categories'
import sihotHotelMappings from './sihot-hotel-mappings'

export default combineReducers({
  airlines,
  airports,
  charterTypeCategories,
  currencies,
  currencyHedge,
  destinations,
  countries,
  exchangeRates,
  flightAddons,
  hotels,
  hotel,
  languages,
  markets,
  generalRoomCategories,
  generalFacilities,
  generalRoomFacilities,
  roomCategories,
  weekdays,
  sihotRoomCategories,
  sihotHotelMappings,
  ssr,
  travelTypes,
  flightNumbers,
  sellers
})
