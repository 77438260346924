import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_ORIGIN_AIRPORTS = createAction('query market airports')
const SET_ACTIVE = createAction('set active market airport')
const SET_ACTIVE_VALUE = createAction('set active market airport value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_ORIGIN_AIRPORTS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    }
  },
  initialState
)

// Action creators
export const queryOriginAirports = (marketId, ignoreCache = false) => {
  return dispatch => {
    api
      .query('market/airport/airports', {
        MarketId: marketId,
        IgnoreCache: ignoreCache
      })
      .then(json => dispatch(QUERY_ORIGIN_AIRPORTS(json.data)))
  }
}

export const submitCreateAirport = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/airport/createAirport', command)
    cmd.then(() => {
      const ignoreCache = true
      dispatch(queryOriginAirports(command.marketId, ignoreCache))
      dispatch(globalMessageActions.setGlobalSuccess('Airport added'))
    })
    return cmd
  }
}

export const submitUpdateAirport = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/airport/updateAirport', command)
    cmd.then(() => {
      const ignoreCache = true
      dispatch(queryOriginAirports(command.marketId, ignoreCache))
      dispatch(globalMessageActions.setGlobalSuccess('Airport updated'))
    })
    return cmd
  }
}

export const submitDeleteAirport = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/airport/deleteAirport', command)
    cmd.then(() => {
      const ignoreCache = true
      dispatch(queryOriginAirports(command.marketId, ignoreCache))
      dispatch(globalMessageActions.setGlobalSuccess('Airport deleted'))
    })
    return cmd
  }
}

export const setActive = airport => {
  return dispatch => {
    dispatch(SET_ACTIVE(airport))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}
