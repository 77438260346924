import { createAction, handleActions } from 'redux-actions'
import customers from './customers-demo'
import api from '../api'

const searchCustomers = customers.map(customer => {
  let search = ''
  for (let key in customer) {
    search += ` ${customer[key]}`.toLowerCase()
  }
  return { ...customer, search: search }
})

// Actions
const SET_CUSTOMERS = createAction('set customers')
const SET_SELECTED_CUSTOMER = createAction('set selected customer')
const SET_VALUE = createAction('set customer value')
const QUERY_PAYING_CUSTOMER = createAction('query payin customer')

const initialState = {
  customers: searchCustomers,
  active: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    cellPhoneNumber: '',
    address1: '',
    postalCode: '',
    city: ''
  }
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_PAYING_CUSTOMER]: (state, action) => {
      return { ...state, active: action.payload ? action.payload : state.active }
    },
    [SET_CUSTOMERS]: (state, action) => {
      return {
        ...state,
        customers: action.payload ? searchCustomers.filter(c => c.search.includes(action.payload.toLowerCase())) : []
      }
    },
    [SET_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    }
  },
  initialState
)

export const queryPayingCustomer = reservationId => {
  return (dispatch, getState) => {
    dispatch(QUERY_PAYING_CUSTOMER(initialState.active))
    let query = api.query('travelBooking/payingCustomer', { reservationId })
    query.then(res => dispatch(QUERY_PAYING_CUSTOMER(res.data)))
    return query
  }
}

export const searchCustomer = value => {
  return dispatch => {
    dispatch(SET_CUSTOMERS(value))
  }
}

export const setSelectedCustomer = value => {
  return dispatch => {
    dispatch(SET_SELECTED_CUSTOMER(value))
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
