import { createAction, handleActions } from 'redux-actions'
import * as Sentry from '@sentry/browser'
import api from '../api'
import * as globalMessageActions from '../global-message'

const UPLOAD_BANKGIRO_FILES = createAction('upload bankgiro files')
const UPLOAD_BANKGIRO_FILES_SUCCESS = createAction(
  'upload bankgiro files succeeded'
)
const UPLOAD_BANKGIRO_FILES_ERROR = createAction('upload bankgiro files error')
const FETCH_LATEST_TRANSACTIONS = createAction('fetch latest transactions')
const FETCH_LATEST_TRANSACTIONS_SUCCESS = createAction(
  'fetch latest transactions succeeded'
)
const FETCH_LATEST_TRANSACTIONS_ERROR = createAction(
  'fetch latest transactions failed'
)

const initalState = {
  loading: false,
  error: null,
  latestTransactions: []
}

export default handleActions(
  {
    [UPLOAD_BANKGIRO_FILES]: (state, action) => ({
      ...state,
      error: null,
      latestTransactions: [
        {
          id: null,
          uploadDateTime: new Date().toString(),
          uploadedInSession: true,
          status: 'uploading'
        }
      ].concat(state.latestTransactions)
    }),
    [UPLOAD_BANKGIRO_FILES_SUCCESS]: (state, action) => state,
    [UPLOAD_BANKGIRO_FILES_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      latestTransactions: [
        {
          ...state.latestTransactions[0],
          status: 'failed',
          message: 'Failed to read the file: ' + action.payload
        }
      ].concat(state.latestTransactions.slice(1))
    }),
    [FETCH_LATEST_TRANSACTIONS]: (state, action) => ({
      ...state,
      loading: true,
      error: null
    }),
    [FETCH_LATEST_TRANSACTIONS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      latestTransactions: action.payload.map(t => ({
        ...t,
        status: 'uploaded'
      }))
    }),
    [FETCH_LATEST_TRANSACTIONS_ERROR]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload
    })
  },
  initalState
)

export function uploadFiles (files) {
  return function (dispatch, getState) {
    dispatch(UPLOAD_BANKGIRO_FILES(files))

    return detectBankGiroFileType(files[0])
      .then(countryCode =>
        api.sendCommandUpload(
          'UploadBankGiroFile',
          { [countryCode]: files[0] },
          ''
        )
      )
      .then(response => {
        setTimeout(() => {
          dispatch(UPLOAD_BANKGIRO_FILES_SUCCESS(response.data))
          if (response.data.duplicate) {
            dispatch(
              globalMessageActions.setGlobalWarning(
                'this bankgiro file has already been read'
              )
            )
          } else {
            dispatch(
              globalMessageActions.setGlobalSuccess('Bankgiro files read')
            )
          }

          dispatch(getLatestTransactions())
        }, 500)

        return response.data.bankPaymentsTransactionId
      })
      .catch(err => {
        console.log(err)
        Sentry.withScope(scope => {
          Sentry.captureException(err)
        })
        dispatch(
          globalMessageActions.setGlobalError(
            err || 'Failed to update latest bank files'
          )
        )

        dispatch(UPLOAD_BANKGIRO_FILES_ERROR(err))
        // dispatch(globalMessageActions.setGlobalError('Failed to read bankgiro file'))
      })
  }
}

export function getLatestTransactions () {
  return function (dispatch) {
    dispatch(FETCH_LATEST_TRANSACTIONS())

    return api
      .query('travelbooking/bankgirofile/LatestBankGiroFiles')
      .then(response => {
        dispatch(FETCH_LATEST_TRANSACTIONS_SUCCESS(response.data))
      })
      .catch(err => {
        dispatch(FETCH_LATEST_TRANSACTIONS_ERROR(err))
      })
  }
}

function detectBankGiroFileType (file) {
  return new Promise((resolve, reject) => {
    var headerBlob = file.slice(0, 80)
    var reader = new FileReader()
    reader.readAsText(headerBlob)
    reader.addEventListener('loadend', () => {
      var header = reader.result
      if (header.startsWith('01BGMAX')) {
        resolve('SE')
      } else if (header.startsWith('NY00001')) {
        resolve('NO')
      } else if (header.startsWith('02') && header[22] === '1') {
        resolve('FI')
      } else if (header.startsWith('<?xml version="1.0" encoding="UTF-8"?>')) {
        resolve('Iso20022')
      } else {
        reject('Unknown bank giro file type.')
      }
    })
    reader.addEventListener('error', err => reject(err))
    reader.addEventListener('abort', err => reject(err))
  })
}
