import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const QUERY_CAPACITY_ERRORS = createAction('query hotel capacity errors')
const QUERY_CAPACITY_ERRORS_SUCCESS = createAction('query hotel capacity errors success')
const CLEAR_CAPACITY_ERRORS = createAction('clear hotel capacity errors')

const initialState = {
  capacityErrors: [],
  loading: false
}

export default handleActions(
  {
    [QUERY_CAPACITY_ERRORS]: (state, action) => {
      return { ...state, capacityErrors: [], loading: true }
    },
    [CLEAR_CAPACITY_ERRORS]: (state, action) => {
      return { ...state, capacityErrors: [], loading: false }
    },
    [QUERY_CAPACITY_ERRORS_SUCCESS]: (state, action) => {
      const capacityErrors = action.payload.reduce((acc, item) => {
        item.summary.forEach(room => {
          const { id, checkinDate, checkoutDate } = item
          acc.push({
            hotelId: id.split('-')[0],
            checkinDate,
            checkoutDate,
            category: room.roomCategoryId,
            inventoryBooked: room.bookedRooms,
            reservationBooked: room.bookedRoomsReservations,
            available: room.availableRooms
          })
        })
        return acc
      }, [])
      return {
        ...state,
        capacityErrors,
        loading: false
      }
    }
  },
  initialState
)

export const queryCapacityErrors = params => {
  return dispatch => {
    dispatch(QUERY_CAPACITY_ERRORS())
    return api
      .query('travelbooking/reports/HotelCapacityErrors', params)
      .then(res => {
        dispatch(QUERY_CAPACITY_ERRORS_SUCCESS(res.data))
      })
      .catch(e => dispatch(CLEAR_CAPACITY_ERRORS()))
  }
}

export const clearCapacityErrors = () => {
  return dispatch => {
    dispatch(CLEAR_CAPACITY_ERRORS())
  }
}
