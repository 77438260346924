import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const QUERY_TRAVEL_TYPES = createAction('query travel types')

const initialState = {
  data: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_TRAVEL_TYPES]: (state, action) => {
      return { ...state, data: action.payload }
    }
  },
  initialState
)

export const queryTravelTypes = () => {
  return dispatch => {
    api.query('general/travelTypes').then(res => dispatch(QUERY_TRAVEL_TYPES(res.data)))
  }
}
