import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'

// Actions
const SET_CANCELLATIONS = createAction('set contract cancellations')
const RESET_ACTIVE_CANCELLATION = createAction('cancel active cancellation')
const SET_VALUE = createAction('update active cancellation')
const SET_ACTIVE_CANCELLATION = createAction('edit active cancellation')

const initialState = {
  active: null,
  loading: false,
  hotelItems: [],
  periodItems: []
}

// Reducers/handlers
export default handleActions(
  {
    [SET_CANCELLATIONS]: (state, action) => {
      return { ...state, loading: false, hotelItems: action.payload.entirePeriod, periodItems: action.payload.periods }
    },
    [SET_ACTIVE_CANCELLATION]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_CANCELLATION]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    }
  },
  initialState
)

export const setCancellations = items => {
  return dispatch => {
    dispatch(SET_CANCELLATIONS(items))
  }
}

export const addCancellation = item => {
  return dispatch => {
    return api.sendCommand('hotel/contract/addCancellation', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_CANCELLATION())
      dispatch(globalMessageActions.setGlobalSuccess('Cancellation rule added'))
    })
  }
}

export const updateCancellation = item => {
  return (dispatch, getState) => {
    return api.sendCommand('hotel/contract/updateCancellation', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_CANCELLATION())
      dispatch(globalMessageActions.setGlobalSuccess('Cancellation rule updated'))
    })
  }
}

export const deleteCancellation = item => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/contract/deleteCancellation', { id: item.id, contractId: item.contractId })
      .then(() => {
        dispatch(contractActions.getHotelContract(item.contractId))
        dispatch(RESET_ACTIVE_CANCELLATION())
        dispatch(globalMessageActions.setGlobalSuccess('Cancellation deleted'))
      })
  }
}

export const setActiveCancellation = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_CANCELLATION(item))
  }
}

export const resetActiveCancellation = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_CANCELLATION())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
