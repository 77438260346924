import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Row } from 'jsxstyle'
import CommonStyles from '../../../common/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import GlobalMessageSnackbar from '../../../common/global-message-snackbar'
import * as accountActions from '../../../store/account/account-actions'

const mapStateToProps = state => ({
  account: state.account.accountActions
})

const mapDispatchToProps = dispatch => ({
  accountActions: bindActionCreators(accountActions, dispatch)
})

class ResetPasswordPage extends Component {
  componentDidMount () {
    const search = this.props.location.search
    const params = new URLSearchParams(search)
    const ticketId = params.get('ticketId')
    if (!ticketId || ticketId.length !== 0) {
      this.props.accountActions.setResetModel('ticketId', ticketId)
    }
  }

  inputChanged = name => event => {
    this.props.accountActions.setResetModel(name, event.target.value)
  }

  resetPassword (e) {
    e.preventDefault()
    if (this.props.account.resetModel.password === this.props.account.resetModel.confirmPassword) {
      this.props.accountActions.resetPassword(this.props.account.resetModel).then(() => {
        setTimeout(() => {
          this.props.history.push('/account/login')
        }, 2000)
      })
    }
  }

  render () {
    return (
      <React.Fragment>
        <Card style={{ margin: 'auto', maxWidth: '600px' }}>
          <CardHeader title='Reset your password' />
          <form onSubmit={e => this.resetPassword(e)}>
            <CardContent>
              <TextField
                margin='normal'
                required
                type='password'
                label='New password'
                name='password'
                onChange={this.inputChanged('password')}
                value={this.props.account.resetModel.password}
                helperText='Minimum length of 6 characters'
                fullWidth
              />
              <TextField
                margin='normal'
                required
                type='password'
                label='Confirm password'
                name='confirmPassword'
                onChange={this.inputChanged('confirmPassword')}
                value={this.props.account.resetModel.confirmPassword}
                fullWidth
              />

            </CardContent>
            <CardActions>
              <Row justifyContent='flex-end' width='100%'>
                <Button type='submit' color='primary' disabled={this.props.account.loading} variant='contained'>
                  Reset password
                </Button>
              </Row>
            </CardActions>
          </form>

        </Card>
        <GlobalMessageSnackbar />
      </React.Fragment>
    )
  }
}

ResetPasswordPage = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage)

export default withStyles(CommonStyles)(ResetPasswordPage)
