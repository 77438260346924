import { createAction, handleActions } from 'redux-actions'
import * as globalMessageActions from '../../global-message'
import api from '../../api'

// Actions
const QUERY_TRANSFER_CONTRACTS_SUCCESS = createAction('query transfer contracts success')
const SET_ACTIVE_CONTRACT = createAction('set transfer contract')
const SET_ACTIVE_VALUE = createAction('transfer set new value')
const SET_FILTER_VALUE = createAction('set transfer filter value')

const initialState = {
  active: null,
  loading: false,
  items: [],
  filter: { airportId: '', destinationId: '' }
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_TRANSFER_CONTRACTS_SUCCESS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE_CONTRACT]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    },
    [SET_FILTER_VALUE]: (state, action) => {
      return { ...state, filter: { ...state.filter, [action.payload.name]: action.payload.value } }
    }
  },
  initialState
)

export const submitCreateTransfer = command => {
  return (dispatch, getState) => {
    return api.sendCommand('flightDeparture/transfer/createTransfer', command).then(() => {
      dispatch(queryTransfers(getState().contract.transfer.contracts.filter))
      dispatch(globalMessageActions.setGlobalSuccess(`Transfer created`))
    })
  }
}

export const submitUpdateTransfer = command => {
  return (dispatch, getState) => {
    return api.sendCommand('flightDeparture/transfer/updateTransfer', command).then(() => {
      dispatch(queryTransfers(getState().contract.transfer.contracts.filter))
      dispatch(globalMessageActions.setGlobalSuccess(`Transfer updated`))
    })
  }
}

export const submitDeleteTransfer = command => {
  return (dispatch, getState) => {
    return api.sendCommand('flightDeparture/transfer/deleteTransfer', command).then(() => {
      dispatch(queryTransfers(getState().contract.transfer.contracts.filter))
      dispatch(globalMessageActions.setGlobalSuccess(`Transfer deleted`))
    })
  }
}

export const queryTransfers = (params = null) => {
  return dispatch => {
    return api.query('flightDeparture/transfer/transfers', params).then(result => {
      dispatch(QUERY_TRANSFER_CONTRACTS_SUCCESS(result.data))
    })
  }
}

export const setActiveContract = value => {
  return dispatch => {
    dispatch(SET_ACTIVE_CONTRACT(value))
  }
}

export const setActiveValue = (value, name) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ value, name }))
  }
}

export const setFilterValue = (value, name) => {
  return dispatch => {
    dispatch(SET_FILTER_VALUE({ value, name }))
  }
}
