import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

const QUERY_USERS = createAction('query backoffice users')
const QUERY_ROLES = createAction('query backoffice roles')
const SET_ACTIVE = createAction('set active backoffice user')
const SET_VALUE = createAction('set active backoffice user value')

const initalState = {
  items: [],
  roles: [],
  active: null
}

export default handleActions(
  {
    [QUERY_USERS]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [QUERY_ROLES]: (state, action) => {
      return { ...state, roles: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_VALUE]: (state, action) => {
      return {
        ...state,
        active: {
          ...state.active,
          [action.payload.name]: action.payload.value
        }
      }
    }
  },
  initalState
)

export const submitCreateUser = command => {
  return dispatch => {
    let cmd = api.sendCommand(
      'security/backofficeuser/createBackOfficeUser',
      command
    )
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('BackOffice user added'))
      dispatch(queryBackofficeUsers())
    })
    return cmd
  }
}

export const submitUpdateUser = command => {
  return dispatch => {
    let cmd = api.sendCommand(
      'security/backofficeuser/updateBackOfficeUser',
      command
    )
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('BackOffice user updated'))
      dispatch(queryBackofficeUsers())
    })
    return cmd
  }
}

export const submitDeleteUser = command => {
  return dispatch => {
    let cmd = api.sendCommand(
      'security/backofficeuser/deleteBackOfficeUser',
      command
    )
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('BackOffice user deleted'))
      dispatch(queryBackofficeUsers())
    })
    return cmd
  }
}

export const queryBackofficeUsers = () => {
  return dispatch => {
    api
      .query('security/backofficeuser/backOfficeUsers')
      .then(res => dispatch(QUERY_USERS(res.data)))
  }
}

export const queryBackofficeRoles = () => {
  return dispatch => {
    api
      .query('security/backofficeuser/backOfficeRoles')
      .then(res => dispatch(QUERY_ROLES(res.data)))
  }
}

export const setActive = user => {
  return dispatch => {
    dispatch(SET_ACTIVE(user))
  }
}

export const setValue = (name, value) => {
  return dispatch => {
    dispatch(SET_VALUE({ name, value }))
  }
}
