import { createAction, handleActions } from 'redux-actions'
import moment from 'moment-timezone'
import api from '../api'
import * as bookingTabActions from './tabs'
import * as proposalActions from './proposals'
import * as globalMessageActions from '../global-message'

const SET_LOADING = createAction('booking reservations loading')
const QUERY_RESERVATION = createAction('booking query reservation')
const QUERY_RESERVATIONS = createAction('booking query reservations')
const QUERY_HOTEL_INFO = createAction('booking query hotel info')
const QUERY_CANCELLATION_FEE = createAction('booking query cancellation fee')
const QUERY_CANCELLATION_FEE_ROOM = createAction(
  'booking query cancellation fee room'
)
const QUERY_RESERVATION_FILTERS = createAction(
  'booking query reservation filters'
)
const SET_SEARCH_CRITERIA = createAction('booking set search criteria')
const SET_ROLLBACKING = createAction('booking set rollbacking')
const SET_SEARCH_MODE = createAction('booking set search mode')

// Conference
const RESET_CONF_RESERVATION = createAction('conference reset reservation')
const QUERY_CONF_RESERVATION = createAction('conference query reservation')
const QUERY_CONF_ARTICLE_TYPES = createAction('query article types')
const QUERY_CONF_FLIGHTS = createAction('conference query flights')
const QUERY_CONF_DESTINATIONS = createAction('conference query destinations')
const QUERY_CONF_AIRPORTS = createAction('conference query origin airports')
const SET_CONF_TRAVELERS = createAction('conference set travelers')
const SET_CONF_TRAVELER_VALUE = createAction('conference set traveler value')
const SET_CONF_CUSTOMER_VALUE = createAction('conference set customer value')
const SET_CONF_HOTELS = createAction('conference set hotels')
const SET_CONF_HOTEL_ROOM_TRAVELERS = createAction(
  'conference set hotel room travelers'
)
const SET_CONF_ARTICLES = createAction('conference set articles')
const SET_CONF_FLIGHTS = createAction('conference set flights')
const SET_CONF_CHARTERS = createAction('conference set charters')
const SET_CONF_PACKAGES = createAction('conference set packages')

const initialState = {
  rollbacking: false,
  reservation: null,
  reservations: [],
  totalReservations: 0,
  loading: false,
  filters: {
    statuses: [],
    destinations: [],
    salesChannels: [],
    agents: [],
    markets: [],
    users: []
  },
  searchMode: null,

  // Conference
  airports: [],
  destinations: [],
  articleTypes: [],
  flights: [],
  confirmDisabled: { disabled: true, message: '' }
}

// handlers
export default handleActions(
  {
    [QUERY_RESERVATION]: (state, action) => {
      return {
        ...state,
        reservation: action.payload
      }
    },
    [QUERY_RESERVATIONS]: (state, action) => {
      return {
        ...state,
        loading: false,
        reservations: action.payload.reservations,
        totalReservations: action.payload.total
      }
    },
    [QUERY_HOTEL_INFO]: (state, action) => {
      return {
        ...state,
        reservation: {
          ...state.reservation,
          hotels: state.reservation.hotels.map((hotel, i) =>
            i === 0 ? { ...hotel, imageUrls: action.payload.imageUrls } : hotel
          )
        }
      }
    },
    [QUERY_CANCELLATION_FEE]: (state, action) => {
      return {
        ...state,
        reservation: { ...state.reservation, cancellationFee: action.payload }
      }
    },
    [QUERY_CANCELLATION_FEE_ROOM]: (state, action) => {
      return {
        ...state,
        reservation: {
          ...state.reservation,
          cancellationFeeRoom: action.payload
        }
      }
    },
    [QUERY_RESERVATION_FILTERS]: (state, action) => {
      return { ...state, filters: action.payload }
    },
    [SET_SEARCH_CRITERIA]: (state, action) => {
      return {
        ...state,
        reservation: { ...state.reservation, searchInfo: action.payload }
      }
    },
    [SET_ROLLBACKING]: (state, action) => {
      return { ...state, rollbacking: action.payload }
    },
    [SET_LOADING]: (state, action) => {
      return { ...state, loading: action.payload }
    },

    // Conference
    [RESET_CONF_RESERVATION]: () => {
      return initialState
    },
    [QUERY_CONF_RESERVATION]: (state, action) => {
      return {
        ...state,
        reservation: {
          ...state.reservation,
          ...action.payload
        },
        confirmDisabled: confirmDisabled(action.payload)
      } // TODO
    },
    [QUERY_CONF_ARTICLE_TYPES]: (state, action) => {
      return { ...state, articleTypes: action.payload }
    },
    [QUERY_CONF_FLIGHTS]: (state, action) => {
      return { ...state, flights: action.payload }
    },
    [QUERY_CONF_DESTINATIONS]: (state, action) => {
      return { ...state, destinations: action.payload }
    },
    [QUERY_CONF_AIRPORTS]: (state, action) => {
      return { ...state, airports: action.payload }
    },
    [SET_CONF_TRAVELERS]: (state, action) => {
      return {
        ...state,
        reservation: { ...state.reservation, travelers: action.payload }
      }
    },
    [SET_CONF_TRAVELER_VALUE]: (state, action) => {
      return {
        ...state,
        reservation: {
          ...state.reservation,
          travelers: state.reservation.travelers.map((traveler, i) =>
            i === action.payload.travelerIndex
              ? { ...traveler, [action.payload.name]: action.payload.value }
              : traveler
          )
        }
      }
    },
    [SET_CONF_CUSTOMER_VALUE]: (state, action) => {
      return {
        ...state,
        reservation: {
          ...state.reservation,
          customer: {
            ...state.reservation.customer,
            [action.payload.name]: action.payload.value
          }
        }
      }
    },
    [SET_CONF_HOTELS]: (state, action) => {
      return {
        ...state,
        reservation: { ...state.reservation, hotels: action.payload }
      }
    },
    [SET_CONF_HOTEL_ROOM_TRAVELERS]: (state, action) => {
      return {
        ...state,
        reservation: {
          ...state.reservation,
          hotels: state.reservation.hotels.map(hotel =>
            hotel.id === action.payload.hotelId
              ? {
                  ...hotel,
                  rooms: hotel.rooms.map(room =>
                    room.id === action.payload.roomId
                      ? { ...room, travelers: action.payload.travelers }
                      : room
                  )
                }
              : hotel
          )
        }
      }
    },
    [SET_CONF_ARTICLES]: (state, action) => {
      return {
        ...state,
        reservation: { ...state.reservation, articles: action.payload }
      }
    },
    [SET_CONF_CHARTERS]: (state, action) => {
      return {
        ...state,
        reservation: { ...state.reservation, charters: action.payload }
      }
    },
    [SET_CONF_PACKAGES]: (state, action) => {
      return {
        ...state,
        reservation: { ...state.reservation, packages: action.payload }
      }
    },
    [SET_SEARCH_MODE]: (state, action) => {
      return { ...state, searchMode: action.payload }
    }
  },
  initialState
)

// thunks
export const queryReservations = (params, isConference) => {
  return dispatch => {
    dispatch(SET_LOADING(true))
    const excludeSalesChannels = isConference
      ? ['Web', 'BackOffice', 'Agent']
      : ['Conference']
    let query = api.query('travelBooking/backoffice/searchReservation', {
      ...params,
      excludeSalesChannels,
      orderByNextDueDate: isConference ? true : false
    })
    query.then(res => dispatch(QUERY_RESERVATIONS(res.data)))
    return query
  }
}

export const queryReservation = reservationId => {
  return dispatch => {
    let query = api.query('travelBooking/backoffice/reservationDetails', {
      reservationId
    })
    query.then(res => {
      let searchInfo = res.data.searchInfo
      let firstRow = res.data.market
      let lastRow =
        searchInfo.travelType === 'Flight'
          ? `${searchInfo.flightTravelers.length} travelers`
          : `${searchInfo.rooms.length} rooms`

      if (res.data.reservationCode) {
        firstRow = res.data.reservationCode
      }

      if (searchInfo.airportId && searchInfo.destinationId) {
        firstRow += `: ${searchInfo.airportId} - ${searchInfo.destinationId}`
      }
      if (searchInfo.outwardDateFrom) {
        lastRow = `${moment(searchInfo.outwardDateFrom).format(
          'ddd D MMM, YYYY'
        )}`
      }

      dispatch(QUERY_RESERVATION(res.data))
      dispatch(bookingTabActions.setTab([firstRow, lastRow], reservationId))
    })
    return query
  }
}

export const queryCancellationFee = (
  reservationId,
  travelerId = null,
  cancellationDate = null,
  ignoreCancellationFee = false
) => {
  return dispatch => {
    const query = api.query('travelBooking/cancellationFee', {
      reservationId,
      travelerId,
      cancellationDate,
      ignoreCancellationFee
    })
    query.then(res => dispatch(QUERY_CANCELLATION_FEE(res.data)))
    return query
  }
}

export const queryCancellationFeeRoom = (
  reservationId,
  roomId = null,
  cancellationDate = null,
  ignoreCancellationFee = false
) => {
  return dispatch => {
    const query = api.query('travelBooking/cancellationFeeByRoom', {
      reservationId,
      roomId,
      cancellationDate,
      ignoreCancellationFee
    })
    query.then(res => dispatch(QUERY_CANCELLATION_FEE_ROOM(res.data)))
    return query
  }
}

export const queryReservationFilters = isConference => {
  return dispatch => {
    api
      .query('travelbooking/backoffice/ReservationFilters', { isConference })
      .then(res => dispatch(QUERY_RESERVATION_FILTERS(res.data)))
  }
}

export const submitCreateReservation = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/createEmptyReservation', {
      ...command,
      salesChannelId: 'BackOffice'
    })
    return cmd
  }
}

export const submitSetCharterSearchCriteria = (
  command,
  ignoreProposalSearch = false
) => {
  return (dispatch, getState) => {
    dispatch(SET_SEARCH_CRITERIA(command))
    let cmd = api.sendCommand('travelBooking/setCharterSearchCriteria', command)
    cmd.then(() => {
      dispatch(queryReservation(command.reservationId))
      const reservation = getState().booking.reservations.reservation
      const search = reservation
        ? { ...reservation.searchInfo, ...command }
        : command
      if (validateSearchForProposal(search) && !ignoreProposalSearch) {
        dispatch(proposalActions.queryProposals(command.reservationId))
      }
    })
    return cmd
  }
}

export const submitSelectProposal = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/selectProposal', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Proposal selected'))
      dispatch(queryReservation(command.reservationId))
      dispatch(SET_SEARCH_MODE(null))
    })
    return cmd
  }
}

export const submitSetTravelerDetails = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/setTravelerDetails', command)
    cmd.then(() =>
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
    )
    return cmd
  }
}

export const submitRemoveCharterTicket = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/removeCharterTicket', command)
    cmd.then(() => {
      dispatch(queryReservation(command.reservationId))
      dispatch(globalMessageActions.setGlobalSuccess('Charter ticket removed'))
    })
    return cmd
  }
}

export const submitSetPayingCustomer = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/setPayingCustomer', command)
    cmd.then(() =>
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
    )
    return cmd
  }
}

export const submitConfirmReservation = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/confirmReservation', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Reservation confirmed'))
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
    })
    return cmd
  }
}

export const submitCancelReservation = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/cancelReservation', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Reservation cancelled'))
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
    })
    return cmd
  }
}

export const submitRollbackReservation = command => {
  return (dispatch, getState) => {
    dispatch(SET_ROLLBACKING(true))
    let cmd = api.sendCommand('travelBooking/rollbackReservation', command)
    cmd
      .then(() => {
        dispatch(SET_ROLLBACKING(false))
        dispatch(
          globalMessageActions.setGlobalSuccess(
            'Reservation changes has been rolled back'
          )
        )
        dispatch(
          getState().booking.reservations.reservation.salesChannelCode ===
            'Conference'
            ? queryConfReservation(command.reservationId)
            : queryReservation(command.reservationId)
        )
      })
      .catch(() => dispatch(SET_ROLLBACKING(false)))
    return cmd
  }
}

export const submitCancelTraveler = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/cancelTraveler', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Traveler cancelled'))
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
    })
    return cmd
  }
}

export const submitAddMedicalCertificate = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/addMedicalCertificate', command)
    cmd.then(() => {
      dispatch(
        globalMessageActions.setGlobalSuccess('Medical certificate added')
      )
      dispatch(queryReservation(command.reservationId))
    })
    return cmd
  }
}

export const submitRevokeMedicalCertificate = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/revokeMedicalCertificate', command)
    cmd.then(() => {
      dispatch(
        globalMessageActions.setGlobalSuccess('Medical certificate revoked')
      )
      dispatch(queryReservation(command.reservationId))
    })
    return cmd
  }
}

export const submitUseCancellationInsurance = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/useCancellationInsurance', command)
    cmd.then(() => {
      dispatch(
        globalMessageActions.setGlobalSuccess(
          'Activated cancellation insurance'
        )
      )
      dispatch(queryReservation(command.reservationId))
    })
    return cmd
  }
}

export const submitAbandonCancellationInsurance = command => {
  return dispatch => {
    let cmd = api.sendCommand(
      'travelBooking/AbandonCancellationInsurance',
      command
    )
    cmd.then(() => {
      dispatch(
        globalMessageActions.setGlobalSuccess(
          'Abandoned cancellation insurance'
        )
      )
      dispatch(queryReservation(command.reservationId))
    })
    return cmd
  }
}

export const submitSetDueDates = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/setPaymentDueDates', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Due dates updated'))
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
    })
    return cmd
  }
}

export const submitSetReservationCode = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/setReservationCode', command)
    cmd.then(() => {
      dispatch(
        globalMessageActions.setGlobalSuccess('Reservation code updated')
      )
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
    })
    return cmd
  }
}

export const submitSendReservationConfirmation = command => {
  return (dispatch, getState) => {
    dispatch(SET_LOADING(true))
    let cmd = api.sendCommand('travelBooking/sendOrderConfirmation', command)
    cmd.then(() => {
      dispatch(SET_LOADING(false))
      dispatch(
        globalMessageActions.setGlobalSuccess('Reservation confirmation sent')
      )
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
    })
    return cmd
  }
}

export const submitSendPriceProposal = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/sendPriceProposal', command)
    cmd.then(() => {
      dispatch(
        globalMessageActions.setGlobalSuccess(
          `Price proposal sent to ${command.email}`
        )
      )
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
    })
    return cmd
  }
}

export const submitAddNote = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/addNote', command)
    cmd.then(() => {
      dispatch(
        globalMessageActions.setGlobalSuccess(`${command.noteType} notes added`)
      )
    })
    return cmd
  }
}

export const submitUpdateNote = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/updateNote', command)
    cmd.then(() => {
      dispatch(
        globalMessageActions.setGlobalSuccess(
          `${command.noteType} notes updated`
        )
      )
    })
    return cmd
  }
}

export const submitRemoveNote = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/removeNote', command)
    cmd.then(() => {
      dispatch(
        globalMessageActions.setGlobalSuccess(
          `${command.noteType} notes removed`
        )
      )
    })
    return cmd
  }
}

export const submitSetOfferCode = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/setOfferCode', {
      ...command,
      ignoreConfirmedRule: true
    })
    cmd.then(() => {
      const reservation = getState().booking.reservations.reservation
      const search = reservation.searchInfo
      if (validateSearchForProposal(search)) {
        dispatch(proposalActions.queryProposals(command.reservationId))
      }
      dispatch(
        globalMessageActions.setGlobalSuccess(
          command.offerCode ? 'Offer code added' : 'Offer code removed'
        )
      )
    })
    return cmd
  }
}

export const submitSetReferralCode = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/setReferralCode', command)
    cmd.then(() => {
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
      dispatch(globalMessageActions.setGlobalSuccess('Referral Code added'))
    })
    return cmd
  }
}

export const submitAddPriceAdjustment = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand(`travelBooking/add${command.variant}`, command)
    cmd.then(() => {
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
      dispatch(globalMessageActions.setGlobalSuccess('Price adjustment added'))
    })
    return cmd
  }
}

export const submitUpdatePriceAdjustment = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/updatePriceAdjustment', command)
    cmd.then(() => {
      dispatch(
        getState().booking.reservations.reservations.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
      dispatch(
        globalMessageActions.setGlobalSuccess('Price adjustment updated')
      )
    })
  }
}

export const submitAddManualPayment = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/addManualPayment', command)
    cmd.then(() => {
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
      dispatch(globalMessageActions.setGlobalSuccess('Manual payment added'))
    })
    return cmd
  }
}

export const submitMovePayment = command => {
  return (dispatch, getState) => {
    dispatch(SET_LOADING(true))
    let cmd = api.sendCommand('travelBooking/movePayment', command)
    cmd.then(() => {
      getState().booking.reservations.reservation.salesChannelCode ===
      'Conference'
        ? queryConfReservation(command.reservationIdFrom)
        : queryReservation(command.reservationIdFrom)
      dispatch(SET_LOADING(false))
      dispatch(
        globalMessageActions.setGlobalSuccess(
          `Payment moved to ${command.reservationCodeTo}`
        )
      )
    })
    return cmd
  }
}

export const submitRemovePriceAdjustment = command => {
  return (dispatch, getState) => {
    api.sendCommand('travelBooking/removePriceAdjustment', command).then(() => {
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
      dispatch(
        globalMessageActions.setGlobalSuccess('Price adjustment removed')
      )
    })
  }
}

export const submitShouldSendMails = (shouldSendEmail, reservationId) => {
  return (dispatch, getState) => {
    api
      .sendCommand(
        `travelBooking/${
          shouldSendEmail ? 'enableCustomerMails' : 'disableCustomerMails'
        }`,
        { reservationId }
      )
      .then(() => {
        getState().booking.reservations.reservation.salesChannelCode ===
        'Conference'
          ? queryConfReservation(reservationId)
          : queryReservation(reservationId)
        dispatch(
          globalMessageActions.setGlobalSuccess(
            `Mail sending turned ${shouldSendEmail ? 'on' : 'off'}`
          )
        )
      })
  }
}

export const setIgnoreTravelerDetails = (value, reservationId) => {
  return (dispatch, getState) => {
    api
      .sendCommand(`travelBooking/setIgnoreTravelerDetails`, {
        reservationId,
        value
      })
      .then(() => {
        dispatch(
          getState().booking.reservations.reservation.salesChannelCode ===
            'Conference'
            ? queryConfReservation(reservationId)
            : queryReservation(reservationId)
        )
      })
  }
}

export const setSearchMode = searchMode => dispatch =>
  dispatch(SET_SEARCH_MODE(searchMode))

export const downloadConfirmationPdf = reservationId => {
  return () => {
    api
      .query('travelBooking/reservationConfirmationPdfDownload', {
        reservationId
      })
      .then(res => {
        var pdfAsDataUri = 'data:application/pdf;base64,' + res.data.data

        // IE 10+
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(dataUriToBlob(pdfAsDataUri), res.data.fileName)
        } else {
          var link = document.createElement('a')
          link.download = res.data.fileName
          link.href = pdfAsDataUri
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          // let pdfWindow = window.open('')
          // pdfWindow.document.write(
          //   "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          //     encodeURI(res.data.data) +
          //     "'></iframe>"
          // )
        }
      })
  }
}

export const validateSearchForProposal = search => {
  if (search.travelType === 'Flight') {
    return (
      search.airportId &&
      search.charterTypeId &&
      search.destinationId &&
      search.outwardDateFrom &&
      search.outwardDateTo
    )
  } else if (search.travelType === 'Hotel') {
    return (
      search.charterTypeId &&
      search.destinationId &&
      search.outwardDateFrom &&
      search.outwardDateTo &&
      search.rooms &&
      search.rooms.length > 0
    )
  }

  return (
    search.airportId &&
    search.charterTypeId &&
    search.destinationId &&
    search.outwardDateFrom &&
    search.outwardDateTo &&
    search.rooms &&
    search.rooms.length > 0
  )
}

export const validateSearchForCharterPeriods = search => {
  return search.airportId && search.charterTypeId && search.destinationId
}

const dataUriToBlob = dataUri => {
  if (!/base64/.test(dataUri)) throw new Error('Supports only base64 encoding.')
  let parts = dataUri.split(/[:;,]/)

  let type = parts[1]

  let binData = atob(parts.pop())

  let mx = binData.length

  let uiArr = new Uint8Array(mx)
  for (var i = 0; i < mx; ++i) {
    uiArr[i] = binData.charCodeAt(i)
  }
  return new Blob([uiArr], { type: type })
}

// Conference
export const submitCreateConfReservation = command => {
  return () => {
    let cmd = api.sendCommand('travelBooking/createEmptyReservation', {
      ...command,
      salesChannelId: 'Conference'
    })
    return cmd
  }
}

export const submitSetConference = () => {
  return (dispatch, getState) => {
    const command = { ...getState().booking.reservations.reservation }
    delete command.customer

    api
      .sendCommand('travelBooking/setConferenceReservation', command)
      .then(() => {
        dispatch(queryConfReservation(command.id))
      })
  }
}

export const submitSetDepositFee = command => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand('travelBooking/setTravelersDepositFee', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Deposit fee updated'))
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
    })
    return cmd
  }
}

export const submitSetConferencePaymentRules = (
  command,
  showMessage = false
) => {
  return (dispatch, getState) => {
    let cmd = api.sendCommand(
      'travelBooking/setConferencePaymentRules',
      command
    )
    cmd.then(() => {
      if (showMessage) {
        dispatch(
          globalMessageActions.setGlobalSuccess('Conference payment rules set')
        )
      }
      dispatch(
        getState().booking.reservations.reservation.salesChannelCode ===
          'Conference'
          ? queryConfReservation(command.reservationId)
          : queryReservation(command.reservationId)
      )
    })
    return cmd
  }
}

export const resetReservation = () => {
  return dispatch => {
    dispatch(RESET_CONF_RESERVATION())
  }
}

export const queryConfReservation = reservationId => {
  return dispatch => {
    api
      .query('travelBooking/conferenceReservationDetails', { reservationId })
      .then(res => {
        dispatch(QUERY_RESERVATION(res.data))
      })
  }
}

export const queryArticleTypes = () => {
  return dispatch => {
    api
      .query('travelbooking/backoffice/conferenceArticleTypes')
      .then(res => dispatch(QUERY_CONF_ARTICLE_TYPES(res.data)))
  }
}

export const queryDestinations = () => {
  return dispatch => {
    api
      .query('destination/destination/destinations/')
      .then(res => dispatch(QUERY_CONF_DESTINATIONS(res.data)))
  }
}

export const queryAirports = () => {
  return dispatch => {
    api.query('general/airports').then(res => {
      dispatch(QUERY_CONF_AIRPORTS(res.data))
    })
  }
}

export const queryFlights = (
  originAirportId,
  destinationAirportId,
  dateTimeFrom
) => {
  return dispatch => {
    api
      .query('flightDeparture/contract/departures', {
        originAirportId,
        destinationAirportId,
        dateTimeFrom,
        dateTimeTo: moment(dateTimeFrom).endOf('day')
      })
      .then(res => {
        dispatch(QUERY_CONF_FLIGHTS(res.data))
      })
  }
}

export const setClearFlights = () => {
  return dispatch => {
    dispatch(QUERY_CONF_FLIGHTS([]))
    dispatch(submitSetConference())
  }
}

export const setTravelers = travelers => {
  return dispatch => {
    dispatch(SET_CONF_TRAVELERS(travelers))
    dispatch(submitSetConference())
  }
}

export const setTravelerValue = (travelerIndex, name, value) => {
  return dispatch => {
    dispatch(SET_CONF_TRAVELER_VALUE({ travelerIndex, name, value }))
  }
}

export const setCustomerValue = (name, value) => {
  return dispatch => {
    dispatch(SET_CONF_CUSTOMER_VALUE({ name, value }))
  }
}

export const setHotels = hotels => {
  return dispatch => {
    dispatch(SET_CONF_HOTELS(hotels))
    dispatch(submitSetConference())
  }
}

export const setHotelRoomTravelers = (hotelId, roomId, travelers) => {
  return dispatch => {
    dispatch(SET_CONF_HOTEL_ROOM_TRAVELERS({ hotelId, roomId, travelers }))
    dispatch(submitSetConference())
  }
}

export const setArticles = articles => {
  return dispatch => {
    dispatch(SET_CONF_ARTICLES(articles))
    dispatch(submitSetConference())
  }
}

export const setFlights = flights => {
  return dispatch => {
    dispatch(SET_CONF_FLIGHTS(flights))
    dispatch(submitSetConference())
  }
}

export const setCharters = charters => {
  return dispatch => {
    dispatch(SET_CONF_CHARTERS(charters))
    dispatch(submitSetConference())
  }
}

export const setPackages = packages => {
  return dispatch => {
    dispatch(SET_CONF_PACKAGES(packages))
    dispatch(submitSetConference())
  }
}

const confirmDisabled = reservation => {
  let disabled = false
  let message = ''

  if (reservation.status === 'Cancelled') {
    disabled = true
    message = 'Cancelled reservation'
  } else if (!reservation.travelers.length) {
    disabled = true
    message = 'You need to add at least one traveler'
  } else if (
    !reservation.customer ||
    Object.values(reservation.customer).some(v => v === null)
  ) {
    disabled = true
    message = 'You need to add a customer'
  } else if (!reservation.hotels.length && !reservation.charters.length) {
    disabled = true
    message = 'You need to add at least one hotel or one flight'
  }

  return { disabled, message }
}
