import { createAction, handleActions } from 'redux-actions'
import api from '../api'

const QUERY_WEEKDAYS = createAction('query destination addon weekdays')

const initialState = {
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_WEEKDAYS]: (state, action) => {
      return { ...state, items: action.payload }
    }
  },
  initialState
)

export const queryWeekdays = () => {
  return dispatch => {
    api.query('general/weekdays').then(res => dispatch(QUERY_WEEKDAYS(res.data)))
  }
}
