import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'
import moment from 'moment-timezone'

// Actions
const QUERY_PRICE_YIELDS = createAction('query price yields')
const QUERY_FILTERED_PRICE_YIELDS = createAction('query filtered price yields')
const QUERY_PRICE_YIELDS_AFTER_UPDATE = createAction(
  'query price yields after update'
)
export const QUERY_PRICE_YIELDS_HISTORY = createAction(
  'query price yield history'
)

const SET_ACTIVE_PRICE_YIELD = createAction('set active price yield')
const RESET_ACTIVE_PRICE_YIELD = createAction('reset active price yield')

const SET_ACTIVE_VALUE = createAction('set price yields value')
const RESET_ACTIVE_VALUE = createAction('reset price yields value')

const SET_LATEST_FILTER = createAction('price yields set latest filter')

export const SET_ACTIVE_HISTORY = createAction('set price yields history value')
const RESET_ACTIVE_HISTORY = createAction('reset price yields history value')

const LOADING = createAction('set yields loading')
const RELOADING = createAction('set yields reloading')
const CLEAR = createAction('clear all yields')

const initialState = {
  loading: false,
  active: null,
  activeHistory: null,
  items: [],
  alpInclusiveCosts: null,
  historyItems: [],
  markets: [],
  destinations: [],
  originAirports: [],
  transports: [],
  hotels: [],
  roomCategories: [],
  charterPeriods: [],
  from: moment(),
  to: moment(),
  noOfPrices: 0,
  latestFilter: '',
  reloading: false
}

// Reducers/handlers
export default handleActions(
  {
    [LOADING]: (state, action) => {
      return { ...state, loading: action.payload }
    },
    [QUERY_PRICE_YIELDS]: (state, action) => {
      return {
        ...state,
        items: action.payload.prices,
        markets: action.payload.markets,
        destinations: action.payload.destinations,
        originAirports: action.payload.originAirports,
        transports: action.payload.transports,
        hotels: action.payload.hotels,
        roomCategories: action.payload.roomCategories,
        charterPeriods: action.payload.charterPeriods,
        noOfPrices: action.payload.noOfPrices
      }
    },
    [QUERY_FILTERED_PRICE_YIELDS]: (state, action) => {
      let filter = action.payload.filter
      return {
        ...state,
        items: action.payload.item.prices,
        noOfPrices: action.payload.item.noOfPrices,
        markets:
          filter.searchMarkets && filter.searchMarkets.length === 0
            ? action.payload.item.markets
            : state.markets,
        destinations:
          filter.searchDestinations && filter.searchDestinations.length === 0
            ? action.payload.item.destinations
            : state.destinations,
        originAirports:
          filter.searchOriginAirports &&
          filter.searchOriginAirports.length === 0
            ? action.payload.item.originAirports
            : state.originAirports,
        transports:
          filter.searchTransports && filter.searchTransports.length === 0
            ? action.payload.item.transports
            : state.transports,
        hotels:
          filter.searchHotels && filter.searchHotels.length === 0
            ? action.payload.item.hotels
            : state.hotels,
        roomCategories:
          filter.searchRoomCategories &&
          filter.searchRoomCategories.length === 0
            ? action.payload.item.roomCategories
            : state.roomCategories,
        charterPeriods:
          filter.searchCharterPeriods &&
          filter.searchCharterPeriods.length === 0
            ? action.payload.item.charterPeriods
            : state.charterPeriods
      }
    },
    [QUERY_PRICE_YIELDS_AFTER_UPDATE]: (state, action) => {
      return {
        ...state,
        items: action.payload.prices,
        noOfPrices: action.payload.noOfPrices
      }
    },
    [QUERY_PRICE_YIELDS_HISTORY]: (state, action) => {
      return {
        ...state,
        historyItems: action.payload.history,
        alpInclusiveCosts: action.payload.alpInclusiveCosts
      }
    },
    [SET_ACTIVE_PRICE_YIELD]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_PRICE_YIELD]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    },
    [RESET_ACTIVE_VALUE]: (state, action) => {
      return { ...state, active: {} }
    },
    [SET_ACTIVE_HISTORY]: (state, action) => {
      return { ...state, activeHistory: action.payload }
    },
    [RESET_ACTIVE_HISTORY]: (state, action) => {
      return { ...state, activeHistory: null }
    },
    [SET_LATEST_FILTER]: (state, action) => {
      return { ...state, latestFilter: action.payload }
    },
    [CLEAR]: (state, action) => {
      return { ...state, items: [], noOfPrices: 0 }
    },
    [RELOADING]: (state, action) => {
      return { ...state, reloading: action.payload }
    }
  },
  initialState
)

// Action creators
export const queryYieldsByFilters = items => {
  return dispatch => {
    dispatch(LOADING(true))
    api.query('yield/yields/filteredPriceYields', items).then(json => {
      dispatch(QUERY_FILTERED_PRICE_YIELDS({ item: json.data, filter: items }))
      dispatch(LOADING(false))
    })
  }
}

export const queryYieldsAfterUpdate = items => {
  return dispatch => {
    dispatch(LOADING(true))
    api.query('yield/yields/filteredPriceYields', items).then(json => {
      dispatch(QUERY_PRICE_YIELDS_AFTER_UPDATE(json.data))
      dispatch(LOADING(false))
    })
  }
}

export const queryHistory = id => {
  return dispatch => {
    return api.query('yield/yields/priceYieldHistory', { id }).then(json => {
      dispatch(QUERY_PRICE_YIELDS_HISTORY(json.data))
    })
  }
}

export const submitSetYield = item => {
  return dispatch => {
    let cmd = api.sendCommand('yield/yields/setYieldAmount', item)
    cmd.then(() => {
      dispatch(
        queryYieldsAfterUpdate({ from: item.from, to: item.to, ...item.filter })
      )
      dispatch(resetActiveYield())
      dispatch(globalMessageActions.setGlobalSuccess('Yield updated'))
    })
    return cmd
  }
}

export const loadData = filter => {
  return dispatch => {
    dispatch(CLEAR())
    dispatch(RELOADING(true))
    return api
      .sendCommand(
        'yield/yields/loadYieldBaseInfo',
        {
          from: moment(filter.from),
          to: moment(filter.to)
        },
        {}
      )
      .then(() => {
        dispatch(queryYieldsByFilters(filter))
        dispatch(RELOADING(false))
        dispatch(globalMessageActions.setGlobalSuccess('Yields loaded'))
      })
  }
}

export const resetActiveYield = item => {
  return dispatch => {
    dispatch(RESET_ACTIVE_PRICE_YIELD(item))
  }
}

export const setActiveYield = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_PRICE_YIELD(item))
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ value, name }))
  }
}

export const setActiveHistory = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_HISTORY(item))
  }
}

export const resetActiveHistory = item => {
  return dispatch => {
    dispatch(RESET_ACTIVE_HISTORY())
  }
}

export const clear = () => {
  return dispatch => {
    dispatch(CLEAR())
  }
}
