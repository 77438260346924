import React, { Component } from 'react'

const GlobalMessageContext = React.createContext()

export class GlobalMessageProvider extends Component {
  state = { message: null }

  setMessage = message => {
    this.setState({ ...this.state, message: message })
  }

  render () {
    return (
      <GlobalMessageContext.Provider
        value={{
          state: this.state,
          setMessage: this.setMessage
        }}
      >
        {this.props.children}
      </GlobalMessageContext.Provider>
    )
  }
}

export const GlobalMessageConsumer = GlobalMessageContext.Consumer
export default GlobalMessageContext
