import { useState, useEffect } from 'react'
import config from '../config'
import moment from 'moment-timezone'
import AuthService from '../common/auth-service'
import * as globalMessageActions from './global-message'

const iterate = obj => {
  Object.keys(obj).forEach(property => {
    const value = obj[property]
    if (moment.isDate(value)) {
      throw new Error(
        'Date objects not allowed since they lack timezone; use moment objects or strings with timezone'
      )
    } else if (moment.isMoment(value)) {
      obj[property] = value.format()
    } else if (value instanceof Object) {
      iterate(value)
    }
  })
}

const showError = message => {
  import('./index').then(store => {
    store.default.dispatch(globalMessageActions.setGlobalError(message))
  })
}

const checkStatus = (response, resolve, reject) => {
  if (response.status >= 200 && response.status < 300) {
    response
      .json()
      .then(json => resolve(json))
      .catch(() => resolve(response))
  } else if (response.status === 401) {
    if (AuthService.isTokenExpired()) {
      AuthService.clearToken()
      window.location = '/login?expired'
    }
  } else {
    response
      .json()
      .then(json => {
        let error = new Error(json.message || json.error.message)
        showError(error.message)
        reject(error)
      })
      .catch(() => {
        let error = new Error(response.statusText)
        showError(error.message)
        reject(error)
      })
  }
}

const getHeaders = () => {
  let authToken = AuthService.getToken()
  let headers = {
    accept: 'application/json',
    'content-type': 'application/json',
    'X-Origin-App': 'backOffice'
  }
  if (authToken) {
    headers.authorization = `Bearer ${authToken}`
  }
  return headers
}

const formatCommand = command => {
  let obj = { ...command }
  iterate(obj)
  return obj
}

const commandQueue = []

const execNext = () => {
  let task = commandQueue[0]

  fetch(`${config.apiUrl}/command/${task.commandPath}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(task.command)
  })
    .then(response => {
      commandQueue.shift()
      checkStatus(response, task.resolve, task.reject)
      if (commandQueue.length > 0) execNext()
    })
    .catch(error => {
      commandQueue.shift()
      task.reject(error.message)
      if (commandQueue.length > 0) execNext()
    })
}

const Api = {
  query: (queryPath, queryArguments = null) => {
    return new Promise((resolve, reject) => {
      let url = `${window.location.origin}${config.apiUrl}/query/${queryPath}`
      let queryParams = new URLSearchParams()

      if (queryArguments) {
        Object.keys(queryArguments).forEach(key => {
          let value = queryArguments[key]
          if (value != null) {
            if (moment.isMoment(value)) {
              value = value.format()
            }
            if (value instanceof Array) {
              value.forEach((arrayValue, i) => {
                if (arrayValue instanceof Object) {
                  for (const aKey in arrayValue) {
                    if (arrayValue.hasOwnProperty(aKey)) {
                      if (arrayValue[aKey] instanceof Array) {
                        arrayValue[aKey].forEach((arrayValue2, j) => {
                          for (const aKey2 in arrayValue2) {
                            if (arrayValue2[aKey2]) {
                              queryParams.append(
                                `${key}[${i}].${aKey}[${j}].${aKey2}`,
                                arrayValue2[aKey2]
                              )
                            }
                          }
                        })
                      } else {
                        queryParams.append(
                          `${key}[${i}].${aKey}`,
                          arrayValue[aKey]
                        )
                      }
                    }
                  }
                } else {
                  queryParams.append(`${key}[${i}]`, arrayValue)
                }
              })
            } else {
              queryParams.append(key, value)
            }
          }
        })
        url += '?' + queryParams.toString()
      }

      fetch(url, { headers: getHeaders() })
        .then(response => checkStatus(response, resolve, reject))
        .catch(error => {
          reject(error.message)
          showError('Error: Server not responding')
        })
    })
  },

  sendCommand: (commandPath, command) => {
    return new Promise((resolve, reject) => {
      command = formatCommand(command)
      commandQueue.push({ commandPath, command, resolve, reject })
      if (commandQueue.length === 1) execNext()
    })
  },

  login: command => {
    return new Promise((resolve, reject) => {
      fetch(`${config.apiUrl}/command/loginbackofficeuser`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(command)
      })
        .then(response => {
          checkStatus(
            response,
            o => {
              let token = response.headers.get('Authorization') ?? response.headers.get('X-Amzn-Remapped-Authorization')
              if (token !== undefined && token !== null && token.length > 0) {
                localStorage.setItem('token', token.substring(7))
              }
              resolve(o)
            },
            reject
          )
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },

  sendCommandUpload: (commandName, files, controller) => {
    var data = new FormData()
    for (var f in files) {
      data.append(f, files[f])
    }

    return new Promise((resolve, reject) =>
      fetch(
        `${config.apiUrl}/command/${
          controller !== '' ? controller + '/' : ''
        }${commandName}`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json'
          },
          body: data
        }
      )
        .then(response => checkStatus(response, resolve, reject))
        .catch(error => {
          reject(error.message)
          showError('Error: Server not responding')
        })
    )
  },

  href: (queryName, queryArguments) => {
    let apiUrl =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000/v1'
        : config.apiUrl
    return `${apiUrl}/query/${queryName}?${queryArguments}`
  }
}

export function useQuery ({ path, initParams = null, manual = false }) {
  const [state, setState] = useState({
    data: null,
    error: undefined,
    loading: false
  })

  const execQuery = (queryParams = initParams) => {
    setState({ ...state, loading: true })
    return Api.query(path, queryParams)
      .then(res => {
        setState({ error: undefined, data: res.data, loading: false })
      })
      .catch(error => setState({ error, loading: false, data: null }))
  }

  useEffect(() => {
    !manual && execQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [state, execQuery]
}

export function useCommand (path) {
  const [state, setState] = useState({
    done: false,
    error: undefined,
    loading: false
  })

  const sendCommand = (command, onResolved = null, propagateError = false) => {
    setState({ ...state, loading: true, done: false })
    return Api.sendCommand(path, command)
      .then(() => {
        setState({ ...state, loading: false, done: true })
        if (onResolved) {
          onResolved()
        }
      })
      .catch(error => {
        setState({ error, loading: false, done: false })
        if (propagateError) {
          throw error
        }
      })
  }

  return [state, sendCommand]
}

export default Api
