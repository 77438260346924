import { createAction, handleActions } from 'redux-actions'
import api from '../../api'

const QUERY_ARTICLES = createAction('query articles')
const SET_ACTIVE_ARTICLE = createAction('set active addon article')
const SET_VALUE = createAction('set active addon articlevalue')

const initialState = {
  items: [],
  active: null
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_ARTICLES]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE_ARTICLE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_VALUE]: (state, action) => {
      return { ...state, active: { ...state.active, [action.payload.name]: action.payload.value } }
    }
  },
  initialState
)

export const queryArticles = (dateFrom, dateTo, addOnType, destinationId) => {
  return dispatch => {
    return api.query('destination/addonContract/articles', { dateFrom, dateTo, addOnType, destinationIds: [destinationId] }).then(res => {
      dispatch(QUERY_ARTICLES(res.data))
      return res.data
    })
  }
}

export const setActiveArticle = article => {
  return dispatch => {
    dispatch(SET_ACTIVE_ARTICLE(article))
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
