import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import moment from 'moment-timezone'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'
import { ContractTypeEnum } from '../../../common/enums'

// Actions
const SET_PRICES = createAction('set contract prices')

const RESET_ACTIVE_PRICE = createAction('cancel active contract price')
const SET_VALUE = createAction('set active contract price value')
const SET_ACTIVE_PRICE = createAction('set active contract price')

const RESET_ACTIVE_DISCOUNT = createAction('cancel active discount')
const SET_DISCOUNT_VALUE = createAction('set active discount value')
const SET_ACTIVE_DISCOUNT = createAction('set active discount')

const NEXT_PRICE = createAction('go to next price')

const initialState = {
  active: null,
  activeDiscount: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [SET_PRICES]: (state, action) => {
      return { ...state, loading: false, items: action.payload.roomCategories }
    },
    [SET_ACTIVE_PRICE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_PRICE]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [SET_ACTIVE_DISCOUNT]: (state, action) => {
      return { ...state, activeDiscount: action.payload }
    },
    [RESET_ACTIVE_DISCOUNT]: (state, action) => {
      return { ...state, activeDiscount: null }
    },
    [SET_DISCOUNT_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let activeDiscount = state.activeDiscount
      activeDiscount.discount = action.payload.value
      return { ...state, activeDiscount: activeDiscount }
    },
    [NEXT_PRICE]: (state, action) => {
      let nextPrice = null
      if (action.payload.isNew) {
        nextPrice = {
          isNew: true,
          startDate: moment(action.payload.endDate),
          endDate: moment(action.payload.endDate).add(14, 'days'),
          contractType: action.payload.contractType,
          priceUnit: action.payload.priceUnit,
          contractId: action.payload.contractId,
          roomCategoryId: action.payload.roomCategoryId
        }
      } else {
        let selectedRoom = state.items.find(i => i.id === action.payload.roomCategoryId)
        let currentPricesPosition = selectedRoom.prices.findIndex(a => a.id === action.payload.id)
        nextPrice = selectedRoom.prices[currentPricesPosition + 1]
      }

      if (nextPrice) {
        return { ...state, active: nextPrice }
      } else return { ...state, active: null }
    }
  },
  initialState
)

export const setPrices = items => {
  return (dispatch, getState) => {
    dispatch(SET_PRICES(items))
  }
}

export const addPrice = (item, actionAfterSave) => {
  return (dispatch, getState) => {
    let url = ''
    if (item.contractType === ContractTypeEnum.notAvailable) {
      url = 'hotel/contract/addPriceNotAvailable'
    } else {
      url = 'hotel/contract/addPrice'
    }

    return api.sendCommand(url, item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))

      let active = getState().contract.hotel.prices.active
      if (actionAfterSave.moveNext) {
        dispatch(RESET_ACTIVE_PRICE()) // Make a reset to make autofocus run. Couldn't get focus() to work...
        dispatch(NEXT_PRICE(active))
      } else {
        dispatch(RESET_ACTIVE_PRICE())
      }
      dispatch(globalMessageActions.setGlobalSuccess('Price added'))
    })
  }
}

export const updatePrice = (item, actionAfterSave) => {
  return (dispatch, getState) => {
    let url = ''
    if (item.contractType === ContractTypeEnum.notAvailable) {
      url = 'hotel/contract/updatePriceNotAvailable'
    } else {
      url = 'hotel/contract/updatePrice'
    }

    return api.sendCommand(url, item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(globalMessageActions.setGlobalSuccess('Price updated'))

      let active = getState().contract.hotel.prices.active
      if (actionAfterSave.moveNext) {
        dispatch(RESET_ACTIVE_PRICE()) // Make a reset to make autofocus run. Couldn't get focus() to work...
        dispatch(NEXT_PRICE(active))
      } else {
        dispatch(RESET_ACTIVE_PRICE())
      }
    })
  }
}

export const deletePrice = item => {
  return (dispatch, getState) => {
    let url = ''
    if (item.contractType === ContractTypeEnum.notAvailable) {
      url = 'hotel/contract/deletePriceNotAvailable'
    } else {
      url = 'hotel/contract/deletePrice'
    }

    return api.sendCommand(url, { id: item.id, contractId: item.contractId }).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_PRICE())
      dispatch(globalMessageActions.setGlobalSuccess('Price deleted'))
    })
  }
}

export const copyPeriods = (contractId, roomCategoryId, nextRoomCategoryId) => {
  return dispatch => {
    return api
      .sendCommand('hotel/contract/copyPricePeriods', {
        contractId: contractId,
        fromRoomCategoryId: roomCategoryId,
        toRoomCategoryId: nextRoomCategoryId
      })
      .then(() => {
        dispatch(contractActions.getHotelContract(contractId))
        dispatch(RESET_ACTIVE_PRICE())
        dispatch(globalMessageActions.setGlobalSuccess('Price periods copied'))
      })
  }
}

export const updateDiscount = item => {
  return (dispatch, getState) => {
    return api.sendCommand('hotel/contract/setPriceDiscount', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_DISCOUNT())
      dispatch(globalMessageActions.setGlobalSuccess('Discount updated'))
    })
  }
}

export const setActivePrice = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_PRICE(item))
  }
}

export const resetActivePrice = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_PRICE())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const setActiveDiscount = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_DISCOUNT(item))
  }
}

export const resetActiveDiscount = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_DISCOUNT())
  }
}

export const setDiscountValue = (value, name) => {
  return dispatch => {
    dispatch(SET_DISCOUNT_VALUE({ value, name }))
  }
}
