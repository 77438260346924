import { combineReducers } from 'redux'
import { createAction } from 'redux-actions'
import airports from './airports'
import alternativeFlights from './alternative-flights'
import cancellationInsurances from './cancellation-insurances'
import charterPeriods from './charter-periods'
import charterTypes from './charter-types'
import customers from './customers'
import customerCategories from './customer-categories'
import destinations from './destinations'
import destinationAddons from './destination-addons'
import dueDatesFees from './due-dates-fees'
import flightMeals from './flight-meals'
import halfboard from './halfboard'
import hotels from './hotels'
import gdpr from './gdpr'
import proposals from './proposals'
import tabs from './tabs'
import markets from './markets'
import notes from './notes'
import offerCodes from './offer-codes'
import payment from './payment'
import reservations from './reservations'
import searchFilter from './search-filter'
import skiluggage from './skiluggages'
import ssrCodes from './ssr-codes'
import submenu from './submenu'
import transfers from './transfers'
import travelers from './travelers'
import travelInsurances from './travel-insurances'
import fullRefundInsurances from './full-refund-insurances'

const bookingReducer = combineReducers({
  airports,
  alternativeFlights,
  cancellationInsurances,
  destinations,
  destinationAddons,
  dueDatesFees,
  charterPeriods,
  charterTypes,
  customers,
  customerCategories,
  flightMeals,
  halfboard,
  hotels,
  markets,
  notes,
  offerCodes,
  payment,
  proposals,
  reservations,
  tabs,
  gdpr,
  searchFilter,
  skiluggage,
  ssrCodes,
  submenu,
  transfers,
  travelers,
  travelInsurances,
  fullRefundInsurances
})

const initialState = bookingReducer({}, {})

const booking = (state, action) => {
  if (action.type === 'resetbooking') {
    // reset all but tabs and reservation searchFilter
    state = {
      ...initialState,
      tabs: state.tabs,
      searchFilter: state.searchFilter,
      reservations: {
        reservation: null,
        reservations: [],
        totalReservations: 0,
        filters: state.reservations.filters
      }
    }
  }

  return bookingReducer(state, action)
}

const RESET_BOOKING = createAction('resetbooking')

export const resetBooking = () => {
  return dispatch => {
    dispatch(RESET_BOOKING())
  }
}

export default booking
