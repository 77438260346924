import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_AIRPORTS = createAction('management/query airports')
const QUERY_AIRPORTS_SUCCESS = createAction('management/query airports success')

const RESET_ACTIVE_AIRPORT = createAction('management/reset active airport')
const SET_ACTIVE_AIRPORT = createAction('management/set active airport')
const SET_ACTIVE_VALUE = createAction('management/set active airport value')

const initialState = {
  active: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_AIRPORTS]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [QUERY_AIRPORTS_SUCCESS]: (state, action) => {
      action.payload.forEach(a => {
        if (a.originAirport) a.type = 'Origin'
        else if (a.destinationAirport) a.type = 'Destination'
      })
      return { ...state, loading: false, items: action.payload }
    },
    [SET_ACTIVE_AIRPORT]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    },
    [RESET_ACTIVE_AIRPORT]: (state, action) => {
      return { ...state, active: null }
    }
  },
  initialState
)

// Action creators
export const queryAirports = (filterDisabled = true, ignoreCache = false) => {
  return dispatch => {
    dispatch(QUERY_AIRPORTS())
    api
      .query('general/airports', {
        filterDisabled: filterDisabled,
        ignoreCache
      })
      .then(json => {
        dispatch(QUERY_AIRPORTS_SUCCESS(json.data))
      })
  }
}

export const submitAddAirport = airport => {
  return dispatch => {
    return api.sendCommand('general/addAirport', airport).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Airport added'))
      dispatch(queryAirports())
    })
  }
}

export const submitUpdateAirport = airport => {
  return dispatch => {
    return api.sendCommand('general/updateAirport', airport).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Airport updated'))
      dispatch(queryAirports())
    })
  }
}

export const setActiveAirport = airport => {
  return dispatch => {
    dispatch(SET_ACTIVE_AIRPORT(airport))
  }
}

export const resetActiveAirport = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_AIRPORT())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ value, name }))
  }
}
