// hotel
import { combineReducers } from 'redux'
import hotel from './hotel'
import destination from './destination'
import flight from './flight'
import moveReservations from './move-reservations'

export default combineReducers({
  flight: flight,
  hotel: hotel,
  destination: destination,
  moveReservations: moveReservations
})
