// hotel
import { combineReducers } from 'redux'
import flight from './flight'
import hotel from './hotel'
import destinationAddon from './destination-addon'
import transfer from './transfer'

export default combineReducers({
  destinationAddon,
  flight: flight,
  hotel: hotel,
  transfer
})
