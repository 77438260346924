import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as reservationActions from './reservations'

// actions
const QUERY_TRAVEL_INSURANCE = createAction('query booking travel insurance')
const QUERY_TRAVEL_INSURANCE_SUCCESS = createAction('query booking travel insurance success')
const SET_TRAVEL_INSURANCE = createAction('set booking travel insurance')

const initialState = {
  error: null,
  loading: false,
  items: []
}

// handlers
export default handleActions(
  {
    [QUERY_TRAVEL_INSURANCE]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [QUERY_TRAVEL_INSURANCE_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_TRAVEL_INSURANCE]: (state, action) => {
      return {
        ...state,
        items: state.items.map(
          (traveler, j) =>
            (traveler.id === action.payload.travelerId
              ? {
                ...traveler,
                travelInsurances: traveler.travelInsurances.map(
                    (travelInsurance, k) =>
                      (travelInsurance.id === action.payload.inventoryId
                        ? { ...travelInsurance, reserved: action.payload.reserved }
                        : travelInsurance)
                  )
              }
              : traveler)
        )
      }
    }
  },
  initialState
)

// thunks
export const queryTravelInsurance = reservationId => {
  return dispatch => {
    api
      .query('travelbooking/travelInsurance', { reservationId })
      .then(res => dispatch(QUERY_TRAVEL_INSURANCE_SUCCESS(res.data)))
  }
}

export const submitAddTravelInsurance = command => {
  return dispatch => {
    dispatch(SET_TRAVEL_INSURANCE({ ...command, reserved: true }))
    let cmd = api
      .sendCommand('travelBooking/selectTravelInsurance', command)
      .then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
    return cmd
  }
}

export const submitRemoveTravelInsurance = command => {
  return dispatch => {
    dispatch(SET_TRAVEL_INSURANCE({ ...command, reserved: false }))
    let cmd = api
      .sendCommand('travelBooking/removeTravelInsurance', command)
      .then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
    return cmd
  }
}
