import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'

// Actions
const SET_DISCOUNTS = createAction('set contract discounts')
const RESET_ACTIVE_DISCOUNT = createAction('cancel active discount')
const SET_VALUE = createAction('update active discount')
const SET_ACTIVE_DISCOUNT = createAction('edit active discount')
const SET_ROOM_CATEGORY = createAction('set discount room category')
const SET_EXTRABED_VALUE = createAction('set extrabed value')

const initialState = {
  active: null,
  copy: null,
  loading: false,
  hotelItems: [],
  roomItems: []
}

// Reducers/handlers
export default handleActions(
  {
    [SET_DISCOUNTS]: (state, action) => {
      return {
        ...state,
        loading: false,
        hotelItems: action.payload.hotelDiscounts,
        roomItems: action.payload.roomCategories
      }
    },
    [SET_ACTIVE_DISCOUNT]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_DISCOUNT]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [SET_ROOM_CATEGORY]: (state, action) => {
      let active = state.active
      active.roomCategoryId = action.payload.value
      active.extraBeds = action.payload.extraBeds
      return { ...state, active: active }
    },
    [SET_EXTRABED_VALUE]: (state, action) => {
      let active = state.active
      let extraBed = active.extraBeds[action.payload.number]
      extraBed[action.payload.name] = action.payload.value
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators
export const setDiscounts = items => {
  return dispatch => {
    dispatch(SET_DISCOUNTS(items))
  }
}

export const addDiscount = item => {
  return dispatch => {
    return api.sendCommand('hotel/contract/addDiscount', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_DISCOUNT())
      dispatch(globalMessageActions.setGlobalSuccess('Discount added'))
    })
  }
}

export const updateDiscount = item => {
  return (dispatch, getState) => {
    return api.sendCommand('hotel/contract/updateDiscount', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_DISCOUNT())
      dispatch(globalMessageActions.setGlobalSuccess('Discount updated'))
    })
  }
}

export const deleteDiscount = item => {
  return (dispatch, getState) => {
    return api
      .sendCommand('hotel/contract/deleteDiscount', { id: item.id, contractId: item.contractId })
      .then(() => {
        dispatch(contractActions.getHotelContract(item.contractId))
        dispatch(RESET_ACTIVE_DISCOUNT())
        dispatch(globalMessageActions.setGlobalSuccess('Discount deleted'))
      })
  }
}

export const copyDiscount = (contractId, from, to) => {
  return dispatch => {
    return api
      .sendCommand('hotel/contract/copyDiscount', { contractId, fromRoomCategoryId: from, toRoomCategoryId: to })
      .then(() => {
        dispatch(contractActions.getHotelContract(contractId))
        dispatch(globalMessageActions.setGlobalSuccess('Discount copied'))
      })
  }
}

export const setActiveDiscount = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_DISCOUNT(item))
  }
}

export const resetActiveDiscount = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_DISCOUNT())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const setRoomCategory = (value, extraBeds) => {
  return dispatch => {
    dispatch(SET_ROOM_CATEGORY({ value, extraBeds }))
  }
}

export const setExtraBedValue = (value, name, number) => {
  return dispatch => {
    dispatch(SET_EXTRABED_VALUE({ value, name, number }))
  }
}
