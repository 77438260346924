import asyncComponent from './common/async-component'
import ResetPasswordPage from './account/pages/reset-password-page'
import LoginPage from './account/pages/login-page'

const AsyncStart = asyncComponent(() =>
  import(/* webpackChunkName: "start" */ './start')
)
const AsyncSetup = asyncComponent(() =>
  import(/* webpackChunkName: "setup" */ './setup')
)
const AsyncProduction = asyncComponent(() =>
  import(/* webpackChunkName: "production" */ './production')
)
const AsyncEconomy = asyncComponent(() =>
  import(/* webpackChunkName: "economy" */ './economy')
)
const AsyncRevenue = asyncComponent(() =>
  import(/* webpackChunkName: "revenue" */ './revenue')
)
const AsyncBooking = asyncComponent(() =>
  import(/* webpackChunkName: "booking" */ './booking')
)
const AsyncGuide = asyncComponent(() =>
  import(/* webpackChunkName: "guide" */ './guide')
)
const AsyncAccount = asyncComponent(() =>
  import(/* webpackChunkName: "account" */ './account')
)
const AsyncErrorTest = asyncComponent(() =>
  import(/* webpackChunkName: "errortest" */ './error-test')
)

const anyPolicy = [
  'Anonymous',
  'Setup',
  'Booking',
  'Production',
  'Revenue',
  'Economy',
  'Accounts',
  'BackOffice',
  'Guide'
]
const setupPolicy = ['Setup', 'BackOffice']
const bookingPolicy = ['Booking', 'BackOffice']
const productionPolicy = ['Production', 'BackOffice']
const revenuePolicy = ['Revenue', 'BackOffice']
const economyPolicy = ['Economy', 'BackOffice']
const accountPolicy = ['Accounts', 'BackOffice']
const guidePolicy = ['Guide', 'BackOffice']

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Start',
    component: AsyncStart,
    hide: true,
    allowedRoles: anyPolicy
  },
  {
    path: '/setup',
    name: 'Setup',
    component: AsyncSetup,
    icon: 'edit',
    allowedRoles: setupPolicy
  },
  {
    path: '/booking',
    name: 'Booking',
    component: AsyncBooking,
    icon: 'today',
    allowedRoles: bookingPolicy
  },
  {
    path: '/production',
    name: 'Production',
    component: AsyncProduction,
    icon: 'build',
    allowedRoles: productionPolicy
  },
  {
    path: '/revenue',
    name: 'Revenue',
    component: AsyncRevenue,
    icon: 'trending_up',
    allowedRoles: revenuePolicy
  },
  {
    path: '/economy',
    name: 'Economy',
    component: AsyncEconomy,
    icon: 'attach_money',
    allowedRoles: economyPolicy
  },
  {
    path: '/guide',
    name: 'Guide',
    component: AsyncGuide,
    icon: 'emoji_people',
    allowedRoles: guidePolicy
  },
  {
    path: '/account',
    name: 'Accounts',
    component: AsyncAccount,
    icon: 'account_circle',
    allowedRoles: accountPolicy
  },
  {
    path: '/error-test',
    exact: true,
    name: 'Error Test',
    component: AsyncErrorTest,
    icon: 'description',
    hide: true,
    allowedRoles: anyPolicy
  }
]
export const publicAccountRoutes = [
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPasswordPage
  },
  { path: '/login', name: 'Login', component: LoginPage }
]

export default routes
