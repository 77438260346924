import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Snackbar from '@material-ui/core/Snackbar'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Row } from 'jsxstyle'
import CircularProgress from '@material-ui/core/CircularProgress'
import CommonStyles from './styles'
import * as globalMessageActions from '../store/global-message'
import Icon from '@material-ui/core/Icon'
import { GlobalMessageConsumer } from '../common/global-message-context'

const mapStateToProps = state => ({
  state: state.globalMessage
})
const mapDispatchToProps = dispatch => ({
  globalMessageActions: bindActionCreators(globalMessageActions, dispatch)
})

class GlobalMessageSnackbar extends Component {
  handleRequestClose = () => {
    this.props.globalMessageActions.resetMessage()
  }

  render () {
    return ReactDOM.createPortal(
      <GlobalMessageConsumer>
        {({ state, setMessage }) => {
          if ((!this.props.state || !this.props.state.message) && !state.message) {
            return null
          }

          let icon =
            this.props.state && this.props.state.status ? this.props.state.status.replace('success', 'done') : 'done'

          return (
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={this.props.state.message !== null || state.message !== null}
              autoHideDuration={icon === 'progress' ? null : 3000}
              onClose={() => {
                this.handleRequestClose()
                setMessage(null)
              }}
              message={
                <div>
                  <Row alignItems='center'>
                    {icon === 'progress' ? (
                      <CircularProgress />
                    ) : (
                      <Icon className={this.props.classes.marginRight}>{icon}</Icon>
                    )}
                    <span>{this.props.state.message || state.message}</span>
                  </Row>
                </div>
              }
              ContentProps={{
                classes: {
                  root:
                    this.props.state.status === 'success'
                      ? this.props.classes.globalSuccess
                      : this.props.state.status === 'error'
                        ? this.props.classes.globalError
                        : this.props.state.status === 'warning'
                          ? this.props.classes.globalWarning
                          : ''
                }
              }}
            />
          )
        }}
      </GlobalMessageConsumer>,
      document.body
    )
  }
}

GlobalMessageSnackbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalMessageSnackbar)

export default withStyles(CommonStyles)(GlobalMessageSnackbar)
