import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_SEASON = createAction('query market seasons')
const SET_ACTIVE = createAction('set active market season')
const SET_ACTIVE_VALUE = createAction('set active market season value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_SEASON]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    }
  },
  initialState
)

// Action creators
export const querySeasons = (filterDisabled = true, ignoreCache = false) => {
  return dispatch => {
    let query = api.query('market/season/seasons', {
      filterDisabled: filterDisabled,
      ignoreCache: ignoreCache
    })
    query.then(json => dispatch(QUERY_SEASON(json.data)))
    return query
  }
}

export const setActive = season => {
  return dispatch => {
    dispatch(SET_ACTIVE(season))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}

export const submitCreateSeason = command => {
  const ignoreCache = true
  const filterDisabled = false
  return dispatch => {
    let cmd = api.sendCommand('market/season/addSeason', command)
    cmd.then(() => {
      dispatch(querySeasons(filterDisabled, ignoreCache))
      dispatch(globalMessageActions.setGlobalSuccess('Season added'))
    })
    return cmd
  }
}

export const submitUpdateSeason = command => {
  const ignoreCache = true
  const filterDisabled = false
  return dispatch => {
    let cmd = api.sendCommand('market/season/updateSeason', command)
    cmd.then(() => {
      dispatch(querySeasons(filterDisabled, ignoreCache))
      dispatch(globalMessageActions.setGlobalSuccess('Season updated'))
    })
    return cmd
  }
}

export const submitDeleteSeason = command => {
  const ignoreCache = true
  const filterDisabled = false
  return dispatch => {
    let cmd = api.sendCommand('market/season/deleteSeason', command)
    cmd.then(() => {
      dispatch(querySeasons(filterDisabled, ignoreCache))
      dispatch(globalMessageActions.setGlobalSuccess('Season deleted'))
    })
    return cmd
  }
}
