import { createAction, handleActions } from 'redux-actions'
import api from '../api'
// import * as charterPeriodActions from './charter-periods'

const QUERY_DESTINATIONS = createAction('query destinations')

const initialState = {
  selectedDestinationId: [],
  data: []
}

// handlers
export default handleActions(
  {
    [QUERY_DESTINATIONS]: (state, action) => {
      return { ...state, data: action.payload }
    }
  },
  initialState
)

// thunks
export const queryDestinationsByAirport = (airportId, charterTypeId) => {
  return (dispatch, getState) => {
    const searchInfo = getState().booking.reservations.reservation.searchInfo

    if (searchInfo.travelType === 'Flight') {
      api.query('flightDeparture/charter/airportDestinations', { airportId, charterTypeId }).then(res => {
        dispatch(QUERY_DESTINATIONS(res.data))
      })
    } else {
      api.query('flightDeparture/charter/destinationsPerCountry', { airportId, charterTypeId }).then(res => {
        dispatch(QUERY_DESTINATIONS(res.data))
      })
    }
  }
}

export const queryDestinationsByCharterType = charterTypeId => {
  return dispatch => {
    api.query('flightDeparture/charter/destinationsPerCountry', { charterTypeId }).then(res => {
      dispatch(QUERY_DESTINATIONS(res.data))
    })
  }
}
