import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_FULL_REFUND_INSURANCES = createAction('query market full refund insurances')
const SET_ACTIVE = createAction('set active full refund insurance')
const SET_ACTIVE_VALUE = createAction('set active full refund insurance value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_FULL_REFUND_INSURANCES]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return { ...state, active: { ...state.active, [action.payload.name]: action.payload.value } }
    }
  },
  initialState
)

// Action creators
export const queryFullRefundInsurances = marketId => {
  return dispatch => {
    api
      .query('market/fullRefundInsurance/fullRefundInsurances', { marketId })
      .then(json => dispatch(QUERY_FULL_REFUND_INSURANCES(json.data)))
  }
}

export const submitCreateFullRefundInsurance = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/fullRefundInsurance/createFullRefundInsurance', command)
    cmd.then(() => {
      dispatch(queryFullRefundInsurances(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Full refund insurance added'))
    })
    return cmd
  }
}

export const submitUpdateFullRefundInsurance = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/fullRefundInsurance/updateFullRefundInsurance', command)
    cmd.then(() => {
      dispatch(queryFullRefundInsurances(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Full refund insurance updated'))
    })
    return cmd
  }
}

export const submitDeleteFullRefundInsurance = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/fullRefundInsurance/deleteFullRefundInsurance', command)
    cmd.then(() => {
      dispatch(queryFullRefundInsurances(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Full refund insurance deleted'))
    })
    return cmd
  }
}

export const setActive = fullRefundInsurance => {
  return dispatch => {
    dispatch(SET_ACTIVE(fullRefundInsurance))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}
