import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'

// Actions
const FETCH_HOTEL_CONTRACTS = createAction('get hotel contracts from server')
const FETCH_HOTEL_CONTRACTS_SUCCESS = createAction('fetch hotel contracts success')

const ADD_HOTEL_CONTRACT = createAction('add hotel contract')
const ADD_HOTEL_CONTRACT_SUCCESS = createAction('add hotel contract success')

const RESET_ACTIVE_HOTEL_CONTRACT = createAction('cancel active hotel contract')
const UPDATE_ACTIVE_HOTEL_CONTRACT = createAction('update active hotel contract')
const EDIT_ACTIVE_HOTEL_CONTRACT = createAction('edit active hotel contract')

const SET_FILTER = createAction('set hotel contract filter')
const SET_SORT = createAction('set hotel contract sort')
const SEASON_SELECTED = createAction('season selected')

const initialState = {
  selectedSeasonId: 0,
  active: null,
  loading: false,
  items: [],
  filter: {},
  sort: { orderBy: 'id', direction: 'asc' }
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_HOTEL_CONTRACTS]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [FETCH_HOTEL_CONTRACTS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [ADD_HOTEL_CONTRACT]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [ADD_HOTEL_CONTRACT_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [EDIT_ACTIVE_HOTEL_CONTRACT]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_HOTEL_CONTRACT]: (state, action) => {
      return { ...state, active: null }
    },
    [UPDATE_ACTIVE_HOTEL_CONTRACT]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [SEASON_SELECTED]: (state, action) => {
      return { ...state, selectedSeasonId: action.payload }
    },
    [SET_FILTER]: (state, action) => {
      let filter = { ...state.filter }
      if (action.payload.value) filter[action.payload.filterType] = action.payload.value
      else delete filter[action.payload.filterType]

      return { ...state, filter: filter }
    },
    [SET_SORT]: (state, action) => {
      return {
        ...state,
        sort: { orderBy: action.payload, direction: state.sort.direction === 'asc' ? 'desc' : 'asc' }
      }
    }
  },
  initialState
)

// Action creators
export const getHotelContracts = () => {
  return (dispatch, getState) => {
    dispatch(FETCH_HOTEL_CONTRACTS())

    api
      .query('hotel/contract/contracts')
      .then(json => {
        dispatch(FETCH_HOTEL_CONTRACTS_SUCCESS(json.data))
      })
      .catch(errorMessage => {
        dispatch(globalMessageActions.setGlobalError(errorMessage))
      })
  }
}

export const seasonSelected = seasonId => {
  return dispatch => {
    dispatch(SEASON_SELECTED(seasonId))
  }
}

export const setFilter = (filterType, value) => {
  return dispatch => {
    dispatch(SET_FILTER({ filterType, value }))
  }
}

export const setSort = sortOrder => {
  return dispatch => {
    dispatch(SET_SORT(sortOrder))
  }
}
