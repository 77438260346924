import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as destinationActions from './destinations'
// import * as charterPeriodActions from './charter-periods'

const QUERY_AIRPORTS = createAction('query booking airports')

const SET_AIRPORT = createAction('set booking airport')
const SET_CHARTERTRIP = createAction('set booking charterTrip')

const initialState = {
  charterTrip: true,
  selectedAirportId: [],
  data: []
}

// handlers
export default handleActions(
  {
    [QUERY_AIRPORTS]: (state, action) => {
      return { ...state, data: action.payload }
    },
    [SET_AIRPORT]: (state, action) => {
      return { ...state, selectedAirportId: action.payload }
    },
    [SET_CHARTERTRIP]: (state, action) => {
      return { ...state, charterTrip: action.payload }
    }
  },
  initialState
)

// thunks
export const queryAirports = (marketId, charterTypeId) => {
  return dispatch => {
    api.query('market/airport/airports', { marketId, charterTypeId }).then(res => {
      dispatch(QUERY_AIRPORTS(res.data))
    })
  }
}

export const setCharterTrip = value => {
  return dispatch => {
    dispatch(SET_CHARTERTRIP(value))
  }
}

export const setAirport = airportId => {
  return (dispatch, getState) => {
    dispatch(SET_AIRPORT(airportId))
    dispatch(destinationActions.queryDestinationsByAirport(airportId[0]))
    // let destinationId = getState().booking.destinations.selectedDestinationId
    // if (airportId && destinationId) {
    //   dispatch(charterPeriodActions.queryCharterPeriods(airportId, destinationId))
    // }
  }
}
