import { createAction, handleActions } from 'redux-actions'
import api from '../api'
// import * as globalMessageActions from '../global-message'

// Actions
const FETCH_COUNTRIES = createAction('get countries from server')
const FETCH_COUNTRIES_SUCCESS = createAction('fetch countries success')
const SELECT_COUNTRY = createAction('select country')

const initialState = {
  active: null,
  loading: false,
  selectedCountryId: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_COUNTRIES]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [FETCH_COUNTRIES_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SELECT_COUNTRY]: (state, action) => {
      return { ...state, selectedCountryId: action.payload }
    }
  },
  initialState
)

// Action creators
export const getCountries = () => {
  return (dispatch) => {
    dispatch(FETCH_COUNTRIES())
    return api.query('general/countries').then(json => {
      dispatch(FETCH_COUNTRIES_SUCCESS(json.data))
      return json.data
    })
  }
}

export const selectCountry = selectedCountryId => {
  return dispatch => {
    dispatch(SELECT_COUNTRY(selectedCountryId))
  }
}
