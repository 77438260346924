import { combineReducers } from 'redux'
import priceYields from './price-yields'
import capacity from './capacity'

const revenue = combineReducers({
  priceYields,
  capacity
})

export default revenue
