import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

import * as globalMessageActions from '../global-message'
import * as upcomingReleasesActions from './upcoming-releases'

// Actions
const QUERY_RELEASES = createAction('query requests')
const QUERY_CUSTOMER_LIST = createAction('release query customer list')

const SET_DESTINATION_ID = createAction('release destination id')
const SET_HOTEL_ID = createAction('release hotel id')
const SET_FROM_DATE = createAction('release from date')
const SET_TO_DATE = createAction('release to date')

const TOGGLE_SELECTED_RELEASES = createAction('toggle selected releases')
const TOGGLE_ALL_RELEASES = createAction('toggle all releases')

const RESET_ACTIVE_CONFIRM_RELEASE = createAction('cancel active confirm release')
const SET_CONFIRM_RELEASE_VALUE = createAction('update active confirm release')
const EDIT_ACTIVE_CONFIRM_RELEASE = createAction('edit active confirm release')

const RESET_DATES = createAction('active price reset dates')
const SET_SEASON_FROM_DATE = createAction('active price season from date')
const SET_SEASON_TO_DATE = createAction('active price season to date')

const initialState = {
  activeRelease: null,
  activeConfirmRelease: null,
  selectedDestinationId: null,
  selectedHotelId: null,
  selectedFromDate: null,
  selectedToDate: null,
  selectedSeasonFromDate: moment(),
  selectedSeasonToDate: moment().add(3, 'weeks'),
  selectedReleases: [],
  loading: false,
  items: [],
  customerListItems: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_RELEASES]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [QUERY_CUSTOMER_LIST]: (state, action) => {
      return { ...state, customerListItems: action.payload }
    },
    [EDIT_ACTIVE_CONFIRM_RELEASE]: (state, action) => {
      return { ...state, activeConfirmRelease: action.payload }
    },
    [RESET_ACTIVE_CONFIRM_RELEASE]: (state, action) => {
      return { ...state, activeConfirmRelease: null }
    },
    [SET_CONFIRM_RELEASE_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.activeConfirmRelease
      active[action.payload.name] = action.payload.value
      return { ...state, activeConfirmRelease: active }
    },
    [SET_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload.item }
    },
    [SET_HOTEL_ID]: (state, action) => {
      return { ...state, selectedHotelId: action.payload.item }
    },
    [SET_FROM_DATE]: (state, action) => {
      return { ...state, selectedFromDate: moment(action.payload.item) }
    },
    [SET_TO_DATE]: (state, action) => {
      return { ...state, selectedToDate: moment(action.payload.item) }
    },
    [TOGGLE_SELECTED_RELEASES]: (state, action) => {
      let selectedReleases = state.selectedReleases

      if (selectedReleases.some(s => s === action.payload.item.id)) {
        selectedReleases.splice(selectedReleases.indexOf(action.payload.item.id), 1)
      } else {
        selectedReleases.push(action.payload.item.id)
      }

      return { ...state, selectedReleases }
    },
    [TOGGLE_ALL_RELEASES]: (state, action) => {
      let allChecked = []
      if (action.payload.checked) {
        state.items.forEach(r => allChecked.push(r.id))
      }

      return { ...state, selectedReleases: allChecked }
    },
    [RESET_DATES]: (state, action) => {
      return { ...state, selectedToDate: initialState.selectedToDate, selectedFromDate: initialState.selectedFromDate }
    },
    [SET_SEASON_FROM_DATE]: (state, action) => {
      return { ...state, selectedSeasonFromDate: moment(action.payload.item) }
    },
    [SET_SEASON_TO_DATE]: (state, action) => {
      return { ...state, selectedSeasonToDate: moment(action.payload.item) }
    }
  },
  initialState
)

// Action creators
export const queryReleases = () => {
  return (dispatch, getState) => {
    var destinationId = getState().production.releases.selectedDestinationId
    var hotelId = getState().production.releases.selectedHotelId
    var fromDate = getState().production.releases.selectedFromDate
    var toDate = getState().production.releases.selectedToDate

    if (hotelId && fromDate && toDate) {
      api
        .query('hotel/release/releasesByHotel', { hotelId, fromDate, toDate })
        .then(json => dispatch(QUERY_RELEASES(json.data)))
    } else if (fromDate && toDate) {
      api
        .query('hotel/release/releasesByDestination', { destinationId, fromDate, toDate })
        .then(json => dispatch(QUERY_RELEASES(json.data)))
    }
  }
}

export const getCustomerList = (hotelId, fromDate, toDate) => {
  return (dispatch, getState) => {
    return api
      .query('hotel/release/customerList', { hotelId, fromDate, toDate })
      .then(json => dispatch(QUERY_CUSTOMER_LIST(json.data)))
  }
}

export const initiateRelease = release => {
  return dispatch => {
    return api.sendCommand('hotel/release/initiateRelease', release).then(() => {
      dispatch(upcomingReleasesActions.resetActiveUpcomingRelease())
      dispatch(upcomingReleasesActions.queryReleases())
      dispatch(globalMessageActions.setGlobalSuccess('Release initiated'))
    })
  }
}

export const initiateReleaseFromCancellation = release => {
  return dispatch => {
    return api.sendCommand('hotel/release/initiateReleaseFromCancellation', release).then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Release initiated'))
    })
  }
}

export const approveRelease = release => {
  return dispatch => {
    return api.sendCommand('hotel/release/approveRelease', release).then(() => {
      dispatch(resetActiveConfirmRelease())
      dispatch(queryReleases())
      dispatch(toggleAllReleases(false))
      dispatch(globalMessageActions.setGlobalSuccess('Release approved'))
    })
  }
}

export const approveReleases = releases => {
  return dispatch => {
    return api.sendCommand('hotel/release/approveReleases', { ids: releases }).then(() => {
      dispatch(resetActiveConfirmRelease())
      dispatch(queryReleases())
      dispatch(toggleAllReleases(false))
      dispatch(globalMessageActions.setGlobalSuccess('Releases approved'))
    })
  }
}

export const rejectReleases = releases => {
  return dispatch => {
    return api.sendCommand('hotel/release/rejectReleases', { ids: releases }).then(() => {
      dispatch(resetActiveConfirmRelease())
      dispatch(queryReleases())
      dispatch(toggleAllReleases(false))
      dispatch(globalMessageActions.setGlobalSuccess('Releases rejected'))
    })
  }
}

export const editActiveConfirmRelease = release => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_CONFIRM_RELEASE(release))
  }
}

export const resetActiveConfirmRelease = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_CONFIRM_RELEASE())
  }
}

export const setConfirmReleaseValue = (value, name) => {
  return dispatch => {
    dispatch(SET_CONFIRM_RELEASE_VALUE({ value, name }))
  }
}

export const setDestinationId = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_ID({ item }))
  }
}

export const setHotelId = item => {
  return dispatch => {
    dispatch(SET_HOTEL_ID({ item }))
  }
}

export const setFromDate = item => {
  return dispatch => {
    dispatch(SET_FROM_DATE({ item }))
  }
}

export const setToDate = item => {
  return dispatch => {
    dispatch(SET_TO_DATE({ item }))
  }
}

export const toggleSelectedReleases = item => {
  return dispatch => {
    dispatch(TOGGLE_SELECTED_RELEASES({ item }))
  }
}

export const toggleAllReleases = checked => {
  return dispatch => {
    dispatch(TOGGLE_ALL_RELEASES({ checked }))
  }
}

export const resetDates = () => {
  return dispatch => {
    dispatch(RESET_DATES())
  }
}

export const setSeasonFromDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_FROM_DATE({ item }))
  }
}

export const setSeasonToDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_TO_DATE({ item }))
  }
}
