import accounting from 'accounting'

const missing = '* MISSING *'

const Currency = (amount = 0, code = missing) => {
  code = !code ? missing : code

  return accounting.formatMoney(amount, {
    symbol: code === 'EUR' ? '€' : code.toUpperCase(),
    decimal: ',',
    precision: 2,
    thousand: ' ',
    format: code === 'EUR' ? '%v%s' : '%v %s'
  })
}

export default Currency
