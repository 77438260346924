import { createAction, handleActions } from 'redux-actions'
import moment from 'moment-timezone'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_PACKAGES = createAction('query market packages')
const QUERY_ARTICLE_TYPES = createAction('query market article types')
const SET_ACTIVE = createAction('set active market package')
const SET_ACTIVE_VALUE = createAction('set active market package value')

const initialState = {
  active: null,
  articleTypes: [],
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_PACKAGES]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return {
        ...state,
        active: { ...state.active, [action.payload.name]: action.payload.value }
      }
    },
    [QUERY_ARTICLE_TYPES]: (state, action) => {
      return { ...state, articleTypes: action.payload }
    }
  },
  initialState
)

// Action creators
export const queryPackages = marketId => {
  return dispatch => {
    api.query('market/package/packages', { marketId }).then(json => {
      let data = json.data
      data.forEach(pkge => {
        pkge.periods.forEach(p => {
          p.bookingDateFrom = moment.parseZone(p.bookingDateFrom).startOf('day')
          p.bookingDateTo = moment.parseZone(p.bookingDateTo).startOf('day')
          p.travelDateFrom = moment.parseZone(p.travelDateFrom).startOf('day')
          p.travelDateTo = moment.parseZone(p.travelDateTo).startOf('day')
        })

        pkge.articles.forEach(article => {
          article.discountType =
            article.discountPercentage && article.discountPercentage >= 0
              ? 'percentage'
              : 'currency'
        })
      })

      dispatch(QUERY_PACKAGES(json.data))
    })
  }
}

export const queryArticleTypes = () => {
  return dispatch => {
    api
      .query('market/package/articleTypes')
      .then(res => dispatch(QUERY_ARTICLE_TYPES(res.data)))
  }
}

export const submitCreatePackage = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/package/createPackage', command)
    cmd.then(() => {
      setTimeout(() => dispatch(queryPackages(command.marketId)), 100)
      dispatch(globalMessageActions.setGlobalSuccess('Package added'))
    })
    return cmd
  }
}

export const submitUpdatePackage = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/package/updatePackage', command)
    cmd.then(() => {
      dispatch(queryPackages(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Package updated'))
    })
    return cmd
  }
}

export const submitDeletePackage = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/package/deletePackage', command)
    cmd.then(() => {
      dispatch(queryPackages(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Package deleted'))
    })
    return cmd
  }
}

export const setActive = pkge => {
  return dispatch => {
    dispatch(SET_ACTIVE(pkge))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}
