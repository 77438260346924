import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const QUERY_LATEST_RESERVATIONS = createAction('query latest reservations')
const QUERY_LATEST_RESERVATIONS_SUCCESS = createAction('query latest reservations success')

const initialState = {
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_LATEST_RESERVATIONS]: (state, action) => {
      return { ...state, loading: true }
    },
    [QUERY_LATEST_RESERVATIONS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    }
  },
  initialState
)

// Action creators
export const queryLatestReservations = () => {
  return (dispatch, getState) => {
    dispatch(QUERY_LATEST_RESERVATIONS())
    return api.query('latestReservations').then(json => dispatch(QUERY_LATEST_RESERVATIONS_SUCCESS(json.data)))
  }
}
