import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const FETCH_DESTINATIONS = createAction('get destinations from server')
const FETCH_DESTINATIONS_SUCCESS = createAction('fetch destinations success')

const ADD_DESTINATION = createAction('add destination')
const ADD_DESTINATION_SUCCESS = createAction('add destination success')

const UPDATE_DESTINATION = createAction('update active destination')
const UPDATE_DESTINATION_SUCCESS = createAction('update destination success')

const RESET_ACTIVE_DESTINATION = createAction('cancel active hdestinationotel')
const UPDATE_ACTIVE_DESTINATION = createAction('update active destination')
const EDIT_ACTIVE_DESTINATION = createAction('edit active destination')

const SELECT_DESTINATION = createAction('select destination')

const initialState = {
  allDestinations: '<All>',
  selectedDestinationId: null,
  active: null,
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_DESTINATIONS]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [FETCH_DESTINATIONS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [ADD_DESTINATION]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [ADD_DESTINATION_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [UPDATE_DESTINATION]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [UPDATE_DESTINATION_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [EDIT_ACTIVE_DESTINATION]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_DESTINATION]: (state, action) => {
      return { ...state, active: null }
    },
    [UPDATE_ACTIVE_DESTINATION]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    },
    [SELECT_DESTINATION]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload }
    }
  },
  initialState
)

// Action creators
export const getDestinations = filterDisabled => {
  let queryParams = { filterDisabled: true }
  if (filterDisabled === false) {
    queryParams = { ...queryParams, filterDisabled: false }
  }

  return dispatch => {
    dispatch(FETCH_DESTINATIONS())
    let query = api.query('destination/destination/destinations', queryParams)
    query.then(json => dispatch(FETCH_DESTINATIONS_SUCCESS(json.data)))
    return query
  }
}

export const addDestination = destination => {
  const filterDisabled = false
  return dispatch => {
    dispatch(ADD_DESTINATION())
    return api
      .sendCommand('destination/destination/addDestination', destination)
      .then(() => {
        dispatch(ADD_DESTINATION_SUCCESS())
        dispatch(getDestinations(filterDisabled))
        dispatch(globalMessageActions.setGlobalSuccess('Destination added'))
      })
  }
}

export const updateDestination = destination => {
  const filterDisabled = false
  return (dispatch, getState) => {
    dispatch(UPDATE_DESTINATION())
    return api
      .sendCommand('destination/destination/updateDestination', destination)
      .then(() => {
        dispatch(UPDATE_DESTINATION_SUCCESS())
        dispatch(getDestinations(filterDisabled))
        dispatch(globalMessageActions.setGlobalSuccess('Destination updated'))
      })
  }
}

export const editActiveDestination = destination => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_DESTINATION(destination))
  }
}

export const resetActiveDestination = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_DESTINATION())
  }
}

export const updateActiveDestination = (value, name) => {
  return dispatch => {
    dispatch(UPDATE_ACTIVE_DESTINATION({ value, name }))
  }
}

export const selectDestination = selectedDestinationId => {
  return dispatch => {
    dispatch(SELECT_DESTINATION(selectedDestinationId))
  }
}
