const customers = [
  {
    firstName: 'Mikael',
    lastName: 'Egnér',
    email: 'mikael.egner@dotnetmentor.se',
    img: 'https://www.gravatar.com/avatar/89fa409841291104b3d9e10652661463',
    phone: '+46707493603',
    customerStatus: 'Returning customer'
  },
  {
    firstName: 'Per',
    lastName: 'Liedman',
    email: 'per.liedman@dotnetmentor.se',
    img: 'https://www.gravatar.com/avatar/da8b4072333da3794dedd74d966527d6',
    phone: '+46708318535',
    customerStatus: 'Returning customer'
  },
  {
    firstName: 'James',
    lastName: 'Kyburz',
    email: 'james.kyburz@dotnetmentor.se',
    img: 'https://www.gravatar.com/avatar/82cae1561ccc493b052c65c25ec7a6d9',
    phone: '+46708235555',
    customerStatus: 'Returning customer'
  },
  {
    firstName: 'Fredrik',
    lastName: 'Hansson',
    email: 'fredrik.hansson@dotnetmentor.se',
    img: 'https://www.gravatar.com/avatar/81c363d8cfba0807e1b2b053b661d6f0',
    customerStatus: 'Returning customer'
  },
  {
    firstName: 'Andreas',
    lastName: 'Larsson',
    email: 'andreas.larsson@dotnetmentor.se',
    img: 'https://www.gravatar.com/avatar/0271e6aa0d5d00e75e03ae4e86fe84a8',
    phone: '+46736740118',
    customerStatus: 'Special customer'
  },
  {
    firstName: 'Andreas',
    lastName: 'Larsson',
    email: 'kurt.olsson@gmail.com',
    phone: '+46734520593',
    customerStatus: 'Returning customer'
  }
]

export default customers
