import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_REPORT = createAction('query flight statistics list')

const SET_DEPARTURE_AIRPORT_ID = createAction('flight statistics list departure airport id')
const SET_ARRIVAL_AIRPORT_ID = createAction('flight statistics list arrival airport id')
const SET_DEPARTURE_FROM_DATE = createAction('flight statistics list departure from date')
const SET_DEPARTURE_TO_DATE = createAction('flight statistics list departure to date')
const SET_FLIGHT_NUMBER = createAction('flight statistics set flight number')
const SET_FLIGHT_CLASS = createAction('flight statistics set flight class')
const SET_SHOW_HOMEWARD_FLIGHTS = createAction('flight statistics show homeward flights')

const initialState = {
  showHomewardFlights: false,
  selectedDepartureAirportId: null,
  selectedArrivalAirportId: null,
  selectedDepartureFromDate: moment().startOf('day'),
  selectedDepartureToDate: moment()
    .add(3, 'weeks')
    .endOf('day'),
  selectedFlightNumber: null,
  selectedFlightClass: null,
  loading: false,
  item: {}
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, item: action.payload }
    },
    [SET_DEPARTURE_AIRPORT_ID]: (state, action) => {
      return { ...state, selectedDepartureAirportId: action.payload.item }
    },
    [SET_ARRIVAL_AIRPORT_ID]: (state, action) => {
      return { ...state, selectedArrivalAirportId: action.payload.item }
    },
    [SET_DEPARTURE_FROM_DATE]: (state, action) => {
      return { ...state, selectedDepartureFromDate: moment(action.payload.item) }
    },
    [SET_DEPARTURE_TO_DATE]: (state, action) => {
      return { ...state, selectedDepartureToDate: moment(action.payload.item) }
    },
    [SET_FLIGHT_NUMBER]: (state, action) => {
      return { ...state, selectedFlightNumber: action.payload.item }
    },
    [SET_FLIGHT_CLASS]: (state, action) => {
      return { ...state, selectedFlightClass: action.payload.item }
    },
    [SET_SHOW_HOMEWARD_FLIGHTS]: (state, action) => {
      return { ...state, showHomewardFlights: action.payload }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    var departureAirportId = getState().production.flightStatistics.selectedDepartureAirportId
    var arrivalAirportId = getState().production.flightStatistics.selectedArrivalAirportId
    var departureDateFrom = getState().production.flightStatistics.selectedDepartureFromDate
    var departureDateTo = getState().production.flightStatistics.selectedDepartureToDate
    var flightNumber = getState().production.flightStatistics.selectedFlightNumber

    if (departureAirportId && arrivalAirportId && departureDateFrom && departureDateTo) {
      api
        .query('flightdeparture/charter/flightStatistics', {
          departureAirportId,
          arrivalAirportId,
          departureDateFrom,
          departureDateTo,
          flightNumber
        })
        .then(json => dispatch(QUERY_REPORT(json.data)))
    }
  }
}

export const setDepartureAirportId = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_AIRPORT_ID({ item }))
  }
}

export const setArrivalAirportId = item => {
  return dispatch => {
    dispatch(SET_ARRIVAL_AIRPORT_ID({ item }))
  }
}

export const setDepartureFromDate = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_FROM_DATE({ item }))
  }
}

export const setDepatureToDate = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_TO_DATE({ item }))
  }
}

export const setFlightNumber = item => {
  return dispatch => {
    dispatch(SET_FLIGHT_NUMBER({ item }))
  }
}

export const setFlightClass = item => {
  return dispatch => {
    dispatch(SET_FLIGHT_NUMBER({ item }))
  }
}

export const setShowHomewardFlights = value => {
  return dispatch => {
    dispatch(SET_SHOW_HOMEWARD_FLIGHTS(value))
  }
}
