import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import moment from 'moment-timezone'

// Actions
const QUERY_REPORT = createAction('query excursion list')

const SET_DESTINATION_ID = createAction('excursion list destination id')
const SET_AIRPORT_ID = createAction('excursion list airport id')
const SET_DEPARTURE_DATE_FROM = createAction('excursion list set departure date from')
const SET_DEPARTURE_DATE_TO = createAction('excursion list set departure date to')
const SET_EXCURSION_ID = createAction('excursion list set excursion id')
const SET_MARKET_ID = createAction('excursion list set market id')

const initialState = {
  selectedDestinationId: null,
  selectedAirportId: null,
  selectedExcursionId: null,
  selectedMarketId: null,
  selectedExcursionDateFrom: moment().startOf('day'),
  selectedExcursionDateTo: moment().endOf('day'),
  loading: false,
  item: {}
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, item: action.payload }
    },
    [SET_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload.item }
    },
    [SET_AIRPORT_ID]: (state, action) => {
      return { ...state, selectedAirportId: action.payload.item }
    },
    [SET_MARKET_ID]: (state, action) => {
      return { ...state, selectedMarketId: action.payload.item }
    },
    [SET_DEPARTURE_DATE_FROM]: (state, action) => {
      return { ...state, selectedExcursionDateFrom: action.payload.item }
    },
    [SET_DEPARTURE_DATE_TO]: (state, action) => {
      return { ...state, selectedExcursionDateTo: action.payload.item }
    },
    [SET_EXCURSION_ID]: (state, action) => {
      return { ...state, selectedExcursionId: action.payload.item }
    }
  },
  initialState
)

// Action creators
export const queryReport = () => {
  return (dispatch, getState) => {
    var destinationId = getState().production.excursion.selectedDestinationId
    var fromDate = getState().production.excursion.selectedExcursionDateFrom
    var toDate = getState().production.excursion.selectedExcursionDateTo
    var airportId = getState().production.excursion.selectedAirportId
    var marketId = getState().production.excursion.selectedMarketId
    var excursionId = getState().production.excursion.selectedExcursionId

    if (destinationId && fromDate && toDate) {
      api
        .query('travelbooking/reports/excursionList', {
          destinationId,
          fromDate,
          toDate,
          airportId,
          marketId,
          excursionId
        })
        .then(json => dispatch(QUERY_REPORT(json.data)))
    }
  }
}

export const setDestinationId = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_ID({ item }))
  }
}

export const setExcursionId = item => {
  return dispatch => {
    dispatch(SET_EXCURSION_ID({ item }))
  }
}

export const setAirportId = item => {
  return dispatch => {
    dispatch(SET_AIRPORT_ID({ item }))
  }
}

export const setExcursionDateFrom = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_DATE_FROM({ item }))
  }
}

export const setExcursionDateTo = item => {
  return dispatch => {
    dispatch(SET_DEPARTURE_DATE_TO({ item }))
  }
}

export const setMarketId = item => {
  return dispatch => {
    dispatch(SET_MARKET_ID({ item }))
  }
}
