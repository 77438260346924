import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_CANCELLATION_FEES = createAction('query market cancellation fees')
const SET_ACTIVE = createAction('set active cancelltion fee')
const SET_ACTIVE_VALUE = createAction('set active cancelltion fee value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_CANCELLATION_FEES]: (state, action) => {
      action.payload.forEach(fee => {
        fee.cancellationFees.forEach(cf => {
          cf.unit = cf.hoursToTravel ? 'Hours' : 'Days'
        })
      })
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return { ...state, active: { ...state.active, [action.payload.name]: action.payload.value } }
    }
  },
  initialState
)

// Action creators
export const queryCancellationFees = marketId => {
  return dispatch => {
    api
      .query('market/cancellationFeeSetup/cancellationFeeSetups', { marketId })
      .then(json => dispatch(QUERY_CANCELLATION_FEES(json.data)))
  }
}

export const submitCreateCancellationFee = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/cancellationFeeSetup/createCancellationFeeSetup', command)
    cmd.then(() => {
      dispatch(queryCancellationFees(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Cancellation fee added'))
    })
    return cmd
  }
}

export const submitUpdateCancellationFee = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/cancellationFeeSetup/updateCancellationFeeSetup', command)
    cmd.then(() => {
      dispatch(queryCancellationFees(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Cancellation fee updated'))
    })
    return cmd
  }
}

export const submitDeleteCancellationFee = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/cancellationFeeSetup/deleteCancellationFeeSetup', command)
    cmd.then(() => {
      dispatch(queryCancellationFees(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Cancellation fee deleted'))
    })
    return cmd
  }
}

export const setActive = CancellationFee => {
  return dispatch => {
    dispatch(SET_ACTIVE(CancellationFee))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}
