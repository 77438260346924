import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import AuthService from '../../common/auth-service'
import * as globalMessageActions from '../global-message'

const SET_LOGIN_MODEL = createAction('set account login model')
const SET_RESET_MODEL = createAction('set account reset model')
const SET_PROFILE = createAction('set profile from jwt')

const RESET_PASSWORD = createAction('reset account password')
const RESET_PASSWORD_SUCCESS = createAction('reset account password successful')
const RESET_PASSWORD_ERROR = createAction('reset account password failed')

const LOGIN = createAction('login backoffice user')
const LOGIN_SUCCESS = createAction('login backoffice user successful')
const LOGIN_ERROR = createAction('login backoffice user error')

const LOGOUT = createAction('logout backoffice user')

const initalState = {
  loading: false,
  loginModel: { username: '', password: '' },
  resetModel: { ticketId: '', password: '', confirmPassword: '' },
  profile: AuthService.getProfile(),
  isAuthenticated: AuthService.isAuthenticated(),
  error: null
}

export default handleActions(
  {
    [SET_LOGIN_MODEL]: (state, action) => {
      let model = state.loginModel
      model[action.payload.name] = action.payload.model
      return { ...state, loginModel: { ...model } }
    },
    [SET_RESET_MODEL]: (state, action) => {
      let model = state.resetModel
      model[action.payload.name] = action.payload.model
      return { ...state, resetModel: { ...model } }
    },
    [SET_PROFILE]: (state, action) => {
      return { ...state, profile: action.payload }
    },
    [RESET_PASSWORD]: (state, action) => {
      return { ...state, loading: true }
    },
    [RESET_PASSWORD_SUCCESS]: (state, action) => {
      return { ...state, loading: false }
    },
    [RESET_PASSWORD_ERROR]: (state, action) => {
      return { ...state, loading: false }
    },
    [LOGIN]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [LOGIN_SUCCESS]: (state, action) => {
      return { ...state, loading: false, isAuthenticated: true }
    },
    [LOGIN_ERROR]: (state, action) => {
      let error = action.payload.error
        ? action.payload.error.message
        : action.payload
      return { ...state, loading: false, error: error }
    },
    [LOGOUT]: (state, action) => {
      return { ...state, isAuthenticated: false, profile: {} }
    }
  },
  initalState
)

export const setLoginModel = (name, model) => {
  return dispatch => {
    dispatch(SET_LOGIN_MODEL({ name, model }))
  }
}

export const setResetModel = (name, model) => {
  return dispatch => {
    dispatch(SET_RESET_MODEL({ name, model }))
  }
}

export const setPassword = command => {
  return dispatch => {
    let cmd = api.sendCommand('setPassword', command)
    cmd.then(() => {
      dispatch(globalMessageActions.setGlobalSuccess('Password changed'))
    })
    return cmd
  }
}

export const forgotPassword = email => {
  return dispatch => {
    let cmd = api.sendCommand(
      'security/backOfficeUser/setPasswordResetTicket',
      { email }
    )
    cmd.then(result => {
      dispatch(
        globalMessageActions.setGlobalMessage(
          'Instruction has been sent to your mail!'
        )
      )
    })
    return cmd
  }
}

export const resetPassword = resetModel => {
  return dispatch => {
    dispatch(RESET_PASSWORD())
    let command = {
      ticketId: resetModel.ticketId,
      newPassword: resetModel.password
    }
    let cmd = api.sendCommand('security/backOfficeUser/resetpassword', command)

    cmd
      .then(result => {
        dispatch(RESET_PASSWORD_SUCCESS())
        dispatch(
          globalMessageActions.setGlobalMessage(
            'Password set, redirecting to login page...'
          )
        )
      })
      .catch(err => {
        dispatch(RESET_PASSWORD_ERROR())
        dispatch(
          globalMessageActions.setGlobalError(
            'Could not reset password. Please try again'
          )
        )
      })

    return cmd
  }
}

export const login = loginModel => {
  return dispatch => {
    dispatch(LOGIN())
    AuthService.clearToken()
    let command = {
      userName: loginModel.username,
      password: loginModel.password
    }

    let cmd = api.login(command)

    cmd
      .then(result => {
        dispatch(LOGIN_SUCCESS())
        AuthService.setProfile()
      })
      .catch(err => {
        dispatch(LOGIN_ERROR(err))
        throw err
      })

    return cmd
  }
}

export const logout = () => {
  return dispatch => {
    AuthService.logout()
    dispatch(LOGOUT())
  }
}

export const setProfile = () => {
  return dispatch => {
    AuthService.setProfile()
    dispatch(SET_PROFILE())
  }
}
