const allotment = 'Allotment'
const warranty = 'Warranty'
const notAvailable = 'NotAvailable'

const labels = {
  allotment: 'Allotment',
  warranty: 'Warranty',
  notAvailable: 'Not available'
}

export const ContractTypeEnum = Object.freeze({
  allotment: allotment,
  warranty: warranty,
  notAvailable: notAvailable,

  labels: labels,

  format: contractType => {
    switch (contractType) {
      case notAvailable:
        return labels.notAvailable
      case allotment:
        return labels.allotment
      case warranty:
        return labels.warranty
      default:
        break
    }
  },

  toList: () => [
    { value: warranty, label: labels.warranty },
    { value: allotment, label: labels.allotment },
    { value: notAvailable, label: labels.notAvailable }
  ]
})

export default ContractTypeEnum
