import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const QUERY_REPORT = createAction('query excursions')

const initialState = {
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_REPORT]: (state, action) => {
      return { ...state, items: action.payload }
    }
  },
  initialState
)

// Action creators
export const queryExcursions = (addonType, destinationId, dateFrom, dateTo) => {
  return dispatch => {
    if (addonType) {
      api
        .query('destination/addoncontract/contracts', {
          addonType,
          destinationIds: [destinationId],
          dateFrom,
          dateTo
        })
        .then(json => dispatch(QUERY_REPORT(json.data)))
    }
  }
}
