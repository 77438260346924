import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const FETCH_SELLERS = createAction('get sellers')
const FETCH_SELLERS_SUCCESS = createAction('fetch sellers success')

const initialState = {
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [FETCH_SELLERS]: (state, action) => {
      return { ...state, loading: true }
    },
    [FETCH_SELLERS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    }
  },
  initialState
)

// Action creators
export const getSellers = () => {
  return dispatch => {
    dispatch(FETCH_SELLERS())
    let query = api.query('security/backofficeuser/BackOfficeUsers')
    query.then(json => {
      return dispatch(FETCH_SELLERS_SUCCESS(json.data))
    })
    return query
  }
}
