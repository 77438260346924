import { createAction, handleActions } from 'redux-actions'
import api from '../../api'
import * as globalMessageActions from '../../global-message'
import * as contractActions from './contract'

// Actions
const SET_MINIMUM_OF_NIGHTS = createAction('set contract minimum of nights')

const RESET_ACTIVE_MINIMUM_OF_NIGHTS = createAction('cancel active minimum of nights')
const SET_VALUE = createAction('set active minimum of nights value')
const SET_ACTIVE_MINIMUM_OF_NIGHTS = createAction('set active minimum of nights')

const initialState = {
  active: null,
  loading: false,
  items: [],
  minOfHotelNights: []
}

// Reducers/handlers
export default handleActions(
  {
    [SET_MINIMUM_OF_NIGHTS]: (state, action) => {
      return {
        ...state,
        loading: false,
        minOfHotelNights: action.payload.hotelPeriods,
        items: action.payload.roomCategoryPeriods
      }
    },
    [SET_ACTIVE_MINIMUM_OF_NIGHTS]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_MINIMUM_OF_NIGHTS]: (state, action) => {
      return { ...state, active: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.active
      active[action.payload.name] = action.payload.value
      return { ...state, active: active }
    }
  },
  initialState
)

// Action creators

export const setMinimumOfNights = items => {
  return dispatch => {
    dispatch(SET_MINIMUM_OF_NIGHTS(items))
  }
}

export const addMinimumOfNights = item => {
  if (!item.forRoomCategory) {
    return addHotelMinimumOfNights(item)
  }
  return dispatch => {
    return api.sendCommand('hotel/contract/addMinimumOfNights', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_MINIMUM_OF_NIGHTS())
      dispatch(globalMessageActions.setGlobalSuccess('Minimum of nights added'))
    })
  }
}

export const updateMinimumOfNights = item => {
  if (!item.forRoomCategory) {
    return updateHotelMinimumOfNights(item)
  }
  return dispatch => {
    return api.sendCommand('hotel/contract/updateMinimumOfNights', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_MINIMUM_OF_NIGHTS())
      dispatch(globalMessageActions.setGlobalSuccess('Minimum of nights updated'))
    })
  }
}

export const deleteMinimumOfNights = item => {
  if (!item.forRoomCategory) {
    return deleteHotelMinimumOfNights(item)
  }
  return dispatch => {
    return api
      .sendCommand('hotel/contract/deleteMinimumOfNights', { id: item.id, contractId: item.contractId })
      .then(() => {
        dispatch(contractActions.getHotelContract(item.contractId))
        dispatch(RESET_ACTIVE_MINIMUM_OF_NIGHTS())
        dispatch(globalMessageActions.setGlobalSuccess('Minimum of nights deleted'))
      })
  }
}

export const addHotelMinimumOfNights = item => {
  return dispatch => {
    return api.sendCommand('hotel/contract/addHotelMinimumOfNights', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_MINIMUM_OF_NIGHTS())
      dispatch(globalMessageActions.setGlobalSuccess('Minimum of nights added'))
    })
  }
}

export const updateHotelMinimumOfNights = item => {
  return dispatch => {
    return api.sendCommand('hotel/contract/updateHotelMinimumOfNights', item).then(() => {
      dispatch(contractActions.getHotelContract(item.contractId))
      dispatch(RESET_ACTIVE_MINIMUM_OF_NIGHTS())
      dispatch(globalMessageActions.setGlobalSuccess('Minimum of nights updated'))
    })
  }
}

export const deleteHotelMinimumOfNights = item => {
  return dispatch => {
    return api
      .sendCommand('hotel/contract/deleteHotelMinimumOfNights', { id: item.id, contractId: item.contractId })
      .then(() => {
        dispatch(contractActions.getHotelContract(item.contractId))
        dispatch(RESET_ACTIVE_MINIMUM_OF_NIGHTS())
        dispatch(globalMessageActions.setGlobalSuccess('Minimum of nights deleted'))
      })
  }
}

export const setActiveMinimumOfNights = item => {
  return dispatch => {
    dispatch(SET_ACTIVE_MINIMUM_OF_NIGHTS(item))
  }
}

export const resetActiveMinimumOfNights = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_MINIMUM_OF_NIGHTS())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}
