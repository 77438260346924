import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const SET_SEARCH = createAction('set gdpr search')
const SET_CUSTOMERS = createAction('set gdpr customers')
const SET_RESERVATIONS = createAction('set gdpr reservations')

const initialState = {
  search: '',
  customers: [],
  reservations: [],
  isValid: false
}

// Reducers/handlers
export default handleActions(
  {
    [SET_SEARCH]: (state, action) => {
      const nextState = {
        ...state,
        search: action.payload || ''
      }
      nextState.isValid = validate(nextState)
      return nextState
    },
    [SET_CUSTOMERS]: (state, action) => {
      const nextState = {
        ...state,
        customers: action.payload || []
      }
      nextState.isValid = validate(nextState)
      return nextState
    },
    [SET_RESERVATIONS]: (state, action) => {
      const nextState = {
        ...state,
        reservations: action.payload || []
      }
      nextState.isValid = validate(nextState)
      return nextState
    }
  },
  initialState
)

export const search = email => {
  return dispatch => {
    dispatch(SET_SEARCH(email))

    if (email.length < 3) {
      dispatch(SET_CUSTOMERS([]))
      dispatch(SET_RESERVATIONS([]))
      return
    }

    const customerQuery = api
      .query('crm/customer/customerbyemail', { email })
      .then(res => {
        dispatch(SET_CUSTOMERS(res.data ? [res.data] : []))
      })

    const reservationQuery = api
      .query('travelbooking/reservationsbypayingcustomeremail', { email })
      .then(res => {
        dispatch(SET_RESERVATIONS(res.data))
      })

    return Promise.all([customerQuery, reservationQuery])
  }
}

export const scrub = () => {
  return (dispatch, getState) => {
    const state = getState().booking.gdpr
    const customerCommands = state.customers.map(c =>
      api.sendCommand('crm/customer/scrubprivatedata', { customerId: c.id })
    )
    const reservationCommands = state.reservations.map(r =>
      api.sendCommand('travelbooking/scrubreservationprivatedata', {
        reservationId: r.id
      })
    )

    return Promise.all(customerCommands.concat(reservationCommands)).then(
      () => {
        console.log(state)
        return search(state.search)(dispatch)
      }
    )
  }
}

function validate (state) {
  return (
    (state.customers.length > 0 || state.reservations.length > 0) &&
    state.customers.reduce(
      (a, c) => (a === c.email ? a : false),
      state.search
    ) &&
    state.reservations.reduce(
      (a, r) => (a === r.payingCustomer.email ? a : false),
      state.search
    )
  )
}
