import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'
import moment from 'moment-timezone'
import { uniqueBy } from '../../common/group-by'

// Actions
const QUERY_PRICE_MANAGEMENT = createAction('query price management')

const SET_SELECTED_CELLS_FLAT = createAction('price management selected cells flat')
const RESET_SELECTED_CELLS_FLAT = createAction('price management reset selected cells flat')
const SET_SELECTED_CELLS = createAction('price management selected cells')
const SET_DESTINATION_ID = createAction('price management destination id')
const SET_HOTEL_ID = createAction('price management hotel id')
const SET_ROOM_CATEGORY_IDS = createAction('price management room category ids')
const SET_NO_OF_STARS = createAction('price management no of stars')
const SET_FROM_DATE = createAction('price management from date')
const SET_TO_DATE = createAction('price management to date')

const SET_FROM_PERIOD = createAction('price management from period')
const SET_TO_PERIOD = createAction('price management to period')

const RESET_ACTIVE_PRICE = createAction('cancel active price')
const SET_VALUE = createAction('set contribution margin value')
const EDIT_ACTIVE_PRICE = createAction('edit active price')

const RESET_ACTIVE_ORIGINAL_PRICE = createAction('cancel active original price')
const SET_ORIGINAL_VALUE = createAction('set contribution original price value')
const EDIT_ACTIVE_ORIGINAL_PRICE = createAction('edit active original price')

const RESET_ACTIVE_ALLOTMENT = createAction('cancel active allotment')
const UPDATE_ACTIVE_ALLOTMENT = createAction('update active allotment')
const EDIT_ACTIVE_ALLOTMENT = createAction('edit active allotment')

const RESET_ACTIVE_ALLOTMENT_QUANTITY = createAction('cancel active allotment quantity')
const UPDATE_ACTIVE_ALLOTMENT_QUANTITY = createAction('update active allotment quantity')
const EDIT_ACTIVE_ALLOTMENT_QUANTITY = createAction('edit active allotment quantity')

const ALLOTMENT_MAIL_TEXT_COPIED = createAction('allotment mail text copied')

const RESET_DATES = createAction('active price reset dates')
const SET_SEASON_FROM_DATE = createAction('active price season from date')
const SET_SEASON_TO_DATE = createAction('active price season to date')

const UPDATE_QUANTITY = createAction('update quantity')
const UPDATE_QUANTITY_SUCCESS = createAction('update quantity success')

const COPY_CONTRIBUTION = createAction('copy contribution')
const COPY_CONTRIBUTION_SUCCESS = createAction('copy contribution success')


const initialState = {
  // selectedCells contains the positions of the selected cells in the grid
  // state = {
  //   selectedCells: [{x: 1, y: 2}]
  // }
  selectedCells: [],
  selectedCellsFlat: [[]],
  activePrice: null,
  activeAllotment: null,
  activeAllotments: null,
  selectedDestinationId: null,
  selectedHotelId: null,
  selectedRoomCategoryIds: [],
  selectedNoOfStars: [],
  selectedFromDate: null,
  selectedFromPeriod: null,
  selectedToPeriod: null,
  selectedToDate: null,
  selectedSeasonFromDate: moment(),
  selectedSeasonToDate: moment().add(3, 'weeks'),
  roomCategories: [],
  loading: false,
  items: [],
  copyContributionLoading: false
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_PRICE_MANAGEMENT]: (state, action) => {
      if (!action.payload) return { ...state, items: [], roomCategories: [] }

      var roomCategories = action.payload.map(p => {
        return {
          roomCategoryId: p.roomCategoryId,
          roomCategoryDescription: p.roomCategoryDescription,
          hotelId: p.hotelId,
          hotelName: p.hotelName,
          imageUrls: p.imageUrls,
          stars: p.stars
        }
      })

      var groupedRoomCategories = []
      if (state.selectedHotelId) {
        groupedRoomCategories = uniqueBy(roomCategories, 'roomCategoryId')
      } else {
        groupedRoomCategories = uniqueBy(roomCategories, 'hotelId', 'roomCategoryId')
      }

      return { ...state, items: action.payload, roomCategories: groupedRoomCategories }
    },
    [EDIT_ACTIVE_PRICE]: (state, action) => {
      return { ...state, activePrice: action.payload }
    },
    [RESET_ACTIVE_PRICE]: (state, action) => {
      return { ...state, activePrice: null }
    },
    [SET_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.activePrice
      active[action.payload.name] = action.payload.value
      return { ...state, activePrice: active }
    },
    [EDIT_ACTIVE_ORIGINAL_PRICE]: (state, action) => {
      return { ...state, activeOriginalPrice: action.payload }
    },
    [RESET_ACTIVE_ORIGINAL_PRICE]: (state, action) => {
      return { ...state, activeOriginalPrice: null }
    },
    [SET_ORIGINAL_VALUE]: (state, action) => {
      if (!action.payload) return { ...state }
      let active = state.activeOriginalPrice
      active[action.payload.name] = action.payload.value
      return { ...state, activeOriginalPrice: active }
    },
    [EDIT_ACTIVE_ALLOTMENT]: (state, action) => {
      return { ...state, activeAllotments: action.payload }
    },
    [RESET_ACTIVE_ALLOTMENT]: (state, action) => {
      return { ...state, activeAllotments: null }
    },
    [UPDATE_ACTIVE_ALLOTMENT]: (state, action) => {
      if (!action.payload) return { ...state }
      let activeAllotments = state.activeAllotments

      if (action.payload.name === 'mailText') {
        activeAllotments.mailText = action.payload.value
        return { ...state, activeAllotments: activeAllotments }
      }

      let active = activeAllotments.selectedAllotments.find(
        a =>
          a.id === action.payload.allotment.id &&
          a.charterPeriodId === action.payload.allotment.charterPeriodId &&
          a.roomCategoryId === action.payload.allotment.roomCategoryId
      )

      active[action.payload.name] = action.payload.value
      return { ...state, activeAllotments: activeAllotments }
    },
    [EDIT_ACTIVE_ALLOTMENT_QUANTITY]: (state, action) => {
      return { ...state, activeAllotment: action.payload }
    },
    [RESET_ACTIVE_ALLOTMENT_QUANTITY]: (state, action) => {
      return { ...state, activeAllotment: null }
    },
    [UPDATE_ACTIVE_ALLOTMENT_QUANTITY]: (state, action) => {
      if (!action.payload) return { ...state }
      return { ...state, activeAllotment: action.payload }
    },
    [ALLOTMENT_MAIL_TEXT_COPIED]: (state, action) => {
      let activeAllotments = state.activeAllotments
      activeAllotments.allotmentMailTextCopied = true
      return { ...state, activeAllotments: activeAllotments }
    },
    [SET_SELECTED_CELLS_FLAT]: (state, action) => {
      return { ...state, selectedCellsFlat: action.payload.cells }
    },
    [RESET_SELECTED_CELLS_FLAT]: (state, action) => {
      let cells = state.selectedCellsFlat

      cells.forEach((cellRow, x) => {
        cells[x].forEach((cellColumn, y) => {
          cells[x][y] = false
        })
      })

      return { ...state, selectedCellsFlat: cells }
    },
    [SET_SELECTED_CELLS]: (state, action) => {
      return { ...state, selectedCells: action.payload.selectedCells }
    },
    [SET_DESTINATION_ID]: (state, action) => {
      return { ...state, selectedDestinationId: action.payload.item }
    },
    [SET_HOTEL_ID]: (state, action) => {
      return { ...state, selectedHotelId: action.payload.item }
    },
    [SET_ROOM_CATEGORY_IDS]: (state, action) => {
      return { ...state, selectedRoomCategoryIds: action.payload.items }
    },
    [SET_NO_OF_STARS]: (state, action) => {
      return { ...state, selectedNoOfStars: action.payload.item }
    },
    [SET_FROM_DATE]: (state, action) => {
      return { ...state, selectedFromDate: moment(action.payload.item) }
    },
    [SET_TO_DATE]: (state, action) => {
      return { ...state, selectedToDate: moment(action.payload.item) }
    },
    [SET_FROM_PERIOD]: (state, action) => {
      return { ...state, selectedFromPeriod: action.payload.item }
    },
    [SET_TO_PERIOD]: (state, action) => {
      return { ...state, selectedToPeriod: action.payload.item }
    },
    [RESET_DATES]: (state, action) => {
      return { ...state, selectedToDate: initialState.selectedToDate, selectedFromDate: initialState.selectedFromDate }
    },
    [SET_SEASON_FROM_DATE]: (state, action) => {
      return { ...state, selectedSeasonFromDate: moment(action.payload.item) }
    },
    [SET_SEASON_TO_DATE]: (state, action) => {
      return { ...state, selectedSeasonToDate: moment(action.payload.item) }
    },
    [UPDATE_QUANTITY]: state => {
      return { ...state, loading: true }
    },
    [UPDATE_QUANTITY_SUCCESS]: state => {
      return { ...state, loading: false }
    },
    [COPY_CONTRIBUTION]: state => {
      return { ...state, copyContributionloading: true }
    },
    [COPY_CONTRIBUTION_SUCCESS]: state => {
      return { ...state, copyContributionloading: false }
    }
  },
  initialState
)

// Action creators
export const queryPrices = () => {
  return (dispatch, getState) => {
    // if (
    //   !getState().production.priceManagementRoomCategories.selectedHotelId &&
    //   !getState().production.priceManagementRoomCategories.selectedRoomCategoryIds
    // ) {
    //   dispatch(QUERY_PRICE_MANAGEMENT(''))
    //   return
    // }

    var destinationId = getState().production.priceManagementRoomCategories.selectedDestinationId
    var noOfStars = getState().production.priceManagementRoomCategories.selectedNoOfStars
    var hotelId = getState().production.priceManagementRoomCategories.selectedHotelId
    var roomCategoryIds = getState().production.priceManagementRoomCategories.selectedRoomCategoryIds
    var fromDate = getState().production.priceManagementRoomCategories.selectedFromDate
    var toDate = getState().production.priceManagementRoomCategories.selectedToDate

    let query = 'hotel/CharterPeriod/ProductionRoomCategories'
    let queryParams = { hotelId, destinationId, roomCategoryIds, noOfStars, fromDate, toDate }

    if (!queryParams) return {}
    return api.query(query, queryParams).then(json => {
      dispatch(QUERY_PRICE_MANAGEMENT(json.data))
      return { destinationId, hotelId, fromDate, toDate }
    })
  }
}

export const setContributionMargin = amount => {
  return dispatch => {
    return api.sendCommand('hotel/charterPeriod/setRoomCategoryContributionMargin', amount).then(() => {
      dispatch(resetActivePrice())
      dispatch(queryPrices())
      dispatch(setSelectedCells([]))
      dispatch(globalMessageActions.setGlobalSuccess('Contribution margin set'))
    })
  }
}

export const stopSales = items => {
  return dispatch => {
    return api.sendCommand('hotel/charterPeriod/stopSalesForRoomCategory', { charterPeriods: items }).then(() => {
      dispatch(queryPrices())
      dispatch(setSelectedCells([]))
      dispatch(globalMessageActions.setGlobalSuccess('Sales stopped'))
    })
  }
}

export const startSales = items => {
  return dispatch => {
    return api.sendCommand('hotel/charterPeriod/startSalesForRoomCategory', { charterPeriods: items }).then(() => {
      dispatch(queryPrices())
      dispatch(setSelectedCells([]))
      dispatch(globalMessageActions.setGlobalSuccess('Sales started'))
    })
  }
}

export const updateQuantity = allotments => {
  return dispatch => {
    dispatch(UPDATE_QUANTITY())

    for (let i = 0; i < allotments.length; i++) {
      const allotment = allotments[i];
      if (allotment.contractType === 'Allotment') {
        allotment.quantityChange = allotment.quantity - allotment.availableAllotmentRooms
      } else {
        allotment.quantityChange = allotment.quantity - allotment.availableWarrantyRooms
      }
    }

    const payload = {
      updates: allotments
    }

    return api.sendCommand('hotel/charterPeriod/updateQuantities', payload).then(() => {
      dispatch(resetActiveAllotmentQuantity())
      dispatch(queryPrices())
      dispatch(setSelectedCells([]))
      dispatch(UPDATE_QUANTITY_SUCCESS())
      dispatch(globalMessageActions.setGlobalSuccess('Quantity updated'))
    })
  }
}

export const editActivePrice = price => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_PRICE(price))
  }
}

export const resetActivePrice = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_PRICE())
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const editActiveOriginalPrice = price => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_ORIGINAL_PRICE(price))
  }
}

export const resetOriginalActivePrice = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_ORIGINAL_PRICE())
  }
}

export const setOriginalValue = (value, name) => {
  return dispatch => {
    dispatch(SET_ORIGINAL_VALUE({ value, name }))
  }
}

export const editActiveAllotments = allotment => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_ALLOTMENT(allotment))
  }
}

export const resetActiveAllotments = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_ALLOTMENT())
  }
}

export const updateActiveAllotments = (value, name, allotment) => {
  return dispatch => {
    dispatch(UPDATE_ACTIVE_ALLOTMENT({ value, name, allotment }))
  }
}

export const editActiveAllotmentQuantity = allotment => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_ALLOTMENT_QUANTITY(allotment))
  }
}

export const resetActiveAllotmentQuantity = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_ALLOTMENT_QUANTITY())
  }
}

export const updateActiveAllotmentQuantity = (allotments) => {
  return dispatch => {
    dispatch(UPDATE_ACTIVE_ALLOTMENT_QUANTITY(allotments))
  }
}

export const allotmentMailTextCopied = () => {
  return dispatch => {
    dispatch(ALLOTMENT_MAIL_TEXT_COPIED({}))
  }
}

export const setDestinationId = item => {
  return dispatch => {
    dispatch(SET_DESTINATION_ID({ item }))
  }
}

export const setHotelId = item => {
  return dispatch => {
    dispatch(SET_HOTEL_ID({ item }))
  }
}

export const setRoomCategoryIds = items => {
  return dispatch => {
    dispatch(SET_ROOM_CATEGORY_IDS({ items }))
  }
}

export const setNoOfStars = item => {
  return dispatch => {
    dispatch(SET_NO_OF_STARS({ item }))
  }
}

export const setFromDate = item => {
  return dispatch => {
    dispatch(SET_FROM_DATE({ item }))
  }
}

export const setToDate = item => {
  return dispatch => {
    dispatch(SET_TO_DATE({ item }))
  }
}

export const setFromPeriod = item => {
  return dispatch => {
    dispatch(SET_FROM_PERIOD({ item }))
  }
}

export const setToPeriod = item => {
  return dispatch => {
    dispatch(SET_TO_PERIOD({ item }))
  }
}

export const reset = () => {
  return dispatch => {
    dispatch(QUERY_PRICE_MANAGEMENT(''))
  }
}

export const setSelectedCells = cells => {
  return dispatch => {
    if (cells.length !== 0) {
      dispatch(SET_SELECTED_CELLS_FLAT({ cells }))
    } else {
      dispatch(RESET_SELECTED_CELLS_FLAT())
    }

    let selectedCells = []
    cells.forEach((rows, x) => {
      return rows.forEach((cell, y) => {
        if (y === 0 || x === 0) {
          dispatch(SET_SELECTED_CELLS({ selectedCells }))
        }
        if (cell) {
          selectedCells.push({ x: x, y: y })
        }
      })
    })
    dispatch(SET_SELECTED_CELLS({ selectedCells }))
  }
}

export const resetDates = () => {
  return dispatch => {
    dispatch(RESET_DATES())
  }
}

export const setSeasonFromDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_FROM_DATE({ item }))
  }
}

export const setSeasonToDate = item => {
  return dispatch => {
    dispatch(SET_SEASON_TO_DATE({ item }))
  }
}

export const copyContribution = () => {
  return (dispatch, getState) => {
    var destinationId = getState().production.priceManagementRoomCategories.selectedDestinationId
    var noOfStars = getState().production.priceManagementRoomCategories.selectedNoOfStars
    var hotelId = getState().production.priceManagementRoomCategories.selectedHotelId
    var roomCategoryIds = getState().production.priceManagementRoomCategories.selectedRoomCategoryIds
    var fromDate = getState().production.priceManagementRoomCategories.selectedFromDate
    var toDate = getState().production.priceManagementRoomCategories.selectedToDate
    dispatch(COPY_CONTRIBUTION())
    return api.sendCommand('hotel/charterPeriod/copyContributionMargin',
      {
        hotelId,
        destinationId,
        roomCategoryIds,
        noOfStars,
        fromDate,
        toDate
      }
      ).then(() => {
      dispatch(COPY_CONTRIBUTION_SUCCESS())
      dispatch(globalMessageActions.setGlobalSuccess('Contribution successfully copied'))
    })
  }
}

