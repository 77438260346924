import { createAction, handleActions } from 'redux-actions'

const SET_TITLE = createAction('set page title')
const SET_SUBMENU = createAction('set submenu')

const initialState = {
  title: 'STS Backoffice',
  submenu: null,
  closableTabs: false,
  closeFallbackUrl: null,
  showMoreMenu: false
}

export default handleActions(
  {
    [SET_TITLE]: (state, action) => {
      return { ...state, title: action.payload }
    },
    [SET_SUBMENU]: (state, action) => {
      return {
        ...state,
        submenu: action.payload.routes,
        closableTabs: action.payload.closable,
        closeFallbackUrl: action.payload.closeFallbackUrl,
        showMoreMenu: action.payload.showMoreMenu
      }
    }
  },
  initialState
)

export const setTitle = title => {
  return dispatch => {
    document.title = title
    dispatch(SET_TITLE(title))
  }
}

export const setSubmenu = (routes, closable = false, closeFallbackUrl = '/', showMoreMenu = false) => {
  return dispatch => {
    dispatch(SET_SUBMENU({ routes, closable, closeFallbackUrl, showMoreMenu }))
  }
}
