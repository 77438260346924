import { combineReducers } from 'redux'
import upload from './upload'
import bankgiro from './bankgiro'
import creditcard from './creditcard'
import bookkeeping from './bookkeeping'
import balanceAccounts from './balance-accounts'
import incomes from './incomes'
import accountsReceivables from './accounts-receivables'
import agentCommissions from './agent-commissions'
import travelInsurances from './travel-insurances'
import cancellationInsurances from './cancellation-insurances';
import unprocessedPayments from './unprocessed-payments'
import costForReservations from './cost-for-reservations'

export default combineReducers({
  upload,
  bankgiro,
  creditcard,
  bookkeeping,
  balanceAccounts,
  incomes,
  accountsReceivables,
  agentCommissions,
  travelInsurances,
  cancellationInsurances,
  unprocessedPayments,
  costForReservations
})
