import moment from 'moment-timezone'

export const groupBy = (list, prop1, prop2) => {
  if (list.length === 0) return []
  if (list.length === 1) return list

  return list.reduce((acc, cp) => {
    const groupIndicator = `${cp[prop1]}-${cp[prop2]}`
    const group = acc.find(g => g.groupIndicator === groupIndicator)
    if (!group) {
      acc.push({groupIndicator: groupIndicator, items: [cp]})
    }
    else {
      group.items.push(cp)
    }

    return acc
  }, [])
}

export const uniqueBy = (list, prop1, prop2) => {
  if (list.length === 0) return []
  if (list.length === 1) return list

  const reducedList = list.reduce((acc, cp) => {
    acc = acc.constructor === Array ? acc : [acc]
    var groupedItem = acc.find(a => a[prop1] === cp[prop1] && a[prop2] === cp[prop2])
    if (!groupedItem) {
      acc.push(cp)
    }
    return acc
  })
  return reducedList
}

export const uniqueByTwoDates = (list, date1, date2) => {
  if (list.length === 0) return []
  if (list.length === 1) return list

  let reducedList = list.reduce((acc, cp) => {
    acc = acc.constructor === Array ? acc : [acc]
    var groupedItem = acc.find(
      a => moment(a[date1]).isSame(moment(cp[date1])) && moment(a[date2]).isSame(moment(cp[date2]))
    )
    if (!groupedItem) {
      acc.push(cp)
    }
    return acc
  })
  return reducedList
}
