import { mapOriginSeatsData } from '../mappers'

export function getSeatsOriginsSeries (originAirportsByWeeks, airportId, date) {
  return [
    mapOriginSeatsData(
      'Seats left',
      '#bbdefb',
      'seatsLeft',
      originAirportsByWeeks,
      airportId,
      date
    ),
    mapOriginSeatsData(
      'Booked seats',
      '#2196f3',
      'seatsBooked',
      originAirportsByWeeks,
      airportId,
      date
    )
  ]
}
