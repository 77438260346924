import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as reservationActions from './reservations'

// actions
const QUERY_CANCELLATION_INSURANCE = createAction('query booking cancellation insurance')
const QUERY_CANCELLATION_INSURANCE_SUCCESS = createAction('query booking cancellation insurance success')
const SET_CANCELLATION_INSURANCE = createAction('set booking cancellation insurance')
const SET_CHANGED = createAction('set booking cancellation changed')

const initialState = {
  error: null,
  loading: false,
  items: []
}

// handlers
export default handleActions(
  {
    [QUERY_CANCELLATION_INSURANCE]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [QUERY_CANCELLATION_INSURANCE_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_CHANGED]: state => {
      return {
        ...state,
        items: state.items.map(traveler => {
          return { ...traveler, cancellationInsurance: { ...traveler.cancellationInsurance, changed: false } }
        })
      }
    },
    [SET_CANCELLATION_INSURANCE]: (state, action) => {
      return {
        ...state,
        items: state.items.map((traveler, i) =>
          traveler.id === action.payload.travelerId
            ? {
              ...traveler,
              cancellationInsurance: {
                ...traveler.cancellationInsurance,
                reserved: action.payload.reserved,
                changed: true
              }
            }
            : traveler
        )
      }
    }
  },
  initialState
)

// thunks
export const queryCancellationInsurance = reservationId => {
  return dispatch => {
    api
      .query('travelbooking/cancellationInsurance', { reservationId, salesChannel: 'BackOffice' })
      .then(res => dispatch(QUERY_CANCELLATION_INSURANCE_SUCCESS(res.data)))
  }
}

export const submitAddCancellationInsurance = command => {
  return dispatch => {
    dispatch(SET_CANCELLATION_INSURANCE({ ...command, reserved: true }))
    let cmd = api
      .sendCommand('travelBooking/addCancellationInsurance', command)
      .then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
    return cmd
  }
}

export const submitRemoveCancellationInsurance = command => {
  return dispatch => {
    dispatch(SET_CANCELLATION_INSURANCE({ ...command, reserved: false }))
    let cmd = api
      .sendCommand('travelBooking/removeCancellationInsurance', command)
      .then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
    return cmd
  }
}

export const setChanged = () => {
  return dispatch => {
    dispatch(SET_CHANGED())
  }
}
