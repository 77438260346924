import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as reservationActions from './reservations'

// actions
const QUERY_ADDONS = createAction('query booking destination addons')
const QUERY_ADDONS_SUCCESS = createAction('query booking destination success')
const SET_ADDON = createAction('set booking destination addon')

const initialState = {
  error: null,
  loading: false,
  items: []
}

// handlers
export default handleActions(
  {
    [QUERY_ADDONS]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [QUERY_ADDONS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_ADDON]: (state, action) => {
      return {
        ...state,
        items: state.items.map((addon, i) =>
          i === action.payload.addonIndex
            ? {
              ...addon,
              travelers: addon.travelers.map((traveler, j) =>
                traveler.id === action.payload.travelerId
                  ? {
                    ...traveler,
                    articles: traveler.articles.map((article, k) =>
                      article.articleId === action.payload.articleId
                        ? { ...article, reserved: action.payload.reserved }
                        : article
                    )
                  }
                  : traveler
              )
            }
            : addon
        )
      }
    }
  },
  initialState
)

// thunks
export const queryAddons = reservationId => {
  return dispatch => {
    api
      .query('travelbooking/destinationArticles', { reservationId })
      .then(res => dispatch(QUERY_ADDONS_SUCCESS(res.data)))
  }
}

export const submitAddAddon = command => {
  return dispatch => {
    dispatch(SET_ADDON({ ...command, reserved: true }))
    let cmd = api
      .sendCommand('travelBooking/addDestinationArticle', command)
      .then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
      .catch(() => dispatch(SET_ADDON({ ...command, reserved: false })))
    return cmd
  }
}

export const submitRemoveAddon = command => {
  return dispatch => {
    dispatch(SET_ADDON({ ...command, reserved: false }))
    let cmd = api
      .sendCommand('travelBooking/removeDestinationArticle', command)
      .then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
    return cmd
  }
}
