import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as globalMessageActions from '../global-message'

// Actions
const QUERY_CANCELLATION_INSURANCES = createAction('query market cancellation insurances')
const SET_ACTIVE = createAction('set active cancelltion insurance')
const SET_ACTIVE_VALUE = createAction('set active cancelltion insurance value')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_CANCELLATION_INSURANCES]: (state, action) => {
      return { ...state, items: action.payload }
    },
    [SET_ACTIVE]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [SET_ACTIVE_VALUE]: (state, action) => {
      return { ...state, active: { ...state.active, [action.payload.name]: action.payload.value } }
    }
  },
  initialState
)

// Action creators
export const queryCancellationInsurances = marketId => {
  return dispatch => {
    api
      .query('market/cancellationInsurance/cancellationInsurances', { marketId })
      .then(json => dispatch(QUERY_CANCELLATION_INSURANCES(json.data)))
  }
}

export const submitCreateCancellationInsurance = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/cancellationInsurance/createCancellationInsurance', command)
    cmd.then(() => {
      dispatch(queryCancellationInsurances(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Cancellation insurance added'))
    })
    return cmd
  }
}

export const submitUpdateCancellationInsurance = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/cancellationInsurance/updateCancellationInsurance', command)
    cmd.then(() => {
      dispatch(queryCancellationInsurances(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Cancellation insurance updated'))
    })
    return cmd
  }
}

export const submitDeleteCancellationInsurance = command => {
  return dispatch => {
    let cmd = api.sendCommand('market/cancellationInsurance/deleteCancellationInsurance', command)
    cmd.then(() => {
      dispatch(queryCancellationInsurances(command.marketId))
      dispatch(globalMessageActions.setGlobalSuccess('Cancellation insurance deleted'))
    })
    return cmd
  }
}

export const setActive = cancellationInsurance => {
  return dispatch => {
    dispatch(SET_ACTIVE(cancellationInsurance))
  }
}

export const setActiveValue = (name, value) => {
  return dispatch => {
    dispatch(SET_ACTIVE_VALUE({ name, value }))
  }
}
