import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const QUERY_SEARCH_RESERVATIONS = createAction('query search reservations')
const QUERY_SEARCH_RESERVATIONS_SUCCESS = createAction('query search reservations success')
const CLEAR_SEARCH_RESERVATIONS = createAction('clear search reservations')

const initialState = {
  loading: false,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_SEARCH_RESERVATIONS]: (state, action) => {
      return { ...state, loading: true }
    },
    [QUERY_SEARCH_RESERVATIONS_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload.reservations }
    },
    [CLEAR_SEARCH_RESERVATIONS]: (state, action) => {
      return { ...initialState }
    }
  },
  initialState
)

// Action creators
export const queryReservations = searchTerm => {
  return dispatch => {
    dispatch(QUERY_SEARCH_RESERVATIONS())
    return api
      .query('travelbooking/backoffice/searchreservation', { searchText: searchTerm })
      .then(json => dispatch(QUERY_SEARCH_RESERVATIONS_SUCCESS(json.data)))
  }
}

export const clearReservations = () => {
  return dispatch => {
    dispatch(CLEAR_SEARCH_RESERVATIONS())
  }
}
