import { createAction, handleActions } from 'redux-actions'
import api from '../api'
import * as reservationActions from './reservations'

// actions
const QUERY_FULL_REFUND_INSURANCE = createAction('query booking full refund insurance')
const QUERY_FULL_REFUND_INSURANCE_SUCCESS = createAction('query booking full refund insurance success')
const SET_FULL_REFUND_INSURANCE = createAction('set booking full refund insurance')
const SET_CHANGED = createAction('set booking full refund changed')

const initialState = {
  error: null,
  loading: false,
  items: []
}

// handlers
export default handleActions(
  {
    [QUERY_FULL_REFUND_INSURANCE]: (state, action) => {
      return { ...state, loading: true, error: null }
    },
    [QUERY_FULL_REFUND_INSURANCE_SUCCESS]: (state, action) => {
      return { ...state, loading: false, items: action.payload }
    },
    [SET_CHANGED]: state => {
      return {
        ...state,
        items: state.items.map(traveler => {
          return { ...traveler, fullRefundInsurance: { ...traveler.fullRefundInsurance, changed: false } }
        })
      }
    },
    [SET_FULL_REFUND_INSURANCE]: (state, action) => {
      return {
        ...state,
        items: state.items.map((traveler, i) =>
          traveler.id === action.payload.travelerId
            ? {
              ...traveler,
              fullRefundInsurance: {
                ...traveler.fullRefundInsurance,
                reserved: action.payload.reserved,
                changed: true
              }
            }
            : traveler
        )
      }
    }
  },
  initialState
)

// thunks
export const queryFullRefundInsurance = reservationId => {
  return dispatch => {
    api
      .query('travelbooking/fullRefundInsurance', { reservationId, salesChannel: 'BackOffice' })
      .then(res => dispatch(QUERY_FULL_REFUND_INSURANCE_SUCCESS(res.data)))
  }
}

export const submitAddFullRefundInsurance = command => {
  return dispatch => {
    dispatch(SET_FULL_REFUND_INSURANCE({ ...command, reserved: true }))
    let cmd = api
      .sendCommand('travelBooking/addFullRefundInsurance', command)
      .then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
    return cmd
  }
}

export const submitRemoveFullRefundInsurance = command => {
  return dispatch => {
    dispatch(SET_FULL_REFUND_INSURANCE({ ...command, reserved: false }))
    let cmd = api
      .sendCommand('travelBooking/removeFullRefundInsurance', command)
      .then(() => dispatch(reservationActions.queryReservation(command.reservationId)))
    return cmd
  }
}

export const setChanged = () => {
  return dispatch => {
    dispatch(SET_CHANGED())
  }
}
