import { createAction, handleActions } from 'redux-actions'
import api from '../api'

// Actions
const QUERY_EVENTS = createAction('query events')
const EDIT_ACTIVE_EVENT = createAction('edit active event')
const RESET_ACTIVE_EVENT = createAction('reset active event')

const initialState = {
  active: null,
  items: []
}

// Reducers/handlers
export default handleActions(
  {
    [QUERY_EVENTS]: (state, action) => {
      return { ...state, items: action.payload.events }
    },
    [EDIT_ACTIVE_EVENT]: (state, action) => {
      return { ...state, active: action.payload }
    },
    [RESET_ACTIVE_EVENT]: (state, action) => {
      return { ...state, active: null }
    }
  },
  initialState
)

// Action creators
export const queryEvents = aggregateRootId => {
  return (dispatch, getState) => {
    api
      .query('travelBooking/backoffice/events', {
        AggregateRootId: aggregateRootId
      })
      .then(json => dispatch(QUERY_EVENTS(json.data)))
  }
}

export const editActiveEvent = item => {
  return dispatch => {
    dispatch(EDIT_ACTIVE_EVENT(item))
  }
}

export const resetActiveEvent = () => {
  return dispatch => {
    dispatch(RESET_ACTIVE_EVENT())
  }
}
